<template>
  <div class="h-full w-full md:mt-24" v-if="vehicle">
    <div class="flex flex-col md:flex-row">
      <div class="w-full mb-2 md:mx-4 md:w-3/6 lg:w-2/6">
        <img
          src="@/assets/vehicle-placeholder-1.svg"
          class="transition w-full rounded-xl shadow-lg m-auto hover:shadow-xl sm:w-1/2 md:w-full"
          alt="vehicleImage"
        />
        <div class="mt-2.5">
          <div
            v-if="
              (isNew || vehicle.status === 'Requested') &&
              vehicle.vin &&
              vehicle.vin.length >= 17
            "
          >
            <button
              @click="lookupVin"
              class="w-full bg-lula-gradient text-white py-1 hover:bg-lula-gradient-alt text-sm mb-1"
            >
              Lookup VIN
            </button>
          </div>
        </div>
      </div>
      <div class="transition bg-white rounded-xl p-4 shadow-lg w-full">
        <div class="pb-4">
          <label class="font-bold">Vehicle Nickname</label>
          <br />
          <input v-model="vehicle.key" />
        </div>
        <form @submit.prevent="saveVehicle">
          <details
            v-for="section in getSectionsFromDetails(fields)"
            :key="section"
            open
          >
            <summary
              @click="sectionStatus[section] = !sectionStatus[section]"
              class="flex bg-gray-200 border-lula justify-between items-center list-none cursor-pointer text-xl text-gray-800 p-2 rounded-lg mb-1"
            >
              {{ section }}
              <span
                class="font-bold text-base rounded-full px-8 text-white"
                :class="{
                  'bg-lula': vehicle.status === 'Active',
                  'bg-lula-alt': vehicle.status !== 'Active',
                }"
                v-if="section === 'Vehicle'"
              >
                {{ statusMessage }}
              </span>
            </summary>
            <div
              class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mb-2 pb-2"
            >
              <div
                v-for="field in getDetailsForSection(fields, section)"
                :key="field.key"
                class="m-1 mb-2"
                :class="{
                  'col-span-3':
                    field.key === 'comments' ||
                    (field.key === 'financeCompanyApplicable' &&
                      !vehicle.financeCompanyApplicable),
                }"
              >
                <label class="font-bold" v-if="field.key !== 'comments'">
                  {{ field.label }}
                </label>
                <br class="font-bold" v-if="field.key !== 'comments'" />
                <vue-google-autocomplete
                  :id="field.key"
                  :class="{
                    'border border-red-500': missingAckFields.includes(
                      isAddressField(field),
                    ),
                    hidden: !(
                      isAddressField(field) && !vehicle[isAddressField(field)]
                    ),
                  }"
                  ref="address"
                  placeholder=""
                  country="us"
                  @blur="getAddressInputChange"
                  @placechanged="getAddressDataRegistration"
                  :required="
                    isAddressField(field) && !vehicle[isAddressField(field)]
                  "
                />
                <state-select
                  v-if="isStateField(field)"
                  v-model="vehicle[field.key]"
                  :states="vehicleRegistrationStates"
                  :class="{
                    'border border-red-500': missingAckFields.includes(
                      field.key,
                    ),
                  }"
                  :disabled="!fieldIsEditable(field)"
                  :required="field.required"
                />
                <input
                  type="text"
                  minlength="17"
                  maxlength="17"
                  required
                  :disabled="!fieldIsEditable(field)"
                  v-model="vehicle[field.key]"
                  v-else-if="field.key === 'vin'"
                />
                <textarea
                  rows="3"
                  placeholder="Additional information about this vehicle"
                  v-model="vehicle.comments"
                  v-else-if="field.key === 'comments'"
                />
                <select
                  v-else-if="field.key === 'financeCompanyApplicable'"
                  v-model="vehicle[field.key]"
                  :disabled="!fieldIsEditable(field)"
                >
                  <option :value="false">Vehicle is not Financed</option>
                  <option :value="true">Vehicle is Financed</option>
                </select>
                <select
                  v-else-if="field.key === 'registrantType'"
                  v-model="vehicle[field.key]"
                  :disabled="!fieldIsEditable(field)"
                  required
                >
                  <option>Business</option>
                  <option>Person</option>
                </select>
                <select
                  v-else-if="field.key === 'registrantGovernmentIdType'"
                  v-model="vehicle[field.key]"
                  @change="backfillNames"
                  :disabled="!fieldIsEditable(field)"
                  required
                >
                  <option>FEIN</option>
                  <option>DriverLicense</option>
                </select>
                <input
                  v-model="vehicle[field.key]"
                  :disabled="!fieldIsEditable(field)"
                  :placeholder="field.placeholder || ''"
                  minlength="5"
                  maxlength="5"
                  :class="{
                    'border border-red-500': missingAckFields.includes(
                      field.key,
                    ),
                  }"
                  :required="fieldIsEditable(field) && field.required"
                  v-else-if="isZipcodeField(field)"
                />
                <input
                  type="date"
                  v-model="vehicle[field.key]"
                  :disabled="!fieldIsEditable(field)"
                  :class="{
                    'border border-red-500': missingAckFields.includes(
                      field.key,
                    ),
                  }"
                  :required="fieldIsEditable(field) && field.required"
                  v-else-if="isDateField(field)"
                  :min="field.min"
                  :max="field.max"
                />
                <input
                  v-model="vehicle[field.key]"
                  :disabled="!fieldIsEditable(field)"
                  :placeholder="field.placeholder || ''"
                  :class="{
                    'border border-red-500': missingAckFields.includes(
                      field.key,
                    ),
                    hidden:
                      isAddressField(field) && !vehicle[isAddressField(field)],
                  }"
                  :required="fieldIsEditable(field) && field.required"
                  v-else
                />
              </div>
            </div>
            <div
              class="w-full flex justify-end -mt-6 mb-2 cursor-pointer hover:underline"
              v-if="section === 'Registration'"
            >
              <span
                @click="addRegistrationInfo"
                class="font-bold text-gray-600"
              >
                Use company information
              </span>
            </div>
          </details>
          <div
            class="text-sm mt-4 flex flex-col pt-4 justify-between items-center lg:flex-row lg:text-base"
          >
            <div
              class="hidden p-4 lg:w-full"
              v-if="
                $store.state.vehicleAckFleet &&
                vehicle.insuranceCriteriaStatus === ''
              "
            >
              <div class="ml-0 flex items-center text-gray-600 w-full lg:ml-4">
                <input
                  type="checkbox"
                  class="w-min"
                  id="renew-option"
                  name="renew-option"
                  checked
                />
                <label class="mx-4 w-full text-lg" for="renew-option"
                  >Renew vehicle?</label
                >
              </div>
            </div>
            <div
              class="flex flex-col md:flex-row mr-0 w-full lg:mr-4 lg:justify-between"
              v-if="
                account.tenantId !== 'carsync' ||
                getLaunchDarklyFlag('cutoff-carsync')
              "
            >
              <div>
                <div class="group relative">
                  <button
                    @click.prevent="removeCoverageProcess"
                    v-if="vehicle.status != 'Inactive'"
                    :disabled="activeVehicleCount <= minFleet"
                    class="w-full bg-gray-100 hover:bg-gray-200 m-1 lg:w-max"
                  >
                    Deactivate
                  </button>
                  <span
                    v-if="activeVehicleCount <= minFleet"
                    class="pointer-events-none absolute -top-8 left-0 w-max opacity-0 transition-opacity group-hover:opacity-100 group-hover:bg-gray-200 rounded-lg p-1"
                  >
                    Must have more than {{ minFleet }} vehicles to deactivate
                  </span>
                </div>
                <div class="group relative">
                  <button
                    v-if="vehicle.status != 'Active'"
                    :disabled="daysRemaining > 0"
                    @click="resumeCoverageProcess"
                    class="m-1 w-full lg:w-max bg-lula-gradient hover:bg-lula-gradient-alt text-white"
                  >
                    Activate
                  </button>
                  <span
                    v-if="daysRemaining > 0"
                    class="pointer-events-none absolute -top-8 left-0 w-max opacity-0 transition-opacity group-hover:opacity-100 group-hover:bg-gray-200 rounded-lg p-1"
                  >
                    Can be reactivated in {{ daysRemaining }} days
                  </span>
                </div>
              </div>
              <div>
                <button
                  type="button"
                  @click="$router.go(-1)"
                  class="w-full bg-gray-100 hover:bg-gray-200 m-1 lg:w-max"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  :disabled="isSaving"
                  class="m-1 w-full lg:w-max"
                  :class="{
                    'bg-lula-gradient hover:bg-lula-gradient-alt text-white':
                      !isSaving,
                    'bg-gray-300 text-gray-600 cursor-default': isSaving,
                  }"
                >
                  <span v-if="$store.state.vehicleAckFleet"
                    >Save and Close</span
                  >
                  <span v-else>Save</span>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>

    <!-- off-rent mileage -->
    <div class="mt-8" v-if="offRent.isFeatureEnabled">
      <h2 class="text-2xl font-bold text-gray-800 mb-4 md:text-4xl">
        Turo Off-Rent Mileage
      </h2>
      <div
        class="flex md:flex-row flex-col justify-between items-center bg-white p-4 rounded-xl shadow-lg"
      >
        <div class="w-full md:w-3/4 flex flex-col md:mr-4">
          <div class="h-1/2 flex flex-row">
            <div class="w-1/2 mr-4">
              <div class="text-base font-bold text-gray-800 text-center mb-1">
                Start Date
              </div>
              <date-time-picker
                class="w-full rounded-lg text-gray-700 hover:border text-lg"
                :input-class-name="`bg-gray-100 border-none`"
                :enable-time-picker="false"
                :hide-input-icon="false"
                format="MM-dd-yyyy"
                v-model="offRent.startDate"
                readonly
              />
            </div>
            <div class="w-1/2">
              <div class="text-base font-bold text-gray-800 text-center mb-1">
                End Date
              </div>
              <date-time-picker
                class="w-full rounded-lg text-gray-700 hover:border text-lg"
                :input-class-name="`bg-gray-100 border-none`"
                :enable-time-picker="false"
                :hide-input-icon="false"
                format="MM-dd-yyyy"
                v-model="offRent.endDate"
                readonly
              />
            </div>
          </div>
          <div class="h-1/2 text-sm text-gray-800 md:text-base mt-4 ml-1">
            <span class="font-bold">Note:</span> Only Bouncie and Zubie
            telematics services are currently supported. Dates are based off
            Eastern Time Zone. Mileage displayed is not real-time and is delayed
            for at least 24 hours.
          </div>
        </div>
        <div
          class="w-full md:w-1/4 flex flex-col rounded-xl shadow border md:mt-1 m-1 mt-4 relative"
        >
          <div class="h-1/2 mt-4">
            <div class="text-center font-bold">Miles Used:</div>
            <div class="text-center mt-1">
              {{ getMilesMessage(offRent, offRent.milesUsed) }}
            </div>
          </div>
          <div class="h-1/2 my-4">
            <div class="text-center font-bold">Miles Left:</div>
            <div
              class="text-center mt-1"
              :class="{ 'text-red-500': offRent.isReachedLimit }"
            >
              {{ getMilesMessage(offRent, offRent.milesLeft) }}
            </div>
          </div>
          <div
            v-tooltip="{
              content:
                'Off-Rent Mileage calculated using data from</br>telematics devices and Turo reservations.</br>Support for GetAround will be coming soon!',
              html: true,
            }"
            class="absolute top-2 right-2"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="
                  M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1
                  1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113
                  8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2
                  0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0
                  100-2 1 1 0 000 2z"
                clip-rule="evenodd"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>

    <!-- vehicle documents -->
    <div class="mt-8" v-if="!isNew">
      <h2 class="text-2xl font-bold text-gray-800 mb-4 md:text-4xl">
        Vehicle Documents
      </h2>
      <div>
        <div
          v-for="doc in vehicleDocs"
          :key="doc.fullPath"
          class="flex justify-between items-center flex-col bg-white p-4 my-2 rounded-xl shadow-lg md:text-lg md:flex-row"
        >
          <div class="w-full">
            <span class="font-bold text-gray-800">{{ doc.name }}</span>
            <br />
            <span class="text-gray-600">{{
              getDocumentTagName(doc.customMetadata?.tag)
            }}</span>
          </div>
          <button
            class="w-full mt-2 text-sm bg-gray-100 hover:bg-gray-200 md:text-base md:mt-0 md:w-56"
            @click.prevent="downloadVehicleDocument(doc)"
          >
            Download File
          </button>
        </div>
        <form
          class="bg-white p-4 rounded-xl shadow-lg"
          @submit.prevent="uploadVehicleDocument"
        >
          <span class="text-2xl font-bold text-gray-800">Upload Document</span>
          <hr class="my-2" />
          <div class="mb-2">
            <label class="font-bold text-gray-800">Document Type</label>
            <br />
            <select v-model="docTag">
              <option value="driver-license">Driver License</option>
              <option value="vehicle-registration">Vehicle Registration</option>
              <option value="proof-of-purchase">Proof of Purchase</option>
              <option value="lease-agreement">Lease Agreement</option>
              <option value="other">Other</option>
            </select>
          </div>
          <file-pond
            ref="pond"
            accepted-file-types="application/pdf, image/jpeg, image/jpg, image/png"
            max-file-size="50MB"
            @updatefiles="updateFileQueue"
            :allowMultiple="false"
            :allow-file-size-validation="true"
            :required="true"
          />
          <button
            v-if="queuedFile"
            class="w-full bg-gray-100 rounded-lg text-gray-600 font-bold hover:bg-gray-200"
            type="submit"
          >
            Upload Document
          </button>
        </form>
      </div>
    </div>

    <!-- activation modal -->
    <modal class="z-20" v-if="showActivationModal">
      <div class="rounded-lg bg-white p-10 w-4/5 md:w-2/3 relative">
        <h1 class="text-center mb-8 font-bold text-3xl">
          Your vehicle can't be activated at this time.
        </h1>
        <p class="text-center text-gray-800 text-lg mx-auto">
          Unfortunately, this vehicle was recently removed from our policy, due
          to this we can’t allow this vehicle to be activated for 45 days. There
          are {{ daysRemaining }} days left before this can be added.
        </p>
        <div class="flex flex-row mt-6 justify-center align-center">
          <button
            type="button"
            @click="showActivationModal = false"
            class="w-full md:w-1/2 text-gray-700 rounded-[60px] bg-gray-200 hover:bg-gray-300"
          >
            Close
          </button>
        </div>
      </div>
    </modal>

    <!-- deactivation modal -->
    <modal class="z-20" v-if="showDeactivationModal">
      <div
        class="rounded-lg bg-white w-3/5 md:w-1/2 relative max-h-full overflow-auto"
      >
        <div
          class="flex justify-between items-center mb-8 px-10 pt-10 pb-5 border-b-2"
        >
          <h1 class="font-bold text-3xl">Deactivate Vehicle</h1>
          <span
            class="icon is-right cursor-pointer"
            @click="showDeactivationModal = false"
          >
            <i class="fa fa-x"></i>
          </span>
        </div>
        <div class="px-10 pb-10 pt-5 space-y-5">
          <p>
            Are you sure you want to deactivate
            <strong>{{
              `${vehicle.year} ${vehicle.make} ${vehicle.model} (VIN: ${vehicle.vin})`
            }}</strong
            >?
          </p>
          <div class="pt-8 space-y-5">
            <li>The coverage will end tomorrow: {{ tomorrow }}</li>
            <li>It cannot be reactivated for 45 days: {{ fortyFiveDays }}</li>
            <li>
              You will have
              <strong
                >{{ subscriptionSpots - activeVehicleCount + 1 }} open
                subscription spot</strong
              >
              that is available until your next billing date of the
              {{ accountBillingDate }}
            </li>
            <li>
              A refund will not be issued.
              <a
                href="https://www.getaddify.com/terms-and-conditions"
                target="_blank"
                ><strong>Terms and Conditions can be found here</strong></a
              >
            </li>
            <li>
              Deactivating (or removing) this vehicle will also remove any
              insurance coverage provided by GetAddify's insurance partners
              effective at 12:01am the following day. GetAddify or its partners
              are not liable for any incidents, damages, losses, lapses or fines
              once the vehicle is deactivated.
            </li>
          </div>
          <div>
            <p>
              GetAddify's Continuous Coverage Program is designed for rental
              fleets with five or more vehicles. Accounts with less than 5
              vehicles may be reviewed for continued eligibility prior to their
              renewal. You may still proceed with deactivating your vehicle at
              this time.
            </p>
          </div>
          <div>
            <p>Reason for deactivating this vehicle</p>
            <select
              required
              v-model="deactivationReason"
              @change="deactivationReason = event.target.value"
            >
              <option value="">Select a reason</option>
              <option value="Total Loss">Total Loss</option>
              <option value="Sold the vehicle">Sold the vehicle</option>
              <option value="No longer renting out">
                No longer renting out
              </option>
              <option value="Moving to another account">
                Moving to another account
              </option>
            </select>
          </div>
          <div v-if="deactivationReason != 'Moving to another account'">
            <p>
              To confirm deactivation of this vehicle, type "Deactivate" below
            </p>
            <input
              placeholder='Type "Deactivate"'
              v-model="deactivate"
              @change="deactivate = event.target.value"
            />
          </div>
          <div v-else class="rounded-lg p-3 bg-green-100 text-green-500">
            In order to move this vehicle to another account, please contact
            support at support@getaddify.com
          </div>
          <div class="flex flex-col md:flex-row mt-6 justify-end align-center">
            <button
              type="button"
              @click="showDeactivationModal = false"
              class="w-full lg:w-max text-gray-700 rounded-lg border hover:bg-gray-300"
            >
              Cancel
            </button>
            <button
              type="button"
              :disabled="
                !deactivationReason ||
                deactivationReason == 'Moving to another account' ||
                deactivate != 'Deactivate'
              "
              @click="
                submitDeactivation();
                showDeactivationModal = false;
              "
              class="w-full lg:w-max text-gray-700 rounded-lg border text-center"
              :class="{
                'hover:bg-gray-300': !(
                  !deactivationReason ||
                  deactivationReason == 'Moving to another account' ||
                  deactivate != 'Deactivate'
                ),
              }"
            >
              Deactivate
            </button>
          </div>
        </div>
      </div>
    </modal>

    <!-- deactivation confirmation modal -->
    <modal class="z-20" v-if="showDeactivationConfirmationModal">
      <div
        class="rounded-lg bg-white w-4/5 md:w-1/2 lg:w-1/3 relative max-h-full overflow-auto"
      >
        <div
          class="flex justify-between items-center mb-8 px-10 pt-10 pb-5 border-b-2"
        >
          <h1 class="font-bold text-3xl">Deactivation Successful</h1>
          <span
            class="icon is-right cursor-pointer"
            @click="showDeactivationConfirmationModal = false"
          >
            <i class="fa fa-x"></i>
          </span>
        </div>
        <div class="px-10 pb-10 pt-5 space-y-5">
          <p>
            You've successfully deactivated
            <strong>{{
              `${vehicle.year} ${vehicle.make} ${vehicle.model}`
            }}</strong
            >?
          </p>
          <div class="space-y-5">
            <li class="flex">
              You now have 1 open subscription spot. Feel free to
              <div
                @click="
                  addVehicle();
                  showDeactivationConfirmationModal = false;
                "
                class="underline cursor-pointer pl-1"
              >
                <strong>add a new vehicle</strong>
              </div>
            </li>
            <li>
              This vehicle can be reactivated in 45 days: {{ fortyFiveDays }}
            </li>
          </div>
          <div class="text-gray-400 ml-6">
            GetAddify does not issue refunds for deactivated vehicles. You can
            see more information
            <a href="google.com" class="underline">here</a>.
          </div>
          <div class="flex flex-row mt-6 justify-end align-center space-x-5">
            <button
              type="button"
              @click="showDeactivationConfirmationModal = false"
              class="bg-lula-gradient hover:bg-lula-gradient-alt text-white"
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { useToast } from "vue-toastification";
import { ref, computed, watch, onMounted } from "vue";
import { detect } from "detect-browser";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import axios from "axios";
import moment from "moment";
import { vehicleRecentlyDeactivated } from "../utils";
import { applyTransformations } from "../vehicleDetails";
const { isValid } = require("usdl-regex");
import { getBillingDate } from "@/utils";
import { requestHeaders } from "../auth";

export default {
  components: {
    VueGoogleAutocomplete,
  },
  setup() {
    const toast = useToast();
    const route = useRoute();
    const router = useRouter();
    const store = useStore();

    const account = computed(() => store.state.account);

    const vehicle = ref(
      {
        ...store.state.vehicles.find(
          ({ entityId }) => entityId === route.params.id,
        ),
      } || {},
    );
    const display5VehicleWarning =
      moment(account.value.created).isAfter("2023-07-01T00:00:00.000Z") &&
      !account.value.isCarSharing;
    const tomorrow = moment().add(1, "d").format("MMMM DD, YYYY");
    const fortyFiveDays = moment().add(45, "d").format("MMMM DD, YYYY");
    const minFleet = computed(() => {
      if (account.value.isCarSharing) {
        return 1;
      } else {
        return 2;
      }
    });

    const isNew = computed(() => route.params.id === "new");
    const isSaving = ref(false);

    const monthStartDateUtc = moment().utc().startOf("month");
    const currentDateUtc = moment().utc();

    const offRent = ref({
      isFeatureEnabled: false,
      startDate: monthStartDateUtc.toString(),
      endDate: currentDateUtc.toString(),
      milesUsed: 0,
      milesLeft: 0,
      isRegistered: false,
      isReachedLimit: false,
      isInitialized: false,
    });

    const pond = ref(null);
    const queuedFile = ref(null);
    const vehicleDocs = ref([]);
    const docTag = ref("lease-agreement");
    const newAddVehicleFlowFlag = ref(false);
    const statusMessage = ref("");
    const daysRemaining = ref(0);
    const showDeactivationModal = ref(false);
    const showDeactivationConfirmationModal = ref(false);
    const deactivationReason = ref("");
    const deactivate = ref("");
    const showActivationModal = ref(false);
    const cutoffCarsync = ref(false);

    const exists = computed(() => {
      return store.state.vehicles.find(({ vin }) => vehicle.value.vin === vin);
    });
    const activeVehicleCount = computed(() => {
      return store.state.vehicles.filter((v) => v.status === "Active").length;
    });

    async function addVehicle() {
      store.dispatch("logEvent", { name: "add_vehicle_clicked" });
      if (await store.dispatch("getLaunchDarklyFlag", "add-vehicle-flow")) {
        if (
          await store.dispatch("getLaunchDarklyFlag", "cavf-new-declined-flow")
        ) {
          router.push({
            name: `${account.value.type}/CustomerActivatesVehicleFlow`,
          });
        } else {
          router.push({ name: `${account.value.type}/NewVehicle` });
        }
      } else {
        router.push({
          name: `${account.value.type}/Vehicle`,
          params: { id: "new" },
        });
      }
    }

    async function refreshDocuments() {
      vehicleDocs.value = await store.dispatch("getVehicleDocuments", {
        accountId: account.value.id,
        vehicleId: vehicle.value.documentId,
      });
    }

    async function initializeOffRentInfo() {
      offRent.value.isFeatureEnabled = await store.dispatch(
        "getLaunchDarklyFlag",
        "customer-off-rent-mileage",
      );
      offRent.value.isFeatureEnabled =
        offRent.value.isFeatureEnabled || account.value.isCarSharing;

      if (!vehicle.value.vin || !offRent.value.isFeatureEnabled) {
        return;
      }

      const offRentDateTimeFormat = "YYYY-MM-DDTHH:mm:ss[Z]";
      const offRentMilesLimit = 300;

      const offRentRequest = {
        vin: vehicle.value.vin,
        startDate: monthStartDateUtc.format(offRentDateTimeFormat).toString(),
        endDate: currentDateUtc.format(offRentDateTimeFormat).toString(),
        tenantId: account.value.entityId,
      };

      const offRentResponse = await store.dispatch(
        "getOffRentMileage",
        offRentRequest,
      );

      if (offRentResponse.success) {
        offRent.value.milesUsed = offRentResponse.data.mileage.toFixed(1);
        const milesDiff = offRentMilesLimit - offRent.value.milesUsed;
        offRent.value.milesLeft = Math.max(0, milesDiff).toFixed(1);
        offRent.value.isReachedLimit = offRent.value.milesLeft <= 0;
        offRent.value.isRegistered = true;
        offRent.value.isInitialized = true;
      } else if (offRentResponse.statusCode === 404) {
        offRent.value.isReachedLimit = false;
        offRent.value.isRegistered = false;
        offRent.value.isInitialized = true;
      } else {
        console.error(
          "[VEH] Cannot get the off-rent mileage info. Error: ",
          offRentResponse.error,
        );
      }
    }

    onMounted(refreshDocuments);
    onMounted(initializeOffRentInfo);

    onMounted(async () => {
      cutoffCarsync.value = await store.dispatch(
        "getLaunchDarklyFlag",
        "cutoff-carsync",
      );
      if (vehicle.value.status && route.params.id) {
        statusMessage.value = vehicle.value.status;
        const carsync =
          store.state.account.tenantId === "carsync" && !cutoffCarsync.value;
        newAddVehicleFlowFlag.value =
          (await store.dispatch("getLaunchDarklyFlag", "add-vehicle-flow")) &&
          !carsync;
        vehicle.value.financeCompanyApplicable =
          typeof vehicle.value.financeCompanyApplicable === "boolean"
            ? vehicle.value.financeCompanyApplicable
            : false;
        vehicle.value.statedValue = vehicle.value.statedValue || 0;
        if (vehicle.value) {
          vehicle.value.effectiveDate = vehicle.value.effectiveDate
            ? moment.utc(vehicle.value.effectiveDate).format("yyyy-MM-DD")
            : "";
        }
        if (vehicle.value.lotAddressCity) {
          vehicle.value.lotCity = vehicle.value.lotAddressCity;
        }
        if (vehicle.value.lotAddressZipcode) {
          vehicle.value.lotZipcode = vehicle.value.lotAddressZipcode;
        }
        if (vehicle.value.lotAddressState) {
          vehicle.value.lotState = vehicle.value.lotAddressState;
        }
        if (
          vehicle.value.insuranceCriteriaStatus == "Declined" &&
          vehicle.value.status == "Inactive"
        ) {
          statusMessage.value = "Declined";
        }
        if (
          vehicle.value.insuranceCriteriaStatus == "Declined" &&
          vehicle.value.status == "Requested"
        ) {
          statusMessage.value = "Requested";
        }
        vehicle.value.statusHistory?.forEach((s) => {
          if (s.status == "Active" && s.end == null) {
            let now = new Date();
            let coverageBeginning = moment.utc(s.start);
            if (now < coverageBeginning.toDate()) {
              statusMessage.value =
                "Pending Coverage " + coverageBeginning.format("MM/DD/yyyy");
            } else {
              statusMessage.value = "Covered";
            }
          }
        });
      } else {
        statusMessage.value = "Not found";
      }
      backfillNames();
    });

    watch(vehicle, () => {
      if (!vehicle.value) {
        return;
      }
      vehicle.value.effectiveDate = vehicle.value.effectiveDate
        ? moment.utc(vehicle.value.effectiveDate).format("yyyy-MM-DD")
        : "";
    });

    function backfillNames() {
      // If the registration type is a person, then we may need to split up the registration name into first/middle/last.
      if (
        vehicle.value.registrantType === "Person" &&
        !vehicle.value.registrantFirstName
      ) {
        const names = (vehicle.value.registrationName || "").split(" ");
        if (names.length === 3) {
          vehicle.value.registrantFirstName = names[0];
          vehicle.value.registrantMiddleName = names[1];
          vehicle.value.registrantLastName = names[2];
        } else if (names.length === 2) {
          vehicle.value.registrantFirstName = names[0];
          vehicle.value.registrantLastName = names[1];
        } else {
          // Don't guess how to parse the name otherwise.
          vehicle.value.registrantFirstName = vehicle.value.registrationName;
        }
      }
    }

    return {
      account,
      backfillNames,
      newAddVehicleFlowFlag,
      isNew,
      isSaving,
      vehicle,
      pond,
      queuedFile,
      docTag,
      statusMessage,
      daysRemaining,
      showActivationModal,
      showDeactivationModal,
      showDeactivationConfirmationModal,
      deactivationReason,
      deactivate,
      display5VehicleWarning,
      tomorrow,
      fortyFiveDays,
      minFleet,
      activeVehicleCount,
      offRent,
      addVehicle,
      getMilesMessage(offRentInfo, targetValue) {
        if (!offRentInfo.isInitialized) {
          return "Loading...";
        } else if (!offRentInfo.isRegistered) {
          return "N/A";
        } else {
          return targetValue;
        }
      },
      updateFileQueue() {
        const file = pond.value.getFiles().pop();
        queuedFile.value = file;
      },
      async downloadVehicleDocument(doc) {
        store.dispatch("logEvent", { name: "download_vehicle_document" });
        const url = await store.dispatch("getFileDownloadUrl", doc.fullPath);
        const a = document.createElement("a");
        a.href = url;
        a.target = "_blank";
        a.download = doc.name;
        document.body.appendChild(a);
        a.click();
        a.remove();
      },
      async uploadVehicleDocument() {
        const vehicleDocument = await store.dispatch("saveVehicleDocument", {
          accountId: account.value.id,
          vehicleId: vehicle.value.documentId,
          file: queuedFile.value.file,
          meta: { tag: docTag.value === "other" ? "" : docTag.value },
        });
        store.dispatch("addVehicleChangelog", {
          vehicleEntityId: vehicle.value.entityId,
          entityId: vehicleDocument.ref.fullPath,
          eventType: "vehicle-document-upload",
          fieldName: "name",
          newValue: queuedFile.value.file.name,
          oldValue: null,
        });
        store.dispatch("addVehicleChangelog", {
          vehicleEntityId: vehicle.value.entityId,
          entityId: vehicleDocument.ref.fullPath,
          eventType: "vehicle-document-meta",
          fieldName: "tag",
          newValue: docTag.value === "other" ? null : docTag.value,
          oldValue: null,
        });
        pond.value.removeFiles();
        refreshDocuments();
      },
      accountBillingDate: computed(() => {
        return getBillingDate(account);
      }),
      subscriptionSpots: computed(() => account.value.spots),
      async submitDeactivation() {
        try {
          const headers = await requestHeaders();
          await fetch(process.env.VUE_APP_DEACTIVATION_URL, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              ...headers,
            },
            credentials: "include",
            body: JSON.stringify({
              vehicleId: vehicle.value.entityId,
              reasonField: "statusReason",
              reasonValue: deactivationReason.value,
            }),
          });
          showDeactivationConfirmationModal.value = true;
          await store.dispatch("getVehicles");
          vehicle.value =
            {
              ...store.state.vehicles.find(
                ({ entityId }) => entityId === route.params.id,
              ),
            } || {};
          daysRemaining.value = 45;
          statusMessage.value = vehicle.value.status;
        } catch (err) {
          console.error(err);
        }
      },
      getDocumentTagName(tag) {
        switch (tag) {
          case "lease-agreement": {
            return "Lease Agreement";
          }
          case "driver-license": {
            return "Driver License";
          }
          case "vehicle-registration": {
            return "Vehicle registration";
          }
          case "certificate-of-insurance": {
            return "Certificate of Insurance";
          }
          case "power-of-attorney": {
            return "Power of Attorney";
          }
          case "insurance-card": {
            return "Insurance Card";
          }
          case "registration": {
            return "Registration";
          }
          case "proof-prior-insurance": {
            return "Proof Of Prior Insurance";
          }
          case "registration-card": {
            return "Registration Card";
          }
          case "ma-rmv-form": {
            return "MA RMV Form";
          }
          case "2a-transfer-form": {
            return "2A Transfer Form";
          }
          default: {
            return tag || "";
          }
        }
      },
      vehicleDocs: computed(() => {
        return vehicleDocs.value.filter((doc) => {
          return doc.customMetadata && doc.customMetadata.active === "true";
        });
      }),
      fieldIsEditable(field) {
        if (vehicle.value.status === "Active" && field.key !== "key") {
          return false;
        }
        if (field.key === "status") {
          return false;
        }
        if (account.value.tenantId === "carsync" && !cutoffCarsync.value) {
          return false;
        }
        if (["vin", "make", "model", "year"].includes(field.key)) {
          return isNew.value;
        }
        return true;
      },
      fields: computed(() => {
        const allFields = [
          {
            key: "vin",
            label: "VIN",
            create: true,
            required: true,
            section: "Vehicle",
          },
          {
            key: "vehicleRegistrationState",
            create: true,
            label: "Vehicle Registration State",
            required: true,
            section: "Vehicle",
          },
          {
            key: "plate",
            label: "Plate",
            create: true,
            required: false,
            placeholder: "optional plate",
            section: "Vehicle",
          },
          {
            key: "make",
            label: "Make",
            create: true,
            required: true,
            section: "Vehicle",
          },
          {
            key: "model",
            label: "Model",
            create: true,
            required: true,
            section: "Vehicle",
          },
          {
            key: "year",
            label: "Model Year",
            create: true,
            required: true,
            section: "Vehicle",
          },
          {
            key: "registrantType",
            label: "Registrant Type",
            create: true,
            required: true,
            section: "Registrant Information",
          },
          {
            key: "registrantGovernmentId",
            label: "Government ID",
            create: true,
            required: true,
            section: "Registrant Information",
          },
          {
            key: "registrantGovernmentIdType",
            label: "Government ID Type",
            create: true,
            required: true,
            section: "Registrant Information",
          },
          {
            key: "registrantDateOfBirth",
            label: "Date of Birth",
            create: true,
            required: false,
            section: "Registrant Information",
            visible: false,
          },
          {
            key: "driverLicenseStateIssued",
            label: "Driver License State Issued",
            create: false,
            required: true,
            section: "Registrant Information",
          },
          {
            key: "registrationName",
            label: "Registration Name",
            create: true,
            required: true,
            section: "Registrant Information",
            visible: true,
          },
          {
            key: "registrantFirstName",
            label: "Registrant First Name",
            create: true,
            required: true,
            section: "Registrant Information",
            visible: true,
          },
          {
            key: "registrantMiddleName",
            label: "Registrant Middle Name",
            create: true,
            required: false,
            section: "Registrant Information",
            visible: true,
          },
          {
            key: "registrantLastName",
            label: "Registrant Last Name",
            create: true,
            required: true,
            section: "Registrant Information",
            visible: true,
          },
          {
            key: "registrationAddressLineOne",
            label: "Address Line 1",
            create: true,
            required: true,
            section: "Registrant Information",
          },
          {
            key: "registrationAddressLineTwo",
            label: "Address Line 2",
            create: true,
            required: false,
            section: "Registrant Information",
          },
          {
            key: "registrationCity",
            label: "City",
            create: true,
            required: true,
            section: "Registrant Information",
          },
          {
            key: "registrationState",
            label: "State",
            create: true,
            required: true,
            section: "Registrant Information",
          },
          {
            key: "registrationZipcode",
            label: "Zipcode",
            create: true,
            required: true,
            section: "Registrant Information",
          },
          {
            key: "financeCompanyApplicable",
            label: "Vehicle Financing",
            create: true,
            required: true,
            section: "Finance Company",
          },
          {
            key: "financeCompanyName",
            label: "Finance Company",
            create: true,
            required: true,
            section: "Finance Company",
          },
          {
            key: "financeCompanyAddressLineOne",
            label: "Address Line 1",
            create: true,
            required: true,
            section: "Finance Company",
          },
          {
            key: "financeCompanyAddressLineTwo",
            label: "Address Line 2",
            create: true,
            required: false,
            section: "Finance Company",
          },
          {
            key: "financeCompanyCity",
            label: "City",
            create: true,
            required: true,
            section: "Finance Company",
          },
          {
            key: "financeCompanyState",
            label: "State",
            create: true,
            required: true,
            section: "Finance Company",
          },
          {
            key: "financeCompanyZipcode",
            label: "Zipcode",
            create: true,
            required: true,
            section: "Finance Company",
          },
          {
            key: "lotAddressLineOne",
            label: "Address Line 1",
            create: true,
            required: true,
            section: "Lot Information",
          },
          {
            key: "lotAddressLineTwo",
            label: "Address Line 2",
            create: true,
            required: false,
            section: "Lot Information",
          },
          {
            key: "lotCity",
            label: "City",
            create: true,
            required: true,
            section: "Lot Information",
          },
          {
            key: "lotState",
            label: "State",
            create: true,
            required: true,
            section: "Lot Information",
          },
          {
            key: "lotZipcode",
            label: "Zipcode",
            create: true,
            required: true,
            section: "Lot Information",
          },
          {
            key: "comments",
            label: "Comments",
            create: true,
            required: false,
            section: "Comments",
          },
        ]
          .filter(({ create }) => (isNew.value ? create : true))
          .filter((detail) => {
            if (
              detail.section === "Finance Company" &&
              detail.key !== "financeCompanyApplicable"
            ) {
              return vehicle.value.financeCompanyApplicable;
            }
            if (detail.section === "Comments") {
              return isNew.value;
            }
            if (
              detail.key === "driverLicenseStateIssued" &&
              vehicle.value.registrantGovernmentIdType !== "DriverLicense"
            ) {
              return false;
            }
            return true;
          });

        applyTransformations({ fields: allFields, vehicle });
        return allFields;
      }),
      missingAckFields: computed(() => {
        return [
          "vin",
          "registrationName",
          "registrationAddressLineOne",
          "registrationCity",
          "registrationState",
          "registrationZipcode",
          "financeCompanyName",
          "financeCompanyCity",
          "financeCompanyAddressLineOne",
          "financeCompanyState",
          "financeCompanyZipcode",
          "financeCompanyState",
          "lotAddressLineOne",
          "lotAddressCity",
          "lotAddressState",
          "lotAddressZipcode",
          "vehicleRegistrationState",
        ].filter((field) => {
          return !vehicle.value[field];
        });
      }),
      isChrome: computed(() => {
        const browser = detect();
        return browser.name === "chrome";
      }),
      isZipcodeField(field) {
        switch (field.key) {
          case "financeCompanyZipcode":
          case "registrationZipcode":
          case "lotAddressZipcode": {
            return true;
          }
          default: {
            return false;
          }
        }
      },
      isDateField(field) {
        switch (field.key) {
          case "registrantDateOfBirth": {
            return true;
          }
          default: {
            return false;
          }
        }
      },
      isAddressField(field) {
        switch (field.key) {
          case "financeCompanyAddressLineOne":
          case "registrationAddressLineOne":
          case "lotAddressLineOne": {
            return field.key;
          }
          default: {
            return false;
          }
        }
      },
      isStateField(field) {
        switch (field.key) {
          case "vehicleRegistrationState":
          case "financeCompanyState":
          case "registrationState":
          case "driverLicenseStateIssued":
          case "lotAddressState": {
            return true;
          }
          default: {
            return false;
          }
        }
      },
      getSectionsFromDetails(details) {
        const sections = [];
        details.forEach((detail) => {
          if (detail.section && !sections.includes(detail.section)) {
            sections.push(detail.section);
          }
        });
        return sections;
      },
      getAddressInputChange(evt) {
        if (!evt?.target?.value) {
          return;
        }
        vehicle.value[evt.target.id] = evt.target.value;
      },
      getAddressDataRegistration(addressData, locationData, fieldKey) {
        if (fieldKey === "registrationAddressLineOne") {
          vehicle.value.registrationAddressLineOne = [
            addressData.street_number,
            addressData.route,
          ].join(" ");
          vehicle.value.registrationCity = addressData.locality;
          vehicle.value.registrationZipcode = addressData.postal_code;
          vehicle.value.registrationState =
            addressData.administrative_area_level_1;
        }
        if (fieldKey === "financeCompanyAddressLineOne") {
          vehicle.value.financeCompanyAddressLineOne = [
            addressData.street_number,
            addressData.route,
          ].join(" ");
          vehicle.value.financeCompanyCity = addressData.locality;
          vehicle.value.financeCompanyZipcode = addressData.postal_code;
          vehicle.value.financeCompanyState =
            addressData.administrative_area_level_1;
        }
        if (fieldKey === "lotAddressLineOne") {
          vehicle.value.lotAddressLineOne = [
            addressData.street_number,
            addressData.route,
          ].join(" ");
          vehicle.value.lotAddressCity = addressData.locality;
          vehicle.value.lotAddressZipcode = addressData.postal_code;
          vehicle.value.lotAddressState =
            addressData.administrative_area_level_1;
        }
        const addressInput = document.querySelector(`#${fieldKey}`);
        if (addressInput) {
          addressInput.value = "";
        }
      },
      getDetailsForSection(details, section) {
        return details.filter((detail) => {
          const isVisible = detail.visible !== false;
          return detail.section === section && isVisible;
        });
      },
      formatEin(ein, { key, setValue } = {}) {
        var cleaned = String(ein).replace(/\D/g, "");
        var match = cleaned.match(
          /^([07][1-7]|1[0-6]|2[0-7]|[35][0-9]|[468][0-8]|9[0-589])(\d{7})$/,
        );
        if (match) {
          const formatted = match[1] + "-" + match[2];
          if (setValue) setValue(key, formatted, { shouldValidate: true });
          return { number: formatted, valid: true };
        }
        return { number: ein, valid: false };
      },
      async saveVehicle() {
        if (isNew.value && exists.value) {
          toast("Vehicle already added to account.");
          return;
        }

        // Set the registrationName based on the full name if the registrant is a person.
        if (vehicle.value.registrantGovernmentIdType === "DriverLicense") {
          const fullName = [
            vehicle.value.registrantFirstName,
            vehicle.value.registrantMiddleName,
            vehicle.value.registrantLastName,
          ]
            .filter(Boolean)
            .join(" ");
          vehicle.value.registrationName = fullName;
        }

        if (!vehicle.value.financeCompanyApplicable) {
          vehicle.value = {
            ...vehicle.value,
            financeCompanyName: "",
            financeCompanyAddressLineOne: "",
            financeCompanyAddressLineTwo: "",
            financeCompanyCity: "",
            financeCompanyZipcode: "",
            financeCompanyState: "",
          };
        }
        if (
          vehicle.value.registrantGovernmentIdType === "DriverLicense" &&
          !isValid(
            vehicle.value.driverLicenseStateIssued,
            vehicle.value.registrantGovernmentId,
          )
        ) {
          toast(
            `Invalid Driver License number for the state of ${vehicle.value.driverLicenseStateIssued}`,
          );
          return;
        }
        if (
          vehicle.value.registrantGovernmentIdType === "FEIN" &&
          typeof vehicle.value.registrantGovernmentId === "string"
        ) {
          let response = this.formatEin(vehicle.value.registrantGovernmentId);
          if (response.valid) {
            vehicle.value.registrantGovernmentId = response.number;
          } else {
            toast("Invalid FEIN number.");
            return;
          }
        }
        isSaving.value = true;
        toast("Saving vehicle...");
        try {
          const { status, insuranceCriteriaStatus, ...restOfVehicleValue } =
            vehicle.value;

          const save = await store.dispatch("saveVehicleForAccount", {
            ...restOfVehicleValue,
            entityId: isNew.value ? undefined : vehicle.value.entityId,
          });

          vehicle.value = { ...vehicle.value, ...save };
          await store.dispatch("getVehicles");
          if (isNew.value || store.state.vehicleAckFleet) {
            router.replace({ name: `${store.state.account.type}/Dashboard` });
          }
          toast.clear();
          toast(`Vehicle saved. ${isNew.value ? "Pending review." : ""}`);
        } catch (err) {
          toast.clear();
          toast("Failed to save vehicle.");
        }
        isSaving.value = false;
      },
      async lookupVin() {
        if (isNew.value && exists.value) {
          toast("WARNING: VIN already added to account");
        }
        try {
          const lookup = await axios({
            method: "GET",
            url: `https://vpic.nhtsa.dot.gov/api/vehicles/DecodeVin/${vehicle.value.vin}?format=JSON`,
          });

          const results = lookup.data["Results"];

          const make = results.find((x) => x["Variable"] === "Make");
          const model = results.find((x) => x["Variable"] === "Model");
          const year = results.find((x) => x["Variable"] === "Model Year");

          if (make && make["Value"]) {
            vehicle.value.make = make["Value"];
          }

          if (model && model["Value"]) {
            vehicle.value.model = model["Value"];
          }

          if (year && year["Value"]) {
            vehicle.value.year = year["Value"];
          }

          toast("VIN Lookup Complete");
        } catch (err) {
          toast("Failed to get VIN information.");
        }
      },
      addRegistrationInfo() {
        vehicle.value = {
          ...vehicle.value,
          registrationName: account.value.businessLegalName,
          registrationAddressLineOne: account.value.businessAddressLineOne,
          registrationAddressLineTwo: account.value.businessAddressLineTwo,
          registrationCity: account.value.businessCity,
          registrationZipcode: account.value.businessZipcode,
          registrationState: account.value.state,
          vehicleRegistrationState: account.value.state,
        };
        toast("Company information applied.");
      },
      sectionStatus: ref({
        Vehicle: true,
        Registration: false,
        "Finance Company": false,
        "Lot Information": false,
        Comments: false,
      }),
      async resumeCoverageProcess() {
        let recentlyDeactivated = vehicleRecentlyDeactivated(
          vehicle.value.changelog,
        );
        if (recentlyDeactivated.recentlyDeactivated) {
          daysRemaining.value = recentlyDeactivated.daysRemaining;
          showActivationModal.value = true;
        } else {
          if (
            await store.dispatch(
              "getLaunchDarklyFlag",
              "cavf-new-declined-flow",
            )
          ) {
            router.replace({
              name: `${store.state.account.type}/ResumeCustomerActivatesVehicleFlow`,
              params: { id: vehicle.value.entityId },
            });
          } else {
            router.replace({
              name: `${store.state.account.type}/ResumeVehicleActivation`,
              params: { id: vehicle.value.entityId },
            });
          }
        }
      },
      async removeCoverageProcess() {
        showDeactivationModal.value = true;
      },
      vehicleRegistrationStates: [
        "AL",
        "AK",
        "AZ",
        "AR",
        "CA",
        "CO",
        "CT",
        "DE",
        "DC",
        "FL",
        "GA",
        "HI",
        "ID",
        "IL",
        "IN",
        "IA",
        "KS",
        "KY",
        "LA",
        "ME",
        "MD",
        "MA",
        "MI",
        "MN",
        "MS",
        "MO",
        "MT",
        "NE",
        "NV",
        "NH",
        "NJ",
        "NM",
        "NY",
        "NC",
        "ND",
        "OH",
        "OK",
        "OR",
        "PA",
        "RI",
        "SC",
        "SD",
        "TN",
        "TX",
        "UT",
        "VT",
        "VA",
        "WA",
        "WV",
        "WI",
        "WY",
      ],
    };
  },
};
</script>

<style></style>
