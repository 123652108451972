<template>
  <div>
    <Loader v-if="loading || isAuthLoading" />
    <VerifyAccount v-else-if="user != null && !user.emailVerified" />
    <PendingAccount
      v-if="$store.state.account?.status === 'Pending Activation'"
    />
    <Cars v-else-if="$store.state.account?.type === 'Car Rental'" />
    <Trucks v-else-if="$store.state.account?.type === 'Trucking'" />
    <MissingAccount v-else-if="user != null" />
    <Claims v-else-if="isClaimRoute" />
    <Telematics v-else-if="isTelematicsRoute" />
  </div>
</template>

<script>
import Claims from "@/Claims.vue";
import Callback from "@/Callback.vue";
import Cars from "@/Cars.vue";
import Trucks from "@/Trucks.vue";
import MissingAccount from "@/MissingAccount.vue";
import PendingAccount from "@/PendingAccount.vue";
import VerifyAccount from "@/VerifyAccount.vue";
import Telematics from "@/Telematics.vue";
import Loader from "@/Loader.vue";
import { useAuth0 } from "@auth0/auth0-vue";
import { watch } from "vue";
import { useStore } from "vuex";

export default {
  components: {
    Cars,
    Callback,
    Claims,
    Loader,
    MissingAccount,
    PendingAccount,
    Telematics,
    Trucks,
    VerifyAccount,
  },
  setup() {
    const { isLoading } = useAuth0();
    const store = useStore();

    watch([isLoading], () => {
      if (isLoading.value) return;
      store.dispatch("initialLoad");
    });

    if (!isLoading.value) {
      store.dispatch("initialLoad");
    }

    return {
      isAuthLoading: isLoading,
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    isClaimRoute() {
      return window.location.pathname.includes("/claims");
    },
    isTelematicsRoute() {
      return window.location.pathname.includes("/telematics");
    },
    loading() {
      return this.$store.state.loading;
    },
  },
};
</script>
