import "vue-toastification/dist/index.css";
import "filepond/dist/filepond.min.css";
import "@fortawesome/fontawesome-free/css/all.css";
import Toast from "vue-toastification";
import { createApp } from "vue";
import vueFilePond from "vue-filepond";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePointPluginFileValidateSize from "filepond-plugin-file-validate-size";
import ToggleSwitch from "./ToggleSwitch.vue";
import launchDarkly from "./launchDarkly";
import {
  setFrontendFallbackSubdomain,
  Modal,
  StateSelect,
  Datepicker,
  FloatingVue,
} from "@getaddify/lula-components";

import App from "./App.vue";
import "./assets/tailwind.css";
import "@getaddify/lula-components/src/assets/index.css";
import "./index.css";
import store from "./store";
import router from "./router";
import { auth0 } from "./auth";

const [fallback] = (process.env["VUE_APP_DEFAULT_CUSTOMER_HOST"] || "").split(
  ".",
);
setFrontendFallbackSubdomain(fallback || "app");

const app = createApp(App);
app.mixin(launchDarkly);
app.component(
  "file-pond",
  vueFilePond(FilePondPluginFileValidateType, FilePointPluginFileValidateSize),
);
app.component("state-select", StateSelect);
app.component("toggle-switch", ToggleSwitch);
app.component("date-time-picker", Datepicker);
app.component("modal", Modal);
app.use(Toast, { toastClassName: "bg-lula-gradient" });
app.use(FloatingVue);
app.use(router);
app.use(store);
app.use(auth0);
app.mount("#app");
