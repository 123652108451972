<template>
  <div class="bg-white w-full rounded-xl p-8 mt-4 shadow-lg">
    <h1 class="font-bold text-4xl text-gray-800">File a Claim</h1>
    <hr class="my-4" />
    <form @submit.prevent="handleSubmit">
      <div class="grid grid-cols-2 mb-2">
        <div class="m-1 col-span-2 md:col-span-1">
          <label class="font-bold text-lg">Driver Name</label>
          <br />
          <input class="mt-1" v-model="claim.driverName" required />
        </div>
        <div class="m-1 col-span-2 md:col-span-1">
          <label class="font-bold text-lg">VIN</label>
          <br />
          <input class="mt-1" v-model="claim.vin" required />
        </div>
        <div class="m-1 col-span-2 md:col-span-1">
          <label class="font-bold text-lg">Incident Location</label>
          <br />
          <input class="mt-1" v-model="claim.location" required />
        </div>
        <div class="m-1 col-span-2 md:col-span-1">
          <label class="font-bold text-lg">Injuries</label>
          <br />
          <select class="mt-1" v-model="claim.injuries">
            <option :value="false">No Injuries</option>
            <option :value="true">Injuries Occured</option>
          </select>
        </div>
        <div class="m-1 col-span-2">
          <div class="flex items-center mb-1">
            <label class="font-bold text-lg mr-2"> Incident Date/Time </label>
            <tooltip>
              <template #content>
                <div class="text-gray-600">
                  Appoximately closest time to the date of the incident
                </div>
              </template>
            </tooltip>
          </div>
          <input type="datetime-local" v-model="claim.incidentDate" required />
        </div>
      </div>
      <div class="m-1">
        <label class="font-bold text-lg">Accident Description</label>
        <br />
        <textarea class="mt-1" rows="5" v-model="claim.description" />
      </div>
      <div class="m-1">
        <label class="font-bold text-lg">Third Party Information</label>
        <br />
        <textarea class="mt-1" rows="5" v-model="claim.thirdParty" />
      </div>
      <div class="m-1">
        <label class="font-bold text-lg">Witness</label>
        <br />
        <textarea class="mt-1" rows="5" v-model="claim.witness" />
      </div>
      <div class="md:flex">
        <div class="md:mr-1 w-full">
          <div class="flex items-center">
            <label class="font-bold text-lg mr-2"> Rental Agreement </label>
            <tooltip zIndex="100">
              <template #content>
                <div class="text-gray-600">Required to submit claim</div>
              </template>
            </tooltip>
          </div>
          <file-pond
            ref="raPond"
            accepted-file-types="image/jpg, image/jpeg, image/png, application/pdf"
            max-file-size="50MB"
            :allow-multiple="false"
            :allow-file-size-validation="true"
            :required="true"
          />
        </div>
        <div class="md:ml-1 w-full">
          <label class="font-bold text-lg"> Police Report </label>
          <file-pond
            ref="poPond"
            accepted-file-types="image/jpg, image/jpeg, image/png, application/pdf"
            max-file-size="50MB"
            :allow-multiple="false"
            :allow-file-size-validation="true"
            :required="false"
          />
        </div>
      </div>
      <div class="m-1">
        <label class="font-bold text-lg">Attachments</label>
        <file-pond
          ref="pond"
          accepted-file-types="image/jpg, image/jpeg, image/png, application/pdf"
          max-file-size="50MB"
          :allow-multiple="true"
          :allow-file-size-validation="true"
          :required="false"
        />
      </div>
      <div class="flex flex-col justify-end mt-4 md:flex-row">
        <button
          type="button"
          @click="$router.replace('/')"
          class="m-1 bg-gray-100 hover:bg-gray-200"
        >
          Cancel
        </button>
        <button
          type="submit"
          class="m-1 bg-lula-gradient text-white hover:bg-lula-gradient-alt"
        >
          Submit Claim
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import { useToast } from "vue-toastification";
import { onMounted, ref } from "vue";
import { useStore } from "vuex";

function getEmptyClaim() {
  return {
    driverName: "",
    vin: "",
    location: "",
    witness: "",
    injuries: false,
    thirdParty: "",
    description: "",
  };
}

export default {
  setup() {
    const toast = useToast();
    const store = useStore();
    const router = useRouter();

    const pond = ref(null);
    const raPond = ref(null);
    const poPond = ref(null);

    const claim = ref(getEmptyClaim());

    function saveClaimAttachments(claimId, files) {
      return new Promise((resolve) => {
        const companyId = store.state.account.id;
        if (files.length) {
          files.forEach((file, idx) => {
            store.dispatch("saveClaimAttachment", {
              companyId,
              claimId,
              attachment: file.file,
            });
            if (idx === files.length - 1) {
              resolve(files);
            }
          });
        } else {
          resolve(files);
        }
      });
    }

    onMounted(() =>
      router.replace({
        name: `${store.state.account.type}/ClaimForm`,
        params: { id: "new" },
      }),
    );

    return {
      claim,
      pond,
      raPond,
      poPond,
      async handleSubmit() {
        try {
          toast("Submitting claim.");
          const submission = await store.dispatch(
            "saveClaimForAccount",
            claim.value,
          );
          const raFiles = raPond.value.getFiles();
          const poFiles = poPond.value.getFiles();
          const files = pond.value.getFiles();
          await saveClaimAttachments(submission.documentId, [
            ...files,
            ...raFiles,
            ...poFiles,
          ]);
          await store.dispatch("getClaims");
          toast.clear();
          toast("Claim has been submitted.");
          pond.value.removeFiles();
          raPond.value.removeFiles();
          poPond.value.removeFiles();
          claim.value = getEmptyClaim();
          router.push({
            name: `${store.state.account.type}/ClaimConfirmation`,
            params: {
              id: submission.entityId,
            },
          });
        } catch (err) {
          toast.clear();
          toast("Failed to submit claim.");
        }
      },
    };
  },
};
</script>
