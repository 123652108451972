<template>
  <modal
    class="fixed inset-0 w-full h-screen flex items-center justify-center bg-semi-75"
  >
    <div
      class="w-3/4 max-w-2xl bg-white shadow-lg rounded-lg p-6 flex flex-col flex-start"
    >
      <div class="py-2 px-2 flex font-bold w-full text-xl">Cancel coverage</div>
      <div class="text-gray-800 text-sm px-2 py-2 text-left">
        Are you sure you want to cancel this coverage? Client will be refunded.
      </div>
      <div class="flex flex-row pt-2 justify-between">
        <button
          @click="() => onCancel()"
          class="transition text-gray-600 cursor-pointer) py-2 mb-2 rounded-[50%] hover:bg-gray-100 text-sm"
        >
          Back
        </button>
        <button
          class="h-10 py-2 px-4 text-sm"
          @click="() => cancelEpisode(reservation)"
          :disabled="isCanceling"
          :class="{
            'bg-lula-gradient text-white hover:bg-lula-gradient-alt':
              !isCanceling,
            'bg-gray-300 text-gray-600 cursor-default': isCanceling,
          }"
        >
          Confirm cancelation
        </button>
      </div>
    </div>
  </modal>
</template>

<script>
import { ref } from "vue";
import { useStore } from "vuex";

export default {
  props: {
    selectedReservation: {
      type: Object,
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const isCanceling = ref(false);

    async function cancelEpisode() {
      try {
        isCanceling.value = true;
        const response = await store.dispatch(
          "cancelEpisode",
          props.selectedReservation.episode,
        );
        if (!response?.success) {
          alert("Fail to cancel episode");
        }
        emit("toggle");
      } catch (err) {
        emit("toggle");
        alert("Fail to cancel episode", err);
      } finally {
        isCanceling.value = false;
        emit("refresh");
      }
    }

    return {
      isCanceling,
      cancelEpisode,
    };
  },
  methods: {
    onCancel() {
      this.$emit("toggle");
    },
    onClose() {
      this.$emit("toggle");
      this.$emit("refresh");
    },
  },
};
</script>
