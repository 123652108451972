<template>
  <InactiveAccount v-if="isInactive" />
  <Onboarding v-else-if="isOnboarding && !handlingOauthRedirect" />
  <div v-else>
    <Navbar :options="options" />
    <main class="p-4 flex justify-center max-w-screen-lg m-auto">
      <Loader v-if="loading" />
      <transition name="fade" v-else>
        <router-view />
      </transition>
    </main>
    <Sidebar v-if="$store.state.sidebar">
      <div class="flex justify-between items-center p-4 border-b">
        <span class="font-bold text-gray-800">
          {{ account.businessLegalName }}
        </span>
        <svg
          @click="$store.commit('setItem', { key: 'sidebar', val: false })"
          xmlns="http://www.w3.org/2000/svg"
          class="transition cursor-pointer text-gray-800 hover:text-gray-600 h-9 w-9"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </div>
      <div
        @click="pushRoute('Account')"
        class="transition select-none w-full p-4 border-b cursor-pointer text-lg hover:bg-gray-100"
      >
        <span>My Account</span>
      </div>
      <div
        @click="pushRoute('Help')"
        class="transition select-none w-full p-4 border-b cursor-pointer text-lg hover:bg-gray-100"
      >
        <span>Help Center</span>
      </div>
      <div
        @click="pushRoute('SignOut')"
        class="transition select-none w-full p-4 border-b cursor-pointer text-lg hover:bg-gray-100"
      >
        <span>Logout</span>
      </div>
    </Sidebar>
  </div>
</template>

<script>
import { useRoute } from "vue-router";
import Navbar from "@/Navbar.vue";
import Loader from "@/Loader.vue";
import Sidebar from "@/Sidebar.vue";
import InactiveAccount from "@/trucks/InactiveAccount.vue";
import Onboarding from "@/trucks/Onboarding.vue";

export default {
  components: {
    Navbar,
    Loader,
    Sidebar,
    InactiveAccount,
    Onboarding,
  },
  methods: {
    pushRoute(route) {
      this.$store.commit("setItem", { key: "sidebar", val: false });
      switch (route) {
        case "SignOut": {
          this.$store.dispatch("signOut");
          break;
        }
        case "Help": {
          window.open(
            "https://lulatrucking.force.com/TruckingHelpCenter/",
            "_blank",
          );
          break;
        }
        default: {
          this.$router.push({ name: `${this.account.type}/${route}` });
        }
      }
    },
  },
  async mounted() {
    this.lulaBucksEnabled = true;
  },
  computed: {
    account() {
      return this.$store.state.account;
    },
    isInactive() {
      return (
        this.$store.state.account?.status === "Inactive" ||
        (this.$store.state.account?.status === "Onboarding" &&
          !this.$store.state.account?.initialInvoice)
      );
    },
    isOnboarding() {
      return (
        window.localStorage.getItem("trucking-onboarding") === "true" ||
        this.$store.state.account?.initialInvoice ||
        (this.lulaBucksEnabled && !this.$store.state.account?.autoRefillAmount)
      );
    },
    handlingOauthRedirect() {
      const route = useRoute();
      return (
        route.path.startsWith("/keeptruckin") ||
        route.path.startsWith("/samsara")
      );
    },
    loading() {
      return this.$store.state.loading;
    },
    options() {
      const vm = this;
      return [
        {
          name: "Dashboard",
          route: `${vm.account.type}/Dashboard`,
        },
        {
          name: "Fleet",
          route: `${vm.account.type}/Fleet`,
        },
        {
          name: "Drivers",
          route: `${vm.account.type}/Drivers`,
        },
        {
          name: "Insurance",
          route: `${vm.account.type}/Insurance`,
        },
        {
          name: "Episodes",
          route: `${vm.account.type}/Episodes`,
        },
        {
          name: "Billing",
          route: `${vm.account.type}/Billing`,
        },
        {
          name: "Account",
          route: `${vm.account.type}/Account`,
        },
      ];
    },
  },
};
</script>
