import Landing from "@/claims/Landing";
import VehicleLookup from "@/claims/VehicleLookup";
import ClaimSteps from "@/claims/ClaimSteps";
import ThirdPartyDetails from "@/claims/ThirdPartyDetails";
import IncidentDetails from "@/claims/IncidentDetails";
import ClaimAttachments from "@/claims/ClaimAttachments";
import ReviewDetails from "@/claims/ReviewDetails";
import Confirmation from "@/claims/Confirmation";
import ClaimLookup from "@/claims/ClaimLookup";
import ClaimDetails from "@/claims/ClaimDetails";

export default [
  {
    name: "Landing",
    path: "/claims",
    component: Landing,
  },
  {
    name: "VehicleLookup",
    path: "/claims/vehicle-lookup",
    component: VehicleLookup,
  },
  {
    name: "ClaimSteps",
    path: "/claims/steps",
    component: ClaimSteps,
  },
  {
    name: "ThirdPartyDetails",
    path: "/claims/personal-details",
    component: ThirdPartyDetails,
  },
  {
    name: "IncidentDetails",
    path: "/claims/incident-details",
    component: IncidentDetails,
  },
  {
    name: "ClaimAttachments",
    path: "/claims/claim-attachments",
    component: ClaimAttachments,
  },
  {
    name: "ReviewDetails",
    path: "/claims/review-details",
    component: ReviewDetails,
  },
  {
    name: "Confirmation",
    path: "/claims/confirmation",
    component: Confirmation,
  },
  {
    name: "ClaimLookup",
    path: "/claims/claim-lookup",
    component: ClaimLookup,
  },
  {
    name: "ClaimDetails",
    path: "/claims/claim-details",
    component: ClaimDetails,
  },
];
