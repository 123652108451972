<template>
  <div
    class="transition-all min-h-screen flex justify-center items-center p-2"
    :class="{
      'opacity-100': settings.length > 0,
      'opacity-0': settings.length <= 0,
    }"
  >
    <div
      class="bg-white rounded-lg shadow-lg p-4 border-b-4 border-lula w-full md:w-2/3"
    >
      <div class="p-4 pb-0" v-if="viewkey === 'welcome'">
        <div class="text-gray-800" id="onboarding-message">
          <div v-html="settings['truckingonboardingmessage']" />
        </div>
      </div>
      <div v-else-if="viewkey === 'invoice'">
        <h1 class="text-2xl text-gray-800 font-bold">Payment Required</h1>
        <span class="text-gray-600 font-bold">
          Payment is required before we can activate your account
        </span>
        <div
          @click="openInvoice"
          class="relative flex items-center justify-center w-80 bg-gray-50 h-96 border rounded-md m-auto mt-8 mb-4 shadow-inner cursor-pointer hover:bg-gray-100"
        >
          <div class="relative bg-white border rounded-md h-3/4 w-2/3">
            <div class="absolute bg-lula h-1 rounded-full w-full" />
            <span class="absolute top-3 left-5 font-bold text-gray-400 text-sm"
              >GetAddify INVOICE</span
            >
            <div
              class="absolute top-10 left-5 bg-gray-100 rounded-full w-1/2 h-2"
            />
            <div
              class="absolute top-14 left-5 bg-gray-100 rounded-full w-1/4 h-2"
            />
            <div
              class="absolute flex flex-col justify-center items-center top-20 w-full"
            >
              <div class="rounded-full bg-gray-100 h-1 w-10/12 my-2" />
              <div class="rounded-full bg-gray-100 h-1 w-10/12 my-2" />
              <div class="rounded-full bg-gray-100 h-1 w-10/12 my-2" />
              <div class="rounded-full bg-gray-100 h-1 w-10/12 my-2" />
              <div class="rounded-full bg-gray-100 h-1 w-10/12 my-2" />
            </div>
            <div class="absolute bottom-5 flex flex-col items-end w-full pr-2">
              <span class="font-bold text-gray-400 mb-px mr-1">TOTAL</span>
              <div class="bg-gray-100 rounded-full h-2 w-2/4" />
            </div>
          </div>
          <div class="absolute flex bottom-6 justify-center w-10/11">
            <div
              class="bg-white border text-gray-500 font-bold px-8 py-1 rounded-full"
            >
              Click to Pay Invoice
            </div>
          </div>
        </div>
        <div class="text-center mb-4" v-if="unpaidInvoice">
          <span class="text-lula font-bold text-lg">
            Cannot continue, unpaid invoice.
          </span>
        </div>
      </div>
      <div v-else-if="viewkey === 'telematics'">
        <table class="table-auto w-full text-gray-600 text-lg">
          <caption class="hidden">
            Show the service provider details
          </caption>
          <thead>
            <tr>
              <th class="text-left text-gray-800">Service Provider</th>
              <th class="hidden text-left text-gray-800 md:table-cell">
                Connection Status
              </th>
              <th class="hidden text-left text-gray-800 md:table-cell">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            <tr class="py-4">
              <td class="py-2">
                <div class="flex justify-between items-center">
                  <span class="hidden md:inline">
                    GoMotive (formerly KeepTruckin)
                  </span>
                  <span class="md:hidden"> GoMotive </span>
                  <a
                    :href="telematicsSettings.keeptruckin.oauth_url"
                    v-if="!account.motiveIntegrationStatus"
                    class="bg-lula-gradient hover:bg-lula-gradient-alt px-4 py-2 rounded-full text-sm text-white md:hidden"
                  >
                    Connect
                  </a>
                </div>
              </td>
              <td class="hidden py-2 md:table-cell">
                <div>
                  {{ account.motiveIntegrationStatus || "Not Connected" }}
                </div>
              </td>
              <td class="hidden py-2 md:table-cell">
                <a
                  :href="telematicsSettings.keeptruckin.oauth_url"
                  v-if="!account.motiveIntegrationStatus"
                  class="bg-lula-gradient hover:bg-lula-gradient-alt px-4 py-2 rounded-full text-white"
                >
                  {{
                    account.motiveIntegrationStatus ? "Refresh" : "Connect Now"
                  }}
                </a>
              </td>
            </tr>
            <tr class="py-4">
              <td class="py-2">
                <div class="flex justify-between items-center">
                  <span>Samsara</span>
                  <a
                    :href="telematicsSettings.samsara.oauth_url"
                    v-if="!account.samsaraIntegrationStatus"
                    class="bg-lula-gradient hover:bg-lula-gradient-alt px-4 py-2 rounded-full text-white text-sm md:hidden"
                  >
                    Connect
                  </a>
                </div>
              </td>
              <td class="hidden py-2 md:table-cell">
                <div>
                  {{ account.samsaraIntegrationStatus || "Not Connected" }}
                </div>
              </td>
              <td class="hidden py-2 md:table-cell">
                <a
                  :href="telematicsSettings.samsara.oauth_url"
                  v-if="!account.samsaraIntegrationStatus"
                  class="bg-lula-gradient hover:bg-lula-gradient-alt px-4 py-2 rounded-full text-white"
                >
                  {{
                    account.samsaraIntegrationStatus ? "Refresh" : "Connect Now"
                  }}
                </a>
              </td>
            </tr>
            <tr class="py-4">
              <td class="py-2">
                <div class="flex justify-between items-center">
                  <span>Geotab</span>
                  <a
                    @click="telematicsSettings.geotab.showForm = true"
                    v-show="
                      !telematicsSettings.geotab.showForm &&
                      !account.geotabIntegrationStatus
                    "
                    class="bg-lula-gradient cursor-pointer hover:bg-lula-gradient-alt px-4 p-2 rounded-full text-white text-sm md:hidden"
                  >
                    Connect
                  </a>
                </div>
              </td>
              <td class="hidden py-2 md:table-cell">
                <div>
                  {{ account.geotabIntegrationStatus || "Not Connected" }}
                </div>
              </td>
              <td class="hidden py-2 md:table-cell">
                <a
                  @click="telematicsSettings.geotab.showForm = true"
                  v-show="
                    !telematicsSettings.geotab.showForm &&
                    !account.geotabIntegrationStatus
                  "
                  class="bg-lula-gradient cursor-pointer hover:bg-lula-gradient-alt px-4 p-2 rounded-full text-white"
                >
                  {{
                    account.geotabIntegrationStatus ? "Refresh" : "Connect Now"
                  }}
                </a>
              </td>
            </tr>
          </tbody>
        </table>
        <div
          class="border-t mt-3 pt-4"
          v-show="telematicsSettings.geotab.showForm"
        >
          <h4 class="text-gray-800 font-bold">Geotab Credentials</h4>
          <br />
          <span class="py-2 font-bold text-gray-600"
            >Username: <input v-model="telematicsSettings.geotab.username"
          /></span>
          <span class="py-2 font-bold text-gray-600"
            >Password: <input v-model="telematicsSettings.geotab.password"
          /></span>
          <span class="py-2 font-bold text-gray-600"
            >Database: <input v-model="telematicsSettings.geotab.database"
          /></span>
          <div class="flex justify-between pt-2">
            <span />
            <button
              class="w-full bg-lula-gradient text-white py-2 text-sm hover:bg-lula-gradient-alt md:text-lg md:w-min"
              @click="connectGeotab"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
      <div v-else-if="viewkey === 'billing'">
        <div ref="billingSettings" />
      </div>
      <div v-else-if="viewkey === 'video'">
        <div class="mb-3.5">
          <h1 class="text-2xl text-gray-800 font-bold">You're all set!</h1>
          <span class="font-bold text-gray-600">
            Watch this video to learn how to use your new platform!
          </span>
        </div>
        <iframe
          width="100%"
          height="500"
          class="rounded-md"
          :src="settings['truckingonboardingvideo']"
          title="Trucking Onboarding"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        />
      </div>
      <div class="flex flex-wrap-reverse mt-2 sm:flex-nowrap">
        <button
          class="transition w-full border rounded-lg m-1 hover:bg-gray-100"
          @click="$store.dispatch('signOut')"
        >
          Logout
        </button>
        <button
          class="transition w-full border bg-white rounded-lg m-1 hover:bg-gray-100"
          :disabled="disableContinue"
          :class="{
            'bg-gray-50 text-gray-500 cursor-default': disableContinue,
            'bg-white hover:bg-gray-100': !disableContinue,
          }"
          @click="clickContinue"
        >
          Continue
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { nextTick, ref, watch, onMounted } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { useToast } from "vue-toastification";
import showdown from "showdown";
import { mapSystemSettings } from "@/utils";
import axios from "axios";
import {
  initializeShadowDom,
  fetchFrontend,
  mountFrontend,
} from "@getaddify/lula-components";
import { Subject } from "rxjs";
import { requestHeaders } from "../auth";

const VIDEO_WAIT_TIMEOUT = 120000;

export default {
  setup() {
    const store = useStore();
    const toast = useToast();
    const route = useRoute();

    const account = store.state.account;
    const viewkey = ref(route.query.viewkey || "welcome");
    const telematicsSettings = ref({
      keeptruckin: {
        oauth_url: process.env["VUE_APP_MOTIVE_OAUTH_URL"],
      },
      samsara: {
        oauth_url: new URL(process.env["VUE_APP_SAMSARA_OAUTH_URL"]),
      },
      geotab: {
        username: null,
        password: null,
        database: null,
        showForm: false,
      },
    });
    telematicsSettings.value.samsara.oauth_url.searchParams.set(
      "state",
      Math.floor(Math.random() * Number.MAX_SAFE_INTEGER),
    );
    const unpaidInvoice = ref(false);
    const disableContinue = ref(false);
    const settings = ref([]);
    const billingSettings = ref(null);

    const autoRefillAmount = ref(null);
    const autoRefillAmountSubject = new Subject();
    autoRefillAmountSubject.subscribe({
      next: (val) => (autoRefillAmount.value = val),
    });

    async function mountBillingSettings() {
      nextTick(async () => {
        await store.dispatch("getVehicles");
        const [billingSettingsShadow, billingSettingsRoot] =
          initializeShadowDom(billingSettings.value);
        await fetchFrontend(
          billingSettingsShadow,
          process.env["VUE_APP_BILLING_UI_URL"],
        );
        mountFrontend("billing-wallet-settings", billingSettingsRoot, {
          autoRefillAmount: autoRefillAmountSubject,
          defaultEpisodePrice: parseFloat(account.episodePrice),
          activeVehicleCount: store.state.vehicles.filter(
            (v) => v.status === "Active",
          ).length,
        });
        billingSettingsRoot.firstChild.style.maxHeight = "600px";
      });
    }

    onMounted(async () => {
      window.localStorage.setItem("trucking-onboarding", true);
      settings.value = mapSystemSettings(
        await store.dispatch("getSystemSettings"),
      );
    });

    watch(settings, () => {
      if (settings.value["truckingonboardingmessage"]) {
        const converter = new showdown.Converter();
        let content = converter.makeHtml(
          settings.value["truckingonboardingmessage"].replace(
            /(\\r)*\\n/g,
            "\n",
          ),
        );
        content = content.replace(
          "[[ COMPANY NAME ]]",
          store.state.account.businessLegalName,
        );
        settings.value["truckingonboardingmessage"] = content;
      }
    });

    return {
      viewkey,
      settings,
      billingSettings,
      telematicsSettings,
      account,
      unpaidInvoice,
      disableContinue,
      openInvoice() {
        const hostedUrl =
          store.state.account.initialInvoice["hosted_invoice_url"];
        window.open(hostedUrl, "_blank");
      },
      async clickContinue() {
        switch (viewkey.value) {
          case "welcome": {
            const account = await store.dispatch("getAccount");
            if (account.initialInvoice) {
              viewkey.value = "invoice";
            } else if (!account.autoRefillAmount) {
              viewkey.value = "billing";
              mountBillingSettings();
            } else {
              if (settings.value["truckingonboardingvideo"]) {
                viewkey.value = "video";
                disableContinue.value = true;
                setTimeout(
                  () => (disableContinue.value = false),
                  VIDEO_WAIT_TIMEOUT,
                );
              } else {
                window.localStorage.removeItem("trucking-onboarding");
                window.location.reload();
              }
            }
            break;
          }
          case "invoice": {
            const account = await store.dispatch("getAccount");
            if (account.initialInvoice) {
              unpaidInvoice.value = true;
              toast.clear();
              toast.error("Invoice is unpaid.");
              return;
            }
            viewkey.value = "billing";
            mountBillingSettings();
            return;
          }
          case "billing": {
            if (!autoRefillAmount.value) {
              toast.clear();
              toast.error("Must select an auto refill amount.");
              return;
            }
            store.dispatch("saveBillingSettingsForAccount", {
              ...store.state.account,
              autoRefillAmount: autoRefillAmount.value,
            });
            viewkey.value = "video";
            break;
          }
          case "telematics": {
            const account = await store.dispatch("getAccount");
            const telematicsEnabled =
              account.motiveIntegration?.enabled ||
              account.samsaraIntegration?.enabled ||
              account.geotabIntegration?.enabled;
            if (!telematicsEnabled) {
              toast.clear();
              toast.error(
                "Complete integration with a telematics provider to continue.",
              );
              return;
            }
            if (settings.value["truckingonboardingvideo"]) {
              viewkey.value = "video";
              disableContinue.value = true;
              setTimeout(
                () => (disableContinue.value = false),
                VIDEO_WAIT_TIMEOUT,
              );
            } else {
              window.localStorage.removeItem("trucking-onboarding");
              window.location.reload();
            }
            break;
          }
          case "video":
          default: {
            window.localStorage.removeItem("trucking-onboarding");
            window.location.reload();
          }
        }
      },
      async connectGeotab() {
        const account = await store.dispatch("getAccount");
        const headers = await requestHeaders();
        const response = await axios({
          method: "POST",
          url: `${process.env["VUE_APP_TELEMATICS_API"]}/geotab/create_account`,
          data: {
            account_ref: account.entityId,
            geotabIntegrationUsername: telematicsSettings.value.geotab.username,
            geotabIntegrationPassword: telematicsSettings.value.geotab.password,
            geotabIntegrationDatabaseName:
              telematicsSettings.value.geotab.database,
          },
          withCredentials: true,
          headers,
        });
        const geotabUpdates = response.data;
        toast(`Saving Geotab Credentials`);
        if (response.status === 200) {
          await store.dispatch("saveDetailsForAccount", {
            geotabIntegration: geotabUpdates,
          });
          toast.clear();
          toast(`Credentials Saved`);
          telematicsSettings.value.geotab.showForm = false;
        } else {
          store.dispatch("saveDetailsForAccount", {
            geotabIntegration: {
              ...account.geotabIntegration,
              telematicsError: response.status,
            },
          });
          toast.clear();
          toast(`Error saving Credentials.`);
          telematicsSettings.value.geotab.showForm = false;
        }
      },
    };
  },
};
</script>

<style>
#onboarding-message h1 {
  font-size: 1.5rem !important;
}

#onboarding-message p {
  font-size: 1rem !important;
  margin: 1rem 0 !important;
}
</style>
