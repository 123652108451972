import moment from "moment";

const defaultDateFormat = "MM/DD/yyyy";

function formatDate(dateString) {
  const todayLocalDate = moment().local().format(defaultDateFormat);
  const targetLocalDate = moment
    .utc(dateString)
    .local()
    .format(defaultDateFormat);

  if (todayLocalDate === targetLocalDate) {
    return "Today";
  }

  return targetLocalDate;
}

function getFormattedModifiedDateFromCoi(coi) {
  if (coi.modifiedDate) {
    return formatDate(coi.modifiedDate);
  }

  return formatDate(coi.certificateDate);
}

function getRevisionText(coi) {
  const revisionNumber = +coi.revisionNumber || 1;
  return revisionNumber > 1 ? revisionNumber.toString() : "";
}

export {
  defaultDateFormat,
  formatDate,
  getFormattedModifiedDateFromCoi,
  getRevisionText,
};
