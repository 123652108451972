import { createRouter, createWebHistory } from "vue-router";

import Callback from "@/Callback.vue";
import ClaimRoutes from "@/claims/routes";
import CarRoutes from "@/cars/routes";
import TruckRoutes from "@/trucks/routes";
import TelematicsRoutes from "@/telematics/routes";

function getTypedRoutes(routes, type) {
  return routes.map((route) => {
    if (route.children) {
      return {
        ...route,
        name: `${type}/${route.name}`,
        children: getTypedRoutes(route.children || [], type),
      };
    }
    return {
      ...route,
      name: `${type}/${route.name}`,
    };
  });
}

function getAnonymousRoutes(routes) {
  return routes.map((route) => {
    if (route.children) {
      return {
        ...route,
        children: getAnonymousRoutes(routes),
        meta: { ...(route.meta || {}), allowAnonymous: true },
      };
    }
    return {
      ...route,
      name: route.name,
      meta: { ...(route.meta || {}), allowAnonymous: true },
    };
  });
}

const routes = [
  {
    name: "Callback",
    path: "/redirect",
    component: Callback,
  },
  ...getTypedRoutes(getAnonymousRoutes(ClaimRoutes), "Claims"),
  ...getTypedRoutes(CarRoutes, process.env["VUE_APP_ACCOUNT_TYPE_CAR_RENTAL"]),
  ...getTypedRoutes(TruckRoutes, process.env["VUE_APP_ACCOUNT_TYPE_TRUCKING"]),
  ...getTypedRoutes(getAnonymousRoutes(TelematicsRoutes), "Telematics"),
];

export default createRouter({
  history: createWebHistory(),
  routes,
});
