<template>
  <div class="w-full py-8">
    <div class="flex items-center font-bold text-gray-600 text-2xl mb-4">
      <span class="mr-2 cursor-pointer hover:underline" @click="toDrivers">
        Drivers
      </span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-6 w-6 mt-px"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M9 5l7 7-7 7"
        />
      </svg>
      <span class="ml-2"> Driver </span>
    </div>
    <div class="flex flex-col md:flex-row">
      <div class="w-full md:w-1/3">
        <div
          @click="uploadImage"
          class="relative rounded-md border cursor-pointer w-full m-auto sm:w-1/2 md:w-full"
        >
          <input
            type="file"
            class="hidden"
            accept=".jpeg,.jpg,.png"
            multiple="false"
            ref="thumbnailFileInput"
          />
          <img
            class="max-h-64 object-cover w-full m-auto rounded-md"
            :src="thumbnailSrc"
            v-if="thumbnailSrc"
          />
          <div class="bg-lula-light p-4 shadow-inner m-auto" v-else>
            <button
              class="absolute shadow-sm text-xs py-2 px-6 right-2 bottom-2"
              v-if="!thumbnailSrc"
            >
              Upload Image
            </button>
            <img
              src="../assets/driver-license-1.svg"
              class="w-full m-auto border rounded-3xl"
            />
          </div>
        </div>
        <div
          class="bg-lula shadow-inner text-white p-2 font-bold rounded-md border text-lg md:text-base lg:text-lg mt-2"
          :class="{
            'bg-lula': driver.status === 'Active',
            'bg-lula-alt': driver.status === 'Inactive',
          }"
          v-if="driver.insuranceCriteriaStatus === 'Approved'"
        >
          {{ driver.status }}
        </div>
        <div
          class="bg-white p-4 text-gray-600 rounded-md border text-lg md:text-base lg:text-lg mt-2"
        >
          <span class="font-bold text-gray-800">Last Modified:</span>
          {{ driver.modified }}
          <br />
          <span class="font-bold text-gray-800">Created:</span>
          {{ driver.created }}
          <hr class="my-4 md:hidden" v-if="driver.status === 'Inactive'" />
          <div
            class="flex items-center mx-1 mt-2.5 cursor-pointer text-gray-600 hover:text-gray-800 md:hidden"
            v-if="driver.status === 'Inactive'"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="
                    M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5
                    7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16
                  "
              />
            </svg>
            <span @click="removeDriver" class="mx-1">
              Request removal of driver
            </span>
          </div>
        </div>
        <div
          class="bg-white p-2 text-gray-600 rounded-md border text-lg md:text-base lg:text-lg mt-2"
          v-if="keepTruckinLink"
        >
          <div
            @click="viewInKeepTruckin"
            class="flex items-center p-2 cursor-pointer hover:bg-gray-100"
          >
            <img alt="keep truckin" class="w-6 h-6" src="/keeptruckin.ico" />
            <span class="mx-2">View in KeepTruckin</span>
          </div>
        </div>
        <div
          class="bg-white p-4 text-gray-600 rounded-md border text-lg md:text-base lg:text-lg mt-2"
          v-if="driver.insuranceCriteriaStatus === 'Under Review'"
        >
          <div class="flex text-gray-600 items-center border-b mb-2 pb-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              />
            </svg>
            <span class="ml-2 font-bold"> Currently Under Review </span>
          </div>
          <span class="text-gray-600">
            Checking if this driver meets the GetAddify's criteria
          </span>
        </div>
        <div
          class="bg-white p-4 text-gray-600 rounded-md border text-lg md:text-base lg:text-lg mt-2"
          v-else-if="driver.insuranceCriteriaStatus === 'Declined'"
        >
          <div class="flex items-center text-red-500 border-b mb-2 pb-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
            <span class="ml-1 font-bold"> Declined </span>
          </div>
          <span class="text-gray-600">
            Driver does not meet GetAddify's criteria
          </span>
        </div>
        <div
          class="bg-white p-4 text-gray-600 rounded-md border text-lg md:text-base lg:text-lg mt-2"
          v-else
        >
          <div class="flex items-center text-green-500 border-b mb-2 pb-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M5 13l4 4L19 7"
              />
            </svg>
            <span class="ml-1 font-bold"> Approved </span>
          </div>
          <div
            @click="toggleDriverStatus"
            class="w-full cursor-pointer p-2 hover:bg-gray-100"
          >
            <span class="text-gray-600" v-if="driver.status === 'Active'">
              Click here to deactivate driver
            </span>
            <span class="text-gray-600" v-else>
              Click here to activate driver
            </span>
          </div>
        </div>
        <div
          class="hidden items-center mx-1 mt-2.5 cursor-pointer text-gray-600 hover:text-gray-800 md:flex"
          v-if="driver.status === 'Inactive'"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="
                  M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5
                  7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16
                "
            />
          </svg>
          <span @click="removeDriver" class="mx-1">
            Request removal of driver
          </span>
        </div>
      </div>
      <div class="w-full mt-2 md:mx-2 md:mt-0 md:w-2/3">
        <div class="w-full bg-white rounded-md border p-4">
          <form @submit.prevent="saveDriver">
            <div class="text-lg grid grid-cols-1 md:grid-cols-2">
              <div
                class="text-gray-800 m-1"
                v-for="detail in driverDetails"
                :key="detail.key"
              >
                <label class="font-bold">{{ detail.label }}</label>
                <br />
                <state-select
                  v-model="driver[detail.key]"
                  required
                  disabled
                  v-if="detail.key === 'state'"
                />
                <input
                  :type="detail.type"
                  v-model="driver[detail.key]"
                  required
                  disabled
                  v-else-if="detail.key === 'licenseYears'"
                />
                <input
                  :type="detail.type"
                  v-model="driver[detail.key]"
                  required
                  disabled
                  v-else
                />
              </div>
            </div>
            <div class="hidden flex justify-end mt-2">
              <button
                type="submit"
                class="bg-lula-gradient text-white hover:bg-lula-gradient-alt"
              >
                Save Driver
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { useToast } from "vue-toastification";
import { getMegabytesFromBytes, getBase64 } from "@/utils";
import moment from "moment";

const driverDetails = [
  { label: "First Name", key: "firstName", type: "text" },
  { label: "Last Name", key: "lastName", type: "text" },
  { label: "Date of Birth", key: "dateOfBirth", type: "date" },
  { label: "Date of Hire", key: "dateOfHire", type: "date" },
  { label: "Years with CDL", key: "licenseYears", type: "number" },
  { label: "CDL State", key: "state", type: "text" },
  { label: "CDL Number", key: "license", type: "text" },
  { label: "CDL Class", key: "class", type: "text" },
];

function getDriverObject(driver) {
  return {
    ...driver,
    created: moment.utc(driver.created).format("MM/DD/yyyy"),
    modified: moment.utc(driver.modified).format("MM/DD/yyyy"),
    dateOfBirth: moment.utc(driver.dateOfBirth).format("yyyy-MM-DD"),
    dateOfHire: moment.utc(driver.dateOfHire).format("yyyy-MM-DD"),
  };
}

export default {
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const toast = useToast();

    const driver = ref(
      getDriverObject(
        store.state.drivers.find(
          ({ entityId }) => entityId === route.params.id,
        ),
      ),
    );

    const thumbnailFileInput = ref(null);
    const thumbnailSrc = ref("");

    const keepTruckinLink = computed(() => {
      if (driver.value.keeptruckinId) {
        return `https://web.keeptruckin.com/en-US/#/fleetview/drivers/${driver.value.keeptruckinId}`;
      }
      return null;
    });

    onMounted(() => {
      thumbnailSrc.value = driver.value?.thumbnail || "";
      if (thumbnailFileInput.value) {
        thumbnailFileInput.value.addEventListener("change", async () => {
          const file = [...thumbnailFileInput.value.files].pop();
          if (file) {
            const sizeMb = getMegabytesFromBytes(file.size);
            if (sizeMb < 5) {
              const baseString = await getBase64(file);
              thumbnailSrc.value = baseString;
              toast("Saving thumbnail.");
              driver.value = getDriverObject(
                await store.dispatch("saveDriverForAccount", {
                  entityId: driver.value.entityId,
                  thumbnail: thumbnailSrc.value,
                }),
              );
              store.dispatch("getDrivers");
              toast.clear();
              toast("Thumbnail saved.");
            } else {
              toast.clear();
              toast("File too large!");
            }
            thumbnailFileInput.value.value = "";
          }
        });
      }
    });

    return {
      driver,
      driverDetails,
      thumbnailFileInput,
      thumbnailSrc,
      toDrivers() {
        router.push({ name: `${store.state.account.type}/Drivers` });
      },
      uploadImage() {
        if (thumbnailFileInput.value) {
          thumbnailFileInput.value.click();
        }
      },
      keepTruckinLink,
      viewInKeepTruckin() {
        window.open(keepTruckinLink.value, "_blank");
      },
      async removeDriver() {
        try {
          toast("Requesting driver removal.");
          await store.dispatch("removeDriverFromAccount", driver.value);
          store.dispatch("getDrivers");
          toast.clear();
          toast("Driver requested to be removed.");
        } catch (err) {
          toast.clear();
          toast("Failed to request driver removal.");
        }
      },
      async toggleDriverStatus() {
        const driverStatus = driver.value.status;
        try {
          toast(
            `${driverStatus === "Active" ? "Deactivating" : "Activating"} driver.`,
          );
          const savedDriver = await store.dispatch("saveDriverForAccount", {
            entityId: driver.value.entityId,
            status: driverStatus === "Active" ? "Inactive" : "Active",
          });
          store.dispatch("getDrivers");
          driver.value = getDriverObject(savedDriver);
          toast.clear();
          toast(
            `Driver ${savedDriver.status === "Active" ? "activated" : "deactivated"}.`,
          );
        } catch (err) {
          toast.clear();
          toast(
            `Failed to ${driverStatus === "Active" ? "deactivate" : "activate"} driver.`,
          );
        }
      },
      async saveDriver() {
        try {
          toast("Saving driver.");
          const savedDriver = await store.dispatch("saveDriverForAccount", {
            ...driver.value,
            dateOfBirth: moment(driver.value.dateOfBirth, "yyyy-MM-DD")
              .local()
              .utc()
              .format(),
            dateOfHire: moment
              .utc(driver.value.dateOfHire, "yyyy-MM-DD")
              .local()
              .utc()
              .format(),
          });
          store.dispatch("getDrivers");
          driver.value = getDriverObject(savedDriver);
          toast.clear();
          toast("Driver saved.");
        } catch (err) {
          toast.clear();
          toast("Failed to save driver.");
        }
      },
    };
  },
};
</script>
