<template>
  <div class="w-full text-gray-800">
    <div class="w-full flex justify-between items-center mt-8">
      <h1 class="font-bold text-4xl">Claims</h1>
      <button
        @click="
          $router.push({
            name: `${$store.state.account.type}/Claim`,
            params: { id: 'new' },
          })
        "
        class="hidden bg-lula-gradient text-white hover:bg-lula-gradient-alt md:block"
      >
        Submit Claim
      </button>
    </div>
    <div class="w-full mt-4">
      <div :class="{ hidden: !claimsGridLoaded }" ref="claimsGridRef" />
      <div
        :class="{ hidden: claimsGridLoaded }"
        class="animate-pulse bg-white w-full border rounded p-4"
      >
        <div class="bg-gray-100 w-full h-4 rounded" />
      </div>
    </div>
    <button
      @click="
        $router.push({
          name: `${$store.state.account.type}/Claim`,
          params: { id: 'new' },
        })
      "
      class="w-full bg-lula-gradient text-white hover:bg-lula-gradient-alt md:hidden"
    >
      Submit Claim
    </button>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import {
  initializeShadowDom,
  fetchFrontend,
  mountFrontend,
} from "@getaddify/lula-components";
import { useAuth } from "../auth";

export default {
  setup() {
    const store = useStore();
    const router = useRouter();

    const claimsGridRef = ref(null);
    const claimsGridLoaded = ref(false);

    onMounted(async () => {
      if (claimsGridRef.value) {
        const [gridShadow, gridRoot] = initializeShadowDom(claimsGridRef.value);
        await fetchFrontend(gridShadow, process.env["VUE_APP_CLAIMS_UI_URL"]);
        const auth = await useAuth();
        const headers = await auth.requestHeaders();
        mountFrontend("claims-grid", gridRoot, {
          mode: "trucks",
          graphUrl: process.env["VUE_APP_CLAIMS_GRAPH"],
          graphHeaders: headers,
          vehicles: store.state.vehicles,
          onMounted() {
            claimsGridLoaded.value = true;
          },
          selectClaim(claim) {
            router.push({
              name: `${store.state.account.type}/Claim`,
              params: { id: claim.id },
            });
          },
        });
      }
    });

    return { claimsGridRef, claimsGridLoaded };
  },
};
</script>
