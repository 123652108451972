<template>
  <div class="w-full py-8">
    <div class="flex items-center font-bold text-gray-600 text-2xl mb-4">
      <span class="mr-2 cursor-pointer hover:underline" @click="toFleet">
        Fleet
      </span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-6 w-6 mt-px"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M9 5l7 7-7 7"
        />
      </svg>
      <span class="ml-2"> Vehicle </span>
    </div>
    <div v-if="vehicle">
      <div class="hidden mb-4 md:block">
        <h1 class="text-gray-800 font-bold mb-1 text-4xl">
          <span v-if="vehicle.key">
            {{ vehicle.key }}
          </span>
          <span v-else>
            {{ vehicle.make }} {{ vehicle.model }} {{ vehicle.year }}
          </span>
        </h1>
        <div class="text-gray-600 text-lg">
          <span class="font-bold mr-1">VIN</span>
          <span>{{ vehicle.vin }}</span>
        </div>
      </div>
    </div>
    <div class="flex flex-col md:flex-row">
      <div class="relative w-full md:w-1/3">
        <div
          @click="uploadImage"
          class="relative w-full m-auto cursor-pointer sm:w-1/2 md:w-full"
        >
          <input
            type="file"
            class="hidden"
            accept=".jpeg,.jpg,.png"
            multiple="false"
            ref="thumbnailFileInput"
          />
          <button
            class="absolute shadow-sm text-xs py-2 px-6 right-2 bottom-2"
            v-if="!thumbnailSrc"
          >
            Upload Image
          </button>
          <img
            :src="thumbnailSrc"
            class="w-full max-h-64 object-cover border rounded-md"
            v-if="thumbnailSrc"
          />
          <img
            src="../assets/truck-placeholder.svg"
            class="w-full border rounded-md"
            v-else
          />
        </div>
        <div
          class="bg-white p-4 text-gray-600 rounded-md border text-lg md:text-base lg:text-lg mt-2"
        >
          <div class="mb-4 md:hidden">
            <h1 class="text-gray-800 font-bold mb-1 text-4xl">
              <span v-if="vehicle.key">
                {{ vehicle.key }}
              </span>
              <span v-else>
                {{ vehicle.make }} {{ vehicle.model }} {{ vehicle.year }}
              </span>
            </h1>
            <div class="text-gray-600 text-lg">
              <span class="font-bold mr-1">VIN</span>
              <span>{{ vehicle.vin }}</span>
            </div>
          </div>
          <hr class="my-2 md:hidden" />
          <span class="font-bold text-gray-800">Last Modified:</span>
          {{ formatVehicleDate(vehicle.modified) }}
          <br />
          <span class="font-bold text-gray-800">Created:</span>
          {{ formatVehicleDate(vehicle.created) }}
          <hr class="my-2 md:hidden" v-if="vehicle.status === 'Inactive'" />
          <div
            class="flex justify-left mx-1 mt-2.5 cursor-pointer text-gray-600 hover:text-gray-800 md:hidden"
            v-if="vehicle.status === 'Inactive'"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
              />
            </svg>
            <span @click="removeVehicleFromFleet" class="mx-1">
              Request to be removed from fleet
            </span>
          </div>
        </div>
        <div
          class="bg-white p-2 text-gray-600 rounded-md border text-lg md:text-base lg:text-lg mt-2"
          v-if="keepTruckinLink"
        >
          <div
            @click="viewInKeepTruckin"
            class="flex items-center p-2 cursor-pointer hover:bg-gray-100"
          >
            <img alt="keep truckin" class="w-6 h-6" src="/keeptruckin.ico" />
            <span class="mx-2">View in KeepTruckin</span>
          </div>
        </div>
        <div
          class="bg-white text-lg rounded-md border mt-2 sm:text-base lg:text-lg"
          v-if="$store.state.accountMetrics"
        >
          <div class="p-4 pb-1">
            <div v-if="vehicle.status === 'Active'">
              <div class="flex justify-between items-center">
                <span class="text-gray-800">
                  This vehicle is covered by GetAddify
                </span>
                <div class="relative">
                  <svg
                    @click="showOptions = !showOptions"
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6 text-gray-800 cursor-pointer"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="
                          M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1
                          1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0
                          110-2 1 1 0 010 2z
                        "
                    />
                  </svg>
                  <div
                    class="absolute w-max py-2 right-0 shadow-sm bg-white rounded-md border"
                    v-if="showOptions"
                  >
                    <div
                      @click="handleSubscription"
                      class="py-2 px-4 cursor-pointer text-gray-800 hover:bg-gray-100"
                    >
                      Request Vehicle Deactivation
                    </div>
                  </div>
                </div>
              </div>
              <img
                src="../assets/shield-check.svg"
                alt="covered"
                class="mt-1 w-16 h-16 md:w-12 md:h-12"
              />
            </div>
            <div v-else-if="vehicle.insuranceCriteriaStatus === 'Declined'">
              <span class="text-gray-800"> Declined for coverage </span>
            </div>
            <div v-else-if="vehicle.insuranceCriteriaStatus === 'Under Review'">
              <span class="text-gray-800"> Vehicle is pending approval </span>
            </div>
            <div v-else-if="accountMetrics.remainingSeats > 0">
              <span class="text-gray-800"> Activate this vehicle </span>
              <br />
              <span class="font-bold text-gray-800 text-2xl">
                Eligible for coverage
              </span>
            </div>
            <div v-else>
              <span class="text-gray-800"> Activate this vehicle for </span>
              <br />
              <span class="font-bold text-gray-800 text-3xl">
                ${{ parseFloat(activationPrice).toFixed(2) }}
              </span>
            </div>
          </div>
          <div class="my-2" v-if="vehicle.status === 'Active'" />
          <button
            @click="handleSubscription"
            class="rounded-none border-t rounded-b-md w-full py-1 mt-2 bg-lula-gradient text-white hover:bg-lula-gradient-alt"
            v-else-if="
              $store.state.account.status === 'Active' &&
              vehicle.insuranceCriteriaStatus === 'Approved'
            "
          >
            {{
              accountMetrics.remainingSeats > 0
                ? "Activate Now"
                : "Pay and Activate"
            }}
          </button>
          <div class="my-2" v-else />
        </div>
        <div
          class="hidden items-center mx-1 mt-2.5 cursor-pointer text-gray-600 hover:text-gray-800 md:flex"
          v-if="vehicle.status === 'Inactive'"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
            />
          </svg>
          <span @click="removeVehicleFromFleet" class="mx-1">
            Request to be removed from fleet
          </span>
        </div>
      </div>
      <div class="w-full mt-2 md:mx-2 md:mt-0 md:w-2/3">
        <div class="w-full bg-white rounded-md border">
          <form class="p-4" @submit.prevent="saveVehicle">
            <div class="grid text-lg grid-cols-1 md:grid-cols-2">
              <div
                class="text-gray-800 m-1"
                :class="{ 'md:col-span-2': detail.key === 'key' }"
                v-for="detail in vehicleDetails"
                :key="detail.key"
              >
                <label class="font-bold">
                  {{ detail.label }}
                </label>
                <br />
                <state-select
                  :required="detail.required"
                  v-model="vehicle[detail.key]"
                  v-if="detail.key === 'domicileState'"
                />
                <input
                  :type="detail.type"
                  :disabled="detail.disabled"
                  :required="detail.required"
                  v-model="vehicle[detail.key]"
                  v-else
                />
              </div>
            </div>
            <div class="flex justify-end mt-2">
              <button
                class="bg-lula-gradient text-white hover:bg-lula-gradient-alt"
              >
                Save Vehicle
              </button>
            </div>
          </form>
        </div>
        <div class="mt-8">
          <div class="flex justify-between items-center mb-2">
            <h2 class="font-bold text-2xl mx-1">Episode History</h2>
            <button
              @click="bookEpisode"
              class="hidden bg-lula-gradient text-white font-bold hover:bg-lula-gradient-alt md:block"
              v-if="
                vehicle.status === 'Active' &&
                getLaunchDarklyFlag('episode-manual')
              "
            >
              Book an Episode
            </button>
          </div>
          <div ref="episodehistory" />
          <button
            @click="bookEpisode"
            class="w-full mt-2 bg-lula-gradient text-white font-bold hover:bg-lula-gradient-alt md:hidden"
          >
            Book an Episode
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { useToast } from "vue-toastification";
import {
  initializeShadowDom,
  fetchFrontend,
  mountFrontend,
} from "@getaddify/lula-components";
import { Subject } from "rxjs";
import {
  getBase64,
  getMegabytesFromBytes,
  sortEpisodeStartDescending,
} from "@/utils";
import moment from "moment";

function getVehicleObject(vehicle, account) {
  return vehicle
    ? { ...vehicle, domicileState: vehicle.domicle || account.garagingState }
    : {};
}

export default {
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const toast = useToast();

    const episodes = new Subject();
    const episodehistory = ref(null);

    onMounted(async () => {
      const fetchedEpisodes = await store.dispatch("getEpisodes");
      const [shadow, root] = initializeShadowDom(episodehistory.value);
      await fetchFrontend(shadow, process.env["VUE_APP_EPISODES_UI_URL"]);
      mountFrontend("episode-vehicle-history", root, {
        episodes,
        emptyMessage: "No Past Episodes",
        selectEpisode(episode) {
          router.push({
            name: `${store.state.account.type}/Episode`,
            params: { id: episode.entityId },
          });
        },
      });
      episodes.next(
        sortEpisodeStartDescending(
          fetchedEpisodes
            .filter(({ vehicle }) => vehicle === route.params.id)
            .map((episode) => {
              const vehicle = store.state.vehicles.find(
                ({ entityId }) => entityId === episode.vehicle,
              );
              return {
                ...episode,
                vehicle,
              };
            }),
        ),
      );
    });

    const keepTruckinLink = computed(() => {
      if (vehicle.value.keeptruckinId) {
        return `https://web.keeptruckin.com/en-US/#/fleetview/vehicles/${vehicle.value.keeptruckinId}`;
      }
      return null;
    });

    const thumbnailFileInput = ref(null);
    const thumbnailSrc = ref("");

    onMounted(() => {
      thumbnailSrc.value = vehicle.value?.thumbnail || "";
      if (thumbnailFileInput.value) {
        thumbnailFileInput.value.addEventListener("change", async () => {
          const file = [...thumbnailFileInput.value.files].pop();
          if (file) {
            const sizeMb = getMegabytesFromBytes(file.size);
            if (sizeMb < 5) {
              const baseString = await getBase64(file);
              thumbnailSrc.value = baseString;
              toast("Saving thumbnail.");
              vehicle.value = await store.dispatch("saveVehicleForAccount", {
                entityId: vehicle.value.entityId,
                thumbnail: thumbnailSrc.value,
              });
              store.dispatch("getVehicles");
              toast.clear();
              toast("Thumbnail saved.");
            } else {
              thumbnailFileInput.value.value = "";
              toast.clear();
              toast("File too large!");
            }
          }
        });
      }
    });

    const vehicle = ref(
      getVehicleObject(
        store.state.vehicles.find((v) => v.entityId === route.params.id),
        store.state.account,
      ),
    );

    onMounted(async () => {
      const vehicles = await store.dispatch("getVehicles");
      vehicle.value =
        vehicles.find((v) => v.entityId === route.params.id) || {};
      await store.dispatch("getMetrics");
    });

    const showOptions = ref(false);

    return {
      vehicle,
      episodehistory,
      accountMetrics: computed(() => {
        return store.state.accountMetrics;
      }),
      activationPrice: computed(() => {
        const standardDeposit =
          vehicle.value.statusHistory.length > 0
            ? 0
            : store.state.accountMetrics.standardDeposit;
        const seatCost = parseFloat(store.state.accountMetrics.seatCost);
        return parseFloat(seatCost + standardDeposit).toFixed(2);
      }),
      showOptions,
      vehicleDetails: [
        {
          label: "Vehicle Nickname",
          key: "key",
          type: "text",
          disabled: false,
          required: false,
        },
        {
          label: "License Plate",
          key: "plate",
          type: "text",
          disabled: false,
          required: true,
        },
        {
          label: "Domicile State",
          key: "domicileState",
          type: "text",
          disabled: false,
          required: true,
        },
        {
          label: "Make",
          key: "make",
          type: "text",
          disabled: true,
          required: false,
        },
        {
          label: "Model",
          key: "model",
          type: "text",
          disabled: true,
          required: false,
        },
        {
          label: "Year",
          key: "year",
          type: "text",
          disabled: true,
          required: false,
        },
        {
          label: "Class",
          key: "weight",
          type: "text",
          disabled: true,
          requred: false,
        },
      ],
      toFleet() {
        router.push({ name: `${store.state.account.type}/Fleet` });
      },
      keepTruckinLink,
      viewInKeepTruckin() {
        window.open(keepTruckinLink.value, "_blank");
      },
      bookEpisode() {
        router.push({
          name: `${store.state.account.type}/EpisodesScheduled`,
          query: { vehicle: vehicle.value.entityId },
        });
      },
      formatVehicleDate(dateString) {
        return moment.utc(dateString).local().format("MM/DD/yyyy");
      },
      thumbnailSrc,
      thumbnailFileInput,
      uploadImage() {
        if (thumbnailFileInput.value) {
          thumbnailFileInput.value.click();
        }
      },
      async handleSubscription() {
        showOptions.value = false;
        if (
          confirm(
            `Are you sure you want to ${vehicle.value.status === "Inactive" ? "activate" : "deactivate"} vehicle.`,
          )
        ) {
          let confirmMessage = "";
          if (vehicle.value.status === "Inactive") {
            toast("Activating vehicle.");
            confirmMessage = "Vehicle activated";
          } else {
            toast("Requesting vehicle deactivation.");
            confirmMessage = "Vehicle deactivation requested.";
          }
          try {
            vehicle.value = await store.dispatch(
              "subscribeVehicleForAccount",
              vehicle.value,
            );
            store.dispatch("getVehicles");
            toast.clear();
            toast(confirmMessage);
          } catch (err) {
            toast.clear();
            toast(
              `Failed to ${vehicle.value.status === "Inactive" ? "activate" : "deactivate"} vehicle.`,
            );
          }
          await store.dispatch("getMetrics");
        }
      },
      async saveVehicle() {
        try {
          toast("Saving vehicle.");
          vehicle.value = await store.dispatch(
            "saveVehicleForAccount",
            vehicle.value,
          );
          store.dispatch("getVehicles");
          toast.clear();
          toast("Vehicle saved.");
        } catch (err) {
          toast.clear();
          toast("Failed to save vehicle.");
        }
        await store.dispatch("getMetrics");
      },
      async removeVehicleFromFleet() {
        if (confirm("Are you sure you want to remove this vehicle?")) {
          try {
            toast("Requesting vehicle to be removed.");
            vehicle.value = await store.dispatch(
              "removeVehicleFromAccount",
              vehicle.value,
            );
            toast.clear();
            toast("Vehicle has been requested to be removed.");
          } catch (err) {
            toast.clear();
            toast("Failed to request removal.");
          }
        }
      },
    };
  },
};
</script>
