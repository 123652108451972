<template>
  <router-view class="w-full" />
</template>

<script>
import { computed, watch, onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

function setDefaultRoute(
  store,
  router,
  children,
  currentRouteName,
  defaultPath,
) {
  if (currentRouteName.value === `${store.state.account.type}/${defaultPath}`) {
    const name = children.value[0].name;
    router.replace({ name });
  }
}

export default {
  setup() {
    const store = useStore();
    const router = useRouter();

    const children = computed(() => {
      try {
        if (router.currentRoute.value.matched[0].children.length) {
          return router.currentRoute.value.matched[0].children;
        }
        return [];
      } catch (err) {
        return [];
      }
    });

    const currentRouteName = computed(() => {
      return router.currentRoute.value.name;
    });

    watch(currentRouteName, () =>
      setDefaultRoute(store, router, children, currentRouteName, "Account"),
    );
    onMounted(() =>
      setDefaultRoute(store, router, children, currentRouteName, "Account"),
    );

    return {};
  },
};
</script>
