<template>
  <div class="w-full py-8">
    <div class="flex items-center font-bold text-gray-600 text-2xl mb-4">
      <span class="mr-2 cursor-pointer hover:underline" @click="toEpisodes">
        Episodes
      </span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-6 w-6 mt-px"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M9 5l7 7-7 7"
        />
      </svg>
      <span class="ml-2"> Episode </span>
    </div>
    <div ref="episodedetail" />
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { Subject } from "rxjs";
import {
  initializeShadowDom,
  fetchFrontend,
  mountFrontend,
} from "@getaddify/lula-components";
import { useAuth } from "../auth";

export default {
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    const episode = new Subject();
    const episodedetail = ref(null);

    onMounted(async () => {
      const episodes = await store.dispatch("getEpisodes");
      const detail = episodes.find(
        ({ entityId }) => entityId === route.params.id,
      );
      detail.vehicle = store.state.vehicles.find(
        ({ entityId }) => entityId === detail.vehicle,
      );
      detail.driver = store.state.drivers.find(
        ({ entityId }) => entityId === detail.driver,
      );
      const [shadow, root] = initializeShadowDom(episodedetail.value);
      await fetchFrontend(shadow, process.env["VUE_APP_EPISODES_UI_URL"]);

      const auth = await useAuth();
      const headers = await auth.requestHeaders();

      mountFrontend("episode-detail", root, {
        episode,
        graphHeaders: headers,
        selectVehicle(vehicle) {
          router.push({
            name: `${store.state.account.type}/Vehicle`,
            params: { id: vehicle.entityId },
          });
        },
        selectDriver(driver) {
          router.push({
            name: `${store.state.account.type}/Driver`,
            params: { id: driver.entityId },
          });
        },
      });
      episode.next(detail);
    });

    return {
      episodedetail,
      toEpisodes() {
        router.push({ name: `${store.state.account.type}/Episodes` });
      },
    };
  },
};
</script>
