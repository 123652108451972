<template>
  <div class="w-full py-8">
    <div>
      <div class="flex justify-between items-start mb-2">
        <h1 class="font-bold text-gray-800 text-4xl text-left">My Account</h1>
        <button
          @click="manageBilling"
          class="hidden bg-lula-gradient text-white font-bold hover:bg-lula-gradient-alt md:block"
        >
          Manage Billing
        </button>
      </div>
      <div class="flex flex-col md:flex-row">
        <div
          class="transition w-full flex flex-row justify-between bg-white rounded-lg shadow-lg p-4 py-6 my-1 md:mr-1 md:flex-col hover:shadow-xl"
        >
          <img
            src="../assets/truck-icon.svg"
            class="hidden w-12 mb-2 md:block"
          />
          <div class="flex flex-col">
            <span class="text-gray-600 text-sm my-1">Subscribed Vehicles</span>
            <span class="text-gray-800 font-bold text-4xl">
              {{ accountMetrics.activeVehicleCount }}
            </span>
          </div>
          <img src="../assets/truck-icon.svg" class="w-12 mb-2 md:hidden" />
        </div>
        <div
          class="transition w-full flex flex-row justify-between bg-white rounded-lg shadow-lg p-4 py-6 my-1 md:mr-1 md:flex-col hover:shadow-xl"
        >
          <img
            src="../assets/truck-icon.svg"
            class="hidden w-12 mb-2 md:block"
          />
          <div class="flex flex-col">
            <span class="text-gray-600 text-sm my-1">Total Vehicles</span>
            <span class="text-gray-800 font-bold text-4xl">
              {{ accountMetrics.totalVehicleCount }}
            </span>
          </div>
          <img src="../assets/truck-icon.svg" class="w-12 mb-2 md:hidden" />
        </div>
        <div
          class="transition w-full flex flex-row justify-between bg-white rounded-lg shadow-lg p-4 py-6 my-1 md:mr-1 md:flex-col hover:shadow-xl"
        >
          <img
            src="../assets/truck-icon.svg"
            class="hidden w-12 mb-2 md:block"
          />
          <div class="flex flex-col">
            <span class="text-gray-600 text-sm my-1">Subscription Spots</span>
            <span class="text-gray-800 font-bold text-4xl">
              {{ accountMetrics.subscriptionSpots }}
            </span>
          </div>
          <img src="../assets/truck-icon.svg" class="w-12 mb-2 md:hidden" />
        </div>
        <div
          class="transition w-full flex flex-row justify-between bg-white rounded-lg shadow-lg p-4 py-6 my-1 md:mr-1 md:flex-col hover:shadow-xl"
        >
          <img
            src="../assets/driver-icon.svg"
            class="hidden w-12 mb-2 md:block"
          />
          <div class="flex flex-col">
            <span class="text-gray-600 text-sm my-1">Subscription Cost</span>
            <span class="text-gray-800 font-bold text-4xl">
              ${{ $store.state.accountMetrics?.monthlySubscription || 0 }}
            </span>
          </div>
          <img src="../assets/driver-icon.svg" class="w-12 mb-2 md:hidden" />
        </div>
        <div
          @click="manageBilling"
          class="transition text-center w-full rounded-lg my-1 shadow-lg bg-lula-gradient text-white font-bold hover:bg-lula-gradient-alt p-4 cursor-pointer md:hidden hover:shadow-xl"
        >
          Manage Billing
        </div>
        <div
          @click="$store.dispatch('signOut')"
          class="transition text-center w-full rounded-lg my-1 shadow-lg bg-white font-bold hover:bg-gray-300 p-4 cursor-pointer md:hidden hover:shadow-xl"
        >
          Logout
        </div>
      </div>
    </div>
    <div class="bg-white rounded-md border p-4 mt-4">
      <h2 class="text-gray-800 font-bold text-2xl">Company Details</h2>
      <hr class="my-2" />
      <form @submit.prevent="saveCompany">
        <div class="grid grid-cols-1 text-lg md:grid-cols-2">
          <div v-for="detail in details" :key="detail.key" class="p-1">
            <label class="font-bold text-gray-800">
              {{ detail.label }}
            </label>
            <br />
            <select
              v-model="account[detail.key]"
              v-if="detail.key === 'authorityType'"
            >
              <option value="intrastate">Intrastate</option>
              <option value="interstate">Interstate</option>
            </select>
            <input :type="detail.type" v-model="account[detail.key]" v-else />
          </div>
        </div>
        <div>
          <h3 class="text-gray-800 font-bold text-xl mt-2">Business Address</h3>
          <hr class="my-2" />
          <div class="grid grid-cols-1 text-lg md:grid-cols-3">
            <div
              v-for="detail in businessAddressDetails"
              :key="detail.key"
              :class="{
                'md:col-span-2': detail.key === 'businessAddressLineOne',
              }"
              class="p-1"
            >
              <label class="font-bold text-gray-800">
                {{ detail.label }}
              </label>
              <br />
              <state-select
                v-model="account[detail.key]"
                v-if="detail.key === 'state'"
              />
              <input :type="detail.type" v-model="account[detail.key]" v-else />
            </div>
          </div>
        </div>
        <div>
          <h3 class="text-gray-800 font-bold text-xl mt-2">Garaging Address</h3>
          <hr class="my-2" />
          <div class="grid grid-cols-1 text-lg md:grid-cols-3">
            <div
              v-for="detail in garagingAddressDetails"
              :key="detail.key"
              :class="{
                'md:col-span-2': detail.key === 'garagingAddressLineOne',
              }"
              class="p-1"
            >
              <label class="font-bold text-gray-800">
                {{ detail.label }}
              </label>
              <br />
              <state-select
                v-model="account[detail.key]"
                v-if="detail.key === 'garagingState'"
              />
              <input :type="detail.type" v-model="account[detail.key]" v-else />
            </div>
          </div>
        </div>
        <div class="flex justify-end mt-2">
          <button
            type="submit"
            class="text-white bg-lula-gradient font-bold hover:bg-lula-gradient-alt"
          >
            Save Company
          </button>
        </div>
      </form>
    </div>
    <div class="bg-white rounded-md border p-4 mt-4">
      <div class="flex items-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          stroke-width="2"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="
                M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724
                0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724
                0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724
                0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724
                0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724
                0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724
                0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724
                0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07
                2.572-1.065z"
          />
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
          />
        </svg>
        <h2 class="text-gray-800 font-bold text-2xl mx-2">Integrations</h2>
      </div>
      <hr class="my-2" />
      <div class="flex">
        <table class="table-auto w-full text-gray-600 text-lg">
          <caption class="hidden">
            Show the service provider details
          </caption>
          <thead>
            <tr>
              <th class="text-left text-gray-800">Service Provider</th>
              <th class="hidden text-left text-gray-800 md:table-cell">
                Connection Status
              </th>
              <th class="hidden text-left text-gray-800 md:table-cell">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            <tr class="py-4">
              <td class="py-2">
                <div class="flex justify-between items-center">
                  <span class="hidden md:inline">
                    GoMotive (formerly KeepTruckin)
                  </span>
                  <span class="md:hidden"> GoMotive </span>
                  <span
                    v-if="!telematics.isInitialized"
                    class="text-sm py-2 mr-2 md:hidden"
                  >
                    Loading...
                  </span>
                  <span
                    v-else-if="
                      telematics.isInitialized && telematics.motive.isConnected
                    "
                    class="text-sm py-2 mr-2 md:hidden"
                  >
                    Connected
                  </span>
                  <a
                    :href="motiveIntegrationUrl"
                    v-else
                    class="bg-lula-gradient hover:bg-lula-gradient-alt px-4 py-2 rounded-full text-white text-sm md:hidden"
                  >
                    Connect
                  </a>
                </div>
              </td>
              <td class="hidden py-2 md:table-cell">
                <div>
                  <span v-if="!telematics.isInitialized"> Loading... </span>
                  <span v-else-if="telematics.motive.isConnected">
                    Connected
                  </span>
                  <span v-else> Not Connected </span>
                </div>
              </td>
              <td class="hidden py-2 md:table-cell">
                <a
                  :href="motiveIntegrationUrl"
                  v-if="
                    telematics.isInitialized && !telematics.motive.isConnected
                  "
                  class="bg-lula-gradient hover:bg-lula-gradient-alt px-4 py-2 rounded-full text-white"
                >
                  Connect Now
                </a>
              </td>
            </tr>
            <tr class="py-4">
              <td class="py-2">
                <div class="flex justify-between items-center">
                  <span>Samsara</span>
                  <span
                    v-if="!telematics.isInitialized"
                    class="text-sm py-2 mr-2 md:hidden"
                  >
                    Loading...
                  </span>
                  <span v-else-if="false" class="text-sm py-2 mr-2 md:hidden">
                    Connected
                  </span>
                  <a
                    :href="samsaraIntegrationUrl"
                    v-else
                    class="bg-lula-gradient hover:bg-lula-gradient-alt px-4 py-2 rounded-full text-white text-sm md:hidden"
                  >
                    Connect
                  </a>
                </div>
              </td>
              <td class="hidden py-2 md:table-cell">
                <div>
                  <span v-if="!telematics.isInitialized"> Loading... </span>
                  <span v-else-if="telematics.samsara.isConnected">
                    Connected
                  </span>
                  <span v-else> Not Connected </span>
                </div>
              </td>
              <td class="hidden py-2 md:table-cell">
                <a
                  :href="samsaraIntegrationUrl"
                  v-if="
                    telematics.isInitialized && !telematics.samsara.isConnected
                  "
                  class="bg-lula-gradient hover:bg-lula-gradient-alt px-4 py-2 rounded-full text-white"
                >
                  Connect Now
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="border-t mt-3 pt-4" v-show="geotabCredentials.showForm">
        <h4 class="text-gray-800 font-bold">Geotab Credentials</h4>
        <br />
        <span class="py-2 font-bold text-gray-600"
          >Username: <input v-model="geotabCredentials.username"
        /></span>
        <span class="py-2 font-bold text-gray-600"
          >Password: <input v-model="geotabCredentials.password"
        /></span>
        <span class="py-2 font-bold text-gray-600"
          >Database: <input v-model="geotabCredentials.database"
        /></span>
        <div class="flex justify-between pt-2">
          <span />
          <button
            class="w-full bg-lula-gradient text-white py-2 text-sm hover:bg-lula-gradient-alt md:text-lg md:w-min"
            @click="connectGeotab"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted } from "vue";
import { useStore } from "vuex";
import { useToast } from "vue-toastification";
import moment from "moment";

function getAccountObject(account) {
  return account
    ? {
        ...account,
        operationalSince: moment
          .utc(account.operationalSince)
          .local()
          .format("yyyy-MM-DD"),
      }
    : {};
}

const details = [
  { label: "Company Name", key: "businessLegalName", type: "text" },
  { label: "Business Email", key: "businessEmail", type: "text" },
  { label: "Business Phone", key: "businessPhone", type: "text" },
  { label: "DOT Number", key: "dotNumber", type: "text" },
  { label: "MC Number", key: "mcNumber", type: "text" },
  { label: "Type of Authority", key: "authorityType", type: "text" },
  { label: "Operational Since", key: "operationalSince", type: "date" },
];

const businessAddressDetails = [
  { label: "Address Line 1", key: "businessAddressLineOne", type: "text" },
  { label: "Address Line 2", key: "businessAddressLineTwo", type: "text" },
  { label: "City", key: "businessCity", type: "text" },
  { label: "Zipcode", key: "businessZipcode", type: "text" },
  { label: "State", key: "state", type: "text" },
];

const garagingAddressDetails = [
  { label: "Address Line 1", key: "garagingAddressLineOne", type: "text" },
  { label: "Address Line 2", key: "garagingAddressLineTwo", type: "text" },
  { label: "City", key: "garagingCity", type: "text" },
  { label: "Zipcode", key: "garagingZipcode", type: "text" },
  { label: "State", key: "garagingState", type: "text" },
];

export default {
  setup() {
    const store = useStore();
    const account = ref(getAccountObject(store.state.account));
    const toast = useToast();

    const motiveIntegrationUrl = new URL(
      process.env["VUE_APP_MOTIVE_OAUTH_URL"],
    );
    const samsaraIntegrationUrl = new URL(
      process.env["VUE_APP_SAMSARA_OAUTH_URL"],
    );
    samsaraIntegrationUrl.searchParams.set(
      "state",
      Math.floor(Math.random() * Number.MAX_SAFE_INTEGER),
    );

    const telematics = ref({
      isInitialized: false,
      samsara: {
        isConnected: false,
      },
      motive: {
        isConnected: false,
      },
    });

    onMounted(async () => {
      const telematicsResponse = await store.dispatch(
        "getTelematicsAccountStatus",
        account.value.entityId,
      );

      if (!telematicsResponse.success) {
        console.error(
          "[ACCTDTL] Cannot get the Telematics account status. Error: ",
          telematicsResponse.error,
        );
        return;
      }

      const telematicsAccount = telematicsResponse.data;

      const samsara = telematicsAccount.integrations.find(
        (item) => item.type === "samsara",
      );
      if (samsara) {
        telematics.value.samsara.isConnected = samsara.status === "connected";
      }

      const motive = telematicsAccount.integrations.find(
        (item) => item.type === "motive",
      );
      if (motive) {
        telematics.value.motive.isConnected = motive.status === "connected";
      }

      telematics.value.isInitialized = true;
    });

    const integrationsEdit = ref([]);
    onMounted(() => store.dispatch("getMetrics"));
    const geotabCredentials = ref({
      username: null,
      password: null,
      database: null,
      showForm: false,
    });
    return {
      account,
      details,
      businessAddressDetails,
      garagingAddressDetails,
      integrationsEdit,
      motiveIntegrationUrl: motiveIntegrationUrl.toString(),
      geotabCredentials,
      telematics,
      samsaraIntegrationUrl: samsaraIntegrationUrl.toString(),
      accountMetrics: computed(() => {
        return {
          totalVehicleCount: store.state.vehicles.length,
          activeVehicleCount: store.state.vehicles.filter((v) => {
            return v.status === "Active";
          }).length,
          subscriptionSpots: store.state.account.spots || 0,
        };
      }),
      async manageBilling() {
        try {
          store.commit("setLoading", true);
          const portal = await store.dispatch("getAccountStripePortal", {
            returnUrl: window.location.origin,
          });
          window.location.replace(portal);
        } catch (err) {
          store.commit("setLoading", false);
        }
      },
      async saveCompany() {
        toast("Saving company.");
        const saveDetails = {};
        [
          ...details,
          ...businessAddressDetails,
          ...garagingAddressDetails,
        ].forEach((detail) => {
          saveDetails[detail.key] = account.value[detail.key];
          if (detail.key === "operationalSince") {
            saveDetails[detail.key] = moment(
              account.value[detail.key],
              "yyyy-MM-DD",
            )
              .local()
              .utc()
              .format();
          }
        });
        try {
          account.value = getAccountObject(
            await store.dispatch("saveDetailsForAccount", saveDetails),
          );
          toast.clear();
          toast("Company saved.");
        } catch (err) {
          toast.clear();
          toast("Failed to saved company.");
        }
      },
      async saveIntegration(intKey) {
        toast("Saving integration setting.");
        try {
          account.value = getAccountObject(
            await store.dispatch("saveDetailsForAccount", {
              [intKey]: account.value[intKey],
            }),
          );
          integrationsEdit.value = [];
          toast.clear();
          toast("Integration setting saved.");
        } catch (err) {
          toast.clear();
          toast("Failed to save integration setting.");
        }
      },
      async connectGeotab() {
        const geotabUpdates = {
          geotabIntegrationEnabled: true,
          geotabIntegrationStatus: "validating",
          geotabIntegrationUsername: geotabCredentials.value.username,
          geotabIntegrationPassword: geotabCredentials.value.password,
          geotabIntegrationDatabaseName: geotabCredentials.value.database,
          geotabIntegrationEnabledDate: moment.utc().format("YYYY-MM-DD"),
        };
        toast(`Saving Geotab Credentials`);
        try {
          account.value = getAccountObject(
            await store.dispatch("saveDetailsForAccount", geotabUpdates),
          );
          toast.clear();
          toast(`Credentials Saved`);
          geotabCredentials.value.showForm = false;
        } catch (err) {
          toast.clear();
          toast(`Failed to save`);
        }
      },
    };
  },
};
</script>
