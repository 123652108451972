<template>
  <div class="pt-2 pb-5 px-4 bg-white rounded-xl w-full">
    <div class="p-4 px-8 w-full mx-auto">
      <h1 class="font-bold text-3xl py-2">LulaSafe</h1>
      <div class="flex flex-row content-between justify-between">
        <div class="flex-1">
          <div>Use LulaSafe to assess your driver's risk.</div>

          <div class="my-2 py-4">
            <div class="my-2 font-bold">
              Thank you for using the LulaSafe Driver Assessment Tool.
            </div>

            <div class="my-2">
              For an optimal experience, we are upgrading you to use LulaSafe's
              enhanced web-based application.
            </div>

            <div class="my-2">
              Open the application with the following link:
            </div>

            <div class="my-4 text-xl font-bold">
              <a
                href="https://lulasafe.getaddify.com"
                class="cursor-pointer text-lula hover:underline"
                target="_blank"
              >
                lulasafe.getaddify.com
              </a>
            </div>
          </div>
        </div>
        <div class="flex-2" style="text-align: right">
          <img
            class="hidden md:inline"
            src="@/assets/lulasafe/banner.png"
            alt="Banner"
            style="max-width: 85%; height: auto; margin-left: auto"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, watchEffect, computed } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import moment from "moment-timezone";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { requestHeaders } from "../../auth";

function getAddessFromQueryParams(params) {
  return {
    addressLineOne: params.assesseeAddress1 || "",
    addressLineTwo: params.assesseeAddress2 || "",
    city: params.assesseeCity || "",
    state: params.assesseeStateCode || "",
    zipcode: params.assesseeZipCode || "",
    // Currently hardcoded to United States
    country: "US",
  };
}

function getDriverFromQueryParams(params) {
  let address = getAddessFromQueryParams(params);
  return {
    assesseeFirstName: params.assesseeFirstName || "",
    assesseeLastName: params.assesseeLastName || "",
    assesseeMiddleName: params.assesseeMiddleName || "",
    assesseeEmail: params.assesseeEmail || "",
    assesseePhone: params.assesseePhone || "",
    drivingLicenseId: params.drivingLicenseId || "",
    assesseeDrivingLicenseState: params.assesseeDrivingLicenseState || "",
    assesseeAddress1: address.assesseeAddress1 || "",
    assesseeAddress2: address.assesseeAddress2 || "",
    assesseeCity: address.assesseeCity || "",
    assesseeStateCode: address.assesseeStateCode || "",
    assesseeZipCode: address.assesseeZipCode || "",
    // Currently hardcoded to United States
    assesseeCountry: "US",
    assesseeDateOfBirth: params.assesseeDateOfBirth || "",
    drivingLicenseExpiryDate: params.drivingLicenseExpiryDate || "",
  };
}

function getReservationFromQueryParams(params) {
  if (params.startDate && params.endDate) {
    let startDate = moment(params.startDate).tz(params.tz || moment.tz.guess());
    let endDate = moment(params.endDate).tz(params.tz || moment.tz.guess());
    const isCurrentDST = moment()
      .tz(params.tz || moment.tz.guess())
      .isDST();
    const isStartDateDST = startDate.isDST();
    const isEndDateDST = endDate.isDST();
    if (isCurrentDST) {
      startDate = isStartDateDST ? startDate : startDate.add(1, "h");
      endDate = isEndDateDST ? endDate : endDate.add(1, "h");
    }
    return {
      startDate: startDate.format("yyyy-MM-DDThh:mm"),
      endDate: endDate.format("yyyy-MM-DDThh:mm"),
      tz: params.tz || moment.tz.guess(),
      platform: params.platform || "",
      externalId: params.externalId || "",
    };
  }

  return {
    startDate: "",
    endDate: "",
    tz: params.tz || "",
    platform: params.platform || "",
    externalId: params.externalId || "",
  };
}

async function initializeSession(enabledCheckFlags, riskApiSessionId) {
  const headers = await requestHeaders();
  const createSessionResponse = await axios({
    method: "POST",
    url: `${process.env["VUE_APP_RISK_API"]}/v0.1-beta1/sessions/`,
    withCredentials: true,
    headers,
  });
  enabledCheckFlags.value.none = true;
  enabledCheckFlags.value.criminalCheck =
    createSessionResponse.data.enabledChecks.criminalCheck;
  enabledCheckFlags.value.documentCheck =
    createSessionResponse.data.enabledChecks.documentCheck;
  enabledCheckFlags.value.identityCheck =
    createSessionResponse.data.enabledChecks.tracersIdentityCheck;
  enabledCheckFlags.value.mvrCheck =
    createSessionResponse.data.enabledChecks.mvrCheck;

  enabledCheckFlags.value.none = true;
  if (
    enabledCheckFlags.value.criminalCheck ||
    enabledCheckFlags.value.documentCheck ||
    enabledCheckFlags.value.identityCheck ||
    enabledCheckFlags.value.mvrCheck
  ) {
    enabledCheckFlags.value.none = false;
  }

  if (
    createSessionResponse.status == 201 ||
    createSessionResponse.status == 200
  ) {
    riskApiSessionId.value = createSessionResponse.data.sessionId;
  } else {
    console.log(getErrorMessage(createSessionResponse.status));
    riskApiSessionId.value = null;
  }
}

// The GraphQL mutation is constructed so that it always asks for an assessment id, and then asks for a series of assessments
// to be initiated based on which were indicated as available in the session creation call.
// If we ask for an assessment that isn't enabled, we'll get an error.
function constructGraphQlAssessmentMutation(flags) {
  // If we include a variable that is not used in the operation, GraphQL will throw us an error.
  let needLicense = flags.mvrCheck;
  let needAddress = flags.identityCheck || flags.criminalCheck;
  return (
    "mutation assess ($assessee: InputAssessee!" +
    (needAddress ? ", $address: InputAddress!" : "") +
    (needLicense ? ", $drivingLicense: InputDrivingLicense!" : "") +
    ") { assess { id" +
    (flags.criminalCheck
      ? " checkCriminal (assessee: $assessee, address: $address) { started }"
      : "") +
    (flags.documentCheck
      ? " documentCheckCredentials { stripeIdentityClientSecret stripeIdentityPublishableKey }"
      : "") +
    (flags.identityCheck
      ? " checkIdentity (assessee: $assessee, address: $address) { started }"
      : "") +
    (flags.mvrCheck
      ? " checkMvr (assessee: $assessee, drivingLicense: $drivingLicense) { orderId started }"
      : "") +
    " } }"
  );
}

// Currently GraphQL returns field validation errors in an F# map that has been translated to a string.
// There is no good way to disentangle them into a user-presentable list so we're just looking for substrings that resemble
// certain errors, and turning them into user-worthy sentences.  This is obviously a hack. :D
function detectErrorsInRawErrorMessage(rawString) {
  if (/is not a valid phone number/g.test(rawString)) {
    return "The phone number does not appear to be valid.";
  } else {
    return "Validation error.";
  }
}

function getErrorMessage(statusCode) {
  switch (statusCode) {
    case 401:
      return "Please make sure you're logged in";
    case 403:
      return "It appears you're not authorized to perform driver vetting. Please contact support if you believe this to be an error.";
    case 410:
      return "Your session has expired. Please reload the page.";
    case 422:
      return "Validation problem.";
    case 500:
      return "Server error.";
    case 503:
      return "Service unavailable.";
    default:
      return "Unexpected error.";
  }
}

export default {
  components: {},
  methods: {},
  setup() {
    // eslint-disable-next-line no-unused-vars
    const store = useStore();
    const route = useRoute();

    // eslint-disable-next-line no-unused-vars
    const requestId = uuidv4();
    const riskApiSessionId = ref(null);
    const assessmentForm = ref(getDriverFromQueryParams(route.query));
    const reservation = ref(getReservationFromQueryParams(route.query));

    const platform = route.query.platform;
    const displayScore = ref(false);

    // These are all mutually exclusive
    const checkNotStarted = ref(true);
    const checkInProgress = ref(false);
    const checkFinished = ref(false);

    const addProofOfInsurance = ref(false);

    const resolveAddress = ref(false);
    const resolvePhone = ref(false);
    const resolveEmail = ref(false);

    const score = ref(null);
    const phoneNumber = ref(null);
    const params = ref({ ...assessmentForm.value, ...reservation.value });
    const today = ref(moment().format("YYYY-MM-DDThh:mm"));
    const loadingAnimation = ref("");
    const message = ref("");
    const errorMessage = ref("");
    const enabledCheckFlags = ref({
      none: true,
      criminalCheck: false,
      documentCheck: false,
      identityCheck: false,
      mvrCheck: false,
    });

    // Response data from finished checks.
    // This used to be consolidated into nested objects but it made Vue act erratically.
    const criminalCheckRecords = ref(null);
    const criminalCheckRecordsCount = ref(null);

    const documentCheckScore = ref(null);

    const identityCheckMatchedFields = ref(null);
    const identityCheckScore = ref(null);
    const identityCheckStatus = ref(null);

    const mvrCheckScore = ref(null);

    // Holding area for stripe credentials when running the strips check
    let stripePromise = ref(null);
    let stripePromiseErrorContainSessionCancelled = ref(false);

    watchEffect(errorMessage, () => {
      setTimeout(() => {
        errorMessage.value = "";
      }, 9000);
    });

    onMounted(() => {
      document.title = "LulaSafe";

      setInterval(() => {
        switch (loadingAnimation.value) {
          case "": {
            loadingAnimation.value = ".";
            break;
          }
          case ".": {
            loadingAnimation.value = "..";
            break;
          }
          case "..": {
            loadingAnimation.value = "...";
            break;
          }
          default: {
            loadingAnimation.value = "";
          }
        }
      }, 500);

      initializeSession(enabledCheckFlags, riskApiSessionId);
    });

    return {
      checkNotStarted,
      checkInProgress,
      checkFinished,
      addProofOfInsurance,
      displayScore,
      params,
      resolveAddress,
      resolvePhone,
      resolveEmail,
      phoneNumber,
      platform,
      today,
      enabledCheckFlags,

      criminalCheckRecords,
      criminalCheckRecordsCount,
      documentCheckScore,
      identityCheckMatchedFields,
      identityCheckScore,
      identityCheckStatus,
      mvrCheckScore,

      stripePromiseErrorContainSessionCancelled,
      score: computed(() => {
        return 100 - parseInt(score.value / 5);
      }),
      minEndDate: computed(() => {
        return moment(reservation?.value?.startDate || today.value).format(
          "YYYY-MM-DDThh:mm",
        );
      }),
      minExpDate: computed(() => {
        return moment(reservation?.value?.endDate || today.value).format(
          "YYYY-MM-DD",
        );
      }),
      message: computed(() => {
        return message.value.replace("[[LOAD]]", loadingAnimation.value);
      }),
      errorMessage,
      assessmentForm,
      reservation,
    };
  },
};
</script>
