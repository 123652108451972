<template>
  <form
    @submit.prevent="submitIncidentDetails"
    class="flex min-h-screen items-center justify-center text-gray-800 p-4"
  >
    <div class="flex flex-col w-full md:w-2/3 lg:w-1/2">
      <div class="mb-8">
        <h1 class="text-lula-alt font-bold text-lg">Report a Claim</h1>
        <span class="text-4xl font-bold"> Incident information </span>
      </div>
      <div class="bg-white rounded-lg border p-4 py-8">
        <div class="mb-8">
          <h2 class="font-bold text-2xl">Incident details</h2>
          <p>
            Provide some details about where the incident happened, and what
            occured.
          </p>
        </div>
        <div class="flex flex-col gap-2">
          <div>
            <label class="font-bold"> Incident Date </label>
            <div class="grid grid-cols-4 gap-1 mt-1">
              <input
                class="w-full bg-white border rounded-lg hover:bg-white text-lg col-span-2"
                :max="maxIncidentDate"
                :value="maxIncidentDate"
                disabled
                type="date"
              />
              <input
                class="w-full bg-white border rounded-lg hover:bg-white text-lg col-span-2"
                type="time"
                v-model="incidentTime"
              />
            </div>
          </div>
          <div>
            <label class="font-bold"> Location / Nearest Address </label>
            <div class="grid grid-cols-4 gap-1 mt-1">
              <vue-google-autocomplete
                id="incident-address"
                class="col-span-4 bg-white border rounded-lg text-lg hover:bg-white"
                placeholder="Nearest location to the incident"
                @placechanged="getAddressData"
                country="us"
                required
              />
            </div>
          </div>
          <iframe
            width="100%"
            height="400"
            class="rounded-lg border"
            frameborder="0"
            referrerpolicy="no-referrer-when-downgrade"
            :src="mapPlaceUrl"
            allowfullscreen
            v-if="mapPlaceUrl"
          />
          <hr class="mb-4" />
          <div>
            <label class="font-bold">Description</label>
            <textarea
              class="bg-white text-lg rounded-lg border mt-1 hover:bg-white"
              rows="4"
              minlength="100"
              maxlength="4000"
              placeholder="Tell us what happened"
              v-model="description"
              required
            />
            <div class="flex justify-between">
              <span />
              <span class="text-sm text-gray-400 -mt-1.5">
                {{ description.length }} / 4000 Characters
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="flex justify-end items-center mt-4 gap-4">
        <span @click="onCancel" class="font-bold cursor-pointer"> Cancel </span>
        <button type="submit" class="bg-lula-primary text-white">
          Continue
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import { onMounted, computed, ref } from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import moment from "moment";
import VueGoogleAutocomplete from "vue-google-autocomplete";

export default {
  components: { VueGoogleAutocomplete },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    store.commit("setItem", {
      key: "claimStartDate",
      val: moment.utc(Date.now()).toISOString(),
    });
    const incidentLocation = ref({
      addressLineOne:
        store.state.claimForm?.incidentLocationAddressLineOne ?? "",
      addressLineTwo:
        store.state.claimForm?.incidentLocationAddressLineTwo ?? "",
      city: store.state.claimForm?.incidentLocationCity ?? "",
      state: store.state.claimForm?.incidentLocationState ?? "",
      zipcode: store.state.claimForm?.incidentLocationZipcode ?? "",
    });

    onMounted(() => {
      if (!incidentLocation.value.addressLineOne) return;
      const el = document.getElementById("incident-address");
      if (!el) return;
      el.value = [
        `${incidentLocation.value.addressLineOne ?? ""} ${incidentLocation.value.addressLineTwo ?? ""}`,
        incidentLocation.value.city ?? "",
        incidentLocation.value.state ?? "",
        incidentLocation.value.zipcode ?? "",
      ].join(", ");
    });

    const description = ref(store.state.claimForm?.description ?? "");
    const incidentTime = ref(store.state.claimForm?.incidentTime ?? "");

    return {
      description,
      incidentTime,
      maxIncidentDate: computed(() => {
        if (!store.state.claimForm?.incidentDate)
          return moment().format("yyyy-MM-DD");
        return store.state.claimForm?.incidentDate;
      }),
      submitIncidentDetails() {
        if (!incidentLocation.value.addressLineOne) {
          alert("Please select a location nearest to the incident.");
          return;
        }
        store.commit("setClaimFormItems", {
          incidentTime: incidentTime.value ?? "",
          incidentLocationAddressLineOne:
            incidentLocation.value.addressLineOne ?? "",
          incidentLocationAddressLineTwo:
            incidentLocation.value.addressLineTwo ?? "",
          incidentLocationCity: incidentLocation.value.city ?? "",
          incidentLocationState: incidentLocation.value.state ?? "",
          incidentLocationZipcode: incidentLocation.value.zipcode ?? "",
          description: description.value ?? "",
        });
        if (route.query.edit) {
          router.push({ name: "Claims/ReviewDetails" });
        } else {
          router.push({ name: "Claims/ClaimAttachments" });
        }
      },
      mapPlaceUrl: computed(() => {
        const mapsApiKey = process.env.VUE_APP_GOOGLE_MAPS_APIKEY;
        const baseUrl = "https://www.google.com/maps/embed/v1/place";
        if (incidentLocation.value.addressLineOne) {
          const query = [
            incidentLocation.value.addressLineOne,
            incidentLocation.value.city,
            incidentLocation.value.zipcode,
            incidentLocation.value.state,
          ].join(", ");
          return `${baseUrl}?key=${mapsApiKey}&q=${escape(query)}`;
        }
        return null;
      }),
      getAddressData(addressData) {
        incidentLocation.value.addressLineOne = [
          addressData.street_number,
          addressData.route,
        ].join(" ");
        incidentLocation.value.city = addressData.locality;
        incidentLocation.value.zipcode = addressData.postal_code;
        incidentLocation.value.state = addressData.administrative_area_level_1;
      },
      onCancel() {
        if (confirm("Are you sure you want to start this process over?")) {
          router.push({ name: "Claims/Landing" });
        }
      },
    };
  },
};
</script>
