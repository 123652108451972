<template>
  <div>
    <Navbar
      :options="options"
      v-if="!isOnboarding && !isVehicleAck && !isInactive"
    />
    <main
      class="p-4"
      :class="{
        'flex justify-center max-w-screen-lg m-auto':
          !isOnboarding && !isVehicleAck && !isInactive,
        'flex justify-center items-center w-screen h-screen':
          isOnboarding || isVehicleAck || isInactive,
      }"
    >
      <Loader v-if="loading" />
      <Onboarding v-else-if="isOnboarding" />
      <VehicleAck v-else-if="isVehicleAck" />
      <transition name="fade" v-else>
        <router-view />
      </transition>
    </main>
  </div>
</template>

<script>
import Loader from "@/Loader.vue";
import Navbar from "@/Navbar.vue";
import Onboarding from "@/cars/Onboarding.vue";
import InactiveAccount from "@/cars/InactiveAccount.vue";
import VehicleAck from "@/cars/VehicleAck.vue";
import { mapSystemSettings } from "@/utils";
import moment from "moment";

function getVehicleAckForm(store) {
  try {
    return JSON.parse(store.state.account.vehicleAckForm);
  } catch (err) {
    return {};
  }
}

export default {
  components: {
    Navbar,
    Loader,
    Onboarding,
    InactiveAccount,
    VehicleAck,
  },
  async mounted() {
    const settings = mapSystemSettings(
      await this.$store.dispatch("getSystemSettings"),
    );
    if (settings.carsupportlink) {
      this.supportLink = settings.carsupportlink;
    }
    this.lulaSafeLinkEnabled = await this.$store.dispatch(
      "getLaunchDarklyFlag",
      "lulasafe-link",
    );
    this.billingPageEnabled = await this.$store.dispatch(
      "getLaunchDarklyFlag",
      "show-car-rental-billing-page",
    );
  },
  
  data() {
    return {
      lulaSafeLinkEnabled: false,
      supportLink: "mailto:support@getaddify.com",
    };
  },
  computed: {
    isInactive() {
      return this.$store.state.account?.status === "Inactive";
    },
    user() {
      return this.$store.state.user;
    },
    loading() {
      return this.$store.state.loading;
    },
    isOnboarding() {
      return this.$store.state.account?.status === "Onboarding";
    },
    isVehicleAck() {
      const isVehicleRoute =
        this.$router.currentRoute.value.name ===
        `${this.$store.state.account.type}/Vehicle`;
      const isFleetAck = this.$store.state.vehicleAckFleet;
      if (isVehicleRoute && isFleetAck) {
        return false;
      }
      if (this.$store.state.vehicleAckRemind) {
        return false;
      }
      if (this.$store.state.user && this.$store.state.account) {
        const ackForm = getVehicleAckForm(this.$store);
        if (ackForm.confirmedVehicles) {
          return this.$store.state.vehicleAckView;
        }
        if (!this.$store.state.account.vehicleAck) {
          return false;
        }
        const ackDay = moment.utc(this.$store.state.account.vehicleAck);
        if (ackDay.isValid()) {
          ackDay.add(1, "days");
          return moment().isBefore(ackDay);
        }
      }
      return false;
    },
    options() {
      const vm = this;
      let billingOptions;

      if (this.billingPageEnabled) {
        billingOptions = {
          name: "Billing",
          route: `${vm.$store.state.account.type}/Billing`,
        };
      } else {
        billingOptions = {
          name: "Billing",
          async click() {
            try {
              vm.$store.commit("setLoading", true);
              const portal = await vm.$store.dispatch(
                "getAccountStripePortal",
                {
                  returnUrl: window.location.origin,
                },
              );
              window.location.replace(portal);
            } catch (err) {
              vm.$store.commit("setLoading", false);
            }
          },
        };
      }
      return [
        {
          name: "Claims",
          route: `${vm.$store.state.account.type}/Claims`,
        },
        {
          name: "LulaSafe",
          // route: `${vm.$store.state.account.type}/LulaSafe`,
          redirect: (function () {
            var hostParts = window.location.host.split(".");
            return `https://lulasafe.${hostParts[1]}.${hostParts[2]}`;
          })(),
        },
        billingOptions,
        {
          name: "Support",
          redirect: `/Support`,
        },
        {
          name: "Integrations",
          route: `${vm.$store.state.account.type}/Integrations`,
        },
      ].filter((option) => {
        console.log(`[CARS] Cars: menu option ${option.name}`);
        switch (option.name) {
          case "Billing": {
            return this.$store.state.account?.stripeId;
          }
          case "LulaSafe": {
            return this.lulaSafeLinkEnabled;
          }
          case "Integrations": {
            //Moving forward, we will use coverageType == ORP to represent ORP customers.
            //For now, we need to use the isCarSharing global state to check whether a customer is ORP, this global state is derived from
            //the 'defaultPolicy' field of the account object on firestore.
            const coverageType = this.$store.state.account?.coverageType;
            const isCarSharing = this.$store.state.account?.isCarSharing;
            const isOrpCoverage = coverageType === "ORP";
            const result = isCarSharing || isOrpCoverage;
            console.log(
              `[CARS] Cars: isCarSharing (${isCarSharing}), coverage (${coverageType}), result(${result})`,
            );
            return result;
          }
          default: {
            return true;
          }
        }
      });
    },
  },
};
</script>
