<template>
  <div
    class="h-screen flex justify-center items-center px-2"
    v-if="isAccountMissing"
  >
    <div
      class="bg-white rounded-lg shadow-lg p-4 border-b-4 border-lula w-full md:w-2/3"
    >
      <h1 class="text-2xl text-gray-800 font-bold">Onboarding Not Completed</h1>
      <span
        class="font-bold text-gray-600"
        v-text="
          `
        You have successfully logged in as ${user.email}, but it seems you have not completed onboarding. 
        It could be that you have signed up for Addify with a different email address.
        If you are a new customer, please click the button below to begin onboarding. 
        If you are still having issues, feel free to contact our customer support 
        team at support@getaddify.com for further assistance.
      `
        "
      >
      </span>
      <div class="flex flex-wrap-reverse mt-2 sm:flex-nowrap">
        <button
          class="transition w-full border rounded-lg m-1 hover:bg-gray-100"
          @click="handleGetStartedClick"
        >
          Get Started
        </button>
        <button
          class="transition w-full border rounded-lg m-1 hover:bg-gray-100"
          @click="handleContactSupportClick"
        >
          Contact Support
        </button>
        <button
          class="transition w-full border rounded-lg m-1 hover:bg-gray-100"
          @click="handleLogoutClick"
        >
          Logout
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";

export default {
  setup() {
    const store = useStore();

    const handleGetStartedClick = () => {
      window.location.href = process.env.VUE_APP_ONBOARDING_START;
    };

    const handleLogoutClick = async () => {
      await store.dispatch("signOut");
    };

    const handleContactSupportClick = () => {
      window.open("mailto: support@getaddify.com", "_blank");
    };

    return {
      handleGetStartedClick,
      handleLogoutClick,
      handleContactSupportClick,
      user: computed(() => {
        return store.state.user;
      }),
      isAccountMissing: computed(() => {
        return store.state.account == null;
      }),
    };
  },
};
</script>
