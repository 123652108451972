<template>
  <modal>
    <div class="hidden">
      <div ref="paymentPlaceholder" />
    </div>
    <div
      class="transition w-full h-full md:w-2/3 lg:w-1/2 xl:w-1/3 max-w-screen-xl rounded p-4 md:h-auto"
    >
      <div
        v-if="!paymentReady && !addNewCreditCard"
        class="flex flex-col bg-white rounded-xl w-full"
      >
        <div
          class="p-8 text-2xl font-bold w-full flex flex-row justify-between items-center border-b"
        >
          <span> Extend reservation </span>
          <button @click="onCancel" class="p-0 hover:opacity-70">
            <div
              class="rounded-full border border-gray-200 p-2 flex justify-center items-center hover:bg-gray-100"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="w-3.5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </div>
          </button>
        </div>
        <div
          class="flex px-16 pt-6 pb-8 flex-col font-['Moderat']"
          v-if="!paymentScreen"
        >
          <span class="text-gray-500 text-sm">
            Choose an amount of days to be added for the vehicle below
            reservation.
          </span>
          <span class="text-gray-500 my-4 text-sm font-bold">
            ID:
            <span class="text-lula-warning-600">{{
              selectedReservation.reservationNumber
            }}</span>
          </span>
          <span class="text-gray-700">
            {{ selectedReservation.vehicle.make }}
            {{ selectedReservation.vehicle.model }}
            {{ selectedReservation.vehicle.year }}
          </span>
          <span class="text-gray-500 text-sm">
            {{ selectedReservation.vehicle.vin }}
          </span>
          <div
            class="w-full flex flex-row bg-gray-100 rounded-md py-2 mt-8 mb-3"
          >
            <button @click="onMinus" class="bg-transparent px-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="w-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M20 12H4"
                />
              </svg>
            </button>
            <div class="with-suffix suffix w-full">
              <input
                class="rounded-none bg-gray-100 text-center text-neutral-900 text-base"
                type="number"
                v-model="daysOfCoverage"
                min="0"
                @change="onChangeDaysOfCoverage"
              />
            </div>
            <button @click="onPlus" class="bg-transparent px-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="w-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M12 4v16m8-8H4"
                />
              </svg>
            </button>
          </div>
          <span class="text-gray-500 text-sm">
            New end date
            <span class="text-gray-700">
              {{
                newEndDate
                  ? newEndDate.toLocaleDateString("en-US", {
                      month: "short",
                      day: "numeric",
                      year: "numeric",
                    }) +
                    " | " +
                    newEndDate.toLocaleTimeString("en-US", {
                      hour: "numeric",
                      minute: "numeric",
                      hour12: true,
                    })
                  : ""
              }}
            </span>
          </span>
        </div>
        <div v-else-if="paymentScreen" class="bg-white p-8 text-base w-full">
          <div class="border rounded-lg p-6">
            <img src="@/assets/purchase.svg" alt="" class="w-8 mb-2 pl-2" />
            <h1 class="font-bold text-driver-neutral-900">
              GetAddify Insurance
            </h1>
            <p class="text-sm font-bold text-driver-neutral-700 mt-1">
              <span class="text-driver-neutral-600 font-normal"
                >Coverage Limits:</span
              >
              State Minimum Financial Requirements
            </p>
            <p class="text-sm font-bold text-driver-neutral-700 mt-1">
              <span class="text-driver-neutral-600 font-normal"
                >Deductible:</span
              >
              $1,000
            </p>
            <p class="text-sm font-bold text-driver-neutral-700 mt-1">
              <span class="text-driver-neutral-600 font-normal"
                >Daily Premium:</span
              >
              $15
            </p>
            <div class="flex justify-between my-6">
              <div class="flex">
                <p class="mr-2 text-driver-neutral-800 font-bold text-sm">
                  {{ daysOfCoverage }}x
                </p>
                <p class="text-driver-neutral-800 text-sm">Daily Premium</p>
              </div>
              <p class="text-driver-neutral-700 font-bold text-sm">$15.00</p>
            </div>
            <div class="flex justify-between items-center">
              <p class="text-driver-neutral-600 text-sm">Total</p>
              <p class="text-driver-neutral-900 text-xl font-bold">
                ${{ (daysOfCoverage * 15).toFixed(2) }}
              </p>
            </div>
          </div>
        </div>
        <div class="w-full font-bold text-center mb-2" v-if="message.content">
          <span
            :class="{
              'text-red-500': message.error,
              'text-gray-600': !message.error,
            }"
          >
            {{ message.content }}
          </span>
        </div>
        <div class="p-6 w-full flex justify-between bg-gray-100 rounded-b-xl">
          <button
            @click="onCancel"
            class="text-base transition transform text-neutral-700 bg-transparent w-fit"
          >
            Close
          </button>
          <button
            :disabled="daysOfCoverage === 0 || loading"
            @click="onConfirm"
            class="w-fit font-bold"
            :class="{
              'bg-lula-gradient text-white hover:bg-lula-gradient-alt':
                daysOfCoverage > 0 && !loading,
              'bg-gray-100 cursor-default text-gray-500':
                daysOfCoverage === 0 || loading,
            }"
          >
            {{
              paymentScreen
                ? `Pay $${(daysOfCoverage * 15.0).toFixed(2)}`
                : "Confirm"
            }}
          </button>
        </div>
      </div>
      <div
        class="flex flex-col items-center pt-16 bg-white rounded-xl w-full"
        v-else-if="success"
      >
        <div class="flex px-4 flex-col items-center">
          <img class="w-24 mb-4" src="@/assets/checked.svg" alt="" />
          <h1
            class="text-4xl leading-{47px} font-bold text-driver-neutral-700 text-center"
          >
            Thank you!
          </h1>
          <p
            class="text-xl leading-{26px} font-bold text-center text-driver-neutral-600 mt-2"
          >
            The payment was successful
          </p>
        </div>
        <div
          class="bg-gray-100 p-4 w-full flex px-4 justify-center mt-4 rounded-b-xl mt-12"
        >
          <button
            @click="onClose"
            class="w-fit font-bold bg-lula-gradient hover:bg-lula-gradient-alt text-white"
          >
            Close
          </button>
        </div>
      </div>
      <div
        class="flex flex-col items-center pt-16 bg-white rounded-xl w-full"
        v-else-if="!success && !addNewCreditCard"
      >
        <div class="flex px-4 flex-col items-center">
          <img class="w-24 mb-4" src="@/assets/cancel.svg" alt="" />
          <h1
            class="text-4xl leading-{47px} font-bold text-driver-neutral-700 text-center"
          >
            Sorry...
          </h1>
          <p
            class="text-xl leading-{26px} font-bold text-center text-driver-neutral-600 mt-2"
          >
            The payment failed
          </p>
        </div>
        <div
          class="bg-gray-100 p-4 w-full flex px-4 justify-between mt-4 rounded-b-xl mt-12"
        >
          <button
            @click="addNewCreditCard = true"
            class="text-base transition transform text-neutral-700 bg-transparent w-fit"
          >
            Change credit card
          </button>
          <button
            @click="onCancel"
            class="w-fit font-bold bg-lula-gradient hover:bg-lula-gradient-alt text-white"
          >
            Close
          </button>
        </div>
      </div>
      <div
        v-else-if="addNewCreditCard"
        class="flex flex-col bg-white rounded-xl w-full"
        :class="{
          'opacity-100': paymentMounted,
          'opacity-0': !paymentMounted,
        }"
      >
        <div
          class="p-8 text-2xl font-bold w-full flex flex-row justify-between items-center border-b"
        >
          <span> Add new credit card </span>
          <button @click="onCancel" class="p-0 hover:opacity-70">
            <div
              class="rounded-full border border-gray-200 p-2 flex justify-center items-center hover:bg-gray-100"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="w-3.5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </div>
          </button>
        </div>

        <div
          v-if="!loadingCard"
          class="flex-grow bg-white p-8 text-base w-full mt-1 px-1"
        >
          <div
            ref="alternativePaymentPlaceholder"
            class="border p-2.5 rounded-lg"
          />
        </div>

        <div class="p-6 w-full flex justify-center bg-gray-100 rounded-b-xl">
          <button
            :disabled="daysOfCoverage === 0 || loading"
            @click="onConfirm"
            class="w-fit font-bold"
            :class="{
              'bg-lula-gradient text-white hover:bg-lula-gradient-alt':
                daysOfCoverage > 0 && !loading,
              'bg-gray-100 cursor-default text-gray-500':
                daysOfCoverage === 0 || loading,
            }"
          >
            {{ `Pay $${(daysOfCoverage * 15.0).toFixed(2)}` }}
          </button>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import { ref, onMounted, computed, watch } from "vue";
import { loadStripe } from "@stripe/stripe-js";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

export default {
  props: {
    selectedReservation: {
      type: Object,
    },
  },
  setup(props) {
    const store = useStore();
    const paymentScreen = ref(null);
    const success = ref(null);
    const fail = ref(false);
    const loading = ref(false);
    const originalDate = ref(new Date(props.selectedReservation.endDate));
    const newEndDate = ref(new Date(props.selectedReservation.endDate));
    const daysOfCoverage = ref(0);
    const addNewCreditCard = ref(false);
    let response = ref(null);

    const message = ref({
      content: "",
      error: false,
    });

    const route = useRoute();
    const stripe = ref(null);

    const paymentPlaceholder = ref(null);
    const alternativePaymentPlaceholder = ref(null);
    const paymentReady = ref(false);
    const paymentMounted = ref(false);
    const stripeElements = ref(null);
    const paymentIntent = ref(null);
    const loadingCard = ref(false);

    const loadCounter = ref("");

    onMounted(() => {
      setInterval(() => {
        switch (loadCounter.value) {
          case "": {
            loadCounter.value = ".";
            break;
          }
          case ".": {
            loadCounter.value = "..";
            break;
          }
          case "..": {
            loadCounter.value = "...";
            break;
          }
          default: {
            loadCounter.value = "";
          }
        }
      }, 500);
    });

    watch(addNewCreditCard, async () => {
      const reservation = props.selectedReservation;
      if (addNewCreditCard.value) {
        try {
          loadingCard.value = true;
          const account = await store.dispatch("getAccount");
          stripe.value = await loadStripe(
            account?.demo
              ? process.env["VUE_APP_STRIPE_PUBLIC_TEST_KEY"]
              : process.env["VUE_APP_STRIPE_PUBLIC_KEY"],
          );
          stripeElements.value = stripe.value.elements({
            clientSecret: reservation.payment,
          });
          const paymentElement = stripeElements.value.create("payment");
          paymentElement.mount(alternativePaymentPlaceholder.value);
          const payment = await stripe.value.retrievePaymentIntent(
            reservation.payment,
          );
          paymentIntent.value = payment.paymentIntent;
          paymentElement.on(
            "change",
            (change) => (paymentReady.value = change.complete),
          );
          paymentElement.on("ready", () => (paymentMounted.value = true));
        } catch (error) {
          console.log(error);
        } finally {
          loadingCard.value = false;
        }
      }
    });

    const confirmPayment = async () => {
      loading.value = true;
      message.value = {
        content: "Submitting payment[[LOAD]]",
        error: false,
      };
      try {
        const response = await store.dispatch("createEpisodeFromEpisode", {
          entityId: props.selectedReservation.episode,
          paymentIntent: paymentIntent.value,
          days: daysOfCoverage.value,
          accountId: route.params.companyId,
        });
        if (response?.error) {
          success.value = false;
        } else {
          success.value = true;
        }
      } catch (err) {
        console.error(err);
        success.value = false;
      } finally {
        paymentReady.value = true;
        addNewCreditCard.value = false;
        loading.value = false;
      }
    };

    return {
      response,
      originalDate,
      newEndDate,
      paymentScreen,
      success,
      fail,
      daysOfCoverage,
      paymentMounted,
      paymentReady,
      paymentPlaceholder,
      alternativePaymentPlaceholder,
      addNewCreditCard,
      confirmPayment,
      loading,
      message: computed(() => {
        if (message.value.content) {
          return {
            ...message.value,
            content: (message.value.content || "").replace(
              "[[LOAD]]",
              loadCounter.value,
            ),
          };
        }
        return message.value;
      }),
    };
  },
  methods: {
    onCancel() {
      this.$emit("toggle");
    },
    onClose() {
      this.$emit("toggle");
      this.$emit("refresh");
    },
    async onConfirm() {
      if (!this.paymentScreen) {
        this.paymentScreen = true;
      } else {
        await this.confirmPayment();
      }
    },
    onMinus: function () {
      if (this.daysOfCoverage <= 0) {
        this.daysOfCoverage = 0;
      } else {
        this.daysOfCoverage--;
      }
      this.recalcDate();
    },
    onPlus: function () {
      if (this.daysOfCoverage >= 0) {
        this.daysOfCoverage++;
      }
      this.recalcDate();
    },
    onChangeDaysOfCoverage(e) {
      if (e.target.value < 0) {
        this.daysOfCoverage = 0;
      }
      this.daysOfCoverage = e.target.value;
      this.recalcDate();
    },
    recalcDate() {
      const newDate = new Date(this.originalDate);
      newDate.setDate(newDate.getDate() + this.daysOfCoverage);
      this.newEndDate = newDate;
    },
  },
};
</script>
<style>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.with-suffix {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 0;
}

.with-suffix input {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  text-align: right;
  min-width: 0;
  width: 50%;
  padding-right: 4px;
  border: none;
  background: none;
}

.suffix::after {
  content: "days";
  width: 50%;
  font-family: "Moderat", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  display: flex;
  align-items: center;
  font-feature-settings:
    "pnum" on,
    "lnum" on;
  color: #5c6575;
}
</style>
