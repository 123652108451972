<template>
  <form
    @submit.prevent="submitClaimAttachments"
    class="flex min-h-screen items-center justify-center text-gray-800 p-4"
  >
    <div class="flex flex-col w-full md:w-2/3">
      <div class="mb-8">
        <h1 class="text-lula-alt font-bold text-lg">Report a Claim</h1>
        <span class="text-4xl font-bold"> Attachments </span>
      </div>
      <div class="bg-white rounded-lg border p-4 py-8">
        <div class="mb-8">
          <h2 class="font-bold text-2xl">Include photos and documents</h2>
          <p>
            Provide any documents or images that would be relevant to the
            incident.
          </p>
        </div>
        <file-pond
          ref="attachmentsUpload"
          accepted-file-types="image/jpg, image/jpeg, image/png, application/pdf"
          max-file-size="50MB"
          :allow-multiple="true"
          :allow-file-size-validation="true"
        />
      </div>
      <div class="flex justify-end items-center mt-4 gap-4">
        <span @click="onCancel" class="font-bold cursor-pointer"> Cancel </span>
        <button type="submit" class="bg-lula-primary text-white">
          Continue
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import { ref, onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
export default {
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const attachmentsUpload = ref(null);
    onMounted(() => {
      (store.state.claimForm?.attachments ?? []).forEach((f) => {
        attachmentsUpload.value.addFile(f);
      });
    });
    return {
      attachmentsUpload,
      submitClaimAttachments() {
        store.commit("setClaimFormItems", {
          attachments: [...attachmentsUpload.value.getFiles()].map(
            (f) => f.file,
          ),
        });
        if (route.query.edit) {
          router.push({ name: "Claims/ReviewDetails" });
        } else {
          router.push({ name: "Claims/ReviewDetails" });
        }
      },
      onCancel() {
        if (confirm("Are you sure you want to start this process over?")) {
          router.push({ name: "Claims/Landing" });
        }
      },
    };
  },
};
</script>
