<template>
  <div
    class="relative border-2 mb-2 p-4 flex rounded-lg align-items-center text-sm"
  >
    <div class="flex items-center">
      <em :class="cardIcon" style="color: #221f51; font-size: 24px"></em>
    </div>
    <div class="flex flex-col mx-4">
      <div>
        {{ capitalizeString(subType) }}
      </div>
      <div class="flex text-xs text-gray-400">
        <div class="mr-2">
          {{ capitalizeString(fundingSource) }}
        </div>
        <div>
          {{ displayLastFour(lastFour) }}
        </div>
      </div>
    </div>
    <div class="absolute right-2 mt-2 items-center flex-row-reverse">
      <span v-show="!isDefaultPm" class="text-lula cursor-pointer">
        <button @click="selectAsDefault">Set as Default</button>
      </span>
      <span
        v-show="isDefaultPm"
        class="px-2 py-1 rounded-full bg-green-300 mr-8"
        >Default</span
      >
      <span
        v-if="canDelete && !isDefaultPm"
        class="text-lula cursor-pointer"
      >
        <button @click="deletePaymentMethod">Remove</button>
      </span>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { getCurrentInstance } from "vue";

function capitalizeString(inputString) {
  return inputString[0].toUpperCase().concat(inputString.slice(1));
}

function displayLastFour(lastFour) {
  return "**** **** **** ".concat(lastFour);
}

export default {
  props: {
    pmId: {
      type: String,
    },
    paymentType: {
      type: String,
    },
    subType: {
      type: String,
    },
    lastFour: {
      type: String,
    },
    fundingSource: {
      type: String,
    },
    canDelete: {
      type: Boolean,
    },
  },
  computed: {
    cardIcon() {
      if (this.paymentType == "Card") {
        switch (this.subType) {
          case "visa":
            return "fab fa-cc-visa";
          case "mastercard":
            return "fab fa-cc-mastercard";
          case "discover":
            return "fab fa-cc-discover";
          case "amex":
            return "fab fa-cc-amex";
          default:
            return "fas fa-credit-card";
        }
      } else if (this.paymentType == "UsBankAccount") {
        return "fas fa-bank";
      } else {
        return "fas fa-credit-card";
      }
    },
    isDefaultPm() {
      return this.$store.state.defaultPM === this.pmId;
    },
  },
  setup(props) {
    const store = useStore();
    const vueInstance = getCurrentInstance();
    const { demo, entityId, type } = store.state.account;
    const configurationType = type == "Car Rental" ? "auto" : "trucking";

    function selectAsDefault() {
      try {
        store.dispatch("updateDefaultPaymentMethodViaLPG", {
          accountId: entityId,
          xTestMode: demo,
          paymentMethodId: props.pmId,
          xConfiguration: configurationType,
        });
      } catch (err) {
        console.error("[PMTMETH] Error setting default payment method", err);
      } finally {
        store.commit("setDefaultPM", props.pmId);
      }
    }

    function deletePaymentMethod() {
      try {
        store.dispatch("deletePaymentMethodViaLPG", {
          accountId: entityId,
          xTestMode: demo,
          xConfiguration: configurationType,
          paymentMethodId: props.pmId,
        });
      } catch (err) {
        console.error("[PMTMETH] Error deleting payment method", err);
      } finally {
        vueInstance.emit("onDelete", props.pmId);
      }
    }

    return {
      capitalizeString,
      displayLastFour,
      selectAsDefault,
      deletePaymentMethod,
    };
  },
};
</script>
