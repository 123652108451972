<template>
  <div
    class="transition-all duration-1000 bg-white rounded-lg shadow-lg p-4 border-b-4 border-lula w-full md:w-2/3"
  >
    <h1 class="text-2xl text-gray-800 font-bold">Welcome to GetAddify</h1>
    <div>
      <span class="font-bold text-gray-600">
        We're sorry, but your account is not setup at the moment
      </span>
      <div class="flex flex-wrap-reverse mt-2 sm:flex-nowrap">
        <button
          class="transition w-full border rounded-lg m-1 hover:bg-gray-100"
          @click="$store.dispatch('signOut')"
        >
          Logout
        </button>
        <button
          class="transition w-full border rounded-lg m-1 hover:bg-gray-100"
          @click="continueOnboarding"
        >
          Continue to Onboarding
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted } from "vue";
import { useStore } from "vuex";

export default {
  setup() {
    const store = useStore();

    const onboardingLink = computed(() => {
      if (store.state.account) {
        const onboardingLink = process.env["VUE_APP_ONBOARDING_URL"];
        return onboardingLink.replace("{{accountId}}", store.state.account.id);
      }
      return null;
    });

    onMounted(() => (window.location.href = onboardingLink.value));

    return {
      continueOnboarding() {
        window.location.href = onboardingLink.value;
      },
    };
  },
};
</script>
