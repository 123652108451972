/**
 * @typedef {Object} User
 * @property {string} email
 * @property {boolean} emailAddress
 */

import { createAuth0 } from "@auth0/auth0-vue";
import { decodeJwt } from "jose";
import axios from "axios";
import store from "./store";

const KratosAuth = {
  login: async () => {
    const loginUrl = new URL(`${process.env["VUE_APP_LULA_AUTH_URL"]}/login`);
    loginUrl.searchParams.append("return_to", window.location.href);
    window.location.href = loginUrl.toString();
  },
  logout: async () => {
    const logout = await axios({
      method: "GET",
      url: `${process.env["VUE_APP_KRATOS_URL"]}/self-service/logout/browser`,
      withCredentials: true,
    });
    const logoutUrl = new URL(logout.data.logout_url);
    logoutUrl.searchParams.append("return_to", window.location.origin);
    window.location.href = logoutUrl.toString();
  },
  /**
   * @returns {Promise<User | undefined>}
   */
  getUser: async () => {
    try {
      const session = await axios({
        method: "GET",
        url: `${process.env["VUE_APP_KRATOS_URL"]}/sessions/whoami`,
        withCredentials: true,
      });
      const identity = session.data.identity;
      const email = identity.traits.email.toLowerCase();
      const emailVerified =
        identity.verifiable_addresses.find(
          (address) =>
            address.value.toLowerCase() === email && address.verified,
        ) != null;
      const user = {
        email,
        emailVerified,
      };
      return user;
    } catch (ex) {
      console.log(ex);
      return undefined;
    }
  },
  /**
   * @returns {Promise<Record<string, string>>}
   */
  requestHeaders: async () => {
    return {};
  },
};

// Register this with the Vue app on startup
export const auth0 = createAuth0({
  domain: process.env["VUE_APP_AUTH0_DOMAIN"],
  clientId: process.env["VUE_APP_AUTH0_CLIENT_ID"],
  useRefreshTokens: true,
  cacheLocation: "localstorage",
  cookieDomain: process.env["VUE_APP_AUTH0_COOKIE_DOMAIN"],
  useFormData: true,
});

const authorizationParams = {
  tenant_id: "8941322c-37f5-4a3f-b49a-f9e06a226122", // Hard-coded ASTER tenant id
};

const Auth0Auth = {
  login: async () => {
    localStorage.setItem("customer_auth_redirect", window.location.href);
    await auth0.loginWithRedirect({
      authorizationParams: {
        ...authorizationParams,
        redirect_uri: `${window.location.origin}/redirect`,
      },
    });
  },
  logout: async () => {
    await auth0.logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  },
  getIdentityToken: async () => {
    try {
      // Get the token from the cache. Even when cache-mode is 'on', this function
      // may return an expired token.
      const cachedTokens = await auth0.getAccessTokenSilently({
        authorizationParams,
        detailedResponse: true,
        cacheMode: "on",
      });

      // Check if the token is expired
      const now = Math.floor(Date.now() / 1000);
      const expiration = decodeJwt(cachedTokens.id_token).exp;

      // Token is not expired, return it
      if (expiration > now) {
        return cachedTokens.id_token;
      }

      // Token is expired, lets try to fetch a new using the refresh token
      const refreshedTokens = await auth0.getAccessTokenSilently({
        authorizationParams,
        detailedResponse: true,
        cacheMode: "off",
      });

      return refreshedTokens.id_token;
    } catch (ex) {
      console.log(`[AUTH] Failed to get a valid identity token: ${ex.message}`);
      return undefined;
    }
  },
  /**
   * @returns {Promise<User | undefined>}
   */
  getUser: async () => {
    const identityToken = await Auth0Auth.getIdentityToken();
    if (identityToken === undefined) {
      return undefined;
    }

    const claims = decodeJwt(identityToken);
    return {
      email: `${claims.email}`,
      emailVerified: claims.email_verified || false,
    };
  },
  /**
   * @returns {Promise<Record<string, string>>}
   */
  requestHeaders: async () => {
    const token = await Auth0Auth.getIdentityToken();
    if (token === undefined) {
      return {};
    }
    return {
      Authorization: `Bearer ${token}`,
    };
  },
};

export const useAuth = async () => {
  const variationFromEnv = process.env["VUE_APP_AUTH_PROVIDER"];
  const variation =
    variationFromEnv != null
      ? variationFromEnv
      : await store.dispatch(
          "getLaunchDarklyFlag",
          "TUMS_CONFIG_CUSTOMER_USER_IDP",
        );
  if (variation === "auth0") {
    return Auth0Auth;
  }
  return KratosAuth;
};

export const requestHeaders = async () => {
  const auth = await useAuth();
  return await auth.requestHeaders();
};
