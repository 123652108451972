export function applyTransformations({ fields, vehicle }) {
  // Transformations access individuals fields, so make field lookup easier.
  const fieldsByKey = {};
  fields.forEach((f) => {
    fieldsByKey[f.key] = f;
  });

  const transformations = [
    toggleRegistrantNames,
    toggleDateOfBirth,
    toggleGovernmentIdLabel,
    toggleGovernmentIdRequired,
    setRegistrantDateOfBirthMinMax,
  ];

  transformations.forEach((t) => t({ fields: fieldsByKey, vehicle }));
}

function toggleRegistrantNames({ fields, vehicle }) {
  const registrantType = vehicle.value.registrantType;
  const personNameFields = [
    "registrantFirstName",
    "registrantMiddleName",
    "registrantLastName",
  ];
  const businessNameFields = ["registrationName"];

  if (registrantType === "Person") {
    personNameFields.forEach((key) => {
      const f = getFieldByKey({ fields, key });
      if (f) {
        f.visible = true;
      }
    });

    businessNameFields.forEach((key) => {
      const f = getFieldByKey({ fields, key });
      if (f) {
        f.visible = false;
        vehicle.value[f.key] = "";
      }
    });
  } else {
    personNameFields.forEach((key) => {
      const f = getFieldByKey({ fields, key });
      if (f) {
        f.visible = false;
        f.required = false;
        vehicle.value[f.key] = "";
      }
    });

    businessNameFields.forEach((key) => {
      const f = getFieldByKey({ fields, key });
      if (f) {
        f.visible = true;
        f.label = "Registrant Company Name";
      }
    });
  }
}

function toggleDateOfBirth({ fields, vehicle }) {
  const isRequired =
    vehicle.value.vehicleRegistrationState === "NY" &&
    vehicle.value.registrantType === "Person";
  const field = getFieldByKey({ fields, key: "registrantDateOfBirth" });

  if (field) {
    field.required = isRequired;
    field.visible = isRequired;
  }

  if (field && !field.visible) {
    vehicle.value.registrantDateOfBirth = "";
  }
}

function toggleGovernmentIdLabel({ fields, vehicle }) {
  const govIdField = getFieldByKey({ fields, key: "registrantGovernmentId" });
  if (!govIdField) {
    return;
  }

  switch (vehicle.value.registrantGovernmentIdType) {
    case "FEIN":
      govIdField.label = "FEIN";
      break;
    case "DriverLicense":
      govIdField.label = "Driver License Number";
      break;
    default:
      govIdField.label = "Government ID";
      break;
  }
}

function toggleGovernmentIdRequired({ fields, vehicle }) {
  const field = getFieldByKey({ fields, key: "registrantGovernmentId" });
  if (!field) {
    return;
  }

  const isBusinessOutsideMA =
    vehicle.value.registrantGovernmentIdType === "FEIN" &&
    vehicle.value.vehicleRegistrationState !== "MA";
  if (isBusinessOutsideMA) {
    field.required = false;
  }
}

function setRegistrantDateOfBirthMinMax({ fields }) {
  const field = getFieldByKey({ fields, key: "registrantDateOfBirth" });
  if (!field) {
    return;
  }

  const today = Date.now();

  const max = new Date(today);
  max.setFullYear(max.getFullYear() - 16);

  field.max = max.toISOString().split("T")[0];
}

function getFieldByKey({ fields, key }) {
  return fields[key];
}
