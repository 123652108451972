<template>
  <div class="flex flex-col lg:flex-row w-full lg:pt-12">
    <!-- steps ui -->
    <div
      class="mb-4 lg:mb-10 lg:mr-6 lg:w-1/4 flex lg:flex-col gap-x-3 lg:gap-0 overflow-x-auto"
    >
      <button
        @click="step = 1"
        class="bg-white rounded-lg px-4 py-3 md:p-4 md:w-1/3 lg:w-full text-left text-sm font-bold hover:bg-gray-200"
        :class="{
          'bg-lula-gradient text-white hover:bg-lula-gradient-alt': step === 1,
        }"
      >
        1<span class="hidden md:inline-block">. Vehicle Information</span>
      </button>
      <button
        @click="step = 2"
        v-if="step >= 2"
        class="bg-white rounded-lg px-4 py-3 md:p-4 md:w-1/3 lg:w-full text-left lg:mt-4 text-sm font-bold hover:bg-gray-200"
        :class="{
          'bg-lula-gradient text-white hover:bg-lula-gradient-alt': step === 2,
        }"
      >
        2<span class="hidden md:inline-block">. Registration Information</span>
      </button>
      <button
        v-if="step >= 2"
        @click="step = 3"
        class="bg-white rounded-lg px-4 py-3 md:p-4 md:w-1/3 lg:w-full text-left lg:mt-4 text-sm font-bold hover:bg-gray-200"
        :class="{
          'bg-lula-gradient text-white hover:bg-lula-gradient-alt': step === 3,
        }"
      >
        3<span class="hidden md:inline-block">. Lienholder Information</span>
      </button>
      <button
        v-if="step >= 2"
        @click="step = 4"
        class="bg-white rounded-lg px-4 py-3 md:p-4 md:w-1/3 lg:w-full text-left lg:mt-4 text-sm font-bold hover:bg-gray-200"
        :class="{
          'bg-lula-gradient text-white hover:bg-lula-gradient-alt': step === 4,
        }"
      >
        4<span class="hidden md:inline-block">. Lot Address</span>
      </button>
      <button
        v-if="step >= 2"
        @click="step = 5"
        class="bg-white rounded-lg px-4 py-3 md:p-4 md:w-1/3 lg:w-full text-left lg:mt-4 text-sm font-bold hover:bg-gray-200"
        :class="{
          'bg-lula-gradient text-white hover:bg-lula-gradient-alt': step === 5,
        }"
      >
        5
        <span
          class="hidden md:inline-block"
          v-if="vehicle.insuranceCriteriaStatus == 'Approved'"
          >. Add to Policy</span
        >
        <span class="hidden md:inline-block" v-else-if="isCarSharing"
          >. Submit Vehicle</span
        >
        <span class="hidden md:inline-block" v-else>. Appeal Decline</span>
      </button>
    </div>

    <!-- main content -->
    <div class="rounded-lg bg-white p-4 lg:p-7 lg:w-11/12 relative">
      <!-- header -->
      <div>
        <button
          v-if="step == 1"
          class="absolute top-7 lg:top-10 p-0 bg-transparent flex justify-center items-center w-6 h-6"
          @click="router.go(-1)"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            stroke-width="2"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M10 19l-7-7m0 0l7-7m-7 7h18"
            />
          </svg>
        </button>

        <h1
          v-if="step === 1"
          class="text-center mb-6 lg:mb-8 text-gray-800 font-bold text-2xl lg:text-3xl"
        >
          Vehicle Information
        </h1>
        <h1
          v-else-if="step === 2"
          class="text-center mb-6 lg:mb-8 text-gray-800 font-bold text-2xl lg:text-3xl"
        >
          Registration Information
        </h1>
        <h1
          v-else-if="step === 3"
          class="text-center mb-6 lg:mb-8 text-gray-800 font-bold text-2xl lg:text-3xl"
        >
          Lienholder Information
        </h1>
        <h1
          v-else-if="step === 4"
          class="text-center mb-6 lg:mb-8 text-gray-800 font-bold text-2xl lg:text-3xl"
        >
          Lot Address
        </h1>
      </div>
      <!-- forms -->
      <div>
        <!-- step 1 -->
        <form v-if="step === 1" @submit.prevent="submitVehicleForm">
          <p class="mb-2 font-bold text-sm text-gray-800">VIN</p>
          <div class="flex-row flex align-center">
            <input
              class="px-4 py-2 md:p-4 text-sm md:text-base border border-gray-300 bg-white hover:bg-gray-100 vin-input"
              :disabled="route.params.id"
              :class="{
                'border-red-500': errors.vin,
              }"
              type="text"
              maxlength="17"
              v-model="vehicle.vin"
              required
              placeholder="VIN"
              autofocus
              @change="onVinSubmit"
            />
            <button
              type="button"
              class="z-10 font-bold submit"
              :disabled="route.params.id"
              :class="{
                'bg-gray-200 cursor-default text-gray-700': vehicle.model,
                'bg-lula-gradient hover:bg-lula-gradient-alt text-white font-bold':
                  !vehicle.model,
              }"
              value="submit"
              @click="onVinSubmit"
            >
              Verify
            </button>
          </div>

          <p v-if="errors.vin" class="mt-1 mb-2 text-sm text-red-500">
            {{ errors.vin.message }}
            <span
              v-if="errors.vin.code == 'NOT_ALLOWED_LETTERS'"
              @click="replaceNotAllowedLetters"
              class="font-bold underline cursor-pointer"
              >Replace letters</span
            >
          </p>
          <div
            v-if="
              ((vehicle.model && vehicle.make && vehicle.year) ||
                fetchingVIN) &&
              !errors.vin
            "
            class="p-2 md:p-4 bg-gray-100 rounded mt-4 flex items-center"
          >
            <div
              class="rounded-full bg-gray-300 w-7 h-7 md:w-9 md:h-9 flex justify-center items-center"
            >
              <img
                src="@/assets/car-icon.svg"
                alt="Vehicle"
                class="w-3/4 h-3/4"
              />
            </div>
            <p
              v-if="!fetchingVIN"
              class="ml-2 text-sm md:text-base text-gray-800"
            >
              {{ vehicle.year }}
              {{ vehicle.make }}
              {{ vehicle.model }}
            </p>
            <p v-else class="ml-2 text-base text-gray-800">Loading...</p>
          </div>
          <div class="flex flex-col justify-between gap-x-3">
            <div class="flex flex-col md:flex-row gap-x-3">
              <div class="flex-1">
                <p class="mt-4 mb-2 font-bold text-sm text-gray-800">Mileage</p>
                <input
                  v-model.number="vehicle.mileage"
                  class="w-full flex-grow px-4 py-2 md:p-4 border border-gray-300 text-sm md:text-base bg-white hover:bg-gray-100"
                  pattern="^[0-9]*$"
                  required
                  min="0"
                  placeholder="Mileage"
                  oninvalid="setCustomValidity('Mileage must only contain numbers')"
                  oninput="setCustomValidity('')"
                />
              </div>
              <div class="flex-1">
                <p class="mt-4 mb-2 font-bold text-sm text-gray-800">
                  Vehicle Registration State
                </p>
                <state-select
                  v-model="vehicle.vehicleRegistrationState"
                  required
                  :states="vehicleRegistrationStates"
                  class="w-full px-4 py-2 md:p-4 border text-sm md:text-base border-gray-300 bg-white hover:bg-gray-100"
                />
              </div>
            </div>
            <div class="flex flex-col md:flex-row gap-x-3">
              <div class="flex-1">
                <p class="mt-4 mb-2 font-bold text-sm text-gray-800">
                  Vehicle Nickname
                </p>
                <input
                  class="w-full px-4 py-2 md:p-4 border text-sm md:text-base border-gray-300 bg-white hover:bg-gray-100"
                  type="text"
                  v-model="vehicle.key"
                  placeholder="OPTIONAL - Company vehicle number"
                />
              </div>
              <div class="flex-1">
                <p class="mt-4 mb-2 font-bold text-sm text-gray-800">
                  License Plate
                </p>
                <input
                  class="w-full px-4 py-2 md:p-4 text-sm md:text-base border border-gray-300 bg-white hover:bg-gray-100"
                  type="text"
                  v-model="vehicle.plate"
                  placeholder="OPTIONAL - License Plate"
                />
              </div>
            </div>
          </div>
          <button
            :disabled="nextDisabled || !vehicle.model"
            type="submit"
            class="mt-8 w-full py-2 md:py-4 text-white rounded-[60px]"
            :class="{
              'bg-gray-200 cursor-default text-gray-700':
                nextDisabled || !vehicle.model,
              'bg-lula-gradient hover:bg-lula-gradient-alt font-bold':
                !nextDisabled && vehicle.model,
            }"
          >
            {{ checkInsuranceMessage.content }}
          </button>
        </form>
        <!-- step 2 -->
        <form v-if="step === 2" @submit.prevent="submitVehicleForm">
          <div class="w-full">
            <p
              class="mb-2 w-full text-center text-sm md:text-lg md:mb-6 text-gray-800"
            >
              Add the vehicle's information as it appears on the registration
              card/form. This information must be <b>accurate and complete</b>,
              to avoid <b>DMV issues.</b>
            </p>
          </div>
          <div
            class="w-full flex flex-col md:flex-row border-2 border-gray-200 rounded-lg py-2 md:py-5 justify-center"
          >
            <div
              v-if="requireRegistrationUpload"
              class="w-full md:w-1/2 flex flex-col justify-between items-center"
            >
              <p class="mb-2 font-bold text-base text-center text-gray-800">
                Is it currently registered?
              </p>
              <div class="flex flex-row">
                <button
                  @click="vehicleAlreadyRegistered = true"
                  type="button"
                  class="py-2 md:py-4"
                  :class="{
                    'bg-lula-gradient z-10 relative px-10 text-white font-bold -mr-12 hover:bg-lula-gradient-alt':
                      vehicleAlreadyRegistered === true,
                    'bg-gray-200 pr-20 hover:bg-gray-300':
                      vehicleAlreadyRegistered === false,
                  }"
                >
                  Yes
                </button>
                <button
                  @click="vehicleAlreadyRegistered = false"
                  type="button"
                  class="py-2 md:py-4"
                  :class="{
                    'bg-lula-gradient z-10 relative px-10 text-white font-bold -ml-12 hover:bg-lula-gradient-alt':
                      vehicleAlreadyRegistered === false,
                    'bg-gray-200 pl-20 hover:bg-gray-300':
                      vehicleAlreadyRegistered === true,
                  }"
                >
                  No
                </button>
              </div>
            </div>

            <div
              class="w-full md:w-1/2 flex flex-col justify-between items-center"
            >
              <p
                v-if="vehicleAlreadyRegistered"
                class="mb-2 font-bold text-base w-full text-center text-gray-800"
              >
                Is it registered to a business or a person?
              </p>
              <p
                v-else
                class="mb-2 font-bold text-base w-full text-center text-gray-800"
              >
                Will the vehicle be registered to a business or a person?
              </p>
              <div class="flex flex-row">
                <button
                  @click="vehicle.registrantType = 'Business'"
                  type="button"
                  class="py-2 md:py-4"
                  :class="{
                    'bg-lula-gradient z-10 relative px-10 text-white font-bold -mr-12 hover:bg-lula-gradient-alt':
                      vehicle.registrantType === 'Business',
                    'bg-gray-200 pr-20 hover:bg-gray-300':
                      vehicle.registrantType === 'Person',
                  }"
                >
                  Business
                </button>
                <button
                  @click="vehicle.registrantType = 'Person'"
                  type="button"
                  class="py-2 md:py-4"
                  :class="{
                    'bg-lula-gradient z-10 relative px-10 text-white font-bold -ml-12 hover:bg-lula-gradient-alt':
                      vehicle.registrantType === 'Person',
                    'bg-gray-200 pl-20 hover:bg-gray-300':
                      vehicle.registrantType === 'Business',
                  }"
                >
                  Person
                </button>
              </div>
            </div>
          </div>

          <div
            v-if="
              vehicle.vehicleRegistrationState === 'NY' &&
              isNewRegistrationFieldEnabled
            "
            class="flex flex-col mt-4 w-full"
          >
            <p class="mb-2 font-bold text-sm text-gray-800">
              Registration Class
            </p>
            <select
              v-model="vehicle.registrationClass"
              class="px-4 py-2 md:p-4 border border-gray-300 text-sm md:text-base bg-white hover:bg-gray-100 w-full"
              required
            >
              <option value="">Select a Class</option>
              <option value="pas">Passenger (PAS)</option>
              <option value="oms">Rental (private daily) (52 OMS)</option>
            </select>

            <div v-if="vehicle.registrantType === 'Person'">
              <p class="mb-2 font-bold text-sm text-gray-800">Date of Birth</p>
              <input
                type="date"
                :max="dateOfBirthRange().max"
                v-model="vehicle.registrantDateOfBirth"
                class="px-4 py-2 md:p-4 border border-gray-300 text-sm md:text-base bg-white hover:bg-gray-100 w-full"
                required
              />
            </div>
          </div>
          <div v-if="isNewRegistrationFieldEnabled">
            <div
              class="flex flex-col flex-wrap md:flex-row mt-4 gap-y-2"
              v-if="vehicle.registrantType === 'Person'"
            >
              <div class="w-full md:w-1/3 md:pr-1">
                <p class="mb-2 font-bold text-sm text-gray-800">
                  Registrant First Name
                </p>
                <input
                  class="w-full px-4 py-2 md:p-4 text-sm md:text-base border border-gray-300 bg-white hover:bg-gray-100"
                  type="text"
                  v-model="vehicle.registrantFirstName"
                  required
                  placeholder="First Name"
                />
              </div>
              <div class="w-full md:w-1/3 md:pr-1">
                <p class="mb-2 font-bold text-sm text-gray-800">
                  Registrant Middle Name
                </p>
                <input
                  class="w-full px-4 py-2 md:p-4 text-sm md:text-base border border-gray-300 bg-white hover:bg-gray-100"
                  type="text"
                  v-model="vehicle.registrantMiddleName"
                  placeholder="OPTIONAL - Middle Name"
                />
              </div>
              <div class="w-full md:w-1/3">
                <p class="mb-2 font-bold text-sm text-gray-800">
                  Registrant Last Name
                </p>
                <input
                  class="w-full px-4 py-2 md:p-4 text-sm md:text-base border border-gray-300 bg-white hover:bg-gray-100"
                  type="text"
                  v-model="vehicle.registrantLastName"
                  required
                  placeholder="Last Name"
                />
              </div>
              <div class="w-full md:w-1/3 md:pr-1">
                <p class="mb-2 font-bold text-sm text-gray-800">
                  Government ID Type
                </p>
                <select
                  class="h-14"
                  v-model="vehicle.registrantGovernmentIdType"
                  required
                >
                  <option value="DriverLicense">Driver License</option>
                  <option value="FEIN">FEIN Number</option>
                </select>
              </div>
              <div
                class="w-full md:w-1/3 pr-1"
                v-if="vehicle.registrantType === 'Person'"
                :class="{
                  'md:w-2/3':
                    vehicle.registrantGovernmentIdType !== 'DriverLicense',
                }"
              >
                <p
                  v-if="vehicle.registrantGovernmentIdType === 'DriverLicense'"
                  class="mb-2 font-bold text-sm text-gray-800"
                >
                  Driver License
                </p>
                <p v-else class="mb-2 font-bold text-sm text-gray-800">
                  Company FEIN number
                </p>
                <input
                  class="w-full px-4 py-2 md:p-4 text-sm md:text-base border border-gray-300 bg-white hover:bg-gray-100"
                  type="text"
                  v-model="vehicle.registrantGovernmentId"
                  placeholder="Government ID"
                  required
                />
              </div>
              <div
                class="w-full md:w-1/3"
                v-if="vehicle.registrantGovernmentIdType === 'DriverLicense'"
              >
                <p class="mb-2 font-bold text-sm text-gray-800">State Issued</p>
                <state-select
                  v-model="vehicle.driverLicenseStateIssued"
                  required
                  :states="vehicleRegistrationStates"
                  class="w-full px-4 py-2 md:p-4 border text-sm md:text-base border-gray-300 bg-white hover:bg-gray-100"
                />
              </div>
            </div>
            <div
              class="flex flex-col flex-wrap md:flex-row mt-4 gap-y-2"
              v-else
            >
              <div class="w-full">
                <div class="flex flex-row justify-between">
                  <p class="mb-2 font-bold text-sm text-gray-800">
                    Company Name
                  </p>
                  <button
                    class="mb-2 font-bold text-sm text-gray-800 underline p-0"
                    @click.prevent="autofillRegistrationInfo"
                  >
                    Autofill your company info
                  </button>
                </div>
                <input
                  class="w-full px-4 py-2 md:p-4 text-sm md:text-base border border-gray-300 bg-white hover:bg-gray-100"
                  type="text"
                  v-model="vehicle.registrationName"
                  required
                  placeholder="Company Name"
                />
              </div>
              <div class="w-full">
                <p class="mb-2 font-bold text-sm text-gray-800">Company EIN</p>
                <input
                  class="w-full px-4 py-2 md:p-4 text-sm md:text-base border border-gray-300 bg-white hover:bg-gray-100"
                  type="text"
                  v-model="vehicle.registrantGovernmentId"
                  placeholder="EIN number"
                  required
                />
              </div>
            </div>
          </div>
          <div v-else>
            <div class="w-full">
              <p
                class="mb-2 font-bold text-sm text-gray-800"
                v-if="vehicle.registrantType === 'Business'"
              >
                Company Name
              </p>
              <p class="mb-2 font-bold text-sm text-gray-800" v-else>
                Registrant Name
              </p>
              <input
                class="w-full px-4 py-2 md:p-4 text-sm md:text-base border border-gray-300 bg-white hover:bg-gray-100"
                type="text"
                v-model="vehicle.registrationName"
                required
                placeholder="Name"
              />
            </div>
            <div class="w-full">
              <p
                class="mb-2 font-bold text-sm text-gray-800"
                v-if="vehicle.registrantType === 'Business'"
              >
                Company EIN
              </p>
              <p class="mb-2 font-bold text-sm text-gray-800" v-else>
                Driver License
              </p>
              <input
                class="w-full px-4 py-2 md:p-4 text-sm md:text-base border border-gray-300 bg-white hover:bg-gray-100"
                type="text"
                v-model="vehicle.registrantGovernmentId"
                placeholder="Government Id"
                required
              />
            </div>
          </div>

          <p class="mt-4 mb-2 font-bold text-sm text-gray-800">
            Address Line 1
          </p>
          <vue-google-autocomplete
            v-if="!vehicle.registrationAddressLineOne"
            id="map"
            ref="addressRegistration"
            class="w-full px-4 py-2 md:p-4 text-sm md:text-base border border-gray-300 bg-white hover:bg-gray-100"
            placeholder="Address"
            country="us"
            v-on:placechanged="getAddressDataRegistration"
            required
          />
          <input
            v-else
            class="w-full px-4 py-2 md:p-4 text-sm md:text-base border border-gray-300 bg-white hover:bg-gray-100"
            type="text"
            v-model="vehicle.registrationAddressLineOne"
            required
            placeholder="Address Line One"
          />
          <div class="flex mt-4 gap-x-4">
            <div class="w-1/2">
              <p class="mb-2 font-bold text-sm text-gray-800">Address Line 2</p>
              <input
                class="w-full px-4 py-2 md:p-4 text-sm md:text-base border border-gray-300 bg-white hover:bg-gray-100"
                type="text"
                v-model="vehicle.registrationAddressLineTwo"
                placeholder="OPTIONAL - Address"
              />
            </div>
            <div class="w-1/2">
              <p class="mb-2 font-bold text-sm text-gray-800">City</p>
              <input
                class="w-full px-4 py-2 md:p-4 text-sm md:text-base border border-gray-300 bg-white hover:bg-gray-100"
                type="text"
                v-model="vehicle.registrationCity"
                required
                placeholder="City"
              />
            </div>
          </div>
          <div class="flex mt-4 gap-x-4">
            <div class="w-1/2">
              <p class="mb-2 font-bold text-sm text-gray-800">State</p>
              <state-select
                v-model="vehicle.registrationState"
                required
                class="w-full px-4 py-2 md:p-4 border text-sm md:text-base border-gray-300 bg-white hover:bg-gray-100"
              />
            </div>
            <div class="w-1/2">
              <p class="mb-2 font-bold text-sm text-gray-800">Zipcode</p>
              <input
                class="w-full px-4 py-2 md:p-4 text-sm md:text-base border border-gray-300 bg-white hover:bg-gray-100"
                type="text"
                v-model="vehicle.registrationZipcode"
                required
                placeholder="Zipcode"
              />
            </div>
          </div>
          <div class="w-full">
            <h2 class="font-bold mt-2">Vehicle Documents</h2>
            <!-- documents -->
            <div
              v-if="uploadedFiles.length"
              class="mt-2 p-2 overflow-y-scroll border-2 border-gray-200 rounded-lg max-h-40 md:max-h-80"
            >
              <div
                v-for="file in uploadedFiles"
                :key="file"
                class="mt-2 flex flex-row items-center justify-between"
              >
                <p class="text-sm md:text-base font-bold">
                  {{ file.file.name }}
                </p>
                <div class="w-full mt-4 md:w-1/2 md:mt-0">
                  <h2 class="font-bold">Document type</h2>
                  <select
                    class="w-full mt-2 py-2 md:py-4 text-sm rounded-[60px] md:text-base border border-gray-300 bg-white hover:bg-gray-100"
                    v-model="file.attachmentType"
                    required
                  >
                    <option value="driver-license">Driver License</option>
                    <option value="vehicle-registration">
                      Vehicle Registration
                    </option>
                    <option value="proof-of-purchase">Proof of Purchase</option>
                    <option value="lease-agreement">Lease Agreement</option>
                    <option value="other">Other</option>
                  </select>
                </div>
              </div>
            </div>

            <div class="flex flex-row relative align-center">
              <input
                class="mt-2 w-full py-2 md:py-4 rounded-[60px] bg-gray-200 text-gray-700"
                type="file"
                @change="handleFileChange"
                multiple
                accept=".doc,.docx,.md,.pdf,.jpg,.png"
                ref="fileInput"
              />
              <button
                type="button"
                v-if="!disableDocumentSubmission"
                class="right-0 top-1 bottom-1 bg-transparent absolute p-0 height-full grid place-items-center mr-2 md:mr-4 mt-2"
                @click="clearFiles"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6"
                  style="width: 25px; height: 25px; max-width: 25px"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          </div>
          <p
            v-if="errorMessage"
            class="w-full font-bold text-center mb-2 text-red-500"
          >
            Error: Driver license number must be valid for the state registered.
          </p>
          <button
            type="submit"
            :disabled="nextDisabled"
            class="mt-8 w-full py-2 md:py-4 text-white rounded-[60px] font-bold"
            :class="{
              'bg-gray-200 cursor-default text-gray-700': nextDisabled,
              'bg-lula-gradient hover:bg-lula-gradient-alt font-bold':
                !nextDisabled,
            }"
          >
            Submit
          </button>
        </form>
        <!-- step 3 -->
        <form v-if="step === 3" @submit.prevent="submitVehicleForm">
          <div class="w-full">
            <p
              class="mb-2 w-full text-center text-sm md:text-lg md:mb-6 text-gray-800"
            >
              If applicable, add <b>accurate lienholder information</b> for this
              vehicle. This information must be complete and will be shared with
              the insurance carrier and DMV.
            </p>
          </div>
          <div
            class="w-full flex flex-col md:flex-row border-2 border-gray-200 rounded-lg py-2 md:py-5 justify-center"
          >
            <div class="flex flex-col items-center">
              <p class="mb-2 font-bold text-center text-gray-800">
                Is the vehicle financed?
              </p>
              <div class="flex flex-row">
                <button
                  @click="vehicle.financeCompanyApplicable = true"
                  type="button"
                  class="py-2 md:py-4"
                  :class="{
                    'bg-lula-gradient z-10 relative px-10 text-white font-bold -mr-12 hover:bg-lula-gradient-alt':
                      vehicle.financeCompanyApplicable === true,
                    'bg-gray-200 pr-20 hover:bg-gray-300':
                      vehicle.financeCompanyApplicable === false,
                  }"
                >
                  Yes
                </button>
                <button
                  @click="vehicle.financeCompanyApplicable = false"
                  type="button"
                  class="py-2 md:py-4"
                  :class="{
                    'bg-lula-gradient z-10 relative px-10 text-white font-bold -ml-12 hover:bg-lula-gradient-alt':
                      vehicle.financeCompanyApplicable === false,
                    'bg-gray-200 pl-20 hover:bg-gray-300':
                      vehicle.financeCompanyApplicable === true,
                  }"
                >
                  No
                </button>
              </div>
            </div>
          </div>
          <div v-if="vehicle.financeCompanyApplicable === true">
            <p class="mb-2 font-bold mt-4 text-sm text-gray-800">
              Lienholder Name
            </p>
            <input
              class="w-full px-4 py-2 md:p-4 text-sm md:text-base border border-gray-300 bg-white hover:bg-gray-100"
              type="text"
              v-model="vehicle.financeCompanyName"
              required
              placeholder="Lienholder Name"
            />
            <p class="mt-4 mb-2 font-bold text-sm text-gray-800">
              Address Line 1
            </p>
            <vue-google-autocomplete
              v-if="!vehicle.financeCompanyAddressLineOne"
              id="map"
              ref="addressFinance"
              class="w-full px-4 py-2 md:p-4 text-sm md:text-base border border-gray-300 bg-white hover:bg-gray-100"
              placeholder="Address"
              country="us"
              v-on:placechanged="getAddressDataFinanceCompany"
              required
            />
            <input
              v-else
              class="w-full px-4 py-2 md:p-4 text-sm md:text-base border border-gray-300 bg-white hover:bg-gray-100"
              type="text"
              v-model="vehicle.financeCompanyAddressLineOne"
              required
              placeholder="Address"
            />
            <div>
              <div class="flex mt-4 gap-x-4">
                <div class="w-1/2">
                  <p class="mb-2 font-bold text-sm text-gray-800">
                    Address Line 2
                  </p>
                  <input
                    class="w-full px-4 py-2 md:p-4 text-sm md:text-base border border-gray-300 bg-white hover:bg-gray-100"
                    type="text"
                    v-model="vehicle.financeCompanyAddressLineTwo"
                    placeholder="OPTIONAL - Address"
                  />
                </div>
                <div class="w-1/2">
                  <p class="mb-2 font-bold text-sm text-gray-800">City</p>
                  <input
                    class="w-full px-4 py-2 md:p-4 text-sm md:text-base border border-gray-300 bg-white hover:bg-gray-100"
                    type="text"
                    v-model="vehicle.financeCompanyCity"
                    required
                    placeholder="City"
                  />
                </div>
              </div>
              <div class="flex mt-4 gap-x-4">
                <div class="w-1/2">
                  <p class="mb-2 font-bold text-sm text-gray-800">State</p>
                  <state-select
                    v-model="vehicle.financeCompanyState"
                    required
                    class="w-full px-4 py-2 md:p-4 border text-sm md:text-base border-gray-300 bg-white hover:bg-gray-100"
                  />
                </div>
                <div class="w-1/2">
                  <p class="mb-2 font-bold text-sm text-gray-800">Zipcode</p>
                  <input
                    class="w-full px-4 py-2 md:p-4 text-sm md:text-base border border-gray-300 bg-white hover:bg-gray-100"
                    type="text"
                    v-model="vehicle.financeCompanyZipcode"
                    required
                    placeholder="Zipcode"
                  />
                </div>
              </div>
            </div>
          </div>
          <button
            type="submit"
            :disabled="nextDisabled"
            class="mt-8 w-full py-2 md:py-4 text-white rounded-[60px] font-bold"
            :class="{
              'bg-gray-200 cursor-default text-gray-700': nextDisabled,
              'bg-lula-gradient hover:bg-lula-gradient-alt font-bold':
                !nextDisabled,
            }"
          >
            Continue
          </button>
        </form>
        <!-- step 4 -->
        <form v-if="step === 4" @submit.prevent="submitVehicleForm">
          <div class="w-full">
            <p
              class="mb-2 w-full text-center text-sm md:text-lg md:mb-6 text-gray-800"
            >
              Complete the Lot Address if the vehicle
              <b>is not stored at the company address or registrant address</b>.
              By selecting No, the company address will be used.
            </p>
          </div>
          <div
            class="w-full flex flex-col md:flex-row border-2 border-gray-200 rounded-lg py-2 md:py-5 justify-center items-center"
          >
            <div class="flex flex-col items-center">
              <p class="mb-2 font-bold text-center text-gray-800">
                Is the Lot Address different from your company address?
              </p>
              <div class="flex flex-row">
                <button
                  @click="vehicle.separateLotAddress = true"
                  type="button"
                  class="py-2 md:py-4"
                  :class="{
                    'bg-lula-gradient z-10 relative px-10 text-white font-bold -mr-12 hover:bg-lula-gradient-alt':
                      vehicle.separateLotAddress === true,
                    'bg-gray-200 pr-20 hover:bg-gray-300':
                      vehicle.separateLotAddress === false,
                  }"
                >
                  Yes
                </button>
                <button
                  @click="vehicle.separateLotAddress = false"
                  type="button"
                  class="py-2 md:py-4"
                  :class="{
                    'bg-lula-gradient z-10 relative px-10 text-white font-bold -ml-12 hover:bg-lula-gradient-alt':
                      vehicle.separateLotAddress === false,
                    'bg-gray-200 pl-20 hover:bg-gray-300':
                      vehicle.separateLotAddress === true,
                  }"
                >
                  No
                </button>
              </div>
            </div>
          </div>

          <div v-if="vehicle.separateLotAddress === true">
            <p class="mt-4 mb-2 font-bold text-sm text-gray-800">
              Address Line 1
            </p>
            <vue-google-autocomplete
              v-if="!vehicle.lotAddressLineOne"
              id="map"
              ref="addressLot"
              class="w-full px-4 py-2 md:p-4 text-sm md:text-base border border-gray-300 bg-white hover:bg-gray-100"
              placeholder="Address"
              country="us"
              v-on:placechanged="getLotAddress"
              required
            />
            <input
              v-else
              class="w-full px-4 py-2 md:p-4 text-sm md:text-base border border-gray-300 bg-white hover:bg-gray-100"
              type="text"
              v-model="vehicle.lotAddressLineOne"
              required
              placeholder="Address"
            />
            <div>
              <div class="flex mt-4 gap-x-4">
                <div class="w-1/2">
                  <p class="mb-2 font-bold text-sm text-gray-800">
                    Address Line 2
                  </p>
                  <input
                    class="w-full px-4 py-2 md:p-4 text-sm md:text-base border border-gray-300 bg-white hover:bg-gray-100"
                    type="text"
                    v-model="vehicle.lotAddressLineTwo"
                    placeholder="OPTIONAL - Address"
                  />
                </div>
                <div class="w-1/2">
                  <p class="mb-2 font-bold text-sm text-gray-800">City</p>
                  <input
                    class="w-full px-4 py-2 md:p-4 text-sm md:text-base border border-gray-300 bg-white hover:bg-gray-100"
                    type="text"
                    v-model="vehicle.lotCity"
                    required
                    placeholder="City"
                  />
                </div>
              </div>
              <div class="flex mt-4 gap-x-4">
                <div class="w-1/2">
                  <p class="mb-2 font-bold text-sm text-gray-800">State</p>
                  <state-select
                    v-model="vehicle.lotState"
                    required
                    class="w-full px-4 py-2 md:p-4 border text-sm md:text-base border-gray-300 bg-white hover:bg-gray-100"
                  />
                </div>
                <div class="w-1/2">
                  <p class="mb-2 font-bold text-sm text-gray-800">Zipcode</p>
                  <input
                    class="w-full px-4 py-2 md:p-4 text-sm md:text-base border border-gray-300 bg-white hover:bg-gray-100"
                    type="text"
                    v-model="vehicle.lotZipcode"
                    required
                    placeholder="Zipcode"
                  />
                </div>
              </div>
            </div>
          </div>
          <button
            type="submit"
            :disabled="nextDisabled"
            class="mt-8 w-full py-2 md:py-4 text-white rounded-[60px] font-bold"
            :class="{
              'bg-gray-200 cursor-default text-gray-700': nextDisabled,
              'bg-lula-gradient hover:bg-lula-gradient-alt font-bold':
                !nextDisabled,
            }"
          >
            Continue
          </button>
        </form>
        <!-- step 5 -->
        <form v-if="step === 5">
          <!-- ready -->
          <div v-if="vehicleHasAllData">
            <!-- activation -->
            <div v-if="vehicle.insuranceCriteriaStatus == 'Approved'">
              <h1 class="text-center mb-8 text-green-500 font-bold text-3xl">
                Your Vehicle is Ready! 🎉🎈
              </h1>
              <p class="text-center text-gray-800 text-lg mx-auto">
                Would you like to cover your vehicle, effective
                {{ dateAvailable }}?
              </p>
              <div class="mt-16 flex flex-col justify-between md:flex-row">
                <button
                  type="button"
                  @click="closeAndSave"
                  class="mt-6 px-4 w-full md:w-2/5 py-2 md:py-4 text-gray-700 rounded-[60px] bg-gray-200 hover:bg-gray-300"
                >
                  Close without Coverage, and Save
                </button>
                <button
                  type="button"
                  @click="activateNow"
                  class="mt-6 px-6 w-full md:w-2/5 py-2 md:py-4 rounded-[60px]font-bold"
                  :class="{
                    'bg-gray-200 cursor-default text-gray-700 ': nextDisabled,
                    'bg-lula-gradient hover:bg-lula-gradient-alt text-white  font-bold':
                      !nextDisabled,
                  }"
                >
                  {{ activateCoverageMessage.content }}
                </button>
              </div>
              <p class="w-full mt-8 text-gray-400 text-center">
                Note: Vehicle proration is calculated based on a vehicle
                coverage effective start date of {{ dateAvailable }} ET. By
                clicking Add Coverage button, this vehicle will use an available
                vehicle subscription spot in your account. If one is not
                available, your credit card on file will be charged for the
                prorated amount displayed.
              </p>
            </div>
            <!-- turo -->
            <div v-else-if="isCarSharing">
              <h1 class="text-center mb-8 font-bold text-3xl">
                Your Submission is Ready
              </h1>
              <p class="text-center text-gray-800 text-lg mx-auto">
                Submitting will notify our Underwriting team that your vehicle
                is ready for a manual review.
              </p>
              <div class="mt-16 flex flex-col justify-between md:flex-row">
                <button
                  type="button"
                  @click="closeAndSave"
                  class="mt-6 px-4 w-full md:w-2/5 py-2 md:py-4 text-gray-700 rounded-[60px] bg-gray-200 hover:bg-gray-300"
                >
                  Save and Close
                </button>
                <button
                  type="button"
                  @click="appealNow"
                  class="mt-6 px-6 w-full md:w-2/5 py-2 md:py-4 rounded-[60px]font-bold"
                  :class="{
                    'bg-gray-200 cursor-default text-gray-700 ': nextDisabled,
                    'bg-lula-gradient hover:bg-lula-gradient-alt text-white  font-bold':
                      !nextDisabled,
                  }"
                >
                  Save and Submit
                </button>
              </div>
            </div>
            <!-- appeal -->
            <div v-else>
              <h1 class="text-center mb-8 font-bold text-3xl">
                Your Appeal is Ready
              </h1>
              <p class="text-center text-gray-800 text-lg mx-auto">
                Appealing will notify our Underwriting team that your vehicle is
                ready for a manual review. Please keep in mind your declined
                reason when appealing.
              </p>
              <div class="mt-16 flex flex-col justify-between md:flex-row">
                <button
                  type="button"
                  @click="closeAndSave"
                  class="mt-6 px-4 w-full md:w-2/5 py-2 md:py-4 text-gray-700 rounded-[60px] bg-gray-200 hover:bg-gray-300"
                >
                  Save and Close
                </button>
                <button
                  type="button"
                  @click="appealNow"
                  class="mt-6 px-6 w-full md:w-2/5 py-2 md:py-4 rounded-[60px]font-bold"
                  :class="{
                    'bg-gray-200 cursor-default text-gray-700 ': nextDisabled,
                    'bg-lula-gradient hover:bg-lula-gradient-alt text-white  font-bold':
                      !nextDisabled,
                  }"
                >
                  {{ appealMessage.content }}
                </button>
              </div>
            </div>
          </div>
          <!-- not ready -->
          <div class="flex flex-col content-between items-center" v-else>
            <h1 class="text-center mb-8 text-red-800 font-bold text-3xl">
              Your Vehicle is Missing Information
            </h1>
            <p class="text-center text-gray-800 text-lg mx-auto">
              Please review previous steps and
              <strong>correct the following fields</strong>:
            </p>
            <div class="bg-red-100 md:my-5 pb-4 px-4 rounded-lg w-full">
              <div
                class="flex flex-row mt-4"
                v-for="reason in missingVehicleData"
                :key="reason"
              >
                <img src="../assets/invalid-reason-icon.svg" />
                <p class="ml-2 text-red-600 text-sm md:text-lg">
                  {{ reason }}
                </p>
              </div>
            </div>
            <button
              type="button"
              @click="closeAndSave"
              class="mt-8 px-4 w-full py-2 md:py-4 text-gray-700 rounded-[60px] bg-gray-200 hover:bg-gray-300"
            >
              Close and Save
            </button>
          </div>
        </form>
      </div>
    </div>

    <!-- eligibility modal -->
    <modal class="z-20" v-if="showEligibilityModal">
      <!-- eligible -->
      <div
        class="rounded-lg bg-white p-8 w-5/6 md:w-2/3 relative"
        v-if="vehicleEligible"
      >
        <h1 class="text-center mb-8 font-bold text-3xl">
          Your vehicle is approved!
        </h1>
        <img class="mx-auto mt-12" src="../assets/Checkmark.svg" />
        <p class="text-center text-gray-800 md:text-lg mx-auto mt-8">
          Your vehicle has been approved for insurance coverage.
        </p>
        <p class="text-center text-gray-800 md:text-lg mx-auto mt-4 md:mt-10">
          To get coverage started as soon as possible, click Continue to add the
          additional information.
        </p>
        <div>
          <!-- buttons -->
          <p class="text-right text-xs md:text-base w-full text-gray-400 mt-8">
            Vehicle has been saved to your dashboard
          </p>
          <div
            class="flex md:flex-row flex-col md:justify-end gap-y-4 w-full mt-4"
          >
            <button
              type="button"
              class="py-2 md:py-4 md:w-40 font-bold cursor-pointer rounded-[60px] bg-gray-200 text-gray-700"
              @click.prevent="closeAndSave"
            >
              Later
            </button>
            <button
              class="md:ml-4 py-2 md:-40 md:py-4 text-white cursor-pointer rounded-[60px] bg-lula-gradient hover:bg-lula-gradient-alt font-bold"
              @click.prevent="continueActivationProcess"
            >
              Continue
            </button>
          </div>
        </div>
      </div>
      <!-- turo -->
      <form class="w-5/6 md:w-2/3 relative max-h-full" v-else-if="isCarSharing">
        <div class="rounded-lg bg-white p-8">
          <h1 class="text-center mb-8 text-gray-800 font-bold text-3xl">
            Vehicle Needs Manual Review
          </h1>
          <p class="text-center text-gray-800 text-sm md:text-lg mx-auto">
            Your vehicle will be reviewed and a decision will be communicated to
            you within 24-48 hours. Please continue to provide Registration,
            Lienholder, and Lot Information.
          </p>
          <div>
            <!-- buttons -->
            <p
              class="text-right text-xs md:text-base w-full text-gray-400 mt-8"
            >
              Vehicle has been saved to your dashboard
            </p>
            <div
              class="flex md:flex-row flex-col md:justify-end gap-y-4 w-full mt-4"
            >
              <button
                type="button"
                class="py-2 md:py-4 md:w-40 font-bold cursor-pointer rounded-[60px] bg-gray-200 text-gray-700"
                @click.prevent="closeAndSave"
              >
                Later
              </button>
              <button
                class="md:ml-4 py-2 md:w-40 md:py-4 text-white cursor-pointer rounded-[60px] bg-lula-gradient hover:bg-lula-gradient-alt font-bold"
                @click.prevent="continueActivationProcess"
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      </form>
      <!-- not eligible -->
      <form class="w-5/6 md:w-2/3 relative max-h-full" v-else>
        <div class="rounded-lg bg-white p-8">
          <h1 class="text-center mb-8 text-gray-800 font-bold text-3xl">
            Vehicle Not Eligible
          </h1>
          <p class="text-center text-gray-800 text-base md:text-lg mx-auto">
            We're unable to accept this vehicle into our insurance program.
          </p>
          <p
            class="text-center text-gray-800 text-base md:text-lg mx-auto mt-8"
          >
            If you'd like to submit this vehicle for further review, please
            continue and enter additional information.
          </p>
          <div class="bg-red-100 mt-8 md:my-5 md:py-4 px-4 rounded-lg">
            <p class="font-bold text-sm">Reasons for denial:</p>
            <div
              class="flex flex-row mt-4"
              v-for="reason in ineligibilityReasons"
              :key="reason"
            >
              <img src="../assets/invalid-reason-icon.svg" />
              <p class="ml-2 text-red-600 text-sm md:text-lg">
                {{ reason }}
              </p>
            </div>
          </div>
          <div>
            <!-- buttons -->
            <p
              class="text-right mt-8 w-full text-xs md:text-base text-gray-400"
            >
              Vehicle has been saved to your dashboard
            </p>
            <div
              class="flex md:flex-row flex-col gap-y-4 w-full mt-4 justify-end"
            >
              <button
                type="button"
                class="py-2 md:py-4 md:w-40 font-bold cursor-pointer rounded-[60px] bg-gray-200 text-gray-700"
                @click.prevent="closeAndSave"
              >
                Cancel
              </button>
              <button
                class="md:ml-4 py-2 md:w-40 md:py-4 text-white cursor-pointer rounded-[60px] bg-lula-gradient hover:bg-lula-gradient-alt font-bold"
                @click.prevent="continueActivationProcess"
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      </form>
    </modal>
    <!-- final success modal -->
    <modal class="z-20" v-if="showFinalSuccessModal">
      <div class="rounded-lg bg-white p-10 w-4/5 md:w-1/2 relative">
        <h1 class="text-center mb-8 text-green-500 font-bold text-3xl">
          VIN {{ vehicle.vin }} was successfully scheduled for Coverage 🎈
        </h1>
        <p class="text-center text-gray-800 text-lg mx-auto">
          Coverage will begin {{ dateAvailable }} 12:01am ET.
        </p>
        <div class="flex flex-col mt-12 md:flex-row gap-x-5 gap-y-3">
          <button
            type="button"
            @click="goToVehicleDetailPage"
            class="w-full md:w-1/3 text-white rounded-[60px] bg-lula-gradient hover:bg-lula-gradient-alt font-bold"
          >
            Go to vehicle
          </button>
          <button
            type="button"
            @click="goToDashboard"
            class="w-full md:w-1/3 rounded-[60px] bg-gray-100 hover:bg-gray-200"
          >
            Go to dashboard
          </button>
          <button
            type="button"
            @click="goToAddVehiclePage"
            class="w-full md:w-1/3 rounded-[60px] bg-gray-100 hover:bg-gray-200"
          >
            Add another vehicle
          </button>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { useToast } from "vue-toastification";
import { ref, computed, watch, onMounted } from "vue";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import { fullFormFieldValidation } from "../utils";
import axios from "axios";
const { isValid } = require("usdl-regex");
import moment from "moment";

export default {
  components: {
    VueGoogleAutocomplete,
  },
  setup() {
    const toast = useToast();
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const account = computed(() => store.state.account);
    const accountMetrics = computed(() => store.state.accountMetrics);
    const step = ref(1);
    const fetchingVIN = ref(false);
    const errors = ref({});
    const vehicle = ref(
      store.state.vehicles.find(
        ({ entityId }) => entityId === route.params.id,
      ) || {
        vin: "",
        mileage: 0,
        registrationState: "",
        vehicleRegistrationState: "",
        statedValue: 0.0,
        financeCompanyApplicable: false,
        make: "",
        model: "",
        year: "",
        lotAddressLineOne: account.value.businessAddressLineOne,
        lotAddressLineTwo: account.value.businessAddressLineTwo,
        lotCity: account.value.businessCity,
        lotZipcode: account.value.businessZipcode,
        lotState: account.value.state,
        status: "Inactive",
        registrantType: "Business",
        registrantGovernmentIdType: "FEIN",
        registrationClass: "",
        registrationName: "",
        separateLotAddress: false,
      },
    );
    const isVehicleRegistration = ref(true);
    const vehicleHasAllData = ref(false);
    const vehicleEligible = ref(false);
    const showEligibilityModal = ref(false);
    const attachmentType = ref("");
    const uploadedFiles = ref([]);
    const activationCost = ref(100);
    const errorMessage = ref("");
    const dateAvailable = ref(moment().add(1, "days").format("MM/DD/yyyy"));
    const nextDisabled = ref(false);
    const disableDocumentSubmission = ref(true);
    const ineligibilityReasons = ref([]);
    const missingVehicleData = ref([]);
    const addressFinance = ref(null);
    const addressRegistration = ref(null);
    const addressLot = ref(null);
    const fileInput = ref(null);
    const loadCounter = ref("");
    const showFinalSuccessModal = ref(false);
    const isNewRegistrationFieldEnabled = ref(false);
    const isCarSharing = ref(false);
    const vehicleAlreadyRegistered = ref(true);
    const isNyCoverageEnabled = ref(false);
    const additionalValidationFields = [
      {
        key: "hasUploadedRegistration",
        validationFunction() {
          return requireRegistrationUpload.value
            ? uploadedFiles.value.some(
                (e) => e.attachmentType === "vehicle-registration",
              )
            : true;
        },
        errorMessage: "Upload a current vehicle registration",
      },
      {
        key: "hasUploadedProofOfPurchase",
        validationFunction() {
          return ineligibilityReasons.value.includes(
            "Only vehicles with current registration are approved.",
          )
            ? uploadedFiles.value.some(
                (e) => e.attachmentType === "proof-of-purchase",
              )
            : true;
        },
        errorMessage: "Upload a proof of purchase",
      },
      {
        key: "registrationClass",
        validationFunction() {
          return vehicle.value.vehicleRegistrationState === "NY" &&
            isNewRegistrationFieldEnabled.value
            ? vehicle.value.registrationClass.length > 0
            : true;
        },
        errorMessage: "Registration class",
      },
    ];

    // loading message vars
    const checkInsuranceMessage = ref({
      content: "Check Insurance Eligibility",
      original: "Check Insurance Eligibility",
    });
    const activateCoverageMessage = ref({
      content: `Activate Coverage proration`,
      original: `Activate Coverage for `,
    });
    const appealMessage = ref({
      content: "Save and Appeal",
      original: "Save and Appeal",
    });

    // watchers
    watch(
      () => vehicle.value.separateLotAddress,
      (newVal) => {
        if (newVal === false) {
          vehicle.value = {
            ...vehicle.value,
            lotAddressLineOne: account.value.businessAddressLineOne,
            lotAddressLineTwo: account.value.businessAddressLineTwo,
            lotCity: account.value.businessCity,
            lotZipcode: account.value.businessZipcode,
            lotState: account.value.state,
          };
        } else {
          vehicle.value = {
            ...vehicle.value,
            lotAddressLineOne: "",
            lotAddressLineTwo: "",
            lotCity: "",
            lotZipcode: "",
            lotState: "",
          };
        }
      },
    );
    watch(
      () => vehicle.value.registrantType,
      (newVal) => {
        if (newVal == "Person") {
          vehicle.value.registrantGovernmentIdType = "DriverLicense";
          vehicle.value.registrationName = "";
        } else if (newVal == "Business") {
          vehicle.value.registrantGovernmentIdType = "FEIN";
          vehicle.value.registrationName = "";
          vehicle.value.registrantFirstName = "";
          vehicle.value.registrantMiddleName = "";
          vehicle.value.registrantLastName = "";
        }
      },
    );
    watch(
      () => vehicle.value.financeCompanyApplicable,
      (newVal) => {
        if (newVal == false) {
          vehicle.value = {
            ...vehicle.value,
            financeCompanyAddressLineOne: "",
            financeCompanyCity: "",
            financeCompanyZipcode: "",
            financeCompanyState: "",
            financeCompanyName: "",
          };
        }
      },
    );
    watch(
      () => vehicle.value.registrantGovernmentIdType,
      () => {
        if (vehicle.value.registrantGovernmentIdType === "DriverLicense") {
          vehicle.value.driverLicenseStateIssued = "";
        }
      },
    );
    watch(step, async () => {
      const validationResponse = await fullFormFieldValidation(
        vehicle.value,
        additionalValidationFields,
      );
      if (validationResponse.valid === true) {
        vehicleHasAllData.value = true;
      } else {
        missingVehicleData.value = validationResponse.missingVehicleData;
        vehicleHasAllData.value = false;
      }
      const save = await store.dispatch("saveVehicleForAccount", {
        ...vehicle.value,
      });
      vehicle.value = { ...save, ...vehicle.value };
    });
    watch(
      () => [
        vehicle.value.registrantFirstName,
        vehicle.value.registrantMiddleName,
        vehicle.value.registrantLastName,
      ],
      () => {
        if (
          vehicle.value.registrantType === "Person" &&
          vehicle.value.registrantFirstName &&
          vehicle.value.registrantLastName
        ) {
          vehicle.value.registrationName = vehicle.value.registrantMiddleName
            ? `${vehicle.value.registrantFirstName} ${vehicle.value.registrantMiddleName} ${vehicle.value.registrantLastName}`
            : `${vehicle.value.registrantFirstName} ${vehicle.value.registrantLastName}`;
        }
      },
    );
    watch(vehicle, (newVehicle, oldVehicle) => {
      if (newVehicle.mileage !== undefined) {
        if (
          isNaN(newVehicle.mileage) ||
          typeof newVehicle.mileage !== "number"
        ) {
          vehicle.value.mileage = oldVehicle.mileage;
        }
      } else {
        vehicle.value.mileage = oldVehicle.mileage;
      }
    });

    // computed
    const requireRegistrationUpload = computed(() => {
      const states = ["NY", "MA", "MD", "GA"];
      return (
        states.includes(vehicle.value.vehicleRegistrationState) ||
        vehicleAlreadyRegistered.value == false
      );
    });
    const exists = computed(() => {
      return store.state.vehicles.find(({ vin }) => vehicle.value.vin === vin);
    });
    const showNyDeclinedModal = computed(() => {
      return (
        isNyCoverageEnabled.value === false &&
        (ineligibilityReasons.value.includes(
          "vehicle not eligible for coverage in this state (NY)",
        ) ||
          ineligibilityReasons.value.includes(
            "Only vehicles with current registration in certain states are approved.",
          ))
      );
    });
    const mileageRange = computed(() => {
      return [
        { value: 24999, label: "Less than 25,000mi" },
        { value: 50000, label: "25,001mi - 50,000mi" },
        { value: 75000, label: "50,001mi - 75,000mi" },
        { value: 100000, label: "75,001mi - 100,000mi" },
        { value: 125000, label: "100,001mi - 125,000mi" },
        { value: 150000, label: "125,001mi - 150,000mi" },
        { value: 175000, label: "150,001mi - 175,000mi" },
        { value: 200000, label: "175,001mi - 200,000mi" },
        { value: 225000, label: "200,001mi - 225,000mi" },
        { value: 250000, label: "225,001mi - 250,000mi" },
        { value: 250001, label: "Greater than 250,000mi" },
      ];
    });

    // mounted
    onMounted(() => {
      setInterval(() => {
        switch (loadCounter.value) {
          case "": {
            loadCounter.value = ".";
            break;
          }
          case ".": {
            loadCounter.value = "..";
            break;
          }
          case "..": {
            loadCounter.value = "...";
            break;
          }
          default: {
            loadCounter.value = "";
          }
        }
      }, 500);
    });
    onMounted(async () => {
      await store.dispatch("getAccount");
      await store.dispatch("getMetrics");
      isNyCoverageEnabled.value = await store.dispatch(
        "getLaunchDarklyFlag",
        "ny-eligible-for-coverage",
      );
      isNewRegistrationFieldEnabled.value = await store.dispatch(
        "getLaunchDarklyFlag",
        "New-Vehicle-Registration-Fields",
      );
      if (!vehicle.value.registrantType) {
        vehicle.value.registrantType = "Person";
      }
      if (!vehicle.value.separateLotAddress) {
        vehicle.value.separateLotAddress = false;
      }
    });
    onMounted(() => {
      showEligibilityModal.value = false;
      vehicleEligible.value = false;
      isCarSharing.value = store.state.account.isCarSharing;
    });

    // functions
    const lookupVin = async () => {
      if (exists.value) {
        errors.value = {
          vin: {
            code: "VIN_ALREADY_EXISTS",
            message:
              "This VIN is already in your account. Please add a new one.",
          },
        };
      }
      try {
        fetchingVIN.value = true;
        const lookup = await axios({
          method: "GET",
          url: `https://vpic.nhtsa.dot.gov/api/vehicles/decodevinvaluesextended/${vehicle.value.vin}?format=JSON`,
        });

        const results = lookup.data["Results"];
        const result = results && results[0];

        if (result["ErrorCode"] !== "0") {
          errors.value.vin = {
            code: "ERROR_FROM_API",
            message: result["ErrorText"]?.split(";")[0].substring(3),
          };
        }
        if (result["Make"]) {
          vehicle.value.make = result["Make"];
        }
        if (result["Model"]) {
          vehicle.value.model = result["Model"];
        }
        if (result["ModelYear"]) {
          vehicle.value.year = result["ModelYear"];
        }
        fetchingVIN.value = false;
      } catch (err) {
        toast("Failed to get VIN information.");
      }
    };
    const validateCoverageEligibility = async () => {
      let inputs = {
        mileage: Number(vehicle.value.mileage),
        vin: vehicle.value.vin,
        state: vehicle.value.vehicleRegistrationState,
        source: "CAVF",
      };
      let response = await store.dispatch("getUnderwritingResponse", {
        inputs,
      });
      if (response.data?.status === "Approved") {
        return true;
      }

      if (response.data?.errors) {
        ineligibilityReasons.value = response.data?.errors;
      } else {
        ineligibilityReasons.value = ["Further review from our team is needed"];
      }
      return false;
    };

    const validateCarSharingEligibility = async () => {
      let inputs = {
        mileage: Number(vehicle.value.mileage),
        vin: vehicle.value.vin,
        state: vehicle.value.vehicleRegistrationState,
        product: "ORP",
        telematics_installed: true,
        source: "CAVF",
      };
      let underwritingResponse = await store.dispatch(
        "getUnderwritingResponse",
        { inputs },
      );
      if (underwritingResponse.data?.status === "Declined") {
        ineligibilityReasons.value = underwritingResponse.data?.errors || [
          "Further review from our team is needed",
        ];
        vehicle.value.insuranceCriteriaStatus = "Declined";
      } else {
        vehicle.value.insuranceCriteriaStatus = "Under Review";
      }
      vehicleEligible.value = false;
      vehicle.value.status = "Requested";
      if (isCarSharing.value) {
        store.dispatch("addAccountToQueue", {
          accountId: store.state.account.entityId,
          coverageType: "ORP",
        });
      }
    };

    const isVinValid = () => {
      vehicle.value.vin = vehicle.value.vin.trim();
      let vin = vehicle.value.vin;
      errors.value.vin = {};
      let index8ValidChars = [
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "x",
        "X",
      ];
      let index9InvalidChars = ["u", "U", "z", "Z"];

      if (vin.length !== 17) {
        errors.value.vin.message = "VINs must be 17 characters in length";
        return false;
      } else if (/[oOiIqQ]/.test(vin)) {
        errors.value.vin = {
          code: "NOT_ALLOWED_LETTERS",
          message:
            "The VIN is incorrect. VINs don’t include the letters O, I & E.",
        };
        return false;
      } else if (!index8ValidChars.includes(vin.charAt(8))) {
        errors.value.vin.message =
          "The VIN is incorrect. 9th digit is a check digit, possible values are 0 through 9 or X";
        return false;
      } else if (index9InvalidChars.includes(vin.charAt(9))) {
        errors.value.vin.message =
          "The VIN is incorrect. 10th digit cannot be U (u) or Z (z)";
        return false;
      } else {
        errors.value.vin = null;
        return true;
      }
    };

    async function declineVehicle() {
      vehicleEligible.value = false;
      vehicle.value.insuranceCriteriaStatus = "Declined";
      if (
        ineligibilityReasons.value == ["Further review from our team is needed"]
      ) {
        vehicle.value.insuranceCriteriaStatus = "Under Review";
      }
      vehicle.value.insuranceCriteriaReason =
        ineligibilityReasons.value.join(";") || "";
    }

    async function acceptVehicle() {
      vehicleEligible.value = true;
      vehicle.value.status = "Inactive";
      vehicle.value.insuranceCriteriaStatus = "Approved";
    }

    async function goToDashboard() {
      showFinalSuccessModal.value = false;
      router.push({ name: `${store.state.account.type}/Dashboard` });
    }
    function goToVehicleDetailPage() {
      showFinalSuccessModal.value = false;
      router.push({
        name: `${store.state.account.type}/Vehicle`,
        params: { id: vehicle.value.entityId },
      });
    }
    function goToAddVehiclePage() {
      showFinalSuccessModal.value = false;
      router.go();
    }
    return {
      nextDisabled,
      errors,
      fetchingVIN,
      step,
      router,
      route,
      mileageRange,
      vehicle,
      vehicleHasAllData,
      vehicleEligible,
      showEligibilityModal,
      account,
      accountMetrics,
      isVehicleRegistration,
      attachmentType,
      uploadedFiles,
      activationCost,
      errorMessage,
      fullFormFieldValidation,
      dateAvailable,
      disableDocumentSubmission,
      fileInput,
      ineligibilityReasons,
      isVinValid,
      addressFinance,
      addressRegistration,
      addressLot,
      showFinalSuccessModal,
      goToAddVehiclePage,
      goToVehicleDetailPage,
      goToDashboard,
      requireRegistrationUpload,
      vehicleAlreadyRegistered,
      isNewRegistrationFieldEnabled,
      isNyCoverageEnabled,
      showNyDeclinedModal,
      missingVehicleData,
      isCarSharing,
      checkInsuranceMessage: computed(() => {
        if (checkInsuranceMessage.value.content) {
          return {
            ...checkInsuranceMessage.value,
            content: (checkInsuranceMessage.value.content || "").replace(
              "[[LOAD]]",
              loadCounter.value,
            ),
          };
        }
        return checkInsuranceMessage.value;
      }),
      activateCoverageMessage: computed(() => {
        if (activateCoverageMessage.value.content) {
          return {
            ...activateCoverageMessage.value,
            content: (activateCoverageMessage.value.content || "").replace(
              "[[LOAD]]",
              loadCounter.value,
            ),
          };
        }
        return activateCoverageMessage.value;
      }),
      appealMessage: computed(() => {
        if (appealMessage.value.content) {
          return {
            ...appealMessage.value,
            content: (appealMessage.value.content || "").replace(
              "[[LOAD]]",
              loadCounter.value,
            ),
          };
        }
        return appealMessage.value;
      }),

      async submitVehicleForm() {
        switch (step.value) {
          case 1:
            if (exists.value && !vehicle.value.entityId) {
              toast("Vehicle already added to account.");
            } else {
              ineligibilityReasons.value = [];
              vehicleEligible.value = false;
              checkInsuranceMessage.value.content += "[[LOAD]]";
              nextDisabled.value = true;
              vehicle.value.status = "Inactive";
              // handling mileage field and casting to number
              if ("mileage" in vehicle.value && vehicle.value.mileage) {
                if (isNaN(vehicle.value.mileage)) {
                  alert("Warning: Mileage should be a number.");
                  return;
                } else {
                  try {
                    vehicle.value.mileage = parseInt(vehicle.value.mileage);
                  } catch (err) {
                    alert("Warning: Mileage should be a number.");
                    return;
                  }
                }
              }
              if (vehicle.value.insuranceCriteriaStatus == "Approved") {
                vehicleEligible.value = true;
              } else if (vehicle.value.insuranceCriteriaStatus == "Declined") {
                // vehicle is previously declined
                vehicleEligible.value = false;
                var insuranceCriteriaReasons =
                  vehicle.value.insuranceCriteriaReason;
                var reasonsArray = insuranceCriteriaReasons.split(";");
                var additionalMessage =
                  "This vehicle has already been declined and must go through an appeals process.";

                if (!reasonsArray.includes(additionalMessage)) {
                  reasonsArray.push(additionalMessage);
                }
                ineligibilityReasons.value = reasonsArray;
              } else if (isCarSharing.value === true) {
                await validateCarSharingEligibility();
              } else if (await validateCoverageEligibility()) {
                vehicleEligible.value = true;
              } else {
                vehicleEligible.value = false;
              }

              if (vehicleEligible.value === true) {
                acceptVehicle();
              } else if (
                vehicleEligible.value === false &&
                isCarSharing.value === false
              ) {
                declineVehicle();
              }
              const save = await store.dispatch(
                "saveVehicleForAccount",
                vehicle.value,
              );
              vehicle.value = { ...save, ...vehicle.value };
              nextDisabled.value = false;
              checkInsuranceMessage.value.content =
                checkInsuranceMessage.value.original;
              showEligibilityModal.value = true;
            }
            break;
          case 2:
            try {
              nextDisabled.value = true;
              errorMessage.value = "";
              if (
                vehicle.value.registrantType === "Person" &&
                vehicle.value.registrantGovernmentIdType === "DriverLicense" &&
                isNewRegistrationFieldEnabled.value &&
                !isValid(
                  vehicle.value.driverLicenseStateIssued,
                  vehicle.value.registrantGovernmentId.replaceAll("-", ""),
                )
              ) {
                errorMessage.value =
                  "Error: Driver license number must be valid for the state registered.";
                nextDisabled.value = false;
                break;
              } else if (
                !isNewRegistrationFieldEnabled.value &&
                vehicle.value.registrantType === "Person" &&
                !isValid(
                  vehicle.value.vehicleRegistrationState,
                  vehicle.value.registrantGovernmentId.replaceAll("-", ""),
                )
              ) {
                errorMessage.value =
                  "Error: Driver license number must be valid for the state registered.";
                nextDisabled.value = false;
                break;
              }
              if (addressRegistration.value !== null) {
                vehicle.value.registrationAddressLineOne =
                  addressRegistration.value.autocompleteText;
              }
              const save = await store.dispatch("saveVehicleForAccount", {
                ...vehicle.value,
              });
              vehicle.value = { ...save, ...vehicle.value };
              toast("Vehicle saved.");
              nextDisabled.value = false;
              step.value += 1;
            } catch (err) {
              console.log(err.message);
              toast("Failed to save vehicle.");
              nextDisabled.value = false;
            }
            break;
          case 3:
            nextDisabled.value = true;
            if (vehicle.value.financeCompanyApplicable) {
              if (addressFinance.value !== null) {
                vehicle.value.financeCompanyAddressLineOne =
                  addressFinance.value.autocompleteText;
              }
              this.progressPage();
            } else {
              step.value += 1;
            }
            nextDisabled.value = false;
            break;
          case 4:
            nextDisabled.value = true;
            if (addressLot.value !== null) {
              vehicle.value.lotAddressLineOne =
                addressLot.value.autocompleteText;
            }
            this.progressPage();
            nextDisabled.value = false;
            break;
        }
      },
      progressPage: async () => {
        const save = await store.dispatch("saveVehicleForAccount", {
          ...vehicle.value,
        });
        vehicle.value = { ...save, ...vehicle.value };
        step.value += 1;
        toast("Vehicle Saved");
      },
      onVinSubmit: async () => {
        vehicle.value.make = "";
        vehicle.value.model = "";
        vehicle.value.year = "";
        if (isVinValid()) {
          vehicle.value.vin = vehicle.value.vin.trim();
          lookupVin();
          return;
        } else {
          vehicle.value.make = "";
          vehicle.value.model = "";
          vehicle.value.year = "";
        }
      },
      replaceNotAllowedLetters: () => {
        vehicle.value.vin = vehicle.value.vin.replaceAll(/[oO]/g, "0");
        vehicle.value.vin = vehicle.value.vin.replaceAll(/[iI]/g, "1");
        vehicle.value.vin = vehicle.value.vin.replaceAll(/[qQ]/g, "9");
        errors.value.vin = null;
      },
      getAddressDataRegistration: function (addressData) {
        vehicle.value.registrationAddressLineOne = [
          addressData.street_number,
          addressData.route,
        ].join(" ");
        vehicle.value.registrationCity = addressData.locality;
        vehicle.value.registrationZipcode = addressData.postal_code;
        vehicle.value.registrationState =
          addressData.administrative_area_level_1;
      },
      getAddressDataFinanceCompany: function (addressData) {
        vehicle.value.financeCompanyAddressLineOne = [
          addressData.street_number,
          addressData.route,
        ].join(" ");
        vehicle.value.financeCompanyCity = addressData.locality;
        vehicle.value.financeCompanyZipcode = addressData.postal_code;
        vehicle.value.financeCompanyState =
          addressData.administrative_area_level_1;
      },
      getLotAddress: function (addressData) {
        vehicle.value.lotAddressLineOne = [
          addressData.street_number,
          addressData.route,
        ].join(" ");
        vehicle.value.lotCity = addressData.locality;
        vehicle.value.lotZipcode = addressData.postal_code;
        vehicle.value.lotState = addressData.administrative_area_level_1;
      },
      continueActivationProcess: async () => {
        showEligibilityModal.value = false;
        step.value += 1;
      },
      activateNow: async () => {
        activateCoverageMessage.value.content += "[[LOAD]]";
        nextDisabled.value = true;
        if (account.value.status == "Onboarding") {
          vehicle.value.status = "Pending";
          await store.dispatch("saveVehicleForAccount", vehicle.value);
        }
        const lpgDirectPaymentsEnabled = await store.dispatch(
          "getLaunchDarklyFlag",
          "prorate-vehicle-uses-lgp-endpoint",
        );
        try {
          if (lpgDirectPaymentsEnabled) {
            const isDemoAccount = store.state.account.demo;
            await store.dispatch("activateVehicleNowWithMessage", {
              vehicleId: vehicle.value.entityId,
              isDemo: isDemoAccount,
            });
          } else {
            await store.dispatch("activateVehicleNow", vehicle.value.entityId);
          }
          await store.dispatch("getVehicles");
          toast("Vehicle activated.");
          activateCoverageMessage.value.content =
            activateCoverageMessage.value.original;
          showEligibilityModal.value = false;
          showFinalSuccessModal.value = true;
          return;
        } catch (e) {
          if (lpgDirectPaymentsEnabled) {
            if ("error" in e && "detail" in e.error) {
              // We check if detail exists in error, as this is a field unique to LPG
              const errorText = e.error.detail.slice(0, -2); // Note: We slice here due to parsing from Stripe's error message, which includes extra periods.
              const toastMessage = `Activation for vehicle failed due to ${errorText}, please update your payment method before proceeding.`;
              toast(toastMessage);
            } else {
              // This catches all other errors not related to LPG (we still might receive other unanticipated errors, e.g. 500 server errors, that need to be accounted for);
              toast("Failed to activate vehicle, please contact support.");
            }
          } else {
            toast("Failed to activate vehicle, please contact support.");
          }
          activateCoverageMessage.value.content =
            activateCoverageMessage.value.original;
          showEligibilityModal.value = false;
        }
      },
      appealNow: async () => {
        appealMessage.value.content += "[[LOAD]]";
        nextDisabled.value = true;
        vehicle.value.status = "Requested";
        const save = await store.dispatch("saveVehicleForAccount", {
          ...vehicle.value,
        });
        vehicle.value = { ...save, ...vehicle.value };
        await Promise.all(
          Array.from(uploadedFiles.value).map((file) => {
            return store.dispatch("saveVehicleDocument", {
              accountId: store.state.account.id,
              vehicleId: vehicle.value.documentId,
              file: file.file,
              meta: { tag: file.attachmentType },
            });
          }),
        );
        router.push("/");
        toast("Appeal submitted.");
      },
      handleFileChange(e) {
        fileInput.value = e;
        uploadedFiles.value = Array.from(e.target.files).map((file) => {
          return {
            file,
          };
        });
      },
      clearFiles() {
        uploadedFiles.value = [];
        fileInput.value.value = "";
      },
      async closeAndSave() {
        if (vehicleEligible.value === true) {
          acceptVehicle();
        } else {
          declineVehicle();
        }
        await store.dispatch("saveVehicleForAccount", {
          ...vehicle.value,
        });
        showEligibilityModal.value = false;
        router.push("/");
      },
      vehicleRegistrationStates: [
        "AL",
        "AK",
        "AZ",
        "AR",
        "CA",
        "CO",
        "CT",
        "DE",
        "DC",
        "FL",
        "GA",
        "HI",
        "ID",
        "IL",
        "IN",
        "IA",
        "KS",
        "KY",
        "LA",
        "ME",
        "MD",
        "MA",
        "MI",
        "MN",
        "MS",
        "MO",
        "MT",
        "NE",
        "NV",
        "NH",
        "NJ",
        "NM",
        "NY",
        "NC",
        "ND",
        "OH",
        "OK",
        "OR",
        "PA",
        "RI",
        "SC",
        "SD",
        "TN",
        "TX",
        "UT",
        "VT",
        "VA",
        "WA",
        "WV",
        "WI",
        "WY",
      ],
      autofillRegistrationInfo() {
        vehicle.value.registrationName = account.value.businessLegalName;
        vehicle.value.registrationAddressLineOne =
          account.value.businessAddressLineOne;
        vehicle.value.registrationAddressLineTwo =
          account.value.businessAddressLineTwo;
        vehicle.value.registrationCity = account.value.businessCity;
        vehicle.value.registrationZipcode = account.value.businessZipcode;
        vehicle.value.registrationState = account.value.state;
        vehicle.value.registrantGovernmentId = account.value.einNumber;
        toast("Company information added.");
      },
      dateOfBirthRange() {
        const today = new Date();

        let max = new Date(today);
        max.setFullYear(max.getFullYear() - 16);

        return {
          max: max.toISOString().split("T")[0],
        };
      },
    };
  },
};
</script>

<style scoped>
.submit {
  border-radius: 0 0.5rem 0.5rem 0;
  position: relative;
  box-sizing: border-box;
  width: 15%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.vin-input {
  border-radius: 0.5rem 0 0 0.5rem;
  box-sizing: content-box;
  width: 85%;
  text-transform: uppercase;
}
</style>
