<template>
  <div></div>
</template>

<script>
export default {
  async mounted() {
    const redirectUrl = localStorage.getItem("customer_auth_redirect");
    localStorage.removeItem("customer_auth_redirect");
    try {
      const url = new URL(redirectUrl);
      console.log(`[CALL] url: ${url}`);
      window.location.replace(url);
    } catch (ex) {
      console.log(ex);
      window.location.replace(window.location.origin);
    }
  },
};
</script>
