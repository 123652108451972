<template>
  <div
    class="relative border-2 mb-2 px-5 py-3 flex rounded-md align-items-center bg-red-200 grid md:grid-cols-8"
    style="font-size: 12px"
  >
    <span class="inline-flex items-center justify-start col-span-2">
      <em
        class="fas fa-circle-exclamation"
        style="color: #ba0d16; font-size: 18px"
      ></em>
      <span class="ml-2 font-bold">{{ dueMessage }} </span>
    </span>
    <span class="inline-flex items-center justify-start col-span-2">{{
      description
    }}</span>
    <span class="inline-flex items-center justify-start col-span-1">
      <span class="bg-red-600 text-white px-2 rounded-xl">{{
        attemptBadgeMessage
      }}</span>
    </span>
    <span class="inline-flex items-center justify-end col-span-3"
      >Past Due Amount: &nbsp;
      <span class="font-bold mr-3">${{ amountDue }}</span>
      <button
        v-if="canClickPay"
        @click="payInvoice"
        style="background-color: rgb(249 115 22)"
        class="text-white font-bold"
      >
        Pay Now
      </button>
      <button
        v-if="!canClickPay"
        style="background-color: rgb(240, 231, 230); cursor: default"
        class="text-white font-bold"
      >
        Pay Now
      </button>
    </span>
  </div>
</template>

<script>
import { ref } from "vue";
import { useStore } from "vuex";
import { getCurrentInstance } from "vue";
import { useToast } from "vue-toastification";
import { Loader } from "@getaddify/lula-components";

export default {
  components: { Loader },
  props: {
    invoiceId: {
      type: String,
    },
    amountDue: {
      type: Number,
    },
    dateDue: {
      type: String,
    },
    description: {
      type: String,
    },
    attempted: {
      type: Boolean,
    },
  },
  setup(props) {
    const store = useStore();
    const toast = useToast();
    const vueInstance = getCurrentInstance();

    const { demo, entityId, type } = store.state.account;
    const configurationType = type == "Car Rental" ? "auto" : "trucking";
    const dueMessage = ref(null);
    const canClickPay = ref(true);
    const attemptBadgeMessage = props.attempted
      ? "Payment Failed"
      : "Missing Payment";

    function calculateDateDiff(dueDate) {
      const currDate = Date.now();
      const parsedDate = Date.parse(dueDate);
      const isPastDue = currDate > parsedDate;
      const absDiff = Math.floor(
        Math.abs((currDate - parsedDate) / (1000 * 60 * 60 * 24)),
      );

      return { isPastDue, days: absDiff };
    }

    async function payInvoice() {
      vueInstance.emit("toggleLoading", true);
      canClickPay.value = false;
      let paymentAttempt;
      try {
        paymentAttempt = await store.dispatch("payInvoicesViaLPG", {
          tenantId: entityId,
          invoiceId: props.invoiceId,
          xTestMode: demo,
          xConfiguration: configurationType,
        });
      } catch (err) {
        console.error("[UNPAID] Error paying invoice", err);
        toast.error(`An issue occurred while attempting to pay invoice`);
        return;
      } finally {
        vueInstance.emit("toggleLoading", false);
        canClickPay.value = true;
      }
      vueInstance.emit("onPaid", paymentAttempt, props.invoiceId);
    }

    const daysOverdue = calculateDateDiff(props.dateDue);
    dueMessage.value =
      daysOverdue.days < 1
        ? "Payment Due Today"
        : `Payment ${daysOverdue.days} Day(s) Overdue`;

    return {
      attemptBadgeMessage,
      canClickPay,
      dueMessage,
      payInvoice,
    };
  },
};
</script>
