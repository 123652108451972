import moment from "moment";
import { PDFDocument } from "pdf-lib";
const { isValid } = require("usdl-regex");

async function createCertificateOfInsurance(
  templateUrl,
  dateMadeActive,
  certificate,
  policy,
  vin,
  businessName,
  businessAddress,
  financeName,
  financeAddress,
) {
  const pdf = await fetch(templateUrl).then((res) => res.arrayBuffer());
  const pdfDoc = await PDFDocument.load(pdf);
  const pages = pdfDoc.getPages();
  const page = pages[0];
  const { width, height } = page.getSize();
  page.drawText(dateMadeActive, {
    x: width - 85,
    y: height - 45,
    size: 10,
  });
  page.drawText((certificate || "").toString(), {
    x: width / 2 - 15,
    y: height - 250,
    size: 10,
  });
  page.drawText((policy || "").toString(), {
    x: width / 2 - 65,
    y: height - 420,
    size: 10,
  });
  page.drawText((vin || "").toString(), {
    x: width / 4,
    y: height / 4 - 30,
    size: 10,
  });
  page.drawText((businessName || "").toString(), {
    x: 25,
    y: 120,
    size: 10,
  });
  page.drawText((businessAddress || "").toString(), {
    x: 25,
    y: 110,
    size: 10,
  });
  page.drawText((financeName || "").toString(), {
    x: 25,
    y: 80,
    size: 10,
  });
  page.drawText((financeAddress || "").toString(), {
    x: 25,
    y: 70,
    size: 10,
  });
  const pdfBytes = await pdfDoc.save();
  return new Blob([pdfBytes], { type: "application/pdf" });
}

async function createInsuranceCard(
  templateUrl,
  effectiveDate,
  naic,
  policy,
  businessName,
  businessAddress,
  year,
  make,
  model,
  vin,
) {
  const pdf = await fetch(templateUrl).then((res) => res.arrayBuffer());
  const pdfDoc = await PDFDocument.load(pdf);
  const pages = pdfDoc.getPages();
  const page = pages[0];
  const { width, height } = page.getSize();
  page.drawText(effectiveDate, {
    x: width - 200,
    y: height - 142,
    size: 20,
  });
  page.drawText((policy || "").toString(), {
    x: 200,
    y: height - 142,
    size: 20,
  });
  page.drawText((businessName || "").toString(), {
    x: 200,
    y: height / 2 - 30,
    size: 15,
  });
  page.drawText((businessAddress || "").toString(), {
    x: 220,
    y: height / 3 + 2,
    size: 15,
  });
  page.drawText((year || "").toString(), {
    x: 115,
    y: 75,
    size: 10,
  });
  page.drawText((make || "").toString(), {
    x: 250,
    y: 75,
    size: 10,
  });
  page.drawText((model || "").toString(), {
    x: 380,
    y: 75,
    size: 10,
  });
  page.drawText((vin || "").toString(), {
    x: 510,
    y: 75,
    size: 10,
  });
  const pdfBytes = await pdfDoc.save();
  return new Blob([pdfBytes], { type: "application/pdf" });
}

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      resolve(reader.result);
    };
    reader.onerror = function (error) {
      reject(error);
    };
  });
}

function getMegabytesFromBytes(sizeInBytes) {
  return (sizeInBytes / (1024 * 1024)).toFixed(2);
}

function mapSystemSettings(settings = []) {
  const mapped = {};
  settings.forEach((setting) => {
    mapped[setting.key] = setting.value;
  });
  return mapped;
}

function sortEpisodeStartDescending(episodes) {
  return (episodes || []).sort((a, b) => {
    const aStart = moment.utc(a.startDate).local().toDate();
    const bStart = moment.utc(b.startDate).local().toDate();
    return aStart > bStart ? -1 : 1;
  });
}

function mapVehiclesToEpisodes(vehicles, episodes) {
  const activeEpisodes = sortEpisodeStartDescending(
    episodes.filter((episode) => {
      const currentDate = moment().toDate();
      const startDate = moment.utc(episode.startDate).local().toDate();
      const endDate = moment.utc(episode.endDate).local().endOf("day").toDate();
      return currentDate <= endDate && currentDate >= startDate;
    }),
  );
  const scheduledEpisodes = sortEpisodeStartDescending(
    episodes.filter((episode) => {
      const currentDate = moment().toDate();
      const startDate = moment.utc(episode.startDate).local().toDate();
      const endDate = moment.utc(episode.endDate).local().toDate();
      return startDate > currentDate && endDate > currentDate;
    }),
  );
  return (vehicles || []).map((vehicle) => {
    const currentEpisode = activeEpisodes.find(
      (e) => e.vehicle === vehicle.entityId,
    );
    const upcomingEpisode = scheduledEpisodes.find(
      (e) => e.vehicle === vehicle.entityId,
    );
    return {
      ...vehicle,
      currentEpisode: currentEpisode
        ? {
            ...currentEpisode,
            startDate: moment
              .utc(currentEpisode.startDate)
              .local()
              .format("MM/DD/yyyy"),
            endDate: moment
              .utc(currentEpisode.endDate)
              .local()
              .format("MM/DD/yyyy"),
          }
        : null,
      upcomingEpisode: upcomingEpisode
        ? {
            ...upcomingEpisode,
            startDate: moment
              .utc(upcomingEpisode.startDate)
              .local()
              .format("MM/DD/yyyy"),
            endDate: moment
              .utc(upcomingEpisode.endDate)
              .local()
              .format("MM/DD/yyyy"),
          }
        : null,
    };
  });
}

function getBillingDate(account) {
  if (!account.value.billingDate) {
    return "";
  }
  const billingDate = account.value.billingDate;
  const day = moment(billingDate).format("Do");
  let month = "of this month";
  if (
    moment(billingDate).format("MM") != moment().format("MM") ||
    moment(billingDate).format("DD") < moment().format("DD")
  ) {
    month = "of next month";
  }

  return day + " " + month;
}

async function downloadFile(url, fileName) {
  const a = document.createElement("a");
  a.href = url;
  a.target = "_blank";
  a.download = fileName || url.split("/").pop();
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

function maskEmail(email) {
  const maskedEmail = email.replace(/([^@.])/g, "*").split("");
  let previous = "";
  for (let i = 0; i < maskedEmail?.length; i++) {
    if (i <= 1 || previous == "." || previous == "@") {
      maskedEmail[i] = email[i];
    }
    previous = email[i];
  }
  return maskedEmail.join("");
}
function vehicleRecentlyDeactivated(changelog) {
  const statusChanges = changelog.filter((obj) => {
    return obj.fieldName == "status";
  });
  const recentChange = statusChanges.sort(function (a, b) {
    return new Date(b.timestamp) - new Date(a.timestamp);
  })[0];
  if (
    recentChange.oldValue == "Active" &&
    recentChange.newValue == "Inactive"
  ) {
    const second = new Date(recentChange.timestamp);
    second.setDate(second.getDate() + 45);
    const daysRemaining = Math.round(
      (second - new Date()) / (1000 * 60 * 60 * 24),
    );
    if (daysRemaining > 0) {
      return { recentlyDeactivated: true, daysRemaining };
    }
  }
  return { recentlyDeactivated: false };
}

async function fullFormFieldValidation(vehicle, additionalFields) {
  try {
    const missingVehicleData = [];
    let vehicleValidation = [
      {
        key: "vin",
        validationFunction(vehicle) {
          return vehicle["vin"]?.length === 17;
        },
        errorMessage: "Vin",
      },
      {
        key: "make",
        validationFunction(vehicle) {
          return vehicle.make?.length > 0;
        },
        errorMessage: "Make",
      },
      {
        key: "model",
        validationFunction(vehicle) {
          return vehicle.make?.length > 0;
        },
        errorMessage: "Model",
      },
      {
        key: "year",
        validationFunction(vehicle) {
          return vehicle.year?.length > 0;
        },
        errorMessage: "Year",
      },
      {
        key: "statedMileageRange",
        validationFunction(vehicle) {
          return String(vehicle["statedMileageRange"])?.length > 0;
        },
        errorMessage: "Stated Mileage Range",
      },
      {
        key: "mileage",
        validationFunction(vehicle) {
          return (
            typeof vehicle["mileage"] === "number" && vehicle["mileage"] >= 0
          );
        },
        errorMessage: "Mileage",
      },
      {
        key: "vehicleRegistrationState",
        validationFunction(vehicle) {
          return vehicle["vehicleRegistrationState"]?.length > 0;
        },
        errorMessage: "Vehicle registration state",
      },
      {
        key: "registrationAddressLineOne",
        validationFunction(vehicle) {
          return vehicle["registrationAddressLineOne"]?.length > 0;
        },
        errorMessage: "Registration address line one",
      },
      {
        key: "registrationCity",
        validationFunction(vehicle) {
          return vehicle.registrationCity?.length > 0;
        },
        errorMessage: "Registration address city",
      },
      {
        key: "registrationState",
        validationFunction(vehicle) {
          return vehicle.registrationState?.length > 0;
        },
        errorMessage: "Registration address state",
      },
      {
        key: "registrationZipcode",
        validationFunction(vehicle) {
          return vehicle.registrationZipcode?.length > 0;
        },
        errorMessage: "Registration address zipcode",
      },
      {
        key: "registrationName",
        validationFunction(vehicle) {
          return vehicle["registrationName"]?.length > 0;
        },
        errorMessage: "Registration name",
      },
      {
        key: "registrantGovernmentId",
        validationFunction(vehicle) {
          return vehicle["registrantGovernmentId"]?.length > 0;
        },
        errorMessage: "Registration government ID number",
      },
      {
        key: "registrantFirstName",
        validationFunction(vehicle) {
          return vehicle.registrantType == "Person"
            ? vehicle.registrantFirstName?.length > 0
            : true;
        },
        errorMessage: "Registrant first name",
      },
      {
        key: "registrantLastName",
        validationFunction(vehicle) {
          return vehicle.registrantType == "Person"
            ? vehicle.registrantLastName?.length > 0
            : true;
        },
        errorMessage: "Registrant last name",
      },
      {
        key: "registrantGovernmentIdType",
        validationFunction(vehicle) {
          return vehicle.registrantType == "Person"
            ? vehicle.registrantGovernmentIdType?.length > 0
            : true;
        },
        errorMessage: "Registrant government ID type",
      },
      {
        key: "driverLicenseStateIssued",
        validationFunction(vehicle) {
          return vehicle.registrantType == "Person" &&
            vehicle.registrantGovernmentIdType == "DriverLicense"
            ? vehicle?.driverLicenseStateIssued?.length > 0 &&
                isValid(
                  vehicle.driverLicenseStateIssued,
                  vehicle.registrantGovernmentId,
                )
            : true;
        },
        errorMessage: "Driver license number",
      },
      {
        key: "financeCompanyName",
        validationFunction(vehicle) {
          return vehicle.financeCompanyApplicable === true
            ? vehicle.financeCompanyName?.length > 0
            : true;
        },
        errorMessage: "Finance company name",
      },
      {
        key: "financeCompanyAddressLineOne",
        validationFunction(vehicle) {
          return vehicle.financeCompanyApplicable === true
            ? vehicle.financeCompanyAddressLineOne?.length > 0
            : true;
        },
        errorMessage: "Finance company address line one",
      },
      {
        key: "financeCompanyCity",
        validationFunction(vehicle) {
          return vehicle.financeCompanyApplicable === true
            ? vehicle.financeCompanyCity?.length > 0
            : true;
        },
        errorMessage: "Finance company address city",
      },
      {
        key: "financeCompanyState",
        validationFunction(vehicle) {
          return vehicle.financeCompanyApplicable === true
            ? vehicle.financeCompanyState?.length > 0
            : true;
        },
        errorMessage: "Finance company address state",
      },
      {
        key: "financeCompanyZipcode",
        validationFunction(vehicle) {
          return vehicle.financeCompanyApplicable === true
            ? vehicle.financeCompanyZipcode?.length > 0
            : true;
        },
        errorMessage: "Finance company address zipcode",
      },
      {
        key: "lotAddressLineOne",
        validationFunction(vehicle) {
          return vehicle.separateLotAddress === true
            ? vehicle.lotAddressLineOne?.length > 0
            : true;
        },
        errorMessage: "Lot address line one",
      },
      {
        key: "lotCity",
        validationFunction(vehicle) {
          return vehicle.separateLotAddress === true
            ? vehicle.lotCity?.length > 0
            : true;
        },
        errorMessage: "Lot address city",
      },
      {
        key: "lotState",
        validationFunction(vehicle) {
          return vehicle.separateLotAddress === true
            ? vehicle.lotState?.length > 0
            : true;
        },
        errorMessage: "Lot address state",
      },
      {
        key: "lotZipcode",
        validationFunction(vehicle) {
          return vehicle.separateLotAddress === true
            ? vehicle.lotZipcode?.length > 0
            : true;
        },
        errorMessage: "Lot address zipcode",
      },
    ];
    if (additionalFields) {
      vehicleValidation = [...vehicleValidation, ...additionalFields];
    }
    vehicleValidation.map((field) => {
      try {
        if (field.validationFunction(vehicle) === false) {
          missingVehicleData.push(field.errorMessage);
        }
      } catch (e) {
        console.log(e);
        console.log(field.key);
        missingVehicleData.push(`${field.key} is not found.`);
      }
    });
    return { valid: missingVehicleData?.length == 0, missingVehicleData };
  } catch (e) {
    console.log(e);
    return {
      valid: false,
      missingVehicleData: ["Error, something went wrong"],
    };
  }
}

export {
  createCertificateOfInsurance,
  createInsuranceCard,
  getBase64,
  getMegabytesFromBytes,
  mapSystemSettings,
  mapVehiclesToEpisodes,
  getBillingDate,
  sortEpisodeStartDescending,
  downloadFile,
  maskEmail,
  vehicleRecentlyDeactivated,
  fullFormFieldValidation,
};
