<template>
  <modal class="z-20 ml-0">
    <div
      class="transition w-full h-full md:w-3/5 lg:w-1/2 xl:w-2/5 max-w-screen-xl"
    >
      <div class="flex flex-col bg-white rounded-xl w-full min-h-full">
        <div class="h-16 border-b-2 w-full grid grid-cols-2 md:grid-cols-4">
          <div
            class="col-span-1 md:col-span-3 ml-4 items-center mt-6 text-xs text-gray-500"
          >
            EDIT PAYMENT INFORMATION
          </div>
          <div class="col-span-1 md:col-span-1 mr-4 justify-self-end">
            <button @click="onClose" class="bg-gray-300 py-1 px-3 mt-4">
              &times;
            </button>
          </div>
        </div>
        <div class="px-4 pt-8 w-full">
          <div>
            <h1 class="text-2xl font-bold">Edit payment information</h1>
          </div>
          <div class="grid grid-cols-7 mt-8 mb-4 font-bold text-sm">
            <span class="col-span-3 py-3">Cards on file</span>
            <span class="col-span-4 justify-self-end">
              <button @click="openAddPaymentModal">
                + Add new payment method
              </button>
            </span>
          </div>
          <Loader v-if="isLoading" />
          <payment-method-item
            v-for="(item, index) in paymentMethodData"
            :key="'payment_method_item'.concat(index)"
            :last-four="item.lastFourDigits"
            :payment-type="item.paymentMethodType"
            :sub-type="item.subtype"
            :funding-source="item.fundingSource"
            :pm-id="item.id"
            :can-delete="canDeletePM"
            @on-delete="deletePaymentMethod"
          />
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import { useStore } from "vuex";
import { ref, onMounted } from "vue";

import PaymentMethodItem from "./PaymentMethodItem.vue";
import { Loader } from "@getaddify/lula-components";

export default {
  setup() {
    const store = useStore();
    const paymentMethodData = ref([]);
    const isLoading = ref(true);
    const canDeletePM = ref(false);
    const { demo, entityId, type } = store.state.account;

    onMounted(async () => {
      try {
        const res = await store.dispatch("getCustomerDetailsFromLPG", {
          accountId: entityId,
          xTestMode: demo,
          xConfiguration: type == "Car Rental" ? "auto" : "trucking",
        });
        store.commit(
          "setDefaultPM",
          res.customerDetails.defaultPaymentMethodId,
        );
      } catch (err) {
        console.error("[PMTMETH] Error getting customer details from LPG", err);
      } finally {
        try {
          const paymentMethodsList = await store.dispatch(
            "getPaymentMethodsFromLpg",
            {
              accountId: entityId,
              xTestMode: demo,
              xConfiguration: type == "Car Rental" ? "auto" : "trucking",
            },
          );
          paymentMethodData.value = paymentMethodsList.paymentMethods;
          canDeletePM.value =
            paymentMethodsList.paymentMethods.length > 1 ? true : false;
        } catch (err) {
          console.error("[PMTMETH] Error loading payment methods", err);
        } finally {
          isLoading.value = false;
        }
      }
    });

    return {
      canDeletePM,
      paymentMethodData,
      isLoading,
    };
  },
  components: {
    PaymentMethodItem,
    Loader,
  },
  methods: {
    onClose() {
      this.$emit("toggle");
    },
    openAddPaymentModal() {
      this.$emit("openAddModal");
    },
    deletePaymentMethod(pmId) {
      for (let i = 0; i < this.paymentMethodData.length; i++) {
        if (this.paymentMethodData[i]["id"] == pmId) {
          this.paymentMethodData.splice(i, 1);
        }
      }
      if (this.paymentMethodData.length < 2) {
        this.canDeletePM = false;
      }
    },
  },
};
</script>
