<template>
  <div class="data-table w-full my-12 flex flex-wrap items-center">
    <div>
      <h3 class="text-2xl font-bold text-lula-neutral-800 mb-8">
        Active Reservations Coverages
      </h3>
    </div>
    <div class="w-full bg-white rounded-md border p-4">
      <table class="w-full" v-if="!isRefreshing">
        <tr class="text-left border-b text-gray-800">
          <th class="pb-2 px-2">Reservation <br />Number</th>
          <th class="pb-2 px-2">Vehicle</th>
          <th class="hidden md:table-cell sm:table-cell pb-2 px-2">Renter</th>
          <th class="hidden md:table-cell pb-2 px-2">Start Date</th>
          <th class="hidden md:table-cell pb-2 px-2">End Date</th>
          <th class="hidden md:table-cell pb-2 px-2"></th>
        </tr>
        <tr
          v-for="(reservation, index) in reservations"
          class="text-gray-600 text-sm hover:bg-gray-50"
          :key="index"
        >
          <td class="px-2">
            <div class="flex items-center justify-center">
              <div class="text-sm font-bold text-lula-neutral-800">
                {{ reservation.reservationNumber }}
              </div>
            </div>
          </td>
          <td class="px-2">
            <div class="flex items-center">
              <div class="">
                <div class="text-sm font-semibold text-lula-neutral-800">
                  {{ reservation.vehicle.vin }}
                </div>
                <div class="text-sm text-lula-neutral-500">
                  {{
                    reservation.vehicle.make +
                    " " +
                    reservation.vehicle.model +
                    " " +
                    reservation.vehicle.year
                  }}
                </div>
                <div class="text-sm text-lula-neutral-500">
                  {{ reservation.vehicle.nickName }}
                </div>
              </div>
            </div>
          </td>
          <td class="hidden md:table-cell sm:table-cell px-2">
            <div class="flex">
              <div class="text-sm font-bold text-lula-neutral-800">
                {{ reservation.renter }}
              </div>
            </div>
          </td>
          <td class="hidden md:table-cell px-2">
            <div class="flex items-center">
              <div class="text-sm leading-5 font-medium text-gray-900">
                <strong>{{ reservation.startDate }}</strong> |
                {{ reservation.startTime }}
              </div>
            </div>
          </td>
          <td class="hidden md:table-cell px-2">
            <div class="flex items-center">
              <div class="text-sm leading-5 font-medium text-gray-900">
                <strong>{{ reservation.endDate }}</strong> |
                {{ reservation.endTime }}
              </div>
            </div>
          </td>
          <td class="hidden md:table-cell px-2">
            <div class="flex flex-row">
              <button
                @click="() => toggleExtendReservation(reservation)"
                class="cursor-pointer rounded-[32px] border flex flex-row justify-center items-center border-gray-300 text-gray-700 bg-white hover:text-gray-500 hover:bg-gray-100 active:text-gray-800 active:bg-gray-50 my-4 mr-2 h-8 py-2 px-4 text-xs"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="w-3 mr-2"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M12 4v16m8-8H4"
                  />
                </svg>
                Extend
              </button>
              <button
                @click="() => toggleCancel(reservation)"
                class="cursor-pointer rounded-[32px] flex flex-row justify-center items-center text-red-500 bg-white hover:bg-red-100 active:text-white active:bg-gray-50 my-4 mx-0 h-8 p-2 px-4 text-xs"
              >
                Cancel
              </button>
            </div>
          </td>
        </tr>
      </table>
      <div
        v-if="!isRefreshing && reservations.lenght === 0"
        class="w-full p-4 text-center font-bold text-lula-neutral-800"
      >
        No reservations to show!
      </div>
    </div>
    <extend-reservation
      v-if="extendReservation"
      :selectedReservation="selectedReservation"
      @toggle="toggleExtendReservation"
      @refresh="refresh"
    />
    <cancel-reservation
      v-if="cancelReservation"
      :selectedReservation="selectedReservation"
      @toggle="toggleCancel"
      @refresh="refresh"
    />
  </div>
</template>
<script>
import { ref } from "vue";
import ExtendReservation from "./ExtendReservation.vue";
import CancelReservation from "./CancelReservation.vue";
import moment from "moment";
import { useStore } from "vuex";

export default {
  setup() {
    const store = useStore();

    const extendReservation = ref(false);
    const selectedReservation = ref(null);
    const cancelReservation = ref(false);
    const isRefreshing = ref(false);
    const isCanceling = ref(false);
    const reservations = ref([]);

    async function refresh() {
      try {
        isRefreshing.value = true;
        const response = await store.dispatch("getReservations");
        reservations.value = response
          .filter(
            (res) => res?.status !== "Canceled" || res?.status === undefined,
          )
          .sort((a, b) => {
            return moment(a.endDate) > moment(b.endDate) ? 1 : -1;
          });
      } catch (err) {
        alert(err);
      } finally {
        isRefreshing.value = false;
      }
    }

    async function closeCancel() {
      cancelReservation.value = false;
    }

    return {
      reservations,
      cancelReservation,
      isRefreshing,
      isCanceling,
      loading: false,
      selectedReservation,
      extendReservation,
      closeCancel,
      refresh,
    };
  },
  components: {
    ExtendReservation,
    CancelReservation,
  },
  methods: {
    toggleExtendReservation(reservation) {
      this.extendReservation = !this.extendReservation;
      this.selectedReservation = reservation;
    },
    toggleCancel(reservation) {
      this.cancelReservation = !this.cancelReservation;
      this.selectedReservation = reservation;
    },
  },
  created() {
    this.refresh();
  },
  watch: {
    async $route() {
      this.refresh();
    },
  },
};
</script>
<style></style>
