<template>
  <div class="h-screen flex justify-center items-center px-2">
    <div
      class="bg-white rounded-lg shadow-lg p-4 border-b-4 border-lula w-full md:w-2/3"
    >
      <h1 class="text-2xl text-gray-800 font-bold">Please Verify Your Email</h1>
      <span class="font-bold text-gray-600">
        Your email address has not been verified. Please check your inbox for an
        email from GetAddify and click the link to verify your email address. If
        you cannot find the email, please check your spam or promotions folders.
      </span>
      <div class="flex flex-wrap-reverse mt-2 sm:flex-nowrap">
        <button
          class="transition w-full border rounded-lg m-1 hover:bg-gray-100"
          @click="handleCheckVerificationClick"
        >
          Check Verification
        </button>
        <button
          class="transition w-full border rounded-lg m-1 hover:bg-gray-100"
          @click="handleResendVerificationClick"
        >
          Resend Email
        </button>
        <button
          class="transition w-full border rounded-lg m-1 hover:bg-gray-100"
          @click="handleLogoutClick"
        >
          Logout
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { useToast } from "vue-toastification";
import * as Customer from "@getaddify/customer-account-management";

export default {
  setup() {
    const store = useStore();
    const toast = useToast();

    const handleCheckVerificationClick = async () => {
      const user = await store.dispatch("getCurrentUser");
      if (!user.emailVerified) {
        toast.clear();
        toast.error(
          "Your email has still not been verified. Please check your inbox for an email from GetAddify and click the link.",
        );
      } else {
        toast.clear();
        toast.success("Your email has been verified.");
      }
    };

    const handleResendVerificationClick = async () => {
      try {
        const configuration = new Customer.Configuration({
          basePath: process.env.VUE_APP_ACCOUNT_MANAGEMENT_API_BASE_PATH,
          credentials: "include",
        });
        const client = new Customer.SubjectApi(configuration);
        await client.sendSelfVerificationEmail();
        toast.clear();
        toast.success(
          "A new verification email has been sent. Please check your inbox for an email from GetAddify.",
        );
      } catch (ex) {
        toast.clear();
        toast.error(
          "Failed to send verification email. Please reach out to support if the problem continues.",
        );
      }
    };

    const handleLogoutClick = async () => {
      await store.dispatch("signOut");
    };

    return {
      handleCheckVerificationClick,
      handleResendVerificationClick,
      handleLogoutClick,
    };
  },
};
</script>
