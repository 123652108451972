<template>
  <div
    class="transition-all duration-1000 w-full"
    :class="{
      'opacity-0': !readyToAck,
      'opacity-100': readyToAck,
      'lg:w-2/3': step === 'note',
      'xl:w-11/12': step === 'fleet',
    }"
  >
    <div class="bg-white rounded-lg shadow-lg border-b-4 border-lula w-full">
      <div
        class="text-sm flex justify-center items-center bg-lula border-b shadow-inner tracking-wide text-white font-bold text-center uppercase py-1 rounded-t md:text-base"
      >
        <span v-if="remainingDays">{{ remainingDays }} days remaining</span>
        <span v-else>final day</span>
        <span class="bg-white h-0.5 w-6 mx-2 block" />
        <span class="hidden lg:block">{{ ackDate }} end of day deadline</span>
        <span class="lg:hidden">{{ ackDate }} deadline</span>
      </div>
      <div class="p-4">
        <div v-if="step === 'note'">
          <div
            style="max-height: 450px; overflow: auto"
            v-html="settings.carsvehicleacknote"
            id="ack-note"
          />
          <div class="flex justify-end flex-col mt-2 md:flex-row">
            <button
              @click="continueAck"
              class="w-full mb-2 transition border rounded-lg shadow-sm font-bold text-white py-2 mr-1 bg-lula-gradient hover:bg-lula-gradient-alt md:mb-0 md:w-max"
            >
              Acknowledge
            </button>
          </div>
        </div>
        <div v-else-if="step === 'fleet'">
          <div class="flex justify-between items-start mb-4">
            <div>
              <h1 class="text-2xl text-gray-800 font-bold">Fleet Review</h1>
              <span class="text-sm font-bold text-gray-500 md:text-base">
                Please select the vehicles you'd like to renew on
                {{ renewDate }}
              </span>
            </div>
            <span
              class="hidden bg-gray-100 text-gray-600 rounded-full py-2 px-4 md:block"
              v-if="fleetFilter === 'active'"
            >
              {{ activeAckCount }} / {{ totalActiveVehicles }} Completed
            </span>
          </div>
          <div class="w-full flex text-gray-600 text-sm md:text-base">
            <div
              @click="fleetFilter = 'active'"
              :class="{
                'bg-gray-100 text-gray-800 border-lula':
                  fleetFilter === 'active',
              }"
              class="w-full border-b-2 border-transparent text-center py-2 px-5 cursor-pointer select-none hover:bg-gray-100"
            >
              Currently Covered
            </div>
            <div
              @click="fleetFilter = 'inactive'"
              :class="{
                'bg-gray-100 text-gray-800 border-lula':
                  fleetFilter === 'inactive',
              }"
              class="w-full border-b-2 border-transparent text-center py-2 px-5 cursor-pointer select-none hover:bg-gray-100"
            >
              Currently Inactive
            </div>
          </div>
          <div>
            <input
              type="text"
              class="w-full rounded-none text-lg bg-gray-50 text-gray-800"
              placeholder="Search for a vehicle"
              v-model="search"
            />
          </div>
          <div
            style="height: 450px"
            class="flex items-center justify-center p-4"
            v-if="vehicles.length === 0"
          >
            <p class="text-center text-gray-600 text-2xl font-bold">
              No Vehicles
            </p>
          </div>
          <div v-else>
            <div style="min-height: 450px; max-height: 450px; overflow: auto">
              <table class="w-full text-gray-600">
                <tr class="sticky top-0 bg-white text-center">
                  <th class="hidden py-2 lg:table-cell">Renewal Option</th>
                  <th class="text-left py-2">Vehicle</th>
                  <th class="hidden p-2 lg:px-0 lg:table-cell">
                    Current Status
                  </th>
                  <th class="hidden py-2 lg:table-cell">
                    Information Completed
                  </th>
                </tr>
                <tr
                  class="text-center cursor-pointer hover:bg-gray-100"
                  v-for="vehicle in vehicles"
                  :key="vehicle.entityId"
                >
                  <td
                    class="hidden text-left py-4 px-2 bg-white cursor-default w-1/4 lg:table-cell"
                  >
                    <div
                      class="flex-col items-center w-full"
                      :class="{ 'mt-4': cantRenewVehicle(vehicle) }"
                    >
                      <div class="flex justify-center w-full items-center">
                        <button
                          disabled
                          class="bg-gray-200 text-sm py-2 mx-1 cursor-default w-full border border-transparent"
                          v-if="cantRenewVehicle(vehicle)"
                        >
                          Ineligible
                        </button>
                        <button
                          class="w-full bg-gray-100 mx-1 text-sm py-2 border border-transparent"
                          @click="ackVehicle(vehicle.entityId, 'renew')"
                          :class="{
                            'bg-gray-100':
                              vehicleAck[vehicle.entityId] !== 'renew',
                            'bg-lula-gradient text-white':
                              vehicleAck[vehicle.entityId] === 'renew',
                          }"
                          v-else
                        >
                          Renew
                        </button>
                        <button
                          class="bg-gray-100 mx-1 text-sm py-2 w-full border border-transparent"
                          @click="ackVehicle(vehicle.entityId, 'cancel')"
                          :class="{
                            'border border-lula-dark text-lula-dark':
                              vehicleAck[vehicle.entityId] === 'cancel',
                          }"
                        >
                          Cancel
                        </button>
                      </div>
                      <div
                        class="text-center w-full"
                        v-if="cantRenewVehicle(vehicle)"
                      >
                        <span
                          class="text-sm mt-1 block"
                          v-if="cantRenewVehicle(vehicle) === 'approval'"
                        >
                          Vehicle not eligible
                        </span>
                        <span
                          class="text-sm mt-1 block"
                          v-if="cantRenewVehicle(vehicle) === 'missing-info'"
                        >
                          Must complete missing information to renew
                        </span>
                        <span
                          class="text-sm mt-1 block"
                          v-if="cantRenewVehicle(vehicle) === 'ineligible'"
                        >
                          Vehicle not eligible for the new policy
                        </span>
                        <span
                          class="text-sm mt-1 block"
                          v-if="cantRenewVehicle(vehicle) === 'review'"
                        >
                          Vehicle still under review
                        </span>
                      </div>
                      <div
                        class="text-center w-full"
                        v-else-if="
                          vehicle.status === 'Active' &&
                          vehicleAck[vehicle.entityId] === 'cancel'
                        "
                      >
                        <span class="text-sm mt-1 block text-lula-dark">
                          This vehicle will be set to
                          <strong>Inactive</strong> on {{ renewDate }}
                        </span>
                      </div>
                    </div>
                  </td>
                  <td
                    @click="selectVehicle(vehicle.entityId)"
                    class="py-4 text-left px-2"
                  >
                    <ul>
                      <li class="font-bold">
                        {{ vehicle.vin }}
                      </li>
                      <li>
                        {{ vehicle.year }} {{ vehicle.make }}
                        {{ vehicle.model }}
                      </li>
                      <li v-if="vehicle.plate">
                        {{ vehicle.plate }}
                      </li>
                      <li
                        class="text-red-500 font-bold lg:hidden"
                        v-if="getVehicleStatus(vehicle).includes('Missing')"
                      >
                        Missing Information
                      </li>
                    </ul>
                    <div
                      @click.stop="() => null"
                      class="cursor-default flex-col items-center w-full mt-4 lg:hidden"
                    >
                      <div class="flex justify-center w-full items-center">
                        <button
                          disabled
                          class="bg-gray-300 text-sm py-2 mx-1 cursor-default w-full border border-transparent"
                          v-if="cantRenewVehicle(vehicle)"
                        >
                          Ineligible
                        </button>
                        <button
                          class="w-full bg-gray-200 mx-1 text-sm py-2 border border-transparent"
                          @click="ackVehicle(vehicle.entityId, 'renew')"
                          :class="{
                            'bg-gray-100':
                              vehicleAck[vehicle.entityId] !== 'renew',
                            'bg-lula-gradient text-white':
                              vehicleAck[vehicle.entityId] === 'renew',
                          }"
                          v-else
                        >
                          Renew
                        </button>
                        <button
                          class="bg-gray-200 mx-1 text-sm py-2 w-full border border-transparent"
                          @click="ackVehicle(vehicle.entityId, 'cancel')"
                          :class="{
                            'border border-lula-dark text-lula-dark':
                              vehicleAck[vehicle.entityId] === 'cancel',
                          }"
                        >
                          Cancel
                        </button>
                      </div>
                      <div
                        class="text-center w-full"
                        v-if="cantRenewVehicle(vehicle)"
                      >
                        <span
                          class="text-sm mt-1 block"
                          v-if="cantRenewVehicle(vehicle) === 'approval'"
                        >
                          Vehicle not eligible
                        </span>
                        <span
                          class="text-sm mt-1 block"
                          v-if="cantRenewVehicle(vehicle) === 'missing-info'"
                        >
                          Must complete missing information to renew
                        </span>
                        <span
                          class="text-sm mt-1 block"
                          v-if="cantRenewVehicle(vehicle) === 'ineligible'"
                        >
                          Vehicle not eligible for the new policy
                        </span>
                        <span
                          class="text-sm mt-1 block"
                          v-if="cantRenewVehicle(vehicle) === 'review'"
                        >
                          Vehicle still under review
                        </span>
                      </div>
                      <div
                        class="text-center w-full"
                        v-else-if="
                          vehicle.status === 'Active' &&
                          vehicleAck[vehicle.entityId] === 'cancel'
                        "
                      >
                        <span class="text-sm mt-1 block text-lula-dark">
                          This vehicle will be set to
                          <strong>Inactive</strong> on {{ renewDate }}
                        </span>
                      </div>
                    </div>
                  </td>
                  <td
                    @click="selectVehicle(vehicle.entityId)"
                    class="hidden py-4 lg:table-cell"
                  >
                    <div
                      class="font-bold text-lula"
                      v-if="vehicle.status === 'Active'"
                    >
                      Covered
                    </div>
                    <div v-else>
                      {{ vehicle.status }}
                    </div>
                  </td>
                  <td
                    @click="selectVehicle(vehicle.entityId)"
                    class="hidden py-4 lg:table-cell"
                  >
                    <div
                      class="flex text-center flex-col text-red-500"
                      v-if="getVehicleStatus(vehicle).includes('Missing')"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-6 w-6 m-auto"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="2"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>
                      <span class="text-sm mt-2"> Click here to complete </span>
                    </div>
                    <div class="flex justify-center" v-else>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-6 w-6 text-green-500"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        stroke-width="2"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <div class="flex justify-end flex-col mt-4 md:flex-row">
            <button
              @click="
                $store.commit('setItem', { key: 'vehicleAckRemind', val: true })
              "
              class="w-full mb-2 transition border rounded-lg shadow-sm py-2 mr-2 text-gray-600 hover:bg-gray-100 hover:text-gray-800 md:mb-0 md:w-max"
              v-if="vehicleAckForm.confirmed"
            >
              Close
            </button>
            <button
              @click="remindMeLater(true)"
              class="w-full mb-2 transition border rounded-lg shadow-sm py-2 mr-2 text-gray-600 hover:bg-gray-100 hover:text-gray-800 md:mb-0 md:w-max"
              v-else
            >
              Save For Later
            </button>
            <button
              @click="continueAck"
              :disabled="
                saving === 'confirm' || vehicleAckCount < totalVehicles
              "
              :class="{
                'bg-lula-gradient font-bold hover:bg-lula-gradient-alt text-white':
                  saving !== 'confirm' && vehicleAckCount === totalVehicles,
                'text-gray-100 cursor-default text-gray-400':
                  saving === 'confirm' || vehicleAckCount < totalVehicles,
              }"
              class="w-full mb-2 transition border rounded-lg shadow-sm py-2 mr-1 md:mb-0 md:w-max"
            >
              {{ saving === "confirm" ? "Confirming..." : "Confirm Fleet" }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="flex justify-between" v-if="step !== 'note'">
      <span />
      <span
        class="text-gray-600 font-bold cursor-pointer hover:underline"
        @click="step = 'note'"
      >
        Return to acknowledgement
      </span>
    </div>
  </div>
  <modal v-if="showFleetConfirmation">
    <div class="bg-white p-4 w-2/3 rounded-md text-gray-600 lg:w-5/12">
      <div>
        <h1 class="text-gray-800 text-xl font-bold mb-2 lg:text-2xl">
          You're all set!
        </h1>
        <span class="text-base lg:text-lg"
          >Thank you for renewing with GetAddify changes will take effect on
          {{ renewDate }}</span
        >
      </div>
      <div class="w-full mt-4">
        <button
          class="bg-lula-gradient w-full text-white hover:bg-lula-gradient-alt"
          @click="closeConfirmationModal"
        >
          Return to Dashboard
        </button>
      </div>
    </div>
  </modal>
</template>

<script>
import { ref, onMounted, computed, nextTick } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { useToast } from "vue-toastification";
import { mapSystemSettings } from "@/utils";
import moment from "moment";
import showdown from "showdown";

/*
const INVALID_MAKES = [
  'Ferrari', 'Lamborghini', 'Maserati', 'Aston Martin',
  'Bugatti', 'Bentley', 'Porsche', 'Lotus',
  'McLaren', 'Rolls-Royce', 'Polaris'
];

const INVALID_MODELS = [
  'i8', 'Model X', 'Model S', 'Slingshot'
];
*/

function getVehicleStatus(vehicle) {
  if (vehicle.insuranceCriteriaStatus === "Declined") {
    return "Declined";
  }
  if (
    [
      "registrationName",
      "registrationAddressLineOne",
      "registrationCity",
      "registrationState",
      "registrationZipcode",
      "vehicleRegistrationState",
    ].find((field) => !vehicle[field])
  ) {
    return "Missing Information";
  }
  if (
    ["lotAddressLineOne", "lotCity", "lotZipcode", "lotState"].find(
      (field) => !vehicle[field],
    )
  ) {
    return "Missing Information";
  }
  if (vehicle.financeCompanyApplicable) {
    if (
      [
        "financeCompanyName",
        "financeCompanyAddressLineOne",
        "financeCompanyCity",
        "financeCompanyZipcode",
        "financeCompanyState",
      ].find((field) => !vehicle[field])
    ) {
      return "Missing Information";
    }
  }
  return vehicle.status;
}

export default {
  setup() {
    const store = useStore();
    const router = useRouter();
    const toast = useToast();

    const vehicleAckForm = computed(() => {
      try {
        if (!store.state.account.vehicleAckForm) {
          return {};
        }
        return JSON.parse(store.state.account.vehicleAckForm);
      } catch (err) {
        return {};
      }
    });

    const step = ref(vehicleAckForm.value.ack ? "fleet" : "note");
    const settings = ref({});
    const fleetFilter = ref("active");
    const search = ref("");
    const vehicleAck = ref(store.state.vehicleAck || {});
    const saving = ref(null);
    const showFleetConfirmation = ref(false);

    const saveVehicleAck = () => {
      store.dispatch("saveDetailsForAccount", {
        vehicleAckForm: JSON.stringify({
          ...vehicleAckForm.value,
          remind: moment.utc(),
          modified: moment.utc(),
          savedVehicles: Object.keys(vehicleAck.value).map((vehicleId) => {
            return {
              vehicle: vehicleId,
              action: vehicleAck.value[vehicleId],
              modified: moment.utc(),
            };
          }),
        }),
      });
    };

    if (store.state.vehicleAckFleet) {
      step.value = "fleet";
      store.commit("setItem", {
        key: "vehicleAckFleet",
        val: false,
      });
    }

    onMounted(async () => {
      settings.value = mapSystemSettings(
        await store.dispatch("getSystemSettings"),
      );
      if (settings.value.carsvehicleacknote) {
        const converter = new showdown.Converter();
        let content = converter.makeHtml(
          settings.value["carsvehicleacknote"].replace(/(\\r)*\\n/g, "\n"),
        );
        content = content.replace(
          "[[ COMPANY NAME ]]",
          store.state.account.businessLegalName,
        );
        settings.value["carsvehicleacknote"] = content;
      }
    });

    onMounted(() => {
      if (Object.keys(vehicleAck.value).length > 0) {
        return;
      }
      store.state.vehicles.forEach((vehicle) => {
        if (
          vehicle.status === "Active" &&
          getVehicleStatus(vehicle).includes("Missing")
        ) {
          return;
        }
        if (getVehicleStatus(vehicle) === "Active") {
          vehicleAck.value[vehicle.entityId] = "renew";
          return;
        }
        vehicleAck.value[vehicle.entityId] = "cancel";
      });
    });

    onMounted(() => {
      const fVehicles = vehicleAckForm.value.savedVehicles || [];
      fVehicles.forEach((fVehicle) => {
        vehicleAck.value[fVehicle.vehicle] = fVehicle.action;
      });
    });

    const vehicleAckCount = computed(() => {
      return Object.values(vehicleAck.value).filter((opt) => opt).length;
    });

    return {
      step,
      saving,
      search,
      settings,
      vehicleAck,
      fleetFilter,
      vehicleAckForm,
      vehicleAckCount,
      getVehicleStatus,
      showFleetConfirmation,
      async closeConfirmationModal() {
        showFleetConfirmation.value = false;
        await nextTick();
        store.commit("setItem", { key: "vehicleAckView", val: false });
      },
      activeAckCount: computed(() => {
        return store.state.vehicles
          .filter((v) => v.status === "Active")
          .filter((vehicle) => {
            return vehicleAck.value[vehicle.entityId];
          }).length;
      }),
      totalActiveVehicles: computed(
        () => store.state.vehicles.filter((v) => v.status === "Active").length,
      ),
      totalVehicles: computed(() => store.state.vehicles.length),
      vehicles: computed(() => {
        return store.state.vehicles
          .filter((vehicle) => {
            if (fleetFilter.value === "active") {
              return vehicle.status === "Active";
            }
            if (fleetFilter.value === "inactive") {
              return vehicle.status !== "Active";
            }
            return true;
          })
          .filter((vehicle) => {
            if (search.value) {
              const query = search.value.toLowerCase();
              const vinMatch = (vehicle.vin || "")
                .toLowerCase()
                .includes(query);
              const keyMatch = (vehicle.key || "")
                .toLowerCase()
                .includes(query);
              const plateMatch = (vehicle.plate || "")
                .toLowerCase()
                .includes(query);
              const makeMatch =
                `${vehicle.year || ""} ${vehicle.make || ""} ${vehicle.model || ""}`
                  .toLowerCase()
                  .includes(query);
              return vinMatch || keyMatch || plateMatch || makeMatch;
            }
            return true;
          });
      }),
      renewDate: computed(() => {
        const ackDay = moment.utc(store.state.account.vehicleAck);
        ackDay.add(1, "days");
        return ackDay.format("MM/DD/yyyy");
      }),
      ackDate: computed(() => {
        const ackDay = moment.utc(store.state.account.vehicleAck);
        return ackDay.format("MM/DD/yyyy");
      }),
      remainingDays: computed(() => {
        const ackDay = moment.utc(store.state.account.vehicleAck);
        ackDay.add(1, "days");
        const today = moment(moment().format("yyyy-MM-DD"), "yyyy-MM-DD");
        return Math.abs(today.diff(ackDay, "days"));
      }),
      readyToAck: computed(() => {
        return settings.value.carsvehicleacknote;
      }),
      remindMeLater(saveVehicles = false) {
        store.dispatch("saveDetailsForAccount", {
          vehicleAckForm: JSON.stringify({
            ...vehicleAckForm.value,
            remind: moment.utc(),
            modified: moment.utc(),
            savedVehicles: saveVehicles
              ? Object.keys(vehicleAck.value).map((vehicleId) => {
                  return {
                    vehicle: vehicleId,
                    action: vehicleAck.value[vehicleId],
                    modified: moment.utc(),
                  };
                })
              : vehicleAckForm.value.savedVehicles || null,
          }),
        });
        store.commit("setItem", {
          key: "vehicleAckRemind",
          val: true,
        });
      },
      cantRenewVehicle(vehicle) {
        if (vehicle.insuranceCriteriaStatus === "Under Review") {
          return "review";
        }
        if (vehicle.insuranceCriteriaStatus !== "Approved") {
          return "approval";
        }
        /*
        if(parseInt(moment().format('yyyy')) - parseInt(vehicle.year) >= 15) {
          return 'ineligible';
        }
        if(INVALID_MAKES.includes(vehicle.make)) {
          return 'ineligible';
        }
        if(INVALID_MODELS.includes(vehicle.model)) {
          return 'ineligible';
        }
        if(vehicle.actualValue && parseInt(vehicle.actualValue) > 75000) {
          return 'ineligible';
        }
        */
        if (getVehicleStatus(vehicle).includes("Missing")) {
          return "missing-info";
        }
        return null;
      },
      ackVehicle(vehicleId, ack) {
        vehicleAck.value = {
          ...vehicleAck.value,
          [vehicleId]: ack,
        };
        store.commit("setItem", {
          key: "vehicleAck",
          val: vehicleAck.value,
        });
        saveVehicleAck();
      },
      selectVehicle(vehicleId) {
        saveVehicleAck();
        store.commit("setItem", {
          key: "vehicleAckFleet",
          val: true,
        });
        router.push({
          name: `${store.state.account.type}/Vehicle`,
          params: { id: vehicleId },
        });
      },
      async continueAck() {
        switch (step.value) {
          case "note": {
            step.value = "fleet";
            store.dispatch("saveDetailsForAccount", {
              vehicleAckForm: JSON.stringify({
                ack: moment.utc().format(),
                deadline: store.state.account.vehicleAck,
                ...vehicleAckForm.value,
                modified: moment.utc().format(),
              }),
            });
            break;
          }
          default: {
            saving.value = "confirm";
            try {
              await store.dispatch("saveDetailsForAccount", {
                vehicleAckForm: JSON.stringify({
                  confirmed: moment.utc().format(),
                  ...vehicleAckForm.value,
                  modified: moment.utc().format(),
                  savedVehicles: Object.keys(vehicleAck.value).map(
                    (vehicleId) => {
                      return {
                        vehicle: vehicleId,
                        action: vehicleAck.value[vehicleId],
                        modified: moment.utc(),
                      };
                    },
                  ),
                  confirmedVehicles: Object.keys(vehicleAck.value).map(
                    (vehicleId) => {
                      return {
                        vehicle: vehicleId,
                        action: vehicleAck.value[vehicleId],
                        modified: moment.utc(),
                      };
                    },
                  ),
                }),
              });
              await store.dispatch("getAccount");
              showFleetConfirmation.value = true;
            } catch (err) {
              toast.clear();
              toast("Failed to confirm fleet.");
            }
          }
        }
      },
    };
  },
};
</script>

<style>
#ack-note h1 {
  font-size: 1.5rem !important;
}

#ack-note p {
  font-size: 1rem !important;
  margin: 1rem 0 !important;
}
</style>
