<template>
  <div>
    <Navbar />
    <router-view />
  </div>
</template>

<script>
import Navbar from "@/claims/Navbar";
export default {
  components: { Navbar },
  async mounted() {
    const user = this.$store.state.user;
    if (user != null) return;

    const allowedRoutes = ["Claims/Landing", "Claims/VehicleLookup"];
    const isAllowedRoute = allowedRoutes.includes(
      this.$router.currentRoute.value.name,
    );
    const hasAccountId = !!this.$store.state.claimForm?.accountId;
    const hasVehicleId = !!this.$store.state.claimForm?.vehicleId;
    if (!isAllowedRoute && !hasAccountId && !hasVehicleId) {
      this.$router.replace({ name: "Claims/Landing" });
    }
  },
};
</script>
