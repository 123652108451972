<template>
  <component :is="renderingComponent"></component>
</template>

<script>
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import CoiDetails from "@/trucks/CoiDetails";

const components = {
  "certificate-of-insurance": CoiDetails,
};

export default {
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const sourceDocument = store.state.insuranceDocuments.find(
      (doc) => doc.entityId === route.params.id,
    );

    if (!sourceDocument) {
      console.error(
        `[INSDTL] Cannot find a document with id "${route.params.id}".`,
      );
      router.push({ name: `${store.state.account.type}/InsuranceDocuments` });
      return {};
    }

    const isComponentExisting = Object.keys(components).includes(
      sourceDocument.docType,
    );

    if (!isComponentExisting) {
      console.error(
        `[INSDTL] Cannot find an appropriate component for the "${sourceDocument.docType}" type.`,
      );
      return {};
    }

    return {
      renderingComponent: components[sourceDocument.docType],
    };
  },
};
</script>
