<template>
  <form class="flex min-h-screen items-center justify-center text-gray-800 p-2">
    <div class="flex flex-col w-full md:w-3/4 lg:w-2/3 xl:w-1/3">
      <div class="mb-8">
        <h1 class="text-lula-alt font-semibold text-lg">View claim status</h1>
        <span class="text-4xl font-semibold"> Details about your claim </span>
      </div>
      <div class="bg-white rounded-lg border p-4 py-8 w-full">
        <div class="flex flex-col gap-4 text-sm">
          <div style="background-color: #1b3664" class="rounded text-white p-4">
            <span class="ml-2 uppercase tracking-widest text-gray-200"
              >Status</span
            >
            <div class="flex items-start ml-2 mt-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="#4CDA85"
                class="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              <div class="pl-2">
                <span class="capitalize font-bold">{{ claim.status }}</span>
                <p>{{ statusText }}</p>
              </div>
            </div>
          </div>
          <div class="flex gap-4">
            <div class="flex flex-col gap-2 w-full">
              <div>
                <span class="font-semibold">Claim Number: </span>
                <span class="text-lula-primary">{{ claim.tpaClaimId }}</span>
              </div>
              <div class="mt-2 font-semibold">
                <span>Adjuster info</span>
              </div>
              <div>
                <span class="font-semibold">Name: </span>
                <span>{{ claim.adjusterName }}</span>
              </div>
              <div>
                <span class="font-semibold">Email: </span>
                <span>{{ claim.adjusterEmail }}</span>
              </div>
              <div>
                <span class="font-semibold">Phone: </span>
                <span>{{ claim.adjusterPhone }}</span>
              </div>
            </div>
            <div class="flex flex-col gap-2 w-full">
              <div>
                <span class="font-semibold">Submitted: </span>
                <span>{{ submittedDate }}</span>
              </div>
              <div class="mt-2 font-semibold">
                <span>Vehicle info</span>
              </div>
              <div>
                <span class="font-semibold">VIN: </span>
                <span>{{ claim.vehicleVin }}</span>
              </div>
              <div>
                <span class="font-semibold">Year: </span>
                <span>{{ claim.vehicleYear }}</span>
              </div>
              <div>
                <span class="font-semibold">Make: </span>
                <span>{{ claim.vehicleMake }}</span>
              </div>
              <div>
                <span class="font-semibold">Model: </span>
                <span>{{ claim.vehicleModel }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex justify-end items-center w-full">
        <router-link
          :to="{ name: 'Claims/ClaimLookup' }"
          class="text-lula-primary underline font-bold"
        >
          Lookup another claim
        </router-link>
      </div>
    </div>
  </form>
</template>
<script>
import { useStore } from "vuex";
import moment from "moment";
import { ref } from "vue";
import { useToast } from "vue-toastification";
import { useRoute } from "vue-router";
import DuplicateClaimToast from "./DuplicateClaimToast.vue";
export default {
  setup() {
    const toast = useToast();
    const store = useStore();
    const route = useRoute();
    const isDuplicateClaim = route.query.isDuplicateClaim;
    if (isDuplicateClaim && isDuplicateClaim === "true") {
      toast.clear();
      toast.error(DuplicateClaimToast);
    }
    const claim = ref(store.state.claimLookup);
    var statusText = ref(null);
    const submittedDate = moment.utc(claim.value.created).format("yyyy-MM-DD");
    if (claim.value.status.toLowerCase() === "reported") {
      const reportedDate = moment.utc(claim.value.created).format("yyyy-MM-DD");
      statusText.value = "We have received your claim on " + reportedDate;
    } else if (claim.value.status.toLowerCase() === "open") {
      const openedDate = moment(
        moment.utc(claim.value.opened).format("yyyy-MM-DD"),
        "yyyy-MM-DD",
      );
      statusText.value = "Your claim is opened on " + openedDate;
    } else {
      const closedDate = moment(
        moment.utc(claim.value.closed).format("yyyy-MM-DD"),
        "yyyy-MM-DD",
      );
      statusText.value = "Your claim is closed on " + closedDate;
    }

    return {
      claim,
      statusText,
      submittedDate,
    };
  },
};
</script>
