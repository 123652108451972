import router from "@/router";
import { createStore } from "vuex";
import {
  saveCertificateOfInsurance,
  sendEmailWithCoiToHolder,
  saveClaimAttachment,
  saveVehicleDocument,
  dispatchEvent,
  getFileDownloadUrl,
  getBlackBookVehicle,
  getVehicleDocuments,
  getClaimDocuments,
  addVehicleChangelog,
  getAccountStripePortal,
  getVehiclesForAccount,
  getEpisodesForAccount,
  saveVehicleForAccount,
  subscribeVehiclesForAccount,
  getMetricsForAccount,
  removeVehicleFromAccount,
  saveDetailsForAccount,
  saveBillingSettingsForAccount,
  getAccountFromId,
  getDriversForAccount,
  getClaimsForAccount,
  getTelematicsAccountStatus,
  getOffRentMileage,
  createSamsaraIntegration,
  createMotiveIntegration,
  createTelematicsIntegration,
  saveClaimForAccount,
  enroll,
  saveDriverForAccount,
  getSystemSettings,
  removeDriverFromAccount,
  getInsuranceDocumentsForAccount,
  submitDocumentRequestForAccount,
  submitDocumentResendForAccount,
  saveEpisodesForAccount,
  activateVehicleNow,
  activateVehicleNowWithMessage,
  activateVehicleLater,
  cancelEpisode,
  createEpisodeFromEpisode,
  getReservations,
  getVinAudit,
  addVehicle,
  getUnderwritingResponse,
  getVinDetailsResponse,
  getChargesListFromLpg,
  getPaymentMethodsFromLpg,
  createClientTokenUsingLPG,
  updateDefaultPaymentMethodViaLPG,
  getCustomerDetailsFromLPG,
  deletePaymentMethodViaLPG,
  getInvoicesFromLPG,
  payInvoicesViaLPG,
  addAccountToQueue,
  getVehicleAccountStatus,
} from "./service";
import {
  getClaimsClient,
  getClaimsVehicleLookup,
  createThirdPartyClaim,
  createThirdPartyClaimAttachment,
  submitThirdPartyClaim,
  getClaimLookup,
} from "./claims-service";
import { useAuth } from "./auth";

export default createStore({
  state() {
    return {
      launchDarkly: null,
      requiresOnboarding: true,
      sidebar: false,
      user: null,
      loading: false,
      account: null,
      accountMetrics: null,
      drivers: [],
      vehicles: [],
      claims: [],
      insuranceDocuments: [],
      processedVehicles: [],
      clientSecret: null,
      defaultPM: null,
    };
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    setLoading(state, loading) {
      state.loading = loading;
    },
    setItem(state, { key, val }) {
      state[key] = val;
    },
    setClaimFormItems(state, items) {
      state.claimForm = state.claimForm ?? {};
      Object.keys(items).forEach((key) => {
        state.claimForm[key] = items[key];
      });
    },
    setClientSecret(state, clientSecret) {
      state.clientSecret = clientSecret;
    },
    setDefaultPM(state, defaultPM) {
      state.defaultPM = defaultPM;
    },
    setLookupClaim(state, lookupClaim) {
      state.claimLookup = lookupClaim;
    },
  },
  actions: {
    async initialLoad({ state, dispatch, commit }) {
      try {
        commit("setLoading", true);

        // Get the current authenticated user, if any
        const user = await dispatch("getCurrentUser");

        // Determine if the current page allows unauthenticated access
        const anonymousRoutes = router
          .getRoutes()
          .filter((rt) => rt.meta?.allowAnonymous)
          .map((rt) => rt.path);
        const currentPathName = window.location.pathname;
        const allowAnonymous = anonymousRoutes.includes(
          currentPathName.replace(/\/$/, ""),
        );

        // If the user is not authenticated and the page requires authentication, send the user
        // to the login page.
        if (user == null && !allowAnonymous) {
          const auth = await useAuth();
          await auth.login();
          return;
        }

        // Try to get an account associated with this authenticated user
        const account = await dispatch("getAccount");
        commit("setItem", { key: "account", val: account });
        if (account == null) {
          return;
        }

        await state.launchDarkly.waitForInitialization();
        const haveRenewalRedirect = state.launchDarkly?.variation(
          "ui-redirect-for-service-agreement-renewal",
          false,
        );

        if (haveRenewalRedirect) {
          // If Cont Coverage account and the account has old signed version send to onboarding (dif for prod and stage)
          if (
            (account.defaultPolicy || "").includes(
              "e7e0ea9b-bd32-46ba-ac65-4dafa5aea688",
            )
          ) {
            if (!(account.signedAgreementVersion || "").includes("2024_")) {
              window.location.href = `https://onboarding.getaddify-staging.com/customer/updated-service-agreement/${account.entityId}`;
            }
          } else if (
            (account.defaultPolicy || "").includes(
              "449540a0-00db-4988-bea4-7c72c7896581",
            )
          ) {
            if (!(account.signedAgreementVersion || "").includes("2024_")) {
              window.location.href = `https://apply.getaddify.com/customer/updated-service-agreement/${account.entityId}`;
            }
          }
        }

        // Get resources associated with the authenticated user's account
        const [vehicles, drivers, metrics, claims, episodes] =
          await Promise.all([
            dispatch("getVehicles"),
            dispatch("getDrivers"),
            dispatch("getMetrics"),
            dispatch("getClaims"),
            dispatch("getEpisodes"),
          ]);

        commit("setItem", { key: "vehicles", val: vehicles });
        commit("setItem", { key: "drivers", val: drivers });
        commit("setItem", { key: "accountMetrics", val: metrics });
        commit("setItem", { key: "claims", val: claims });
        commit("setItem", { key: "episodes", val: episodes });
      } catch (ex) {
        console.log(ex);
      } finally {
        commit("setLoading", false);
      }
    },
    async getCurrentUser({ commit }) {
      const auth = await useAuth();
      const user = await auth.getUser();
      commit("setUser", user);
      return user;
    },
    async signOut() {
      const auth = await useAuth();
      await auth.logout();
    },
    async initClaimsClient({ commit }) {
      const claimsClient = await getClaimsClient();
      commit("setItem", {
        key: "claimsClient",
        val: claimsClient,
      });
    },
    async saveCoiDocument(_contex, documentConfig) {
      try {
        const doc = await saveCertificateOfInsurance(documentConfig);
        return { doc };
      } catch (error) {
        return error.response.data;
      }
    },
    async sendEmailWithCoiToHolder(_context, parameters) {
      try {
        const result = await sendEmailWithCoiToHolder(parameters);
        return { result };
      } catch (error) {
        return error.response.data;
      }
    },
    async getAccount({ commit, dispatch }) {
      const account = await getAccountFromId();
      if (account?.type != process.env["VUE_APP_ACCOUNT_TYPE_TRUCKING"]) {
        const onboardingLink = process.env["VUE_APP_ONBOARDING_URL"];
        if (account?.status === "Onboarding" && onboardingLink) {
          window.location.href = onboardingLink.replace(
            "{{accountId}}",
            account.id,
          );
        }
      }
      commit("setItem", { key: "account", val: account });
      return account;
    },
    async getMetrics({ commit }) {
      const metrics = await getMetricsForAccount();
      commit("setItem", { key: "accountMetrics", val: metrics });
      return metrics;
    },
    async getEpisodes({ commit }) {
      const episodes = await getEpisodesForAccount();
      commit("setItem", { key: "episodes", val: episodes });
      return episodes;
    },
    async getVehicles({ commit }) {
      const vehicles = await getVehiclesForAccount();
      commit("setItem", { key: "vehicles", val: vehicles });
      return vehicles;
    },
    async getDrivers({ commit }) {
      const drivers = await getDriversForAccount();
      commit("setItem", { key: "drivers", val: drivers });
      return drivers;
    },
    async removeVehicleFromAccount(context, vehicle) {
      return await removeVehicleFromAccount(vehicle);
    },
    async removeDriverFromAccount(context, driver) {
      return await removeDriverFromAccount(driver);
    },
    async saveClaimForAccount({ dispatch }, claim) {
      dispatch("logEvent", { name: "submit_claim" });
      return await saveClaimForAccount(claim);
    },
    async enroll({ dispatch }, policy) {
      dispatch("logEvent", { name: "enroll" });
      return await enroll(policy);
    },
    async saveClaimAttachment(context, { companyId, claimId, attachment }) {
      return await saveClaimAttachment(companyId, claimId, attachment);
    },
    async saveVehicleDocument(
      { dispatch },
      { accountId, vehicleId, file, meta },
    ) {
      dispatch("logEvent", { name: "upload_vehicle_document" });
      return await saveVehicleDocument(accountId, vehicleId, file, meta);
    },
    async submitDocumentRequestForAccount({ dispatch }, documentRequest) {
      dispatch("logEvent", { name: "request_account_document" });
      return await submitDocumentRequestForAccount(documentRequest);
    },
    async submitDocumentResendForAccount({ state, dispatch }, documentRequest) {
      dispatch("logEvent", { name: "resend_account_document" });
      return await submitDocumentResendForAccount(
        state.account.id,
        documentRequest,
      );
    },
    async getInsuranceDocuments({ commit }) {
      const account = await getAccountFromId();
      const insuranceDocuments = await getInsuranceDocumentsForAccount(
        account.entityId,
      );
      commit("setItem", { key: "insuranceDocuments", val: insuranceDocuments });

      return insuranceDocuments;
    },
    async getVehicleDocuments(context, { accountId, vehicleId }) {
      return await getVehicleDocuments(accountId, vehicleId);
    },
    async getClaimDocuments(context, { accountId, claimId }) {
      return await getClaimDocuments(accountId, claimId);
    },
    async getSystemSettings({ commit }) {
      const settings = await getSystemSettings();
      commit("setItem", { key: "settings", val: settings });
      return settings;
    },
    async saveEpisodesForAccount(context, episodes) {
      const savedEpisodes = await saveEpisodesForAccount(episodes);
      return savedEpisodes;
    },
    async getBlackBookVehicle(context, { vin }) {
      const blackbookVehicle = await getBlackBookVehicle(vin);
      return blackbookVehicle;
    },
    async activateVehicleNow(context, vehicleId) {
      const success = await activateVehicleNow(vehicleId);
      return success;
    },

    async activateVehicleNowWithMessage(context, { vehicleId, isDemo }) {
      const result = await activateVehicleNowWithMessage(vehicleId, isDemo);
      if (result.success) {
        return result.success;
      } else {
        throw new Error(result.error);
      }
    },

    async activateVehicleLater(context, vehicleId) {
      const success = await activateVehicleLater(vehicleId);
      return success;
    },
    async addVehicleChangelog(
      context,
      { vehicleEntityId, entityId, eventType, fieldName, newValue, oldValue },
    ) {
      return await addVehicleChangelog(
        vehicleEntityId,
        entityId,
        eventType,
        fieldName,
        newValue,
        oldValue,
      );
    },
    logEvent({ state }, { name, data }) {
      // TODO MOP-86 Add PostHog

      /* Firebase Analytics */
      dispatchEvent(name, {
        application: "lula_app",
        uid: state.user?.email,
        accountId: state.account?.id,
        email: state.user?.email,
        ...(data || {}),
      });
    },
    async getFileDownloadUrl(context, path) {
      return await getFileDownloadUrl(path);
    },
    async getAccountStripePortal(context, { returnUrl }) {
      return await getAccountStripePortal(returnUrl);
    },
    async saveVehicleForAccount({ dispatch }, vehicle) {
      if (vehicle.entityId === null) {
        dispatch("logEvent", { name: "save_new_vehicle" });
      } else if (vehicle.status === "Requested") {
        dispatch("logEvent", { name: "save_requested_vehicle" });
      } else {
        dispatch("logEvent", { name: "save_vehicle_key" });
      }
      return await saveVehicleForAccount(vehicle);
    },
    async saveBillingSettingsForAccount({ state, commit }, settings) {
      const accountId = state.account.id;
      const account = await saveBillingSettingsForAccount(settings);
      commit("setItem", { key: "account", val: { ...account, id: accountId } });
      return account;
    },
    async saveDetailsForAccount({ state, commit }, details) {
      const accountId = state.account.id;
      const account = await saveDetailsForAccount(details);
      commit("setItem", { key: "account", val: { ...account, id: accountId } });
      return account;
    },
    async subscribeVehicleForAccount(context, vehicle) {
      const subscriptions = await subscribeVehiclesForAccount([vehicle]);
      if (subscriptions && subscriptions.length) {
        return subscriptions.pop();
      } else {
        throw new Error("Vehicle subscription failed.");
      }
    },
    async subscribeVehiclesForAccount(context, vehicles) {
      const subscriptions = await subscribeVehiclesForAccount(vehicles);
      if (subscriptions) {
        return subscriptions;
      } else {
        throw new Error("Vehicle subscription failed.");
      }
    },
    async saveDriverForAccount(context, driver) {
      return await saveDriverForAccount(driver);
    },
    async getClaims({ commit }) {
      const claims = await getClaimsForAccount();
      commit("setItem", { key: "claims", val: claims });
      return claims;
    },
    async getTelematicsAccountStatus(context, entityId) {
      const result = await getTelematicsAccountStatus(entityId);
      return result;
    },
    async getVehicleAccountStatus(context, entityId) {
      const result = await getVehicleAccountStatus(entityId);
      return result;
    },
    async getOffRentMileage(context, requestInfo) {
      const result = await getOffRentMileage(
        requestInfo.vin,
        requestInfo.startDate,
        requestInfo.endDate,
        requestInfo.tenantId,
      );
      return result;
    },
    async createSamsaraIntegration(context, requestInfo) {
      const result = await createSamsaraIntegration(requestInfo);
      return result;
    },
    async createMotiveIntegration(context, requestInfo) {
      const result = await createMotiveIntegration(requestInfo);
      return result;
    },
    async createTelematicsIntegration(context, { providerId, requestInfo }) {
      const result = await createTelematicsIntegration(providerId, requestInfo);
      return result;
    },
    async cancelEpisode(context, entityId) {
      return await cancelEpisode(entityId);
    },
    async createEpisodeFromEpisode(
      context,
      { entityId, paymentIntent, days, accountId },
    ) {
      return await createEpisodeFromEpisode(
        entityId,
        paymentIntent,
        days,
        accountId,
      );
    },
    async getReservations() {
      const data = await getReservations();
      return data.docs;
    },
    async getLaunchDarklyFlag({ state }, flag) {
      await state.launchDarkly.waitForInitialization();
      return state.launchDarkly?.variation(flag, false);
    },
    async getVinAudit(context, vin) {
      return await getVinAudit(vin);
    },
    async addVehicle(context, { accountId, vehicle }) {
      return await addVehicle(accountId, vehicle);
    },
    async getUnderwritingResponse(context, { inputs }) {
      return await getUnderwritingResponse(inputs);
    },
    async getVinDetailsResponse(context, { vin }) {
      return await getVinDetailsResponse(vin);
    },
    getChargesListFromLpg(context, { accountId, xTestMode }) {
      return getChargesListFromLpg(accountId, xTestMode);
    },
    getPaymentMethodsFromLpg(context, { accountId, xTestMode }) {
      return getPaymentMethodsFromLpg(accountId, xTestMode);
    },
    createThirdPartyClaim({ state }) {
      return createThirdPartyClaim(state.claimsClient, {
        ...state.claimForm,
        started: state.claimStartDate,
      });
    },
    submitThirdPartyClaim({ state }, { claimId, tenantId }) {
      return submitThirdPartyClaim(state.claimsClient, claimId, tenantId);
    },
    createThirdPartyClaimAttachment({ state }, { file, claimId, accountId }) {
      return createThirdPartyClaimAttachment(
        state.claimsClient,
        claimId,
        accountId,
        file,
      );
    },
    getClaimsVehicleLookup({ state }, { vin, incidentDate }) {
      return getClaimsVehicleLookup(state.claimsClient, vin, incidentDate);
    },
    createClientTokenUsingLPG(context, { accountId, xTestMode }) {
      return createClientTokenUsingLPG(accountId, xTestMode);
    },
    updateDefaultPaymentMethodViaLPG(
      context,
      { accountId, xTestMode, paymentMethodId, xConfiguration },
    ) {
      return updateDefaultPaymentMethodViaLPG(
        accountId,
        xTestMode,
        paymentMethodId,
        xConfiguration,
      );
    },
    getCustomerDetailsFromLPG(
      context,
      { accountId, xTestMode, xConfiguration },
    ) {
      return getCustomerDetailsFromLPG(accountId, xTestMode, xConfiguration);
    },
    deletePaymentMethodViaLPG(
      context,
      { accountId, xTestMode, xConfiguration, paymentMethodId },
    ) {
      return deletePaymentMethodViaLPG(
        accountId,
        xTestMode,
        xConfiguration,
        paymentMethodId,
      );
    },
    getInvoicesFromLPG(context, { accountId, xTestMode, xConfiguration }) {
      return getInvoicesFromLPG(accountId, xTestMode, xConfiguration);
    },
    getClaimLookup({ state }, { claimNumber, incidentDate, vehicleId }) {
      return getClaimLookup(
        state.claimsClient,
        claimNumber,
        incidentDate,
        vehicleId,
      );
    },
    payInvoicesViaLPG(
      context,
      { tenantId, invoiceId, xTestMode, xConfiguration },
    ) {
      return payInvoicesViaLPG(tenantId, invoiceId, xTestMode, xConfiguration);
    },
    addAccountToQueue(context, { accountId, coverageType }) {
      return addAccountToQueue(accountId, coverageType);
    },
  },
});
