<template>
  <header>
    <div
      class="w-full bg-white shadow-2xl p-4 lg:p-0 lg:flex justify-center items-center"
    >
      <div
        class="flex flex-col justify-between w-full max-w-screen-xl lg:flex-row"
      >
        <div class="w-full flex justify-between">
          <router-link
            :to="{ name: homeRoute }"
            class="flex items-center justify-center lg:ml-4"
          >
            <img
              src="@/assets/AddifyLogo_Transparent.png"
              alt="GetAddify Logo"
              class="h-8"
            />
          </router-link>
          <div
            class="flex flex-col items-center justify-center w-10 h-10 rounded-lg bg-lula-light cursor-pointer border border-transparent hover:border-lula transition hover:border-lula lg:hidden"
            :class="{ 'border-lula': open }"
            @click="open = !open"
          >
            <div
              class="h-0.5 my-0.5 bg-lula rounded-2xl transform transition-all"
              :class="{
                'rotate-45 translate-y-0.5 w-6/12': open,
                'w-9/12': !open,
              }"
            />
            <div
              class="h-0.5 w-9/12 my-0.5 bg-lula rounded-2xl"
              :class="{ hidden: open }"
            />
            <div
              class="h-0.5 my-0.5 bg-lula rounded-2xl transform transition-all"
              :class="{
                '-rotate-45 -translate-y-1 w-6/12': open,
                'w-9/12': !open,
              }"
            />
          </div>
        </div>
        <div
          class="transform pt-px lg:pt-0 lg:block"
          :class="{ hidden: !open }"
        >
          <ul class="text-gray-500 lg:flex">
            <li
              class="relative flex select-none cursor-pointer hover:text-lula transition lg:text-center"
              v-for="option in options"
              :key="option.name"
            >
              <a
                :href="option.redirect"
                class="w-full h-full lg:p-6 lg:m-0"
                target="_blank"
                v-if="option.redirect"
              >
                {{ option.name }}
              </a>
              <a
                :href="option.link"
                class="w-full h-full lg:p-6 lg:m-0"
                v-else-if="option.link"
              >
                {{ option.name }}
              </a>
              <a
                href="#"
                class="w-full h-full lg:p-6 lg:m-0"
                @click="
                  open = false;
                  option.click();
                "
                v-else-if="option.click"
              >
                {{ option.name }}
              </a>
              <div
                class="flex justify-start items-center h-full w-full lg:px-8 lg:justify-center"
                v-else-if="
                  option.route === `${$store.state.account.type}/Account`
                "
              >
                <div
                  @click="
                    open = false;
                    $store.commit('setItem', { key: 'sidebar', val: true });
                  "
                  class="hidden items-center justify-center bg-lula-alt text-white rounded-full w-12 h-12 cursor-pointer border-2 hover:border-lula lg:flex"
                >
                  <span>{{
                    $store.state.account.contactFirstName.substring(0, 1)
                  }}</span>
                </div>
                <div
                  @click="
                    open = false;
                    $router.push({ name: option.route });
                  "
                  class="lg:hidden w-full"
                >
                  <span>Account</span>
                </div>
              </div>
              <router-link
                class="flex items-center w-max h-full lg:p-6 lg:m-0"
                @click="
                  open = false;
                  $store.dispatch('logEvent', {
                    name: 'select_navigation_option',
                    data: { option: option.name },
                  });
                "
                :to="{ name: option.route }"
                replace
                v-else
              >
                {{ option.name }}
                <span
                  style="
                    padding: 0.5px 10px 2px 10px;
                    background-color: #03ad03;
                  "
                  class="hidden mx-2 rounded-full text-white lg:block"
                  v-if="
                    option.route === episodeRouteName && currentEpisodeCount
                  "
                >
                  {{ currentEpisodeCount }}
                </span>
              </router-link>
              <div
                v-if="isCurrentPath(option.route, option.name)"
                class="hidden w-2/3 h-1 bg-lula-gradient absolute bottom-0 left-0 right-0 mx-auto rounded-tl-lg rounded-tr-lg lg:block"
              />
            </li>
            <li
              class="relative flex select-none cursor-pointer hover:text-lula transition lg:text-center"
              v-if="!hasAccount"
            >
              <a
                class="w-full h-full lg:p-6 lg:m-0"
                @click="$store.dispatch('signOut')"
                href="#"
                >Logout</a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div
      class="bg-gray-50 shadow-md border-t flex justify-center"
      v-if="childRoutes.length > 0"
    >
      <router-link
        v-for="route in childRoutes"
        :to="{ name: route.name }"
        :key="route.name"
        class="transition relative mx-4 py-4 cursor-pointer text-gray-700 hover:text-gray-800 md:mx-8"
      >
        <span>
          {{ route.meta.title }}
        </span>
        <div
          v-if="isCurrentPath(route.name)"
          class="w-4/6 h-1 bg-lula-gradient absolute bottom-0 left-0 right-0 mx-auto rounded-tl-lg rounded-tr-lg"
        />
      </router-link>
    </div>
    <div v-if="ackBanner" @click="viewVehicleAck">
      <div
        class="flex items-center px-4 bg-lula shadow-inner text-white w-full border-b cursor-pointer"
      >
        <div
          class="text-xs w-full flex justify-center items-center py-2 select-none md:text-lg"
        >
          <span class="text-center font-bold" v-if="ackCompleted">
            Renewal Complete, Click Here to Review
          </span>
          <span class="text-center font-bold" v-else>
            Click Here to Confirm Renewal
          </span>
          <span class="bg-white h-0.5 w-6 mx-2 block" />
          <span class="text-center font-bold" v-if="ackDaysRemaining">
            {{ ackDaysRemaining }} Days Remaining
          </span>
          <span class="text-center font-bold uppercase" v-else>
            final day
          </span>
        </div>
      </div>
    </div>
    <transition v-else>
      <div
        class="relative flex items-center px-4 bg-white w-full border-t border-b"
        v-if="banner < 3"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="transition md:absolute cursor-pointer text-gray-800 hover:text-gray-600 left-5 md:left-10 top-4 h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          @click="toggleBanner"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </div>
    </transition>
  </header>
</template>

<script>
import { ref, watch, computed, onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { detect } from "detect-browser";
import moment from "moment";

//We will store only the current month for the banner so we can periodically update the banner; This should be made into an API
const BANNER_STORAGE_ITEM =
  "lula-banner-" + new Date().toISOString().substring(0, 7);

export default {
  props: ["options"],
  setup(props) {
    const bannerStorage = window.localStorage.getItem(BANNER_STORAGE_ITEM);

    const store = useStore();
    const router = useRouter();
    const banner = ref(
      bannerStorage === null || bannerStorage === "true"
        ? 0
        : window.localStorage.getItem(BANNER_STORAGE_ITEM),
    );

    watch(banner, () => {
      window.localStorage.setItem(BANNER_STORAGE_ITEM, banner.value);
    });

    const currentEpisodeCount = ref(0);
    watch(router.currentRoute, () => {
      const localEpisodes = window.localStorage.getItem("saved-episodes");
      if (localEpisodes) {
        currentEpisodeCount.value = JSON.parse(localEpisodes).length;
      }
    });

    onMounted(() => {
      const localEpisodes = window.localStorage.getItem("saved-episodes");
      if (localEpisodes) {
        currentEpisodeCount.value = JSON.parse(localEpisodes).length;
      }
    });

    const childRoutes = computed(() => {
      try {
        return router.currentRoute.value.matched[0].children;
      } catch (err) {
        return [];
      }
    });

    return {
      banner: computed(() => {
        if (
          store.state.account.type !==
          process.env["VUE_APP_ACCOUNT_TYPE_CAR_RENTAL"]
        ) {
          return 3;
        }
        return banner.value;
      }),
      currentIsoDate: ref(new Date().toISOString().substring(0, 10)),
      toggleBanner() {
        banner.value = banner.value + 1;
      },
      open: ref(false),
      childRoutes,
      ackCompleted: computed(() => {
        const ackForm = JSON.parse(store.state.account.vehicleAckForm);
        return ackForm.confirmed;
      }),
      ackBanner: computed(() => {
        if (
          store.state.account.type !==
          process.env["VUE_APP_ACCOUNT_TYPE_CAR_RENTAL"]
        ) {
          return false;
        }
        if (!store.state.account.vehicleAck) {
          return false;
        }
        const ackDay = moment.utc(store.state.account.vehicleAck);
        ackDay.add(1, "days");
        if (ackDay.isValid() && moment().isBefore(ackDay)) {
          return !store.state.vehicleAckFleet;
        }
        return false;
      }),
      ackDaysRemaining: computed(() => {
        const ackDay = moment.utc(store.state.account.vehicleAck);
        ackDay.add(1, "days");
        const today = moment(moment().format("yyyy-MM-DD"), "yyyy-MM-DD");
        return Math.abs(today.diff(ackDay, "days"));
      }),
      viewVehicleAck() {
        store.commit("setItem", { key: "vehicleAckFleet", val: false });
        store.commit("setItem", { key: "vehicleAckView", val: true });
        store.commit("setItem", { key: "vehicleAckRemind", val: false });
      },
      currentEpisodeCount,
      episodeRouteName: computed(() => {
        return `${store.state.account.type}/Episodes`;
      }),
      isCurrentPath(path, name = "") {
        if (path === `${store.state.account.type}/Account`) {
          return false;
        }
        if (router.currentRoute.value.meta?.nav === name) {
          return true;
        }
        return (
          router.currentRoute.value.name === path ||
          (name
            ? childRoutes.value.find((route) => {
                return route.name.includes(name);
              })
            : false)
        );
      },
      homeRoute: computed(() => {
        return `${store.state.account.type}/Dashboard`;
      }),
      hasAccount: computed(() => {
        return props.options.find(
          (opt) => opt.route === `${store.state.account.type}/Account`,
        );
      }),
      extensionLink: computed(() => {
        const browser = detect();
        if (browser.name === "safari") {
          return "https://apps.apple.com/app/id1621350368";
        }
        return "https://chrome.google.com/webstore/detail/lula/opmofoabhhdachjoinpoachbgdidhamo";
      }),
    };
  },
};
</script>

<style scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
