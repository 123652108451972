import { initialize } from "launchdarkly-js-client-sdk";

export default {
  created() {
    this.initDarkly(process.env["VUE_APP_LAUNCHDARKLY_ID"], this.getDarkUser());
  },
  computed: {
    user() {
      return this.$store.state.user ?? undefined;
    },
  },
  methods: {
    initDarkly(key, user) {
      if (this.$store.state.launchDarkly) {
        this.$store.state.launchDarkly.identify(user);
      } else {
        this.$store.commit("setItem", {
          key: "launchDarkly",
          val: initialize(key, user),
        });
      }
    },
    getDarkUser() {
      if (this.user) {
        return {
          key: this.user["email"],
          email: this.user["email"],
        };
      }
      return {
        kind: "user",
        anonymous: true,
      };
    },
    getLaunchDarklyFlag(flag) {
      if (this.$store.state.launchDarkly) {
        return this.$store.state.launchDarkly.variation(flag);
      }
      return false;
    },
  },
  watch: {
    user() {
      if (this.user) {
        this.initDarkly(
          process.env["VUE_APP_LAUNCHDARKLY_ID"],
          this.getDarkUser(),
        );
      }
    },
  },
};
