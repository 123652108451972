<template>
  <div class="bg-white border rounded-md p-4">
	<h1 class="w-full text-gray-800 text-3xl font-bold" >Contact Support</h1>
	<p class="mt-12">
	  For assistance, please call or text us at <strong>1-888-623-3439</strong> or email our support team at 
	  <a class="font-bold" href="mailto:support@getaddify.com">support@getaddify.com</a>.
	</p>
  </div>
</template>

<script>
export default {
  name: 'Support'
};
</script>

<style scoped>
</style>