<template>
  <div
    class="flex justify-between items-center bg-white w-full px-4 py-4 border-b lg:px-16"
  >
    <Logo class="text-lula-primary w-32" />
    <div>
      <a :href="loginUrl" class="text-lula-primary underline font-bold">
        Login
      </a>
    </div>
  </div>
</template>

<script>
import Logo from "@/Logo";
export default {
  components: { Logo },
  computed: {
    loginUrl() {
      return `${process.env.VUE_APP_LULA_AUTH_URL}/login`;
    },
  },
};
</script>
