<template>
  <div class="flex flex-col justify-center items-center min-h-screen"></div>
</template>
<script>
import { useRoute } from "vue-router";
import { useStore } from "vuex";

export default {
  async mounted() {
    const route = useRoute();
    const store = useStore();
    const accessCode = route.query?.code;
    let account = null;
    try {
      account = await store.dispatch("getAccount");
    } catch (e) {
      //do nothing.
    }

    //When user has not signed in to lula app, this means the user started the motive oauth flow from customer presales.
    //Therefore, we should redirect them back to presales.
    if (account === null) {
      const presalesURL = process.env["VUE_APP_CUSTOMER_PRESALES_URL"];
      const target = presalesURL + "/motive-oauth-handler?code=" + accessCode;
      window.location.href = target;
      return;
    }

    //If the user has signed into lula app, we redirect them back to the lula app motive oauth handler.
    const target =
      process.env["VUE_APP_DEFAULT_CUSTOMER_HOST_WITH_PROTOCOL"] +
      "/keeptruckin_oauth_handler?code=" +
      accessCode;
    window.location.href = target;
    return;
  },
};
</script>
