<template>
  <div
    class="relative w-full h-full overflow-scroll max-w-screen-xl bg-white rounded-md p-4 border md:w-2/3 lg:w-1/2 md:h-auto"
  >
    <form @submit.prevent="submitForm">
      <div class="font-bold mb-2">
        <h2
          class="text-green-500 text-2xl lg:text-3xl"
          v-if="currentView.headerType === 'success'"
        >
          {{ currentView.header }}
        </h2>
        <h2
          class="text-red-500 text-2xl lg:text-3xl"
          v-else-if="currentView.headerType === 'failure'"
        >
          {{ currentView.header }}
        </h2>
        <h2 class="text-gray-800 text-2xl lg:text-3xl" v-else>
          {{ currentView.header }}
        </h2>
        <span class="text-gray-600 text-base lg:text-xl">
          {{ currentView.subheader }}
        </span>
      </div>
      <div :class="{ 'opacity-0 py-12': loading }">
        <div v-if="currentView.confirmKey !== 'requestVehicle'">
          <div class="text-lg">
            <div v-if="editVehicle">
              <div>
                <span class="font-bold text-xl text-gray-600">
                  {{ vehicle.vin }}
                </span>
                <div class="mt-2">
                  <label class="font-bold text-grau-800"> Make </label>
                  <br />
                  <input
                    type="text"
                    placeholder="Vehicle Make"
                    v-model="vehicle.make"
                  />
                </div>
                <div class="mt-2">
                  <label class="font-bold text-grau-800"> Model </label>
                  <br />
                  <input
                    type="text"
                    placeholder="Vehicle Make"
                    v-model="vehicle.model"
                  />
                </div>
                <div class="mt-2">
                  <label class="font-bold text-grau-800"> Year </label>
                  <br />
                  <input
                    type="text"
                    placeholder="Vehicle Make"
                    v-model="vehicle.year"
                  />
                </div>
                <div class="mt-2">
                  <label class="font-bold text-grau-800">
                    Domicile State
                  </label>
                  <br />
                  <state-select v-model="vehicle.domicileState" />
                </div>
              </div>
            </div>
            <table class="w-full" v-else>
              <tr class="text-left text-gray-800 border-b">
                <th class="py-2 lg:hidden">Vehicle</th>
                <th class="hidden py-2 lg:table-cell">VIN</th>
                <th class="hidden py-2 font-normal lg:table-cell">Year</th>
                <th class="hidden py-2 font-normal lg:table-cell">Make</th>
                <th class="hidden py-2 font-normal lg:table-cell">Model</th>
                <th class="hidden py-2 font-normal lg:table-cell">
                  Domicile State
                </th>
              </tr>
              <tr
                class="text-gray-600"
                v-for="tVehicle in tabledVehicles"
                :key="tVehicle.vin"
              >
                <td class="py-2">
                  <div class="flex items-center ml-2">
                    <img
                      alt="truck icon"
                      class="w-12 w-12"
                      src="../assets/truck-placeholder-icon.svg"
                    />
                    <div class="flex flex-col">
                      <span class="ml-2 font-bold">
                        {{ tVehicle.vin }}
                      </span>
                      <span class="ml-2 lg:hidden">
                        {{ tVehicle.year }} {{ tVehicle.make }}
                        {{ tVehicle.model }} ({{ tVehicle.domicileState }})
                      </span>
                    </div>
                  </div>
                </td>
                <td class="hidden py-2 lg:table-cell">
                  {{ tVehicle.year }}
                </td>
                <td class="hidden py-2 lg:table-cell">
                  {{ tVehicle.make }}
                </td>
                <td class="hidden py-2 lg:table-cell">
                  {{ tVehicle.model }}
                </td>
                <td class="hidden py-2 lg:table-cell">
                  {{ tVehicle.domicileState }}
                </td>
              </tr>
            </table>
            <div
              v-if="currentView.confirmKey === 'activate'"
              @click="
                vehicle = {};
                vin = '';
              "
              :class="{ 'opacity-0': loading }"
              class="flex items-center bg-gray-50 cursor-pointer font-bold text-gray-600 p-2 rounded-md hover:bg-gray-100"
            >
              <img
                alt="truck icon"
                class="w-12 w-12"
                src="../assets/truck-placeholder-icon.svg"
              />
              <span class="ml-2"> Request Additional Vehicle </span>
            </div>
            <div
              class="rounded-md flex w-full cursor-pointer justify-between mt-2 text-gray-600 bg-gray-50 p-4 hover:bg-gray-100"
              @click="submitManualReview"
              v-if="vehicle.insuranceCriteriaStatus === 'Declined'"
            >
              <span class="font-bold">
                Something not right? Submit for manual review
              </span>
            </div>
            <div
              class="rounded-md flex w-full cursor-pointer justify-between mt-2 text-gray-600 bg-gray-50 p-4 hover:bg-gray-100"
              @click="editVehicle = true"
              v-if="
                currentView.confirmKey === 'confirmEligibility' && !editVehicle
              "
            >
              <span class="font-bold">
                Something not right? Click to edit vehicle
              </span>
            </div>
            <div class="rounded-md bg-gray-50 p-4 mt-2" v-if="requiresPayment">
              <div class="flex w-full justify-between text-gray-600">
                <span class="font-bold"> Activation cost </span>
                <span> ${{ activationCost }} </span>
              </div>
              <hr class="my-2" />
              <div class="flex w-full mb-2 justify-between text-gray-500">
                <span> Subscription cost </span>
                <span> ${{ subscriptionCost }} </span>
              </div>
              <div class="flex w-full mb-2 justify-between text-gray-500">
                <span> GetAddify Bucks Additional Deposit </span>
                <span> ${{ standardDeposit }} </span>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="text-xl">
            <label class="font-bold text-gray-800"> VIN </label>
            <br />
            <input
              type="text"
              class="w-full mt-1"
              v-model="vin"
              minlength="17"
              maxlength="17"
              :placeholder="placeholderVin"
              required
            />
          </div>
        </div>
      </div>
      <Loader v-if="loading" />
      <div
        class="bg-gray-50 rounded-md text-gray-600 font-bold cursor-pointer p-4 text-lg mt-2 hover:bg-gray-100"
        @click="
          vehicle = { insuranceCriteriaStatus: 'Approved' };
          vin = '';
        "
        v-if="
          !loading &&
          currentView.confirmKey !== 'activate' &&
          eligibleVehicles.length > 0
        "
      >
        Return to approved vehicles
      </div>
      <div
        :class="{ 'opacity-0 md:opacity-100': loading }"
        class="w-full flex flex-col justify-end mt-2 lg:flex-row"
      >
        <button
          type="button"
          class="lg:mr-1"
          @click="$emit('cancel')"
          :disabled="loading"
          :class="{
            'bg-gray-100 hover:bg-gray-200': !loading,
            'bg-gray-100 text-gray-600 cursor-default': loading,
          }"
        >
          {{ currentView.cancel }}
        </button>
        <button
          :disabled="loading || !canSubmit"
          :class="{
            'bg-lula-gradient text-white hover:bg-lula-gradient-alt':
              !loading && canSubmit,
            'bg-gray-100 text-gray-600 cursor-default': loading || !canSubmit,
          }"
          type="submit"
          class="mt-1 lg:mt-0"
        >
          {{ currentView.confirm }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { useToast } from "vue-toastification";
import { Loader } from "@getaddify/lula-components";
import axios from "axios";

async function getVinLookup(vehicle) {
  const lookup = await axios({
    method: "GET",
    url: `https://vpic.nhtsa.dot.gov/api/vehicles/DecodeVin/${vehicle.vin}?format=JSON`,
  });

  const results = lookup.data["Results"];

  const make = results.find((x) => x["Variable"] === "Make");
  const model = results.find((x) => x["Variable"] === "Model");
  const year = results.find((x) => x["Variable"] === "Model Year");

  if (make && make["Value"]) {
    vehicle.make = make["Value"];
  }

  if (model && model["Value"]) {
    vehicle.model = model["Value"];
  }

  if (year && year["Value"]) {
    vehicle.year = year["Value"];
  }

  return vehicle;
}

export default {
  emits: ["cancel"],
  components: { Loader },
  setup(props, { emit }) {
    const store = useStore();
    const router = useRouter();
    const toast = useToast();

    const loading = ref(false);
    const vin = ref("");
    const vehicle = ref({});
    const editVehicle = ref(false);

    const eligibleVehicles = ref([]);

    const currentView = computed(() => {
      if (Object.keys(vehicle.value).length > 0) {
        if ("insuranceCriteriaStatus" in vehicle.value) {
          switch (vehicle.value.insuranceCriteriaStatus) {
            case "Exists": {
              return {
                headerType: "failure",
                header: "Vehicle already added",
                subheader: "This vehicle is already in your fleet",
                confirm: "Next",
                cancel: "Cancel",
                confirmKey: "requestVehicle",
              };
            }
            case "LookupFailed": {
              return {
                headerType: "failure",
                header: "Failed to find VIN",
                subheader: "We could not identify this vehicle",
                confirm: "Next",
                cancel: "Cancel",
                confirmKey: "requestVehicle",
              };
            }
            case "Approved": {
              return {
                headerType: "success",
                header: "Your vehicle has been approved! 🎉🎈",
                subheader: "Would you like to activate now?",
                confirm:
                  store.state.accountMetrics.remainingSeats > 0
                    ? "Activate Now"
                    : "Pay and Activate",
                cancel: "Activate Later",
                confirmKey: "activate",
              };
            }
            case "Declined": {
              return {
                headerType: "failure",
                header: "Your vehicle is not eligible for coverage. 😢",
                subheader:
                  "Unfortunately, we are unable to provide insurance for your vehicle at this time.",
                confirm: "New Vehicle",
                cancel: "Cancel",
                confirmKey: "tryAgain",
              };
            }
            case "Under Review":
            default: {
              return {
                headerType: "normal",
                header: "Your vehicle is under review.",
                subheader:
                  "Check back to see if your vehicle is eligible for coverage.",
                confirm: "New Vehicle",
                cancel: "Cancel",
                confirmKey: "tryAgain",
              };
            }
          }
        }
        return {
          headerType: "normal",
          header: "Request new Vehicle",
          subheader: "Confirm vehicle",
          confirm: "Confirm",
          cancel: "Cancel",
          confirmKey: "confirmEligibility",
        };
      }
      return {
        headerType: "normal",
        header: "Request new Vehicle",
        subheader: "Add a vehicle to your fleet",
        confirm: "Next",
        cancel: "Cancel",
        confirmKey: "requestVehicle",
      };
    });

    return {
      loading,
      vin,
      vehicle,
      currentView,
      editVehicle,
      eligibleVehicles,
      tabledVehicles: computed(() => {
        if (currentView.value.confirmKey === "activate") {
          return eligibleVehicles.value;
        }
        return [vehicle.value];
      }),
      requiresPayment: computed(() => {
        return (
          vehicle.value.insuranceCriteriaStatus === "Approved" &&
          store.state.accountMetrics.remainingSeats <= 0
        );
      }),
      placeholderVin: computed(() => {
        const example = [...store.state.vehicles].pop();
        return example ? example.vin : "";
      }),
      canSubmit: computed(() => {
        if (currentView.value.confirmKey === "requestVehicle") {
          return vin.value.length === 17;
        }
        return true;
      }),
      activationCost: computed(() => {
        const seatCost = parseFloat(store.state.accountMetrics.seatCost);
        const standardDeposit = parseFloat(
          store.state.accountMetrics.standardDeposit,
        );
        return parseFloat(
          (eligibleVehicles.value.length -
            store.state.accountMetrics.remainingSeats) *
            (seatCost + standardDeposit),
        ).toFixed(2);
      }),
      subscriptionCost: computed(() => {
        return parseFloat(
          (eligibleVehicles.value.length -
            store.state.accountMetrics.remainingSeats) *
            store.state.accountMetrics.seatCost,
        ).toFixed(2);
      }),
      standardDeposit: computed(() => {
        return parseFloat(
          (eligibleVehicles.value.length -
            store.state.accountMetrics.remainingSeats) *
            store.state.accountMetrics.standardDeposit,
        ).toFixed(2);
      }),
      async submitManualReview() {
        loading.value = true;
        try {
          const savedVehicle = await store.dispatch("saveVehicleForAccount", {
            ...vehicle.value,
            insuranceCriteriaStatus: "Under Review",
          });
          setTimeout(() => {
            loading.value = false;
            vehicle.value = savedVehicle;
          }, 2500);
        } catch (err) {
          loading.value = false;
          toast.clear();
          toast("Failed to submit for manual review.");
        }
      },
      async submitForm() {
        switch (currentView.value.confirmKey) {
          case "tryAgain": {
            vin.value = "";
            vehicle.value = {};
            break;
          }
          case "confirmEligibility": {
            loading.value = true;
            editVehicle.value = false;
            const savedVehicle = await store.dispatch(
              "saveVehicleForAccount",
              vehicle.value,
            );
            await store.dispatch("getVehicles");
            await store.dispatch("getMetrics");
            if (savedVehicle.insuranceCriteriaStatus === "Approved") {
              eligibleVehicles.value = [
                ...eligibleVehicles.value,
                savedVehicle,
              ];
            }
            setTimeout(() => {
              loading.value = false;
              vehicle.value = savedVehicle;
            }, 2500);
            break;
          }
          case "activate": {
            loading.value = true;
            try {
              await store.dispatch("getVehicles");
              await store.dispatch(
                "subscribeVehiclesForAccount",
                eligibleVehicles.value,
              );
              toast.clear();
              toast(
                `${eligibleVehicles.value.length > 1 ? "Vehicles" : "Vehicle"} activated.`,
              );
              await store.dispatch("getMetrics");
              await store.dispatch("getVehicles");
              emit("cancel");
              router.push({ name: `${store.state.account.type}/Fleet` });
            } catch (err) {
              setTimeout(() => {
                loading.value = false;
                toast.clear();
                toast(
                  `Failed to activate ${eligibleVehicles.value.length > 1 ? "vehicles" : "vehicle"}.`,
                );
              }, 2500);
            }
            break;
          }
          case "requestVehicle":
          default: {
            loading.value = true;
            vin.value = vin.value.toUpperCase();
            if (store.state.vehicles.find((v) => v.vin === vin.value)) {
              setTimeout(() => {
                loading.value = false;
                vehicle.value = {
                  vin: vin.value,
                  insuranceCriteriaStatus: "Exists",
                };
              }, 2500);
            } else {
              try {
                const lookupVehicle = await getVinLookup({ vin: vin.value });
                if (
                  lookupVehicle.make &&
                  lookupVehicle.model &&
                  lookupVehicle.year
                ) {
                  setTimeout(() => {
                    vehicle.value = {
                      ...lookupVehicle,
                      domicileState: store.state.account.garagingState,
                    };
                    loading.value = false;
                  }, 2500);
                } else {
                  setTimeout(() => {
                    loading.value = false;
                    vehicle.value = {
                      vin: vin.value,
                      insuranceCriteriaStatus: "LookupFailed",
                    };
                  }, 2500);
                }
              } catch (err) {
                setTimeout(() => {
                  loading.value = false;
                  vehicle.value = {
                    vin: vin.value,
                    insuranceCriteriaStatus: "LookupFailed",
                  };
                }, 2500);
              }
            }
          }
        }
      },
    };
  },
};
</script>
