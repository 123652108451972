<template>
  <div
    class="flex justify-end items-center absolute w-screen h-screen inset-0"
    style="background: rgba(0, 0, 0, 0.5)"
  >
    <div class="bg-white w-full h-full border-l md:w-1/3">
      <slot />
    </div>
  </div>
</template>

<script>
import { onMounted, onUnmounted } from "vue";

export default {
  setup() {
    onMounted(() => {
      window.scrollTo(0, 0);
      document.body.style.overflow = "hidden";
    });

    onUnmounted(() => {
      document.body.style.overflow = null;
    });
  },
};
</script>
