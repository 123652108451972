import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  gql,
} from "@apollo/client/core";
import axios from "axios";
import { requestHeaders } from "./auth";

export async function getClaimsClient() {
  const headers = await requestHeaders();
  return new ApolloClient({
    cache: new InMemoryCache(),
    link: createHttpLink({
      uri: process.env["VUE_APP_CLAIMS_GRAPH"],
      credentials: "include",
    }),
    headers,
  });
}

export async function getClaimsVehicleLookup(client, vin, incidentDate) {
  console.log(
    `[CLMS] getClaimsVehicleLookup: vin(${vin}), incidentDate(${incidentDate})`,
  );
  if (!client) throw new Error("No Client GraphQL Client");
  const result = await client.query({
    query: gql`
      query LookupVehicle($vin: String!, $incidentDate: String!) {
        lookupVehicle(vin: $vin, incidentDate: $incidentDate) {
          accountId
          isActive
          vehicleId
        }
      }
    `,
    variables: { vin, incidentDate },
  });
  return result.data.lookupVehicle ?? null;
}

export async function createThirdPartyClaim(client, claim) {
  if (!client) throw new Error("No Client GraphQL Client");
  const result = await client.mutate({
    mutation: gql`
      mutation Mutation(
        $description: String
        $incidentLocationZipcode: String
        $incidentLocationState: String
        $incidentLocationCity: String
        $incidentLocationAddressLineTwo: String
        $incidentLocationAddressLineOne: String
        $incidentTime: String
        $incidentDate: String
        $contactEmail: String
        $contactPhone: String
        $insuranceCarrierName: String
        $insuranceContactPhone: String
        $insuranceContactEmail: String
        $policyNumber: String
        $claimantDriverLicenseState: String
        $claimantDriverLicenseNumber: String
        $claimantZipcode: String
        $claimantState: String
        $claimantCity: String
        $claimantAddressLineTwo: String
        $claimantAddressLineOne: String
        $claimantPhone: String
        $claimantEmail: String
        $claimantLastName: String
        $claimantFirstName: String
        $representativeZipcode: String
        $representativeState: String
        $representativeCity: String
        $representativeAddressLineTwo: String
        $representativeAddressLineOne: String
        $representativeContactPhone: String
        $representativeContactEmail: String
        $representativeContactName: String
        $representativeName: String
        $reportedBy: String
        $status: String
        $vehicleId: ID
        $tenantId: ID
        $started: String
      ) {
        thirdPartyClaim(
          description: $description
          incidentLocationZipcode: $incidentLocationZipcode
          incidentLocationState: $incidentLocationState
          incidentLocationCity: $incidentLocationCity
          incidentLocationAddressLineTwo: $incidentLocationAddressLineTwo
          incidentLocationAddressLineOne: $incidentLocationAddressLineOne
          incidentTime: $incidentTime
          incidentDate: $incidentDate
          contactEmail: $contactEmail
          contactPhone: $contactPhone
          insuranceCarrierName: $insuranceCarrierName
          insuranceContactPhone: $insuranceContactPhone
          insuranceContactEmail: $insuranceContactEmail
          policyNumber: $policyNumber
          claimantDriverLicenseState: $claimantDriverLicenseState
          claimantDriverLicenseNumber: $claimantDriverLicenseNumber
          claimantZipcode: $claimantZipcode
          claimantState: $claimantState
          claimantCity: $claimantCity
          claimantAddressLineTwo: $claimantAddressLineTwo
          claimantAddressLineOne: $claimantAddressLineOne
          claimantPhone: $claimantPhone
          claimantEmail: $claimantEmail
          claimantLastName: $claimantLastName
          claimantFirstName: $claimantFirstName
          representativeZipcode: $representativeZipcode
          representativeState: $representativeState
          representativeCity: $representativeCity
          representativeAddressLineTwo: $representativeAddressLineTwo
          representativeAddressLineOne: $representativeAddressLineOne
          representativeContactPhone: $representativeContactPhone
          representativeContactEmail: $representativeContactEmail
          representativeContactName: $representativeContactName
          representativeName: $representativeName
          reportedBy: $reportedBy
          status: $status
          vehicleId: $vehicleId
          tenantId: $tenantId
          started: $started
        ) {
          id
        }
      }
    `,
    variables: {
      description: claim.description,
      incidentLocationZipcode: claim.incidentLocationZipcode,
      incidentLocationState: claim.incidentLocationState,
      incidentLocationCity: claim.incidentLocationCity,
      incidentLocationAddressLineTwo: claim.incidentLocationAddressLineTwo,
      incidentLocationAddressLineOne: claim.incidentLocationAddressLineOne,
      incidentTime: claim.incidentTime,
      incidentDate: claim.incidentDate,
      contactEmail: claim.claimantEmail,
      contactPhone: `${claim.claimantPhone} ${
        claim.claimantPhoneExtension ?? ""
      }`.trim(),
      insuranceCarrierName: claim.policyCarrierName,
      insuranceContactPhone: `${claim.policyCarrierPhone} ${
        claim.policyCarrierPhoneExtension ?? ""
      }`,
      insuranceContactEmail: claim.policyCarrierEmail,
      policyNumber: claim.policyNumber,
      claimantDriverLicenseState: claim.claimantLicenseState,
      claimantDriverLicenseNumber: claim.claimantLicenseNumber,
      claimantZipcode: claim.claimantAddressZipcode,
      claimantState: claim.claimantAddressState,
      claimantCity: claim.claimantAddressCity,
      claimantAddressLineOne: claim.claimantAddressLineOne,
      claimantAddressLineTwo: claim.claimantAddressLineTwo,
      claimantPhone: `${claim.claimantPhone} ${
        claim.claimantPhoneExtension ?? ""
      }`.trim(),
      claimantEmail: claim.claimantEmail,
      claimantLastName: claim.claimantLastName,
      claimantFirstName: claim.claimantFirstName,
      representativeZipcode: claim.representativeZipcode,
      representativeState: claim.representativeState,
      representativeCity: claim.representativeCity,
      representativeAddressLineTwo: claim.representativeAddressLineTwo,
      representativeAddressLineOne: claim.representativeAddressLineONe,
      representativeContactPhone: `${claim.representativePhone} ${
        claim.representativePhoneExtension ?? ""
      }`.trim(),
      representativeContactEmail: claim.representativeEmail,
      representativeContactName: claim.representativeName,
      representativeName: claim.representativeCompany,
      reportedBy: claim.partyType,
      status: "reported",
      vehicleId: claim.vehicleId,
      tenantId: claim.accountId,
      started: claim.started,
    },
  });
  return result.data.thirdPartyClaim || null;
}

export async function createThirdPartyClaimAttachment(
  client,
  claimId,
  tenantId,
  file,
) {
  if (!client) throw new Error("No Client GraphQL Client");
  const result = await client.mutate({
    mutation: gql`
      mutation Mutation(
        $claimId: ID!
        $tenantId: ID
        $name: String
        $type: String
        $description: String
      ) {
        thirdPartyClaimAttachment(
          claimId: $claimId
          tenantId: $tenantId
          name: $name
          type: $type
          description: $description
        ) {
          id
          url
        }
      }
    `,
    variables: {
      claimId,
      tenantId,
      name: file.name,
      type: "",
      description: "",
    },
  });
  // TODO: does this require auth
  if (
    result.data.thirdPartyClaimAttachment.url.includes("storage.googleapis.com")
  ) {
    await axios.put(result.data.thirdPartyClaimAttachment.url, file);
  } else {
    await axios.post(result.data.thirdPartyClaimAttachment.url, file);
  }
  return result.data.thirdPartyClaimAttachment || null;
}

export async function submitThirdPartyClaim(client, claimId, tenantId) {
  if (!client) throw new Error("No Client GraphQL Client");
  const result = await client.mutate({
    mutation: gql`
      mutation Mutation($submitThirdPartyClaimId: ID!, $tenant: ID!) {
        submitThirdPartyClaim(id: $submitThirdPartyClaimId, tenant: $tenant) {
          message
          success
        }
      }
    `,
    variables: {
      submitThirdPartyClaimId: claimId,
      tenant: tenantId,
    },
  });
  return result.data.submitThirdPartyClaim || null;
}

export async function getClaimLookup(
  client,
  claimNumber = null,
  incidentDate = null,
  vehicleId = null,
) {
  if (!client) throw new Error("No Client GraphQL Client");
  const result = await client.query({
    query: gql`
      query ThirdPartyClaimWithoutAuth(
        $claimNumber: String!
        $incidentDate: String
        $vehicleId: String
      ) {
        thirdPartyClaimWithoutAuth(
          tpaClaimId: $claimNumber
          incidentDate: $incidentDate
          vehicleId: $vehicleId
        ) {
          status
          created
          opened
          closed
          tpaClaimId
          adjusterName
          adjusterEmail
          adjusterPhone
          vehicleVin
          vehicleYear
          vehicleMake
          vehicleModel
        }
      }
    `,
    variables: { claimNumber, incidentDate, vehicleId },
  });
  return result.data.thirdPartyClaimWithoutAuth ?? null;
}
