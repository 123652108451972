import Dashboard from "@/trucks/Dashboard.vue";
import Fleet from "@/trucks/Fleet.vue";
import Vehicle from "@/trucks/Vehicle.vue";
import Drivers from "@/trucks/Drivers.vue";
import Driver from "@/trucks/Driver.vue";
import Claim from "@/trucks/Claim.vue";
import Insurance from "@/trucks/Insurance.vue";
import InsuranceDocuments from "@/trucks/InsuranceDocuments.vue";
import InsuranceDocumentDetails from "@/trucks/InsuranceDocumentDetails.vue";
import InsuranceClaims from "@/trucks/InsuranceClaims.vue";
import Episodes from "@/trucks/Episodes.vue";
import Episode from "@/trucks/Episode.vue";
import EpisodesScheduled from "@/trucks/EpisodesScheduled.vue";
import EpisodesHistory from "@/trucks/EpisodesHistory.vue";
import Billing from "@/trucks/Billing.vue";
import Account from "@/trucks/Account.vue";
import AccountDetails from "@/trucks/AccountDetails.vue";
import AccountContact from "@/trucks/AccountContact.vue";
import MotiveOauthHandlerView from "@/trucks/MotiveOauthHandlerView.vue";
import Samsara from "@/trucks/Samsara.vue";

export default [
  {
    name: "Dashboard",
    path: "",
    component: Dashboard,
  },
  {
    name: "Fleet",
    path: "/fleet",
    component: Fleet,
  },
  {
    name: "Vehicle",
    path: "/fleet/:id",
    component: Vehicle,
    meta: { nav: "Fleet" },
  },
  {
    name: "Drivers",
    path: "/drivers",
    component: Drivers,
  },
  {
    name: "Driver",
    path: "/drivers/:id",
    component: Driver,
    meta: { nav: "Drivers" },
  },
  {
    name: "Insurance",
    path: "/insurance",
    component: Insurance,
    children: [
      {
        name: "InsuranceDocuments",
        path: "documents",
        component: InsuranceDocuments,
        meta: { title: "Documents" },
      },
      {
        name: "InsuranceClaims",
        path: "claims",
        component: InsuranceClaims,
        meta: { title: "Claims" },
      },
    ],
  },
  {
    name: "InsuranceDocumentDetails",
    path: "/insurance/documents/:id",
    component: InsuranceDocumentDetails,
    meta: { nav: "Insurance" },
  },
  {
    name: "Claim",
    path: "/insurance/claims/:id",
    component: Claim,
    meta: { nav: "Insurance" },
  },
  {
    name: "Episodes",
    path: "/episodes",
    component: Episodes,
    children: [
      {
        name: "EpisodesScheduled",
        path: "schedule",
        component: EpisodesScheduled,
        meta: { title: "Scheduled" },
      },
      {
        name: "EpisodesHistory",
        path: "history",
        component: EpisodesHistory,
        meta: { title: "History" },
      },
    ],
  },
  {
    name: "Episode",
    path: "/episodes/:id",
    component: Episode,
    meta: { nav: "Episodes" },
  },
  {
    name: "Billing",
    path: "/billing",
    component: Billing,
  },
  {
    name: "Account",
    path: "/account",
    component: Account,
    children: [
      {
        name: "AccountDetails",
        path: "details",
        component: AccountDetails,
        meta: { title: "Account" },
      },
      {
        name: "ContactInfo",
        path: "contact",
        component: AccountContact,
        meta: { title: "Contact" },
      },
    ],
  },
  {
    name: "MotiveOauthHandler",
    path: "/keeptruckin_oauth_handler",
    component: MotiveOauthHandlerView,
    meta: { title: "Motive connection" },
  },
  {
    name: "Samsara",
    path: "/samsara_oauth_handler",
    component: Samsara,
    meta: { title: "Samsara connection" },
  },
];
