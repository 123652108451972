<template>
  <div v-if="!(account === null)" class="w-full">
    <div class="my-4">
      <h1 class="font-bold text-2xl">
        Welcome back, {{ account.contactFirstName }}! 👋
      </h1>
      <span>Your billing date is on the {{ accountBillingDate }}</span>
    </div>
    <div class="grid md:grid-cols-3">
      <div
        class="transition overflow-scroll bg-white rounded-2xl p-4 m-1 shadow-lg text-sm hover:shadow-xl md:text-base"
      >
        <span class="font-bold text-gray-500">Your total active vehicles</span>
        <br />
        <span class="text-2xl font-bold text-gray-800 tracking-wide">{{
          activeVehicleCount
        }}</span>
      </div>
      <div
        class="transition overflow-scroll bg-white rounded-2xl p-4 m-1 shadow-lg text-sm hover:shadow-xl md:text-base"
      >
        <span class="font-bold text-gray-500"
          >Your total subscription spots</span
        >
        <br />
        <span class="text-2xl font-bold text-gray-800 tracking-wide">{{
          subscriptionSpots || 0
        }}</span>
      </div>
      <div
        class="transition overflow-scroll bg-white rounded-2xl p-4 m-1 shadow-lg text-sm hover:shadow-xl md:text-base"
      >
        <span class="font-bold text-gray-500"
          >Your projected subscription cost</span
        >
        <br />
        <span class="text-2xl font-bold text-gray-800 tracking-wide"
          >${{ projectedSubscriptionCost }}</span
        >
      </div>
    </div>
    <div class="grid md:grid-cols-2 lg:grid-cols-3" v-if="showInfo">
      <div class="p-2" v-for="field in fields" :key="field.key">
        <label class="font-bold">{{ field.label }}</label>
        <br />
        <input
          :value="account[field.key]"
          class="bg-gray-300 hover:bg-gray-300"
          disabled
        />
      </div>
    </div>
    <div class="flex justify-end px-4 mt-1 items-center">
      <router-link
        @click="
          $store.dispatch('logEvent', { name: 'show_account_info' });
          showInfo = !showInfo;
        "
        class="text-sm font-bold text-gray-600 hover:underline"
        :to="{ path: '' }"
      >
        {{ showInfo ? "Hide account info" : "Show account info" }}
      </router-link>
    </div>
    <hr class="mb-4 mt-2" />
    <div class="mb-4">
      <div class="flex justify-between mb-2">
        <h2 class="text-lg font-bold text-gray-800 sm:text-2xl md:text-4xl">
          Vehicles
        </h2>
        <button
          @click="addVehicle"
          class="hidden bg-lula-gradient text-white hover:bg-lula-gradient-alt md:text-base sm:block"
          v-if="
            account.tenantId !== 'carsync' ||
            getLaunchDarklyFlag('cutoff-carsync')
          "
        >
          Add Vehicle
        </button>
      </div>
      <input
        class="bg-gray-200 hover:bg-gray-300"
        placeholder="Search vehicles"
        v-model="vehicleSearch"
      />
      <div class="py-2">
        <div
          @click="addVehicle"
          class="transition w-full p-4 bg-lula-gradient text-center cursor-pointer shadow-md rounded-xl hover:shadow-lg hover:bg-gray-100 font-bold text-white hover:bg-lula-gradient-alt sm:hidden"
          v-if="
            account.tenantId !== 'carsync' ||
            getLaunchDarklyFlag('cutoff-carsync')
          "
        >
          Add Vehicle
        </div>
        <div
          v-if="subscriptionSpots - activeVehicleCount > 0"
          class="w-full rounded-lg p-3 bg-green-100 border border-green-500 text-center"
        >
          <div class="font-bold">
            You have {{ subscriptionSpots - activeVehicleCount }} subscription
            spot open
          </div>
          <div class="flex space-x-1 justify-center">
            <div class="text-gray-400">
              You can get coverage for
              {{ subscriptionSpots - activeVehicleCount }} vehicle at no extra
              cost until the {{ accountBillingDate }}.
            </div>
            <div
              class="cursor-pointer text-lula-primary underline"
              @click="addVehicle"
            >
              <strong>Add a vehicle now</strong>
            </div>
          </div>
        </div>
        <transition-group :name="vehicleSearch ? '' : 'fade'" tag="p">
          <div
            class="list-item-card transition flex flex-col bg-white rounded-xl cursor-pointer shadow-md my-1 md:flex-row md:justify-between hover:bg-gray-200"
            v-for="vehicle in vehicles"
            :key="vehicle.entityId"
            @click="
              $router.push({
                name: `${account.type}/Vehicle`,
                params: { id: vehicle.entityId },
              })
            "
          >
            <div class="p-4">
              <h3 class="font-bold text-gray-800">
                {{ vehicle.vin }}
                {{ vehicle.plate ? `(${vehicle.plate})` : "" }}
              </h3>
              <span class="text-gray-600">{{
                vehicle.key ||
                `${vehicle.year} ${vehicle.make} ${vehicle.model}`
              }}</span>
            </div>
            <div
              class="flex flex-col items-center justify-end w-full pb-2 py-1 bg-lula border-l border-gray-200 shadow-inner rounded-b-xl md:w-24 md:rounded-b-none md:rounded-tr-xl md:rounded-br-xl md:justify-center md:border-transparent"
              v-if="
                vehicle.status === 'Active' && vehicle.withoutActiveCoverage
              "
            >
              <span class="text-white font-bold">Scheduled</span>
            </div>
            <div
              class="flex flex-col items-center justify-end w-full pb-2 py-1 bg-lula border-l border-gray-200 shadow-inner rounded-b-xl md:w-24 md:rounded-b-none md:rounded-tr-xl md:rounded-br-xl md:justify-center md:border-transparent"
              v-else-if="vehicle.status === 'Active'"
            >
              <span class="text-white font-bold">Covered</span>
            </div>
            <div
              class="flex flex-col items-center justify-end w-full pb-2 py-1 bg-lula-alt border-l border-gray-200 shadow-inner rounded-b-xl md:w-24 md:rounded-b-none md:rounded-tr-xl md:rounded-br-xl md:justify-center md:border-transparent"
              v-else-if="
                vehicle.insuranceCriteriaStatus === 'Declined' &&
                vehicle.status === 'Inactive'
              "
            >
              <span class="text-white font-bold">{{
                vehicle.insuranceCriteriaStatus
              }}</span>
            </div>
            <div
              class="flex flex-col items-center justify-end w-full pb-2 py-1 bg-lula-alt border-l border-gray-200 shadow-inner rounded-b-xl md:w-24 md:rounded-b-none md:rounded-tr-xl md:rounded-br-xl md:justify-center md:border-transparent"
              v-else-if="
                vehicle.insuranceCriteriaStatus === 'Declined' &&
                vehicle.status === 'Requested'
              "
            >
              <span class="text-white font-bold">{{ vehicle.status }}</span>
            </div>
            <div
              class="flex flex-col items-center justify-end w-full pb-2 py-1 bg-lula-alt border-l border-gray-200 shadow-inner rounded-b-xl md:w-24 md:rounded-b-none md:rounded-tr-xl md:rounded-br-xl md:justify-center md:border-transparent"
              v-else
            >
              <span class="text-white font-bold">{{ vehicle.status }}</span>
            </div>
          </div>
        </transition-group>
        <div
          class="text-center text-gray-600 font-bold p-4 text-lg bg-white rounded-2xl mt-2"
          v-if="vehicles.length === 0"
        >
          No Vehicles
        </div>
        <div class="flex justify-end px-2" v-if="showMoreVehicles">
          <span
            class="font-bold text-gray-800 cursor-pointer hover:underline"
            @click="incrementVehicleIdx"
          >
            View more vehicles
          </span>
        </div>
      </div>
    </div>
    <div class="mb-4" v-if="getLaunchDarklyFlag('show-drivers')">
      <div class="w-full flex justify-between items-center mb-2">
        <h2
          class="text-lg font-bold text-gray-800 mb-2 sm:text-2xl md:text-4xl"
        >
          Drivers
        </h2>
        <button
          class="bg-lula-gradient text-white hover:bg-lula-gradient-alt"
          @click="
            $router.push({
              name: `${account.type}/Driver`,
              params: { id: 'new' },
            })
          "
          v-if="
            account.tenantId !== 'carsync' ||
            getLaunchDarklyFlag('cutoff-carsync')
          "
        >
          Add Driver
        </button>
      </div>
      <input
        class="bg-gray-200 hover:bg-gray-300"
        placeholder="Search drivers"
        v-model="driverSearch"
      />
      <div class="py-2">
        <transition-group :name="driverSearch ? '' : 'fade'" tag="span">
          <div
            class="list-item-card transition bg-white rounded-xl p-4 cursor-pointer shadow-md my-1 hover:bg-gray-200"
            v-for="driver in drivers"
            :key="driver.entityId"
            @click="
              $router.push({
                name: `${account.type}/Driver`,
                params: { id: driver.entityId },
              })
            "
          >
            <h3 class="font-bold text-gray-800">
              {{ driver.firstName }} {{ driver.lastName }}
            </h3>
            <span class="text-gray-600">
              {{ driver.license }}
            </span>
          </div>
        </transition-group>
        <div
          class="text-center text-gray-600 font-bold p-4 text-lg bg-white rounded-2xl mt-2"
          v-if="drivers.length === 0"
        >
          No Drivers
        </div>
        <div class="flex justify-end px-2" v-if="showMoreDrivers">
          <span
            class="active-label font-bold text-gray-800 cursor-pointer hover:underline"
            @click="incrementDriverIdx"
            >View more drivers</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { getBillingDate } from "@/utils";

function searchVehicles(list, search) {
  return list.filter((vehicle) => {
    if (
      (vehicle.vin || "")
        .toString()
        .toLowerCase()
        .trim()
        .includes(search.toLowerCase())
    ) {
      return true;
    }
    if (
      (vehicle.year || "")
        .toString()
        .toLowerCase()
        .trim()
        .includes(search.toLowerCase())
    ) {
      return true;
    }
    if (
      (vehicle.make || "")
        .toString()
        .toLowerCase()
        .trim()
        .includes(search.toLowerCase())
    ) {
      return true;
    }
    if (
      (vehicle.model || "")
        .toString()
        .toLowerCase()
        .trim()
        .includes(search.toLowerCase())
    ) {
      return true;
    }
    if (
      (vehicle.key || "")
        .toString()
        .toLowerCase()
        .trim()
        .includes(search.toLowerCase())
    ) {
      return true;
    }
    if (
      (vehicle.plate || "")
        .toString()
        .toLowerCase()
        .trim()
        .includes(search.toLowerCase())
    ) {
      return true;
    }
    return search === "" ? true : false;
  });
}

function searchDrivers(list, search) {
  return list.filter((driver) => {
    const searchString = search.toLowerCase();
    const driverName = `${driver.firstName || ""} ${driver.lastName || ""}`;
    if (driverName.toString().toLowerCase().trim().includes(searchString)) {
      return true;
    }
    const licenseString = (driver.license || "").toString();
    if (licenseString.toLowerCase().trim().includes(searchString)) {
      return true;
    }
    return search === "" ? true : false;
  });
}

export default {
  setup() {
    const store = useStore();
    const router = useRouter();

    const account = ref(store.state.account);
    const vehicles = ref(store.state.vehicles);
    const drivers = ref(store.state.drivers);

    const vehicleIdx = ref(10);
    const driverIdx = ref(3);

    const vehicleSearch = ref("");
    const driverSearch = ref("");

    onMounted(() => {
      router.replace({ name: `${account.value.type}/Dashboard` });
    });

    onMounted(async () => {
      vehicles.value = await store.dispatch("getVehicles");
      vehicles.value
        .filter(
          (vehicle) =>
            vehicle.statusHistory.length && vehicle.status == "Active",
        )
        .forEach((vehicle) => {
          vehicle.statusHistory.forEach((s) => {
            let now = new Date();
            if (
              s.status == "Active" &&
              (s.end == null || now < new Date(s.end))
            ) {
              vehicle.withoutActiveCoverage = now < new Date(s.start);
            }
          });
        });
      drivers.value = await store.dispatch("getDrivers");
    });

    return {
      account,
      async addVehicle() {
        store.dispatch("logEvent", { name: "add_vehicle_clicked" });
        if (await store.dispatch("getLaunchDarklyFlag", "add-vehicle-flow")) {
          if (
            await store.dispatch(
              "getLaunchDarklyFlag",
              "cavf-new-declined-flow",
            )
          ) {
            router.push({
              name: `${account.value.type}/CustomerActivatesVehicleFlow`,
            });
          } else {
            router.push({ name: `${account.value.type}/NewVehicle` });
          }
        } else {
          router.push({
            name: `${account.value.type}/Vehicle`,
            params: { id: "new" },
          });
        }
      },
      projectedSubscriptionCost: computed(() => {
        let cost = 0;

        const subscriptionPrice = parseFloat(
          account.value.vehicleSubscriptionPrice || 0,
        );
        const roadsidePrice = parseFloat(
          account.value.roadsideAssistancePrice || 0,
        );

        vehicles.value.forEach((vehicle) => {
          if (vehicle.status === "Active") {
            if (vehicle.override) {
              cost += parseFloat(vehicle.override || 0) + roadsidePrice;
            } else {
              cost += subscriptionPrice + roadsidePrice;
            }
          }
        });
        return parseFloat(cost).toFixed(2);
      }),
      subscriptionSpots: computed(() => account.value.spots),
      vehicleSearch,
      vehicles: computed(() => {
        return searchVehicles(
          vehicles.value
            .filter((vehicle) => vehicle.status !== "Archive")
            .sort((a, b) => {
              // sort in a way that active will come first, then inactive and only then requested
              if (a.status === "Active" && b.status !== "Active") {
                return -1;
              }
              if (a.status !== "Active" && b.status === "Active") {
                return 1;
              }
              if (a.status === "Inactive" && b.status !== "Inactive") {
                return -1;
              }
              if (a.status !== "Inactive" && b.status === "Inactive") {
                return 1;
              }
              return 0;
            })
            .filter(
              (_vehicle, idx) => vehicleSearch.value || idx < vehicleIdx.value,
            ),
          vehicleSearch.value,
        );
      }),
      activeVehicleCount: computed(
        () => vehicles.value.filter((v) => v.status === "Active").length,
      ),
      incrementVehicleIdx() {
        store.dispatch("logEvent", { name: "view_more_vehicles" });
        vehicleIdx.value = vehicleIdx.value + 2;
        setTimeout(() => {
          vehicleIdx.value = vehicleIdx.value + 2;
          setTimeout(() => {
            vehicleIdx.value = vehicleIdx.value + 2;
          }, 10);
        }, 10);
      },
      showMoreVehicles: computed(() => {
        return vehicles.value.length > vehicleIdx.value;
      }),
      driverSearch,
      drivers: computed(() => {
        return searchDrivers(
          drivers.value.filter(
            (driver, idx) => driverSearch.value || idx < driverIdx.value,
          ),
          driverSearch.value,
        );
      }),
      incrementDriverIdx() {
        store.dispatch("logEvent", { name: "view_more_drivers" });
        driverIdx.value = driverIdx.value + 1;
        setTimeout(() => {
          driverIdx.value = driverIdx.value + 1;
          setTimeout(() => {
            driverIdx.value = driverIdx.value + 1;
          });
        }, 10);
      },
      showMoreDrivers: computed(() => {
        return drivers.value.length > driverIdx.value;
      }),
      showInfo: ref(false),
      accountBillingDate: computed(() => {
        return getBillingDate(account);
      }),
      fields: [
        { key: "businessLegalName", label: "Business Name" },
        { key: "businessEmail", label: "Business Email" },
        { key: "businessPhone", label: "Business Phone" },
        { key: "businessAddressLineOne", label: "Business Address Line One" },
        { key: "businessAddressLineTwo", label: "Business Address Line Two" },
        { key: "businessCity", label: "Business City" },
        { key: "businessZipcode", label: "Business Zipcode" },
        { key: "state", label: "Business State" },
        { key: "einNumber", label: "EIN/FEIN Number" },
        { key: "contactEmail", label: "Contact Email" },
        { key: "contactNumber", label: "Contact Phone" },
        { key: "contactLicense", label: "Contact License" },
      ],
    };
  },
};
</script>

<style scoped>
.list-item-card:hover .active-label {
  transition: background 300ms;
  background: #fff;
}
</style>
