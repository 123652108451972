<template>
  <div class="w-full py-8">
    <div class="flex items-center font-bold text-gray-600 text-2xl mb-4">
      <span class="mr-2 cursor-pointer hover:underline" @click="goToDocuments">
        Documents
      </span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-6 w-6 mt-px"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M9 5l7 7-7 7"
        />
      </svg>
      <span class="sm:hidden ml-2"> COI </span>
      <span class="hidden sm:inline ml-2"> Certificate of Insurance </span>
    </div>
    <div>
      <div class="flex flex-col md:flex-row md:justify-between mb-4">
        <h1 class="text-gray-800 font-bold mb-1 text-3xl md:text-4xl">
          <span>
            {{ coi.certificateNumber }}
          </span>
        </h1>
        <button
          @click="sendEmailWithCoiToHolder"
          :disabled="!canResendEmail"
          :class="{
            'send-email-button bg-lula-gradient text-white hover:bg-lula-gradient-alt mt-1 md:mt-0':
              canResendEmail,
            'send-email-button bg-gray-300 cursor-default text-gray-500 mt-1 md:mt-0':
              !canResendEmail,
          }"
        >
          {{ sendingEmailnProgress ? "Sending..." : "Resend COI to Holder" }}
        </button>
      </div>
    </div>
    <div class="flex flex-col md:flex-row">
      <div class="relative w-full md:w-1/3">
        <div
          class="image-container flex flex-col gap-y-4 justify-center items-center bg-white p-4 text-gray-600 rounded-md border w-full"
        >
          <a
            v-if="documentStatus == availableStatus"
            :href="documentUrl"
            target="_blank"
          >
            <img
              src="@/assets/coi-placeholder.png"
              width="250"
              alt="COI document placeholder"
            />
          </a>
          <button
            v-if="documentStatus == availableStatus"
            class="bg-gray-200 hover:bg-gray-300 shadow-sm text-xs py-2 px-6 right-2 bottom-2"
            @click.prevent="downloadDocument"
          >
            Download document
          </button>
          <span v-if="documentStatus == initializingStatus"
            >Initializing...</span
          >
          <span v-if="documentStatus == unavailableStatus">Generating...</span>
        </div>

        <div
          class="bg-white p-4 text-gray-600 rounded-md border text-lg md:text-base lg:text-lg mt-2"
        >
          <span class="font-bold text-gray-800">Last Modified:</span>
          {{ getFormattedModifiedDateFromCoi(coi) }}
          <br />
          <span class="font-bold text-gray-800">Created:</span>
          {{ formatDate(coi.certificateDate) }}
        </div>
      </div>
      <div class="w-full mt-2 md:ml-2 md:mt-0 md:w-2/3">
        <div class="w-full bg-white rounded-md border px-6 py-4">
          <div class="mt-0">
            <div class="relative pb-4">
              <label
                :class="[
                  'font-bold text-gray-800 block',
                  { required: v$.holderInfo.companyName.required },
                ]"
              >
                Company Name
              </label>
              <input
                v-model.trim="v$.holderInfo.companyName.$model"
                :disabled="formIsBusy"
                type="text"
                class="mt-1"
                placeholder="Name of the Ceritificate Holder"
              />
              <span
                class="text-xs pl-4 absolute left-0 bottom-0"
                v-if="
                  v$.holderInfo.companyName.required &&
                  v$.holderInfo.companyName.required.$invalid
                "
              >
                The value is required
              </span>
              <span
                class="text-xs pl-4 absolute left-0 bottom-0"
                v-if="
                  v$.holderInfo.companyName.maxLength &&
                  v$.holderInfo.companyName.maxLength.$invalid
                "
              >
                The value is too long
              </span>
            </div>

            <div class="relative pb-4">
              <label
                :class="[
                  'font-bold text-gray-800 mt-1 block',
                  { required: v$.holderInfo.addressLineOne.required },
                ]"
              >
                Address Line 1
              </label>
              <vue-google-autocomplete
                id="coi-holder-address-line-one-input-coi-details"
                ref="addressAutoCompleteInputCoiDetails"
                class="mt-1"
                :disabled="formIsBusy"
                placeholder="Address Line One"
                country="us"
                @placechanged="onAddressSelected"
                @inputChange="onAddressInputChanged"
              />
              <span
                class="text-xs pl-4 absolute left-0 bottom-0"
                v-if="
                  v$.holderInfo.addressLineOne.required &&
                  v$.holderInfo.addressLineOne.required.$invalid
                "
              >
                The value is required
              </span>
              <span
                class="text-xs pl-4 absolute left-0 bottom-0"
                v-if="
                  v$.holderInfo.addressLineOne.maxLength &&
                  v$.holderInfo.addressLineOne.maxLength.$invalid
                "
              >
                The value is too long
              </span>
            </div>

            <div class="relative pb-4">
              <label class="font-bold text-gray-800 mt-1 block">
                Address Line 2
              </label>
              <input
                v-model.trim="v$.holderInfo.addressLineTwo.$model"
                :disabled="formIsBusy"
                type="text"
                class="mt-1"
                placeholder="Address Line Two"
              />
              <span
                class="text-xs pl-4 absolute left-0 bottom-0"
                v-if="
                  v$.holderInfo.addressLineTwo.maxLength &&
                  v$.holderInfo.addressLineTwo.maxLength.$invalid
                "
              >
                The value is too long
              </span>
            </div>
          </div>

          <div class="md:flex md:gap-3">
            <div class="relative pb-4 md:w-1/3">
              <label
                :class="[
                  'font-bold text-gray-800 block mt-1',
                  { required: v$.holderInfo.city.required },
                ]"
              >
                City
              </label>
              <input
                v-model.trim="v$.holderInfo.city.$model"
                :disabled="formIsBusy"
                type="text"
                class="mt-1"
                placeholder="City"
              />
              <span
                class="text-xs pl-4 absolute left-0 bottom-0"
                v-if="
                  v$.holderInfo.city.required &&
                  v$.holderInfo.city.required.$invalid
                "
              >
                The value is required
              </span>
              <span
                class="text-xs pl-4 absolute left-0 bottom-0"
                v-if="
                  v$.holderInfo.city.maxLength &&
                  v$.holderInfo.city.maxLength.$invalid
                "
              >
                The value is too long
              </span>
            </div>

            <div class="relative pb-4 md:w-1/3">
              <label
                :class="[
                  'font-bold text-gray-800 block mt-1',
                  { required: v$.holderInfo.state.required },
                ]"
              >
                State
              </label>
              <state-select
                v-model="v$.holderInfo.state.$model"
                :disabled="formIsBusy"
                class="mt-1"
              />
              <span
                class="text-xs pl-4 absolute left-0 bottom-0"
                v-if="
                  v$.holderInfo.state.required &&
                  v$.holderInfo.state.required.$invalid
                "
              >
                The value is required
              </span>
            </div>

            <div class="relative pb-4 md:w-1/3">
              <label
                :class="[
                  'font-bold text-gray-800 block mt-1',
                  { required: v$.holderInfo.zipCode.required },
                ]"
              >
                Zip Code
              </label>
              <input
                v-model.trim="v$.holderInfo.zipCode.$model"
                :disabled="formIsBusy"
                type="text"
                class="mt-1"
                placeholder="Zip Code"
              />
              <span
                class="text-xs pl-4 absolute left-0 bottom-0"
                v-if="
                  v$.holderInfo.zipCode.required &&
                  v$.holderInfo.zipCode.required.$invalid
                "
              >
                The value is required
              </span>
              <span
                class="text-xs pl-4 absolute left-0 bottom-0"
                v-if="
                  v$.holderInfo.zipCode.maxLength &&
                  v$.holderInfo.zipCode.maxLength.$invalid
                "
              >
                The value is too long
              </span>
            </div>
          </div>

          <div class="relative pb-4 mt-1">
            <label
              :class="[
                'font-bold text-gray-800 mt-1 block',
                { required: v$.recipientEmail.required },
              ]"
            >
              Recipient's Email
            </label>
            <input
              v-model.trim="v$.recipientEmail.$model"
              :disabled="formIsBusy"
              type="text"
              class="mt-1"
              placeholder="Would you like us to send this to someone?"
            />
            <span
              class="text-xs pl-4 absolute left-0 bottom-0"
              v-if="
                v$.recipientEmail.required &&
                v$.recipientEmail.required.$invalid
              "
            >
              The value is required
            </span>
            <span
              class="text-xs pl-4 absolute left-0 bottom-0"
              v-if="v$.recipientEmail.email && v$.recipientEmail.email.$invalid"
            >
              The value should be a valid email
            </span>
            <span
              class="text-xs pl-4 absolute left-0 bottom-0"
              v-if="
                v$.recipientEmail.maxLength &&
                v$.recipientEmail.maxLength.$invalid
              "
            >
              The value is too long
            </span>
          </div>

          <div class="relative pb-4 mt-1">
            <label class="font-bold text-gray-800 mt-1 block">
              Document Description
            </label>
            <input
              v-model.trim="v$.documentDescription.$model"
              :disabled="formIsBusy"
              type="text"
              class="mt-1"
              placeholder="Document description"
            />
            <span
              class="text-xs pl-4 absolute left-0 bottom-0"
              v-if="
                v$.documentDescription.maxLength &&
                v$.documentDescription.maxLength.$invalid
              "
            >
              The value is too long
            </span>
          </div>

          <div class="flex justify-end flex-col md:flex-row mt-4">
            <button
              @click="resetDocumentFields"
              :disabled="!formHasChanges || formIsBusy"
              :class="{
                'bg-gray-100 hover:bg-gray-200 md:mr-2 mr-0':
                  formHasChanges && !formIsBusy,
                'bg-gray-300 cursor-default text-gray-500 md:mr-2 mr-0':
                  !formHasChanges || formIsBusy,
              }"
            >
              Cancel
            </button>
            <button
              @click="saveCoiDocumentChanges"
              :disabled="!canSaveChanges"
              :class="{
                'save-changes-button bg-lula-gradient hover:bg-lula-gradient-alt text-white mt-1 md:mt-0':
                  canSaveChanges,
                'save-changes-button bg-gray-300 cursor-default text-gray-500 mt-1 md:mt-0':
                  !canSaveChanges,
              }"
            >
              {{ savingChangesInProgress ? "Saving..." : "Save Changes" }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <h2 class="font-bold text-gray-800 text-2xl mb-2 mt-6">
      Specified Drivers in COI
    </h2>
    <div class="w-full bg-white rounded-md border mt-2 p-4">
      <p v-if="drivers.length === 0" class="text-center text-gray-600">
        No Drivers
      </p>
      <table v-else class="w-full" aria-describedby="Driver table">
        <tr class="text-left font-bold text-gray-600 border-b text-gray-800">
          <th class="font-bold pb-2">Name</th>
          <th class="hidden pb-2 font-normal md:table-cell">License Number</th>
        </tr>
        <tr
          v-for="driver in visibleDrivers"
          :key="driver.entityId"
          @click="goToDriverDetails(driver.entityId)"
          class="text-gray-600 cursor-pointer hover:bg-gray-100"
        >
          <td class="py-4">
            <div class="flex items-center ml-2">
              <img
                alt="driver icon"
                src="@/assets/driver-placeholder-icon.svg"
              />
              <div class="ml-2">
                <span class="font-bold">
                  {{ `${driver.firstName} ${driver.lastName}` }}
                </span>
              </div>
            </div>
          </td>
          <td class="hidden py-4 md:table-cell">
            {{ driver.license }}
          </td>
        </tr>
        <tr
          class="cursor-pointer text-gray-600 hover:bg-gray-100"
          @click="visibleDriverCount += 3"
          v-if="visibleDriverCount < drivers.length"
        >
          <td class="px-4 py-2">View More</td>
          <td class="hidden px-4 py-2 md:table-cell"></td>
        </tr>
      </table>
    </div>

    <h2 class="font-bold text-gray-800 text-2xl mb-2 mt-6">
      Specified Vehicles in COI
    </h2>
    <div class="w-full bg-white rounded-md border p-4">
      <p v-if="vehicles.length === 0" class="text-center text-gray-600">
        No Vehicles
      </p>
      <table v-else class="w-full" aria-describedby="Vehicle table">
        <tr class="text-left border-b text-gray-800">
          <th class="pb-2">Vehicle</th>
        </tr>
        <tr
          v-for="vehicle in visibleVehicles"
          :key="vehicle.entityId"
          @click="goToVehicleDetails(vehicle.entityId)"
          class="text-gray-600 cursor-pointer hover:bg-gray-100"
        >
          <td class="flex justify-between items-center pl-2 py-4">
            <div class="flex items-center">
              <img alt="truck icon" src="@/assets/truck-placeholder-icon.svg" />
              <div>
                <span class="ml-2 font-bold">
                  {{ vehicle.vin }}
                </span>
                <br />
                <span v-if="vehicle.key" class="ml-2 text-gray-600">
                  {{ vehicle.key }}
                </span>
                <span v-else class="ml-2 text-gray-600">
                  {{ vehicle.make }} {{ vehicle.model }} {{ vehicle.year }}
                </span>
              </div>
            </div>
          </td>
        </tr>
        <tr
          v-if="visibleVehicleCount < vehicles.length"
          class="cursor-pointer text-gray-600 hover:bg-gray-100"
          @click="visibleVehicleCount += 3"
        >
          <td class="px-4 py-2">View More</td>
          <td class="hidden px-4 py-2 md:table-cell"></td>
          <td class="hidden px-4 py-2 md:table-cell"></td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import { ref, computed, reactive, onMounted, onUnmounted } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { useToast } from "vue-toastification";
import { required, maxLength, email } from "@vuelidate/validators";
import { downloadFile } from "@/utils";
import { isFileAvailable, getFileCustomMetadata } from "@/firebase";
import {
  formatDate,
  getFormattedModifiedDateFromCoi,
} from "@/trucks/coi-helpers";
import useVuelidate from "@vuelidate/core";
import VueGoogleAutocomplete from "vue-google-autocomplete";

const addressAutoCompleteInputCoiDetails = ref(null);

function updateValidatableFieldsFromCoi(validatableFields, coi) {
  validatableFields.holderInfo.companyName = coi.certificateHolder ?? "";
  validatableFields.holderInfo.addressLineOne =
    coi.certificateHolderAddressLineOne ?? "";
  validatableFields.holderInfo.addressLineTwo =
    coi.certificateHolderAddressLineTwo ?? "";
  validatableFields.holderInfo.city = coi.certificateHolderCity ?? "";
  validatableFields.holderInfo.state = coi.certificateHolderState ?? "";
  validatableFields.holderInfo.zipCode = coi.certificateHolderZipcode ?? "";
  validatableFields.recipientEmail = coi.recipientEmail ?? "";
  validatableFields.documentDescription = coi.description ?? "";

  return validatableFields;
}

function getValidatableFieldsFromCoi(coi) {
  const validatableFields = {
    holderInfo: {
      companyName: "",
      addressLineOne: "",
      addressLineTwo: "",
      city: "",
      state: "",
      zipCode: "",
    },
    recipientEmail: "",
    documentDescription: "",
  };

  return updateValidatableFieldsFromCoi(validatableFields, coi);
}

function areFieldsSame(left, right) {
  return (
    left.holderInfo.companyName === right.holderInfo.companyName &&
    left.holderInfo.addressLineOne === right.holderInfo.addressLineOne &&
    left.holderInfo.addressLineTwo === right.holderInfo.addressLineTwo &&
    left.holderInfo.city === right.holderInfo.city &&
    left.holderInfo.state === right.holderInfo.state &&
    left.holderInfo.zipCode === right.holderInfo.zipCode &&
    left.recipientEmail === right.recipientEmail &&
    left.documentDescription === right.documentDescription
  );
}

export default {
  props: [],
  emits: [],
  components: {
    VueGoogleAutocomplete,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const toast = useToast();

    const savingChangesInProgress = ref(false);
    const sendingEmailnProgress = ref(false);
    const formIsBusy = computed(
      () => savingChangesInProgress.value || sendingEmailnProgress.value,
    );
    let isAddressSelecting = false;

    const sourceCoi = ref(
      store.state.insuranceDocuments.find(
        (doc) => doc.entityId === route.params.id,
      ),
    );
    const coi = ref(JSON.parse(JSON.stringify(sourceCoi.value)));

    const visibleDriverCount = ref(3);
    const drivers = computed(() =>
      store.state.drivers.filter(
        (driver) => driver.entityId === coi.value.driver,
      ),
    );
    const visibleDrivers = computed(() =>
      drivers.value.filter((_, index) => index <= visibleDriverCount.value - 1),
    );

    const visibleVehicleCount = ref(3);
    const vehicles = computed(() =>
      store.state.vehicles.filter(
        (vehicle) => vehicle.entityId === coi.value.vehicle,
      ),
    );
    const visibleVehicles = computed(() =>
      vehicles.value.filter(
        (_, index) => index <= visibleVehicleCount.value - 1,
      ),
    );

    const initializingStatus = "initializing";
    const availableStatus = "available";
    const unavailableStatus = "unavailable";
    const documentStatus = ref(initializingStatus);
    const documentUrl = ref("");

    const initialValidatableFields = ref(
      getValidatableFieldsFromCoi(coi.value),
    );
    const validatableFields = reactive(
      JSON.parse(JSON.stringify(initialValidatableFields.value)),
    );
    const formHasChanges = computed(
      () => !areFieldsSame(initialValidatableFields.value, validatableFields),
    );

    const isHolderInfoEmpty = computed(
      () =>
        !validatableFields.holderInfo.companyName &&
        !validatableFields.holderInfo.addressLineOne &&
        !validatableFields.holderInfo.addressLineTwo &&
        !validatableFields.holderInfo.city &&
        !validatableFields.holderInfo.state &&
        !validatableFields.holderInfo.zipCode,
    );

    const validationRules = computed(() => {
      const emptyValidator = {};
      const companyNameValidator = { required, maxLength: maxLength(255) };
      const addressLineOneValidator = { required, maxLength: maxLength(500) };
      const addressLineTwoValidator = { maxLength: maxLength(500) };
      const cityValidator = { required, maxLength: maxLength(255) };
      const stateValidator = { required };
      const zipCodeValidator = { required, maxLength: maxLength(255) };
      const recipientEmailBaseValidator = { email, maxLength: maxLength(1000) };
      const recipientEmailRequiredValidator = {
        required,
        ...recipientEmailBaseValidator,
      };
      const documentDescriptionValidator = { maxLength: maxLength(255) };

      const rules = {
        holderInfo: {
          companyName: companyNameValidator,
          addressLineOne: addressLineOneValidator,
          addressLineTwo: addressLineTwoValidator,
          city: cityValidator,
          state: stateValidator,
          zipCode: zipCodeValidator,
        },
        recipientEmail: recipientEmailBaseValidator,
        documentDescription: documentDescriptionValidator,
      };

      if (isHolderInfoEmpty.value) {
        rules.holderInfo.companyName = emptyValidator;
        rules.holderInfo.addressLineOne = emptyValidator;
        rules.holderInfo.city = emptyValidator;
        rules.holderInfo.state = emptyValidator;
        rules.holderInfo.zipCode = emptyValidator;
        rules.recipientEmail = recipientEmailRequiredValidator;
      } else {
        rules.holderInfo.companyName = companyNameValidator;
        rules.holderInfo.addressLineOne = addressLineOneValidator;
        rules.holderInfo.city = cityValidator;
        rules.holderInfo.state = stateValidator;
        rules.holderInfo.zipCode = zipCodeValidator;
        rules.recipientEmail = recipientEmailBaseValidator;
      }

      const isRecipientEmailEmpty =
        validatableFields.recipientEmail.trim().length == 0;

      if (!isHolderInfoEmpty.value && isRecipientEmailEmpty) {
        rules.recipientEmail = emptyValidator;
      } else if (!isHolderInfoEmpty.value && !isRecipientEmailEmpty) {
        rules.recipientEmail = recipientEmailBaseValidator;
      } else {
        rules.recipientEmail = recipientEmailRequiredValidator;
      }

      return rules;
    });

    const v$ = useVuelidate(validationRules, validatableFields);

    const canSaveChanges = computed(
      () =>
        !v$.value.holderInfo.$invalid &&
        !v$.value.recipientEmail.$invalid &&
        !v$.value.documentDescription.$invalid &&
        formHasChanges.value &&
        !formIsBusy.value,
    );

    const canResendEmail = computed(
      () =>
        !v$.value.holderInfo.$invalid &&
        !v$.value.recipientEmail.$invalid &&
        !v$.value.documentDescription.$invalid &&
        !formHasChanges.value &&
        documentStatus.value == availableStatus &&
        v$.value.recipientEmail.$model &&
        !formIsBusy.value,
    );

    const updateAddressAutoCompleteInput = () =>
      setTimeout(
        () =>
          addressAutoCompleteInputCoiDetails.value?.update(
            validatableFields.holderInfo.addressLineOne,
          ),
        0,
      );

    let checkTimerId = null;
    let isComponentDeactivated = false;

    function clearTimerIfNeeded() {
      if (checkTimerId) {
        clearTimeout(checkTimerId);
      }
    }

    async function checkDocumentAvailability() {
      function runTimerIfNotActive() {
        if (checkTimerId || isComponentDeactivated) {
          return;
        }
        checkTimerId = setInterval(checkDocumentAvailability, 2000);
      }

      const isAvailable = await isFileAvailable(
        `accounts/${store.state.account.id}/documents`,
        coi.value.filePath,
      );

      if (!isAvailable) {
        documentStatus.value = unavailableStatus;
        return runTimerIfNotActive();
      }

      const metadata = await getFileCustomMetadata(
        `accounts/${store.state.account.id}/documents`,
        coi.value.filePath,
      );
      const coiRevisionNumber = +coi.value.revisionNumber || 1;
      const fileRevisionNumber = +(metadata?.revisionNumber || 1);

      if (coiRevisionNumber !== fileRevisionNumber) {
        documentStatus.value = unavailableStatus;
        return runTimerIfNotActive();
      }

      documentUrl.value = await store.dispatch(
        "getFileDownloadUrl",
        coi.value.filePath,
      );
      documentStatus.value = availableStatus;

      clearTimerIfNeeded();
    }

    checkDocumentAvailability();
    onMounted(updateAddressAutoCompleteInput);
    onUnmounted(() => {
      isComponentDeactivated = true;
      clearTimerIfNeeded();
    });

    return {
      coi,
      documentUrl,
      savingChangesInProgress,
      sendingEmailnProgress,
      formIsBusy,
      canSaveChanges,
      canResendEmail,
      visibleDriverCount,
      drivers,
      visibleDrivers,
      visibleVehicleCount,
      vehicles,
      visibleVehicles,
      validatableFields,
      formHasChanges,
      documentStatus,
      initializingStatus,
      availableStatus,
      unavailableStatus,
      v$,

      addressAutoCompleteInputCoiDetails,
      updateAddressAutoCompleteInput,
      formatDate,
      getFormattedModifiedDateFromCoi,

      async sendEmailWithCoiToHolder() {
        sendingEmailnProgress.value = true;

        const sendingParameters = {
          coiDocumentEntityId: coi.value.entityId,
        };

        const savingResult = await store.dispatch(
          "sendEmailWithCoiToHolder",
          sendingParameters,
        );
        sendingEmailnProgress.value = false;

        if (savingResult?.result) {
          toast("The email was sent.");
        } else if (savingResult?.errors) {
          savingResult.errors.forEach((error) =>
            console.warn(`[COIDTL] ${error.code}: ${error.message}`),
          );
          toast("Failed to send an email.");
        } else {
          toast("Failed to send an email..");
        }
      },
      async saveCoiDocumentChanges() {
        const certificateHolder = {
          companyName: validatableFields.holderInfo.companyName,
          addressLineOne: validatableFields.holderInfo.addressLineOne,
          addressLineTwo: validatableFields.holderInfo.addressLineTwo,
          city: validatableFields.holderInfo.city,
          state: validatableFields.holderInfo.state,
          zipCode: validatableFields.holderInfo.zipCode,
        };

        const documentConfiguration = {
          documentEntityId: coi.value.entityId,
          certificateHolder: !isHolderInfoEmpty.value
            ? certificateHolder
            : null,
          driverId: coi.value.driver,
          vehicleId: coi.value.vehicle,
          recipientEmail: validatableFields.recipientEmail || null,
          documentDescription: validatableFields.documentDescription || null,
        };

        savingChangesInProgress.value = true;
        const savingResult = await store.dispatch(
          "saveCoiDocument",
          documentConfiguration,
        );
        await store.dispatch("getInsuranceDocuments");

        sourceCoi.value = store.state.insuranceDocuments.find(
          (doc) => doc.entityId === route.params.id,
        );
        coi.value = JSON.parse(JSON.stringify(sourceCoi.value));
        initialValidatableFields.value = getValidatableFieldsFromCoi(coi.value);
        savingChangesInProgress.value = false;

        documentStatus.value = unavailableStatus;
        checkDocumentAvailability();

        if (savingResult?.doc) {
          toast("The changes were saved.");
        } else if (savingResult?.errors) {
          savingResult.errors.forEach((error) =>
            console.warn(`[COIDTL] ${error.code}: ${error.message}`),
          );
          toast("Failed to save changes.");
        } else {
          toast("Failed to save changes.");
        }
      },
      resetDocumentFields() {
        updateValidatableFieldsFromCoi(validatableFields, sourceCoi.value);
        updateAddressAutoCompleteInput();
      },
      goToDocuments() {
        router.push({ name: `${store.state.account.type}/InsuranceDocuments` });
      },
      goToDriverDetails(entityId) {
        router.push({
          name: `${store.state.account.type}/Driver`,
          params: { id: entityId },
        });
      },
      goToVehicleDetails(entityId) {
        router.push({
          name: `${store.state.account.type}/Vehicle`,
          params: { id: entityId },
        });
      },
      async downloadDocument() {
        const url = await store.dispatch(
          "getFileDownloadUrl",
          coi.value.filePath,
        );
        downloadFile(url, coi.value.filePath.split("/").pop());
      },
      onAddressInputChanged(input) {
        if (isAddressSelecting) {
          addressAutoCompleteInputCoiDetails.value.update(
            validatableFields.holderInfo.addressLineOne,
          );
        } else {
          validatableFields.holderInfo.addressLineOne = input.newVal;
        }

        isAddressSelecting = false;
      },
      onAddressSelected(address) {
        isAddressSelecting = true;
        validatableFields.holderInfo.addressLineOne = `${address.street_number ?? ""} ${address.route ?? ""}`;
        validatableFields.holderInfo.city = address.locality ?? "";
        validatableFields.holderInfo.zipCode = address.postal_code ?? "";
        validatableFields.holderInfo.state =
          address.administrative_area_level_1 ?? "";
      },
    };
  },
};
</script>

<style scoped>
.required:after {
  content: "*";
}

.image-container {
  min-height: 340px;
}

.send-email-button {
  min-width: 250px;
}

.save-changes-button {
  min-width: 185px;
}
</style>
