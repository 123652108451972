import Smartcar from "@smartcar/auth";

const smartCarProperties = {
  // Identifying information: id, make, model, year
  attributes: {
    name: "attributes",
    permission: "read_vehicle_info",
    requestType: "GET",
    componentType: "VehicleProperty",
    text: "Vehicle",
  },
  amperage: {
    name: "amperage",
    permission: "read_charge",
    supportedMakes: ["TESLA"],
    requestType: "GET",
    componentType: "VehicleProperty",
    text: "Amperage",
  },
  setAmperage: {
    name: "setAmperage",
    permission: "control_charge",
    supportedMakes: ["TESLA"],
    requestType: "POST",
    componentType: "SetVehicleProperty",
    targetProperty: "amperage",
    text: "Set new amperage",
    htmlAttributes: {
      type: "number",
      min: 0,
      step: 1,
    },
  },
  batteryLevel: {
    name: "batteryLevel",
    permission: "read_battery",
    requestType: "GET",
    componentType: "VehicleProperty",
    text: "Battery level",
  },
  batteryCapacity: {
    name: "batteryCapacity",
    permission: "read_battery",
    requestType: "GET",
    componentType: "VehicleProperty",
    text: "Capacity",
  },
  chargeCompletion: {
    name: "chargeCompletion",
    permission: "read_charge",
    supportedMakes: ["CADILLAC", "CHEVROLET", "TESLA"],
    requestType: "GET",
    componentType: "VehicleProperty",
    text: "Time to completion",
  },
  chargeLimit: {
    name: "chargeLimit",
    permission: "read_charge",
    requestType: "GET",
    componentType: "VehicleProperty",
    text: "Charge limit",
  },
  setChargeLimit: {
    name: "setChargeLimit",
    permission: "control_charge",
    requestType: "POST",
    componentType: "SetVehicleProperty",
    targetProperty: "chargeLimit",
    text: "Set new limit",
    htmlAttributes: {
      type: "number",
      min: 50, // min and max may vary by oem
      max: 100,
      step: 1,
    },
  },
  chargeState: {
    // returns CHARGING FULLY_CHARGED NOT_CHARGING
    name: "chargeState",
    permission: "read_charge",
    requestType: "GET",
    componentType: "VehicleProperty",
    text: "Current state",
  },
  disconnect: {
    name: "disconnect",
    permission: null,
    requestType: "DELETE",
    componentType: "Disconnect",
    text: "",
  },
  engineOil: {
    name: "engineOil",
    permission: "read_engine_oil",
    requestType: "GET",
    componentType: "VehicleProperty",
    text: "Engine oil life",
  },
  // remaining range of an electric vehicle's battery
  evRange: {
    name: "evRange",
    permission: "read_battery",
    requestType: "GET",
    componentType: "VehicleProperty",
    text: "Range",
  },
  // Note: The fuel tank API is only available for vehicles sold in the United States.
  fuel: {
    name: "fuel",
    permission: "read_fuel",
    requestType: "GET",
    componentType: "VehicleProperty",
    text: "Fuel",
  },
  // estimated remaining distance the car can travel based on the vehicle’s gas tank
  // Note: The fuel tank API is only available for vehicles sold in the United States.
  iceRange: {
    name: "iceRange",
    permission: "read_fuel",
    requestType: "GET",
    componentType: "VehicleProperty",
    text: "Range",
  },
  isPluggedIn: {
    name: "isPluggedIn",
    permission: "read_charge",
    requestType: "GET",
    componentType: "VehicleProperty",
    text: "Plugged in",
  },
  location: {
    name: "location",
    permission: "read_location",
    requestType: "GET",
    componentType: "VehiclePropertyList",
    text: "Location",
  },
  lockUnlock: {
    name: "lockUnlock",
    permission: "control_security",
    requestType: "POST",
    componentType: "LockUnlock",
    text: "text",
  },
  odometer: {
    name: "odometer",
    permission: "read_odometer",
    requestType: "GET",
    componentType: "VehicleProperty",
    text: "Odometer",
  },
  // requires isPluggedIn and chargeState to show
  startStopCharge: {
    name: "startStopCharge",
    permission: "control_charge",
    requestType: "POST",
    componentType: "StartStopCharge",
    text: "",
  },
  tirePressure: {
    name: "tirePressure",
    permission: "read_tires",
    requestType: "GET",
    componentType: "VehiclePropertyList",
    text: "Tire Pressure",
  },
  vin: {
    name: "vin",
    permission: "read_vin",
    requestType: "GET",
    componentType: "VehicleProperty",
    text: "VIN",
  },
  extendedVehicleInfo: {
    name: "extendedVehicleInfo",
    permission: "read_extended_vehicle_info",
    supportedMakes: ["TESLA"],
    requestType: "GET",
    componentType: "VehicleProperty",
    text: "Extended Vehicle Info",
  },
  voltage: {
    name: "voltage",
    permission: "read_charge",
    supportedMakes: ["CADILLAC", "CHEVROLET", "TESLA"],
    requestType: "GET",
    componentType: "VehicleProperty",
    text: "Voltage",
  },
  wattage: {
    name: "wattage",
    permission: "read_charge",
    supportedMakes: ["TESLA"],
    requestType: "GET",
    componentType: "VehicleProperty",
    text: "Wattage",
  },
};

const getSmartCarPermissions = () => {
  const properties = smartCarProperties;

  const permissions = [
    properties.attributes,
    properties.vin,
    properties.odometer,
    properties.location,
    properties.extendedVehicleInfo,
  ].map((property) => property.permission);

  const uniquePermissions = [...new Set(permissions)];

  return uniquePermissions;
};

export const SmartCarOems = [
  { id: "smart_car_acura", makeId: "acura", displayName: "Acura" },
  { id: "smart_car_audi", makeId: "audi", displayName: "Audi" },
  { id: "smart_car_bmw", makeId: "bmw", displayName: "BMW" },
  { id: "smart_car_buick", makeId: "buick", displayName: "Buick" },
  { id: "smart_car_cadillac", makeId: "cadillac", displayName: "Cadillac" },
  { id: "smart_car_chevrolet", makeId: "chevrolet", displayName: "Chevrolet" },
  { id: "smart_car_chrysler", makeId: "chrysler", displayName: "Chrysler" },
  { id: "smart_car_dodge", makeId: "dodge", displayName: "Dodge" },
  { id: "smart_car_ford", makeId: "ford", displayName: "Ford" },
  { id: "smart_car_gmc", makeId: "gmc", displayName: "GMC" },
  { id: "smart_car_hyundai", makeId: "hyundai", displayName: "Hyundai" },
  { id: "smart_car_infiniti", makeId: "infiniti", displayName: "Infiniti" },
  { id: "smart_car_jaguar", makeId: "jaguar", displayName: "Jaguar" },
  { id: "smart_car_jeep", makeId: "jeep", displayName: "Jeep" },
  { id: "smart_car_kia", makeId: "kia", displayName: "Kia" },
  {
    id: "smart_car_land_rover",
    makeId: "land_rover",
    displayName: "Land Rover",
  },
  { id: "smart_car_lexus", makeId: "lexus", displayName: "Lexus" },
  { id: "smart_car_lincoln", makeId: "lincoln", displayName: "Lincoln" },
  { id: "smart_car_mazda", makeId: "mazda", displayName: "Mazda" },
  {
    id: "smart_car_mercedes_benz",
    makeId: "mercedes_benz",
    displayName: "Mercedes-Benz",
  },
  { id: "smart_car_mini", makeId: "mini", displayName: "Mini" },
  { id: "smart_car_nissan", makeId: "nissan", displayName: "Nissan" },
  { id: "smart_car_porsche", makeId: "porsche", displayName: "Porsche" },
  { id: "smart_car_ram", makeId: "ram", displayName: "Ram" },
  { id: "smart_car_rivian", makeId: "rivian", displayName: "Rivian" },
  { id: "smart_car_tesla", makeId: "tesla", displayName: "Tesla" },
  { id: "smart_car_toyota", makeId: "toyota", displayName: "Toyota" },
  {
    id: "smart_car_volkswagen",
    makeId: "volkswagen",
    displayName: "Volkswagen",
  },
  { id: "smart_car_volvo", makeId: "volvo", displayName: "Volvo" },
];

export const SmartCar = {
  connect: function (make, accountEntityId, store, onSuccess, onError) {
    const onComplete = async (error, code, status) => {
      if (!code) {
        console.warn(`[TEL] SmartCar connection failed. Error: '${error}'`);
        return onError && onError();
      }

      const requestInfo = {
        tenantId: accountEntityId,
        accessCode: code,
      };

      const response = await store.dispatch("createTelematicsIntegration", {
        providerId: "smart-car",
        requestInfo,
      });

      if (!response.success) {
        console.warn(
          `[TEL] Cannot create SmartCar integration. API error:`,
          response.error,
        );
        return onError && onError();
      }

      console.info(
        `[TEL] SmartCar integration was successfully created:`,
        response.data,
      );
      return onSuccess && onSuccess();
    };

    const smartCarClient = new Smartcar({
      clientId: process.env["VUE_APP_SMARTCAR_OAUTH_CLIENT_ID"],
      redirectUri: process.env["VUE_APP_SMARTCAR_OAUTH_REDIRECT_URI"],
      scope: getSmartCarPermissions(),
      mode: process.env["VUE_APP_SMARTCAR_OPERATION_MODE"],
      onComplete,
    });

    smartCarClient.openDialog({
      forcePrompt: true,
      // bypass car brand selection screen: https://smartcar.com/docs/api#brand-select
      vehicleInfo: {
        make: make || "",
      },
      // only allow users to authenticate ONE vehicle
      singleSelect: false,
    });
  },
};
