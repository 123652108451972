<template>
  <div class="w-full py-8">
    <div class="bg-white rounded-md border p-4 mt-4">
      <div class="flex items-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          stroke-width="2"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="
                M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724
                0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724
                0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724
                0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724
                0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724
                0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724
                0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724
                0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07
                2.572-1.065z"
          />
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
          />
        </svg>
        <h2 class="text-gray-800 font-bold text-2xl mx-2">Integrations</h2>
      </div>
      <hr class="my-2" />
      <div class="flex">
        <table class="table-auto w-full text-gray-600 text-lg">
          <caption class="hidden">
            Show the service provider details
          </caption>
          <thead>
            <tr>
              <th class="text-left text-gray-800">Service Provider</th>
              <th class="hidden text-left text-gray-800 md:table-cell">
                Connection Status
              </th>
              <th class="hidden text-left text-gray-800 md:table-cell">
                Actions
              </th>
            </tr>
            <tr
              v-for="integration in integrations"
              :key="integration.id"
              class="py-4"
            >
              <td class="py-2">
                <div class="flex justify-between items-center">
                  <span>{{ integration.name }}</span>
                  <span
                    v-if="!integration.isInitialized"
                    class="text-sm py-2 mr-2 md:hidden"
                  >
                    Loading...
                  </span>
                  <span
                    v-else-if="
                      integration.isInitialized && integration.isConnected
                    "
                    class="text-sm py-2 mr-2 md:hidden"
                  >
                    Connected
                  </span>
                  <a
                    :href="integration.connectionUrl"
                    v-else
                    class="bg-lula-gradient hover:bg-lula-gradient-alt px-4 py-2 rounded-full text-white text-sm md:hidden"
                  >
                    Connect
                  </a>
                </div>
              </td>
              <td class="hidden py-2 md:table-cell">
                <div>
                  <span v-if="!integration.isInitialized"> Loading... </span>
                  <span v-else-if="integration.isConnected"> Connected </span>
                  <span v-else> Not Connected </span>
                </div>
              </td>
              <td class="hidden py-2 md:table-cell">
                <a
                  v-if="integration.isInitialized"
                  :href="integration.connectionUrl"
                  class="bg-lula-gradient hover:bg-lula-gradient-alt px-4 py-2 rounded-full text-white"
                >
                  {{ integration.isConnected ? "Refresh" : "Connect Now" }}
                </a>
              </td>
            </tr>
            <tr v-if="isSmartCarFeatureEnabled">
              <td class="py-2">
                <div class="flex justify-between items-center">
                  <span>{{ "SmartCar(OEM's)" }}</span>
                  <span class="text-sm py-2 mr-2 md:hidden">
                    <button
                      class="bg-lula-gradient hover:bg-lula-gradient-alt px-4 py-2 rounded-full text-sm text-white"
                      @click="connectSmartCar()"
                    >
                      {{ "Connect" }}
                    </button>
                  </span>
                </div>
              </td>
              <td class="hidden py-2 md:table-cell">
                <div>
                  {{ "" }}
                </div>
              </td>
              <td class="hidden py-2 md:table-cell">
                <button
                  class="bg-lula-gradient hover:bg-lula-gradient-alt px-4 py-2 rounded-full text-white"
                  @click="connectSmartCar()"
                >
                  {{ "Connect Now" }}
                </button>
              </td>
            </tr>
            <tr
              v-for="integration in smartCarIntegrations"
              :key="integration.id"
              class="py-4"
              v-if="isSmartCarFeatureEnabled"
            >
              <td
                v-if="integration.isInitialized && integration.isConnected"
                class="py-2"
              >
                <div class="flex justify-between items-center">
                  <span>{{ integration.name }}</span>
                  <span class="text-sm py-2 mr-2 md:hidden">
                    {{ "" }}
                  </span>
                  <span class="text-sm py-2 mr-2 md:hidden"> Connected </span>
                </div>
              </td>
              <td
                v-if="integration.isInitialized && integration.isConnected"
                class="hidden py-2 md:table-cell"
              >
                <div>
                  <span> Connected </span>
                </div>
              </td>
              <td
                v-if="integration.isInitialized && integration.isConnected"
                class="hidden py-2 md:table-cell"
              >
                <button
                  v-if="integration.isInitialized"
                  class="bg-lula-gradient hover:bg-lula-gradient-alt px-4 py-2 rounded-full text-white"
                  @click="connectSmartCar(integration.make)"
                >
                  {{ "Refresh" }}
                </button>
              </td>
            </tr>
          </thead>
        </table>
      </div>
    </div>
    <div
      v-if="getLaunchDarklyFlag('telematics-status-dashboard')"
      class="mt-12"
    >
      <h2 class="text-lg font-bold text-gray-800 sm:text-2xl md:text-4xl mb-4">
        Vehicles
      </h2>
      <input
        class="bg-gray-200 hover:bg-gray-300"
        placeholder="Search vehicles"
        v-model="vehicleSearch"
      />
      <div class="py-2" v-if="!(account === null)">
        <div class="w-full flex p-2 mt-2 mb-0.5">
          <span class="font-medium text-gray-700 ml-auto mr-14">
            Service Provider
          </span>
          <span class="font-medium text-gray-700 font mr-11">
            Connection Status
          </span>
        </div>
        <transition-group :name="vehicleSearch ? '' : 'fade'" tag="p">
          <div
            class="list-item-card transition flex flex-col bg-white rounded-xl cursor-pointer shadow-md mb-4 md:flex-row hover:bg-gray-200 items-center h-20"
            v-for="vehicle in vehicles"
            :key="vehicle.entityId"
            @click="
              $router.push({
                name: `${account.type}/Vehicle`,
                params: { id: vehicle.tenantId },
              })
            "
          >
            <div class="p-4">
              <h3 class="font-normal text-black-700 underline">
                {{ vehicle.vin }} {{ vehicle.licensePlate }}
              </h3>
              <h3 class="font-normal text-black">
                {{
                  vehicle.key ||
                  `${vehicle.year} ${vehicle.make} ${vehicle.model}`
                }}
              </h3>
            </div>
            <div class="p-4 ml-auto w-52 justify-center flex">
              <div
                v-if="vehicle.eldProvider === 'Not Connected'"
                class="flex justify-center"
              >
                <h3 class="font-normal text-red-800 mr-0.5">
                  {{ vehicle.eldProvider }}
                </h3>
              </div>
              <div
                v-else-if="vehicle.eldProvider === 'Pending'"
                class="flex justify-center"
              >
                <h3 class="font-normal text-yellow-500 mr-0.5">
                  {{ vehicle.eldProvider }}
                </h3>
              </div>
              <div v-else class="flex justify-center">
                <h3 class="font-normal capitalize" style="color: #3cb371">
                  {{ vehicle.eldProvider }}
                </h3>
              </div>
            </div>
            <div class="p-4 flex mr-6">
              <div
                class="flex justify-center w-36"
                v-if="vehicle.lastEldUpdate === 'Not Connected'"
              >
                <h3 class="font-normal text-red-800 mr-1.5">Not Connected</h3>
                <em
                  class="fas fa-circle-exclamation mt-1"
                  style="color: #e04d43; font-size: 18px"
                ></em>
              </div>
              <div
                class="flex justify-center w-36"
                v-else-if="vehicle.lastEldUpdate === 'Pending'"
              >
                <h3 class="font-normal text-yellow-500 mr-1.5">Pending</h3>
              </div>
              <div class="flex justify-center w-36" v-else>
                <h3
                  class="font-normal text mr-1.5 justify-center"
                  style="color: #3cb371"
                >
                  Connected
                </h3>
                <div class="inline-block relative">
                  <em
                    class="fas fa-car mt-1"
                    style="color: #3cb371; font-size: 13px"
                  ></em>
                  <em
                    class="fas fa-plug-circle-check absolute -top-1 left-2"
                    style="color: #3cb371; font-size: 15px"
                  ></em>
                </div>
              </div>
            </div>
          </div>
        </transition-group>
        <div
          class="text-center text-gray-600 font-bold p-4 text-lg bg-white rounded-2xl mt-2"
          v-if="telematicsInitialized && vehicles.length === 0"
        >
          No Vehicles
        </div>
        <div class="flex justify-end px-2" v-if="showMoreVehicles">
          <span
            class="font-bold text-gray-800 cursor-pointer hover:underline"
            @click="incrementVehicleIdx"
          >
            View more vehicles
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed } from "vue";
import { useStore } from "vuex";
import { useToast } from "vue-toastification";
import { Providers } from "@/telematics";
import { SmartCar, SmartCarOems } from "./telematics";

function getAccountObject(account) {
  return account ? { ...account } : {};
}

function searchVehicles(list, search) {
  return list.filter((vehicle) => {
    if (vehicle.vin.toLowerCase().trim().includes(search.toLowerCase())) {
      return true;
    }
    if (
      (vehicle.year || "")
        .toString()
        .toLowerCase()
        .trim()
        .includes(search.toLowerCase())
    ) {
      return true;
    }
    if (
      (vehicle.make || "")
        .toString()
        .toLowerCase()
        .trim()
        .includes(search.toLowerCase())
    ) {
      return true;
    }
    if (
      (vehicle.model || "")
        .toString()
        .toLowerCase()
        .trim()
        .includes(search.toLowerCase())
    ) {
      return true;
    }
    if (
      (vehicle.key || "")
        .toString()
        .toLowerCase()
        .trim()
        .includes(search.toLowerCase())
    ) {
      return true;
    }
    if (
      (vehicle.licensePlate || "")
        .toLowerCase()
        .trim()
        .includes(search.toLowerCase())
    ) {
      return true;
    }
    return search === "";
  });
}

export default {
  setup() {
    const store = useStore();
    const account = ref(getAccountObject(store.state.account));
    const toast = useToast();

    const vehicles = ref([]);
    const vehicleIdx = ref(10);
    const vehicleSearch = ref("");
    const activeVehicles = ref([]);
    const telematicsInitialized = ref(false);
    const isTelematicsConnected = ref(false);
    const isSmartCarFeatureEnabled = ref(false);

    const integrations = ref([
      {
        id: Providers.Bouncie.id,
        name: "Bouncie",
        isInitialized: false,
        isConnected: false,
        connectionUrl: encodeURI(
          new URL(process.env["VUE_APP_BOUNCIE_OAUTH_URL"]),
        ),
      },
      {
        id: Providers.Zubie.id,
        name: "Zubie",
        isInitialized: false,
        isConnected: false,
        connectionUrl: encodeURI(
          new URL(process.env["VUE_APP_ZUBIE_OAUTH_URL"]),
        ),
      },
    ]);

    const smartCarIntegrations = ref(
      SmartCarOems.map((item) => {
        return {
          id: item.id,
          make: item.makeId,
          name: item.displayName,
          isInitialized: false,
          isConnected: false,
        };
      }),
    );

    const connectSmartCar = (make) => {
      const onSuccess = () => window.location.reload(); // TODO: remove this hack in a scope of the next task

      const onError = () =>
        toast(
          "There is an error while connecting to SmartCar. See the console for details.",
        );

      SmartCar.connect(make, account.value.entityId, store, onSuccess, onError);
    };

    onMounted(async () => {
      isSmartCarFeatureEnabled.value = await store.dispatch(
        "getLaunchDarklyFlag",
        "show-smart-car-row-in-integrations",
      );
    });

    onMounted(async () => {
      const telematicsResponse = await store.dispatch(
        "getTelematicsAccountStatus",
        account.value.entityId,
      );

      if (!telematicsResponse.success) {
        console.error(
          "[PROVINTG] Cannot get the current Telematics account status. Error: ",
          telematicsResponse.error,
        );
        toast(
          "There is an error while getting the current Telematics account status. See the console for details.",
        );
        return;
      }

      const telematicsAccount = telematicsResponse.data;

      smartCarIntegrations.value.forEach((integration) => {
        const oemIntegration = telematicsAccount.integrations.find(
          (item) => item.type === integration.id,
        );

        if (oemIntegration && oemIntegration.status === "connected") {
          isTelematicsConnected.value = true;
          integration.isConnected = true;
        }

        integration.isInitialized = true;
      });

      integrations.value.forEach((integration) => {
        const accountIntegration = telematicsAccount.integrations.find(
          (item) => item.type === integration.id,
        );

        if (accountIntegration && accountIntegration.status === "connected") {
          isTelematicsConnected.value = true;
          integration.isConnected = true;
        }

        integration.isInitialized = true;
      });

      if (
        await store.dispatch(
          "getLaunchDarklyFlag",
          "telematics-status-dashboard",
        )
      ) {
        const activeVehicleData = await store.dispatch("getVehicles");
        activeVehicles.value = activeVehicleData.filter((vehicle) => {
          if (vehicle.status === "Active") {
            return true;
          }
        });

        const vehicleResponse = await store.dispatch(
          "getVehicleAccountStatus",
          account.value.entityId,
        );
        if (!vehicleResponse.success) {
          console.error(
            "[PROVINTG] Cannot get the current vehicle account status. Error: ",
            vehicleResponse.error,
          );
          toast(
            "There is an error while getting the current vehicle account status. See the console for details.",
          );
          return;
        }
        vehicles.value = vehicleResponse.data;

        const updatedActiveVehicles = [];
        activeVehicles.value.forEach((activeVehicle) => {
          const vehicle = vehicles.value.find(
            (vehicle) => activeVehicle.vin === vehicle.vin,
          );
          const integrationForVehicle = integrations.value.find(
            (integration) => vehicle.eldProvider === integration.id,
          );
          const isVehicleConnected = integrationForVehicle
            ? integrationForVehicle.isConnected
            : false;
          console.log(isVehicleConnected);
          updatedActiveVehicles.push({
            vin: activeVehicle.vin,
            eldProvider:
              isVehicleConnected && vehicle.eldProvider
                ? vehicle.eldProvider
                : isTelematicsConnected.value
                  ? "Pending"
                  : "Not Connected",
            lastEldUpdate:
              isVehicleConnected && vehicle.lastEldUpdate
                ? vehicle.lastEldUpdate
                : isTelematicsConnected.value
                  ? "Pending"
                  : "Not Connected",
            key: activeVehicle.key,
            year: activeVehicle.year,
            make: activeVehicle.make,
            model: activeVehicle.model,
            tenantId: vehicle.tenantId,
          });
        });
        telematicsInitialized.value = true;
        vehicles.value = updatedActiveVehicles;
      }
    });

    return {
      account,
      integrations,
      smartCarIntegrations,
      isSmartCarFeatureEnabled,
      vehicleSearch,
      telematicsInitialized,
      vehicles: computed(() => {
        return searchVehicles(
          vehicles.value.filter(
            (_vehicle, idx) => vehicleSearch.value || idx < vehicleIdx.value,
          ),
          vehicleSearch.value,
        );
      }),
      incrementVehicleIdx() {
        store.dispatch("logEvent", { name: "view_more_vehicles" });
        vehicleIdx.value = vehicleIdx.value + 2;
        setTimeout(() => {
          vehicleIdx.value = vehicleIdx.value + 2;
          setTimeout(() => {
            vehicleIdx.value = vehicleIdx.value + 2;
          }, 10);
        }, 10);
      },
      showMoreVehicles: computed(() => {
        return vehicles.value.length > vehicleIdx.value;
      }),
      connectSmartCar,
    };
  },
};
</script>
