<template>
  <modal class="z-20 ml-0">
    <Loader v-if="isLoading" />
    <div
      v-show="!isLoading"
      class="transition w-full h-full md:w-3/5 lg:w-1/2 xl:w-1/3 max-w-screen-xl overflow-y-scroll"
    >
      <div
        class="flex flex-col bg-white rounded-xl w-full min-h-full sm:pt-8 md:pt-20 overflow-y-scroll"
      >
        <div
          ref="paymentMethodPlaceholder"
          class="border p-3 rounded-lg overflow-y-scroll"
        />
        <div class="w-full flex justify-center">
          <button @click="onSubmit">Submit</button>
          <button @click="onClose">Cancel</button>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import { ref, onMounted, toRaw, getCurrentInstance } from "vue";
import { loadStripe } from "@stripe/stripe-js";
import { useStore } from "vuex";
import { useToast } from "vue-toastification";
import { Loader } from "@getaddify/lula-components";

export default {
  components: { Loader },
  setup() {
    const store = useStore();
    const toast = useToast();
    const vueInstance = getCurrentInstance();

    const { demo, entityId } = store.state.account;
    const stripe = ref(null);
    const paymentMethodPlaceholder = ref(null);
    const elementObj = ref(null);
    const isLoading = ref(true);

    function emitModalClose() {
      vueInstance.emit("toggleModal");
    }
    function deleteClientToken() {
      store.commit("setClientSecret", null);
    }

    onMounted(async () => {
      let clientSecret;
      try {
        stripe.value = await loadStripe(
          demo
            ? process.env["VUE_APP_STRIPE_PUBLIC_TEST_KEY"]
            : process.env["VUE_APP_STRIPE_PUBLIC_KEY"],
        );
      } catch (error) {
        console.error("[ADDPMT] There was an error loading Stripe: ", error);
      }
      if (store.state.clientSecret !== null) {
        clientSecret = store.state.clientSecret;
      } else {
        try {
          const lpgResponse = await store.dispatch(
            "createClientTokenUsingLPG",
            {
              accountId: entityId,
              xTestMode: demo,
            },
          );
          clientSecret = lpgResponse.clientSecret;
          store.commit("setClientSecret", clientSecret);
        } catch (error) {
          console.error("[ADDPMT] Error getting client token", error);
        }
      }
      try {
        elementObj.value = stripe.value.elements({ clientSecret });
        const paymentElement = elementObj.value.create("payment");
        paymentElement.mount(paymentMethodPlaceholder.value);
      } catch (error) {
        console.error("[ADDPMT] Error mounting Stripe element", error);
      } finally {
        isLoading.value = false;
      }
    });
    return {
      paymentMethodPlaceholder,
      stripe,
      elementObj,
      isLoading,
      emitModalClose,
      deleteClientToken,
      onSubmit() {
        this.stripe
          .confirmSetup({
            elements: toRaw(elementObj.value),
            redirect: "if_required",
          })
          .then((res) => {
            if (res.error) {
              console.error(
                "[ADDPMT] Stripe add payment method error: ",
                res.error.code,
              );
              toast.error(
                `Stripe error while submitting payment method: ${res.error.message}`,
              );
            } else {
              deleteClientToken();
              emitModalClose();
            }
          });
      },
    };
  },
  methods: {
    onClose() {
      this.$emit("toggleModal");
    },
  },
};
</script>
