<template>
  <div class="w-full py-8">
    <div class="flex justify-between items-center mb-4">
      <h1 class="font-bold text-gray-800 text-4xl">Fleet</h1>
      <button
        @click="showRequestVehicle = true"
        class="hidden bg-lula-gradient font-bold text-white hover:bg-lula-gradient-alt sm:block"
      >
        Request new Vehicle
      </button>
    </div>
    <div class="mt-8">
      <h2 class="font-bold text-gray-800 text-2xl mb-2">Active Vehicles</h2>
      <div class="w-full bg-white rounded-md border p-4">
        <p class="text-center text-gray-600" v-if="vehicles.length === 0">
          No Vehicles
        </p>
        <table class="w-full" v-else>
          <tr class="text-left border-b text-gray-800">
            <th class="pb-2">Vehicle</th>
            <th class="hidden pb-2 font-normal md:table-cell">Plate</th>
            <th class="hidden pb-2 font-normal md:table-cell">
              Episode Status
            </th>
          </tr>
          <tr
            @click="selectVehicle(vehicle.entityId)"
            class="text-gray-600 cursor-pointer hover:bg-gray-100"
            v-for="vehicle in vehicles"
            :key="vehicle.entityId"
          >
            <td class="flex justify-between items-center pl-2 py-4">
              <div class="flex items-center">
                <img
                  alt="truck icon"
                  src="../assets/truck-active-1.svg"
                  v-if="vehicle.currentEpisode"
                />
                <img
                  alt="truck icon"
                  src="../assets/truck-inactive-placeholder-icon.svg"
                  v-else-if="vehicle.status === 'Active'"
                />
                <img
                  alt="truck icon"
                  src="../assets/truck-placeholder-icon.svg"
                  v-else
                />
                <div>
                  <span class="ml-2 font-bold" v-if="vehicle.key">
                    {{ vehicle.key }}
                  </span>
                  <span class="ml-2 font-bold" v-else>
                    {{ vehicle.make }} {{ vehicle.model }} {{ vehicle.year }}
                  </span>
                  <br />
                  <span class="ml-2 text-gray-600">
                    {{ vehicle.vin }}
                  </span>
                </div>
              </div>
            </td>
            <td class="hidden py-4 md:table-cell">
              {{ vehicle.plate }}
            </td>
            <td class="hidden py-4 font-bold md:table-cell">
              <span class="text-green-500" v-if="vehicle.currentEpisode">
                Active Episode
              </span>
              <span
                class="flex text-gray-600"
                v-else-if="vehicle.upcomingEpisode"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6 mr-1"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="
                        M8 7V3m8 4V3m-9 8h10M5 21h14a2 2
                        0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2
                        2v12a2 2 0 002 2z"
                  />
                </svg>
                Scheduled
              </span>
              <span
                class="text-lula cursor-pointer hover:underline"
                @click.stop="
                  $router.push({
                    name: `${account.type}/EpisodesScheduled`,
                    query: { vehicle: vehicle.entityId },
                  })
                "
                v-else
              >
                Book More
              </span>
            </td>
          </tr>
          <tr
            class="cursor-pointer text-gray-600 hover:bg-gray-100"
            @click="activeVehicleIdx += 5"
            v-if="activeVehicleIdx <= vehicles.length - 1"
          >
            <td class="px-4 py-2">View More</td>
            <td class="hidden px-4 py-2 md:table-cell" />
            <td class="hidden px-4 py-2 md:table-cell" />
          </tr>
        </table>
      </div>
    </div>
    <div class="w-full mt-2">
      <button
        @click="showRequestVehicle = true"
        class="w-full bg-lula-gradient font-bold text-white hover:bg-lula-gradient-alt sm:hidden"
      >
        Request new Vehicle
      </button>
    </div>
    <hr class="my-8" />
    <div class="flex flex-col lg:flex-row">
      <div class="w-full mt-4 lg:mt-0">
        <h2 class="text-xl mb-2 font-bold text-gray-800">Inactive Vehicles</h2>
        <div class="w-full bg-white rounded-md border p-4 lg:mr-2">
          <p
            class="text-center text-gray-600"
            v-if="pendingVehicles.length === 0"
          >
            No Vehicles
          </p>
          <table class="w-full" v-else>
            <tr class="text-left border-b text-gray-800">
              <th class="pb-2">Vehicle</th>
              <th class="hidden font-normal pb-2 md:table-cell">Status</th>
            </tr>
            <tr
              @click="selectVehicle(vehicle.entityId)"
              class="text-gray-600 cursor-pointer hover:bg-gray-100"
              v-for="vehicle in pendingVehicles"
              :key="vehicle.entityId"
            >
              <td class="flex items-center py-4">
                <img
                  class="ml-2"
                  alt="truck icon"
                  src="../assets/truck-placeholder-icon.svg"
                />
                <div>
                  <span class="ml-2 font-bold" v-if="vehicle.key">
                    {{ vehicle.key }}
                  </span>
                  <span class="ml-2 font-bold" v-else>
                    {{ vehicle.make }} {{ vehicle.model }} {{ vehicle.year }}
                  </span>
                  <br />
                  <span class="ml-2 text-gray-600">
                    {{ vehicle.vin }}
                  </span>
                </div>
              </td>
              <td class="hidden py-4 md:table-cell">
                <div
                  class="flex text-lula items-center"
                  v-if="vehicle.insuranceCriteriaStatus === 'Approved'"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="
                            M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955
                            11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824
                            10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
                    />
                  </svg>
                  <span
                    @click.stop="activateNow(vehicle)"
                    class="ml-1 font-bold cursor-pointer hover:underline"
                  >
                    Activate Now
                  </span>
                </div>
                <div
                  class="flex text-gray-600 items-center"
                  v-if="vehicle.insuranceCriteriaStatus === 'Under Review'"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                    />
                  </svg>
                  <span class="ml-1 font-bold"> Under Review </span>
                </div>
              </td>
            </tr>
            <tr
              class="cursor-pointer text-gray-600 hover:bg-gray-100"
              @click="pendingVehicleIdx += 5"
              v-if="pendingVehicleIdx <= pendingVehicles.length - 1"
            >
              <td class="px-4 py-2">View More</td>
              <td class="hidden px-4 py-2 md:table-cell" />
            </tr>
          </table>
        </div>
      </div>
      <div class="w-full mt-4 lg:mt-0 lg:ml-2">
        <h2 class="text-xl mb-2 font-bold text-gray-800">Declined Vehicles</h2>
        <div class="w-full bg-white rounded-md border p-4">
          <p
            class="text-center text-gray-600"
            v-if="declinedVehicles.length === 0"
          >
            No Vehicles
          </p>
          <table class="w-full" v-else>
            <tr class="text-left font-bold border-b text-gray-800">
              <th class="pb-2">Vehicle</th>
              <th class="hidden pb-2 font-normal md:table-cell">Status</th>
            </tr>
            <tr
              class="text-gray-600 cursor-pointer hover:bg-gray-100"
              v-for="vehicle in declinedVehicles"
              :key="vehicle.entityId"
            >
              <td
                @click="selectVehicle(vehicle.entityId)"
                class="flex items-center py-4 pl-2"
              >
                <img
                  alt="truck icon"
                  src="../assets/truck-placeholder-icon.svg"
                />
                <div>
                  <span class="ml-2 font-bold" v-if="vehicle.key">
                    {{ vehicle.key }}
                  </span>
                  <span class="ml-2 font-bold" v-else>
                    {{ vehicle.make }} {{ vehicle.model }} {{ vehicle.year }}
                  </span>
                  <br />
                  <span class="ml-2 text-gray-600">
                    {{ vehicle.vin }}
                  </span>
                </div>
              </td>
              <td class="hidden py-4 md:table-cell">
                <div class="flex items-center mr-2 text-red-500">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="mt-px mx-px h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                  <span class="font-bold"> Declined </span>
                </div>
              </td>
            </tr>
            <tr
              class="cursor-pointer text-gray-600 hover:bg-gray-100"
              @click="declinedVehicleIdx += 5"
              v-if="declinedVehicleIdx <= declinedVehicles.length - 1"
            >
              <td class="px-4 py-2">View More</td>
              <td class="hidden px-4 py-2 md:table-cell" />
            </tr>
          </table>
        </div>
      </div>
    </div>
    <modal v-if="activateNowVehicle">
      <div
        class="w-full h-full overflow-scroll max-w-screen-xl bg-white rounded-md p-4 border md:w-2/3 lg:w-1/2 md:h-auto"
      >
        <div>
          <h2 class="font-bold text-gray-800 text-2xl md:text-3xl">
            Activate Vehicle
          </h2>
          <span class="font-bold text-gray-600 text-sm md:text-base">
            Would you like to activate this vehicle now?
          </span>
        </div>
        <div class="text-gray-600">
          <div class="bg-gray-50 rounded-md p-4 my-2">
            <div class="flex">
              <img
                alt="truck icon"
                src="../assets/truck-placeholder-icon.svg"
              />
              <div>
                <span class="ml-2 font-bold" v-if="activateNowVehicle.key">
                  {{ activateNowVehicle.key }}
                </span>
                <span class="ml-2 font-bold" v-else>
                  {{ activateNowVehicle.make }} {{ activateNowVehicle.model }}
                  {{ activateNowVehicle.year }}
                </span>
                <br />
                <span class="ml-2 text-gray-600">
                  {{ activateNowVehicle.vin }}
                </span>
              </div>
            </div>
          </div>
          <div class="bg-gray-50 rounded-md p-4 my-2">
            <div class="flex justify-between">
              <span class="font-bold">Activation price</span>
              <span>${{ parseFloat(activationPrice).toFixed(2) }}</span>
            </div>
            <hr class="my-2" />
            <div class="text-gray-500">
              <div class="flex justify-between mb-2">
                <span class="font-bold">Subscription price</span>
                <span
                  >${{
                    parseFloat($store.state.accountMetrics.seatCost).toFixed(2)
                  }}</span
                >
              </div>
              <div
                class="flex justify-between mb-2"
                v-if="activateNowVehicle.statusHistory.length === 0"
              >
                <span class="font-bold"
                  >GetAddify Bucks Additional Deposit</span
                >
                <span
                  >${{
                    parseFloat(
                      $store.state.accountMetrics.standardDeposit,
                    ).toFixed(2)
                  }}</span
                >
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-col md:justify-end md:flex-row">
          <button
            class="bg-gray-100 mr-1 hover:bg-gray-200"
            @click="activateNowVehicle = null"
          >
            Close
          </button>
          <button
            class="bg-lula-gradient text-white mt-1 hover:bg-lula-gradient-alt md:mt-0"
            @click="confirmActivateNow"
          >
            Pay and Activate
          </button>
        </div>
      </div>
    </modal>
    <modal v-if="showRequestVehicle">
      <RequestVehicle @cancel="showRequestVehicle = false" />
    </modal>
  </div>
</template>

<script>
import { ref, computed, onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { useToast } from "vue-toastification";
import { mapVehiclesToEpisodes } from "@/utils";
import RequestVehicle from "@/trucks/RequestVehicle.vue";

export default {
  components: { RequestVehicle },
  setup() {
    const store = useStore();
    const router = useRouter();
    const toast = useToast();

    const activateNowVehicle = ref(null);

    const activeVehicleIdx = ref(4);
    const pendingVehicleIdx = ref(4);
    const declinedVehicleIdx = ref(4);

    onMounted(() => store.dispatch("getMetrics"));

    return {
      account: computed(() => {
        return store.state.account;
      }),
      activationPrice: computed(() => {
        const standardDeposit =
          activateNowVehicle.value.statusHistory.length > 0
            ? 0
            : parseFloat(store.state.accountMetrics.standardDeposit);
        const seatCost = parseFloat(store.state.accountMetrics.seatCost);
        return parseFloat(seatCost + standardDeposit).toFixed(2);
      }),
      activeVehicleIdx,
      vehicles: computed(() => {
        return mapVehiclesToEpisodes(store.state.vehicles, store.state.episodes)
          .filter((v) => v.status === "Active")
          .filter((_, idx) => idx <= activeVehicleIdx.value);
      }),
      pendingVehicleIdx,
      pendingVehicles: computed(() => {
        const reviews = store.state.vehicles.filter((v) => {
          return v.insuranceCriteriaStatus === "Under Review";
        });
        const inactive = store.state.vehicles.filter((v) => {
          return (
            v.status === "Inactive" && v.insuranceCriteriaStatus === "Approved"
          );
        });
        return [...reviews, ...inactive].filter(
          (_, idx) => idx <= pendingVehicleIdx.value,
        );
      }),
      declinedVehicleIdx,
      declinedVehicles: computed(() => {
        return store.state.vehicles
          .filter((v) => v.insuranceCriteriaStatus === "Declined")
          .filter((_, idx) => idx <= declinedVehicleIdx.value);
      }),
      subscribedVehicles: computed(() => {
        return store.state.vehicles.filter((v) => v.status === "Active");
      }),
      selectVehicle(id) {
        router.push({
          name: `${store.state.account.type}/Vehicle`,
          params: { id },
        });
      },
      activateNowVehicle,
      showRequestVehicle: ref(false),
      async activateNow(vehicle) {
        if (store.state.accountMetrics?.remainingSeats > 0) {
          if (confirm("Are you sure you want to activate this vehicle?")) {
            toast("Activating vehicle.");
            try {
              await store.dispatch("subscribeVehicleForAccount", vehicle);
              await store.dispatch("getMetrics");
              await store.dispatch("getVehicles");
              toast.clear();
              toast("Vehicle activated.");
            } catch (err) {
              toast.clear();
              toast("Failed to activate vehicle.");
            }
          }
        } else {
          activateNowVehicle.value = vehicle;
        }
      },
      async confirmActivateNow() {
        const vehicle = { ...activateNowVehicle.value };
        activateNowVehicle.value = null;
        toast("Activating vehicle.");
        try {
          await store.dispatch("subscribeVehicleForAccount", vehicle);
          await store.dispatch("getMetrics");
          await store.dispatch("getVehicles");
          toast.clear();
          toast("Vehicle activated.");
        } catch (err) {
          toast.clear();
          toast("Failed to activate vehicle.");
        }
      },
    };
  },
};
</script>
