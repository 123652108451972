<template>
  <div class="m-auto p-4 max-w-screen-2xl">
    <div class="mb-4">
      <h1 class="text-lula-alt font-bold text-lg">Report a Claim</h1>
      <span class="text-2xl font-bold"> Review information </span>
    </div>
    <div class="flex flex-col gap-4 lg:flex-row">
      <div class="w-full flex flex-col gap-4 lg:w-1/3">
        <div
          class="flex flex-col gap-2 bg-white text-gray-800 rounded-lg border p-4"
        >
          <div class="flex justify-between items-center mb-2">
            <h2 class="font-bold text-gray-600">Vehicle Information</h2>
          </div>
          <div v-for="detail in vehicleDetails" :key="detail.key">
            <div class="flex flex-col" :class="{ hidden: !detail.value }">
              <label class="font-bold">{{ detail.label }}</label>
              <span>{{ detail.value }}</span>
            </div>
          </div>
        </div>
        <div
          class="flex flex-col gap-2 bg-white text-gray-800 rounded-lg border p-4"
        >
          <div class="flex justify-between items-center mb-2">
            <h2 class="font-bold text-gray-600">Incident Details</h2>
            <span
              class="font-bold text-lula-primary mr-2 cursor-pointer hover:underline"
              @click="
                $router.push({
                  name: 'Claims/IncidentDetails',
                  query: { edit: 'true' },
                })
              "
            >
              Edit
            </span>
          </div>
          <div v-for="detail in incidentDetails" :key="detail.key">
            <div class="flex flex-col" :class="{ hidden: !detail.value }">
              <label class="font-bold">{{ detail.label }}</label>
              <span
                class="max-h-32 break-all overflow-y-auto"
                v-if="detail.label === 'Description'"
              >
                {{ detail.value }}
              </span>
              <span v-else>{{ detail.value }}</span>
            </div>
          </div>
        </div>
        <div
          class="flex flex-col gap-2 bg-white text-gray-800 rounded-lg border p-4"
        >
          <div class="flex justify-between items-center mb-2">
            <h2 class="font-bold text-gray-600">Attachments</h2>
            <span
              class="font-bold text-lula-primary mr-2 cursor-pointer hover:underline"
              @click="
                $router.push({
                  name: 'Claims/ClaimAttachments',
                  query: { edit: true },
                })
              "
            >
              Edit
            </span>
          </div>
          <div v-for="detail in attachmentDetails" :key="detail.key">
            <div class="flex items-center bg-gray-100 rounded px-4 py-2">
              <DocumentIcon class="h-6 w-6 mr-4" />
              <div class="flex flex-col">
                <span class="font-bold">
                  {{ detail.name }}
                </span>
                <span class="text-gray-600">
                  {{ (detail.size / (1024 * 1024)).toFixed(2) }}MB
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="hidden lg:flex flex-col gap-2">
          <div class="flex justify-end items-center gap-4">
            <router-link :to="{ name: 'Claims/Landing' }" class="font-bold">
              Cancel
            </router-link>
            <button
              @click.prevent="submitReviewDetails"
              type="button"
              class="bg-lula-primary text-white"
              :disabled="isSubmitting"
            >
              Submit
            </button>
          </div>
          <span class="text-lula-primary font-bold text-sm" v-if="isSubmitting">
            Submitting claim, please wait...
          </span>
        </div>
      </div>
      <div class="w-full lg:w-2/3">
        <div class="bg-white rounded-lg text-gray-800 border p-4">
          <div class="flex justify-between items-center mb-2">
            <h2 class="font-bold text-gray-600">Claim Information</h2>
            <span
              class="font-bold text-lula-primary mr-2 cursor-pointer hover:underline"
              @click="
                $router.push({
                  name: 'Claims/ThirdPartyDetails',
                  query: { edit: 'true' },
                })
              "
            >
              Edit
            </span>
          </div>
          <div class="flex flex-col gap-4">
            <div class="grid grid-cols-2 gap-2">
              <div v-for="detail in claimantDetails" :key="detail.key">
                <div class="flex flex-col" :class="{ hidden: !detail.value }">
                  <label class="font-bold">{{ detail.label }}</label>
                  <span>{{ detail.value }}</span>
                </div>
              </div>
            </div>
            <div class="grid grid-cols-2 gap-2">
              <div v-for="detail in representativeDetails" :key="detail.key">
                <div class="flex flex-col" :class="{ hidden: !detail.value }">
                  <label class="font-bold">{{ detail.label }}</label>
                  <span>{{ detail.value }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-col gap-2 lg:hidden">
          <div class="flex justify-end items-center gap-4 mt-4">
            <router-link :to="{ name: 'Claims/Landing' }" class="font-bold">
              Cancel
            </router-link>
            <button
              @click.prevent="submitReviewDetails"
              type="button"
              class="bg-lula-primary text-white"
              :disabled="isSubmitting"
            >
              Submit
            </button>
          </div>
          <span class="text-lula-primary font-bold text-sm" v-if="isSubmitting">
            Submitting claim, please wait...
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { useToast } from "vue-toastification";
import { DocumentIcon } from "@heroicons/vue/24/solid";

export default {
  components: {
    DocumentIcon,
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      isSubmitting: false,
    };
  },
  computed: {
    vehicleDetails() {
      return [
        {
          label: "Vehicle",
          value:
            `${this.claimForm.vehicleYear ?? ""} ${this.claimForm.vehicleMake ?? ""} ${this.claimForm.vehicleModel ?? ""}`.trim(),
        },
        {
          label: "VIN Number",
          value: this.claimForm.vehicleVin,
        },
      ];
    },
    incidentDetails() {
      const incidentDate = moment(this.claimForm.incidentDate);
      if (this.claimForm.incidentTime) {
        const [hour, minute] = (this.claimForm.incidentTime ?? "").split(":");
        if (hour && minute) {
          incidentDate.hour(hour);
          incidentDate.minute(minute);
        }
      }
      return [
        {
          label: "Description",
          value: this.claimForm.description,
        },
        {
          label: "Incident Date",
          value: this.claimForm.incidentTime
            ? incidentDate.format("yyyy-MM-DD h:mm a")
            : incidentDate.format("yyyy-MM-DD"),
        },
        {
          label: "Incident Location",
          value: `
            ${this.claimForm.incidentLocationAddressLineOne} ${this.claimForm.incidentLocationAddressLineTwo}\n
            ${this.claimForm.incidentLocationCity} ${this.claimForm.incidentLocationZipcode} ${this.claimForm.incidentLocationState}
          `,
        },
      ];
    },
    attachmentDetails() {
      return (this.claimForm.attachments ?? []).map((att) => ({
        key: att.name,
        name: att.name,
        size: att.size,
      }));
    },
    claimantDetails() {
      return [
        {
          label: "Claimant Name",
          value: `${this.claimForm.claimantFirstName} ${this.claimForm.claimantLastName}`,
        },
        {
          label: "Claimant Email",
          value: `${this.claimForm.claimantEmail}`,
        },
        {
          label: "Claimant Phone",
          value:
            `${this.claimForm.claimantPhone} ${this.claimForm.claimantPhoneExtension ?? ""}`.trim(),
        },
        {
          label: "Claimant Address",
          value: `
            ${this.claimForm.claimantAddressLineOne} ${this.claimForm.claimantAddressLineTwo ?? ""}\n
            ${this.claimForm.claimantAddressCity} ${this.claimForm.claimantAddressZipcode ?? ""} ${this.claimForm.claimantAddressState ?? ""}
          `,
        },
        {
          label: "Claimant License",
          value:
            `${this.claimForm.claimantLicenseNumber ?? ""} ${this.claimForm.claimantLicenseState ?? ""}`.trim(),
        },
        {
          label: "Policy Number",
          value: `${this.claimForm.policyNumber}`,
        },
        {
          label: "Carrier Name",
          value: `${this.claimForm.policyCarrierName}`,
        },
        {
          label: "Carrier Contact Email",
          value: `${this.claimForm.policyCarrierEmail}`,
        },
        {
          label: "Carrier Contact Phone",
          value:
            `${this.claimForm.policyCarrierPhone} ${this.claimForm.policyCarrierPhoneExtension ?? ""}`.trim(),
        },
      ];
    },
    representativeDetails() {
      let party = this.claimForm.partyType ?? "";
      (() => {
        switch (party) {
          case "attorney": {
            party = "Attorney";
            break;
          }
          case "insurance-company": {
            party = "Insurance Company";
            break;
          }
          case "healthcare-provider": {
            party = "Healthcare Provider";
            break;
          }
          case "adjuster": {
            party = "Adjuster";
            break;
          }
          default: {
            party = party.toUpperCase();
          }
        }
      })();
      return [
        {
          label: `${party} Company`,
          value: `${this.claimForm.representativeCompany}`,
        },
        {
          label: `${party} Name`,
          value: `${this.claimForm.representativeName}`,
        },
        {
          label: `${party} Email`,
          value: `${this.claimForm.representativeEmail}`,
        },
        {
          label: `${party} Phone`,
          value:
            `${this.claimForm.representativePhone} ${this.claimForm.representativePhoneExtension ?? ""}`.trim(),
        },
        {
          label: `${party} Address`,
          value: `
            ${this.claimForm.representativeAddressLineOne} ${this.claimForm.representativeAddressLineTwo ?? ""}\n
            ${this.claimForm.representativeCity} ${this.claimForm.representativeZipcode ?? ""} ${this.claimForm.representativeState ?? ""}
          `.trim(),
        },
      ];
    },
    claimForm() {
      return this.$store.state.claimForm ?? {};
    },
  },
  methods: {
    async submitReviewDetails() {
      this.isSubmitting = true;
      this.toast.clear();
      this.toast("Submitting Claim");
      try {
        const claim = await this.$store.dispatch("createThirdPartyClaim");
        const accountId = this.$store.state.claimForm?.accountId;
        await Promise.all(
          (this.$store.state.claimForm?.attachments ?? []).map((file) =>
            this.$store.dispatch("createThirdPartyClaimAttachment", {
              claimId: claim.id,
              accountId,
              file,
            }),
          ),
        );
        await this.$store.dispatch("submitThirdPartyClaim", {
          claimId: claim.id,
          tenantId: accountId,
        });
        this.toast.clear();
        this.toast("Claim Submitted");
      } catch (err) {
        this.toast.clear();
        this.toast.error("Failed to Submit Claim");
        throw err;
      }
      this.isSubmitting = false;
      this.$router.push({ name: "Claims/Confirmation" });
    },
  },
};
</script>
