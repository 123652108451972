<template>
  <form
    @submit.prevent="submitClaimLookup"
    class="flex min-h-screen items-center justify-center text-gray-800 p-4"
  >
    <div class="flex flex-col w-full md:w-3/4 lg:w-2/3 xl:w-1/3">
      <div class="mb-8">
        <h1 class="text-lula-alt font-bold text-lg">View claim status</h1>
        <span class="text-4xl font-bold"> Details about your claim </span>
      </div>
      <div class="bg-white rounded-lg border p-4 py-8">
        <div class="mb-8">
          <h2 class="font-bold text-2xl">Let us know who you are</h2>
          <p>
            To access your claim, we need a little bit of information about
            yourself.
          </p>
        </div>
        <div class="flex flex-col gap-4 text-lg">
          <div>
            <label class="font-bold"> Claim number </label>
            <input
              class="mt-1 bg-white border rounded-lg text-lg hover:bg-white"
              type="text"
              minlength="1"
              maxlength="50"
              required
              placeholder="Claim Number"
              v-model="claimNumber"
            />
          </div>
          <span class="ml-2 text-sm text-lula-primary font-bold">{{
            message
          }}</span>
        </div>
      </div>
      <div class="flex justify-end items-center mt-4 gap-4">
        <router-link :to="{ name: 'Claims/Landing' }" class="font-bold">
          Cancel
        </router-link>
        <button type="submit" class="bg-lula-primary text-white">Next</button>
      </div>
    </div>
  </form>
</template>

<script>
export default {
  watch: {
    claimNumber() {
      this.message = "";
    },
  },
  data() {
    return {
      claimNumber: "",
      message: "",
    };
  },
  methods: {
    async submitClaimLookup() {
      const claimNumber = (this.claimNumber ?? "").trim().toUpperCase();
      if (!claimNumber) return;
      this.message = "Searching for claim...";
      try {
        const claimLookup = await this.$store.dispatch("getClaimLookup", {
          claimNumber,
        });
        if (!claimLookup) {
          setTimeout(() => (this.message = "Cound not find claim."), 1000);
          return;
        }
        this.$store.commit("setLookupClaim", claimLookup);
      } catch (err) {
        setTimeout(() => (this.message = "Error finding claim."), 1000);
        throw err;
      }
      this.$router.push({ name: "Claims/ClaimDetails" });
    },
  },
};
</script>
