<template>
  <form
    @submit.prevent="submitVehicleLookup"
    class="flex min-h-screen items-center justify-center text-gray-800 p-4"
  >
    <div class="flex flex-col w-full md:w-3/4 lg:w-2/3 xl:w-1/3">
      <div class="mb-8">
        <h1 class="text-lula-alt font-bold text-lg">Report a Claim</h1>
        <span class="text-4xl font-bold">
          Before we get started, we need some quick info.
        </span>
      </div>
      <div class="bg-white rounded-lg border p-4 py-8">
        <div class="mb-8">
          <h2 class="font-bold text-2xl">
            Information about the GetAddify policy
          </h2>
          <p>
            To process your claim, we need some information about the GetAddify
            policy holder.
          </p>
        </div>
        <div class="flex flex-col gap-4 text-lg">
          <div>
            <label class="font-bold"> VIN Number </label>
            <input
              class="mt-1 bg-white border rounded-lg text-lg hover:bg-white"
              type="text"
              minlength="17"
              maxlength="17"
              required
              placeholder="17 digit VIN number"
              v-model="vin"
            />
          </div>
          <div>
            <label class="font-bold"> Incident Date </label>
            <input
              class="mt-1 bg-white border rounded-lg text-lg hover:bg-white"
              type="date"
              :max="maxIncidentDate"
              v-model="incidentDate"
              required
            />
          </div>
          <div v-if="getLaunchDarklyFlag('Claims.DuplicateClaim')">
            <label class="font-bold"> Claimant Email </label>
            <input
              class="mt-1 bg-white border rounded-lg text-lg hover:bg-white"
              type="text"
              required
              placeholder="Claimant Email"
              v-model="claimantEmail"
            />
          </div>
          <span class="ml-2 text-sm text-lula-primary font-bold">{{
            message
          }}</span>
        </div>
      </div>
      <div class="flex justify-end items-center mt-4 gap-4">
        <router-link :to="{ name: 'Claims/Landing' }" class="font-bold">
          Cancel
        </router-link>
        <button type="submit" class="bg-lula-primary text-white">Submit</button>
      </div>
    </div>
  </form>
</template>

<script>
import moment from "moment";
import axios from "axios";

async function getVehicleLookup(vin) {
  try {
    const lookup = await axios({
      method: "GET",
      url: `https://vpic.nhtsa.dot.gov/api/vehicles/DecodeVin/${vin}?format=JSON`,
    });

    const results = lookup.data["Results"];

    const make = results.find((x) => x["Variable"] === "Make");
    const model = results.find((x) => x["Variable"] === "Model");
    const year = results.find((x) => x["Variable"] === "Model Year");

    return {
      make: make?.Value ?? null,
      model: model?.Value ?? null,
      year: year?.Value ?? null,
    };
  } catch (err) {
    console.log(`[VEH] Error getting vehicle lookup for ${vin}: ${err}`);
    return {
      make: null,
      model: null,
      year: null,
    };
  }
}

export default {
  computed: {
    maxIncidentDate() {
      return moment().local().format("yyyy-MM-DD");
    },
  },
  watch: {
    vin() {
      this.message = "";
    },
  },
  data() {
    return {
      vin: "",
      incidentDate: moment().format("yyyy-MM-DD"),
      claimantEmail: "",
      message: "",
    };
  },
  methods: {
    async submitVehicleLookup() {
      const vin = (this.vin ?? "").trim().toUpperCase();
      if (!vin) return;
      this.message = "Searching for vehicle...";
      try {
        const vehicle = await getVehicleLookup(vin);
        const lookup = await this.$store.dispatch("getClaimsVehicleLookup", {
          incidentDate: this.incidentDate,
          vin,
        });
        console.log(
          `[VEH] submitVehicleLookup: accountId(${lookup.accountId}), vehicleId(${lookup.vehicleId})`,
        );
        const duplicateClaimCheckEnabled = await this.$store.dispatch(
          "getLaunchDarklyFlag",
          "Claims.DuplicateClaim",
        );
        if (duplicateClaimCheckEnabled) {
          const claimLookup = await this.$store.dispatch("getClaimLookup", {
            claimNumber: this.claimantEmail,
            incidentDate: this.incidentDate,
            vehicleId: lookup.vehicleId,
          });
          if (claimLookup) {
            this.$store.commit("setLookupClaim", claimLookup);
            this.$router.push({
              name: "Claims/ClaimDetails",
              query: { isDuplicateClaim: true },
            });
            return;
          }
        }
        if (!lookup.accountId || !lookup.vehicleId) {
          setTimeout(() => (this.message = "Cound not find vehicle."), 5000);
          return;
        }
        this.$store.commit("setClaimFormItems", {
          accountId: lookup.accountId,
          vehicleVin: vin,
          vehicleYear: vehicle.year,
          vehicleMake: vehicle.make,
          vehicleModel: vehicle.model,
          vehicleId: lookup.vehicleId,
          incidentDate: this.incidentDate,
        });
      } catch (err) {
        setTimeout(() => (this.message = "Error finding vehicle."), 1000);
        throw err;
      }
      this.$router.push({ name: "Claims/ClaimSteps" });
    },
  },
};
</script>
