import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent, setUserId } from "firebase/analytics";
import {
  getStorage,
  ref,
  uploadBytes,
  getDownloadURL,
  listAll,
  getMetadata,
  updateMetadata,
} from "firebase/storage";

function getFileNameList(path) {
  return new Promise((resolve, reject) => {
    const storage = getStorage();
    const listRef = ref(storage, path);
    listAll(listRef)
      .then(async (res) => {
        if (res.items.length) {
          resolve(
            await Promise.all(res.items.map((fileRef) => getMetadata(fileRef))),
          );
        } else {
          resolve([]);
        }
      })
      .catch((err) => reject(err));
  });
}

async function getFileCustomMetadata(storagePath, targetFilePath) {
  const storage = getStorage();
  const storageRef = ref(storage, storagePath);
  const listResult = await listAll(storageRef);
  const targetFileRef = listResult.items.find(
    (item) => item.fullPath === targetFilePath,
  );

  if (!targetFileRef) {
    return null;
  }

  const fullMetadata = await getMetadata(targetFileRef);

  return fullMetadata.customMetadata;
}

async function isFileAvailable(storagePath, targetFilePath) {
  const storage = getStorage();
  const storageRef = ref(storage, storagePath);
  const listResult = await listAll(storageRef);
  const filePaths = listResult.items.map((item) => item.fullPath);

  return filePaths.includes(targetFilePath);
}

async function saveFile(path, file) {
  const storage = getStorage();
  const storageRef = ref(storage, path);
  const snap = await uploadBytes(storageRef, file);
  return snap;
}

function dispatchEvent(eventName, data) {
  const analytics = getAnalytics(app);
  if (data.uid) {
    setUserId(analytics, data.uid);
  }
  logEvent(analytics, eventName, data);
}

async function getFileDownloadUrl(path) {
  const storage = getStorage();
  const fileRef = ref(storage, path);
  const url = await getDownloadURL(fileRef);
  return url;
}

async function saveFileMeta(path, meta = {}) {
  const storage = getStorage();
  const storageRef = ref(storage, path);
  const update = await updateMetadata(storageRef, meta);
  return update;
}

export {
  saveFile,
  getFileNameList,
  getFileDownloadUrl,
  dispatchEvent,
  saveFileMeta,
  isFileAvailable,
  getFileCustomMetadata,
};

const app = initializeApp({
  apiKey: process.env["VUE_APP_FIREBASE_APIKEY"],
  authDomain: process.env["VUE_APP_FIREBASE_AUTH_DOMAIN"],
  projectId: process.env["VUE_APP_FIREBASE_PROJECT_ID"],
  storageBucket: process.env["VUE_APP_FIREBASE_STORAGE_BUCKET"],
  messagingSenderId: process.env["VUE_APP_FIREBASE_MESSAGING_SENDER_ID"],
  appId: process.env["VUE_APP_FIREBASE_APP_ID"],
  measurementId: process.env["VUE_APP_FIREBASE_MEASUREMENT_ID"],
});

export default app;
