<template>
  <div class="h-screen flex justify-center items-center px-2">
    <div
      class="bg-white rounded-lg shadow-lg p-4 border-b-4 border-lula w-full md:w-2/3"
    >
      <h1 class="text-2xl text-gray-800 font-bold">Account not ready</h1>
      <span class="font-bold text-gray-600">
        We're sorry, but your account is not setup at the moment
      </span>
      <div class="flex flex-wrap-reverse mt-2 sm:flex-nowrap">
        <button
          class="transition w-full border rounded-lg m-1 hover:bg-gray-100"
          @click="$store.dispatch('signOut')"
        >
          Logout
        </button>
        <button
          class="transition w-full border rounded-lg m-1 hover:bg-gray-100"
          @click="contactSupport"
        >
          Contact Support
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { useStore } from "vuex";
import { mapSystemSettings } from "@/utils";

export default {
  setup() {
    const store = useStore();
    const settings = ref({});

    onMounted(async () => {
      settings.value = mapSystemSettings(
        await store.dispatch("getSystemSettings"),
      );
    });

    return {
      settings,
      contactSupport() {
        window.open(settings.value["truckingsupportlink"], "_blank");
      },
    };
  },
};
</script>
