<template>
  <div class="w-full" v-if="claim">
    <div class="bg-green-50 rounded-lg w-full p-4">
      <div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-20 w-20 text-lula-success"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fill-rule="evenodd"
            d="
              M10 18a8 8 0 100-16 8 8 0 000
              16zm3.707-9.293a1 1 0 00-1.414-1.414L9
              10.586 7.707 9.293a1 1 0 00-1.414
              1.414l2 2a1 1 0 001.414 0l4-4z"
            clip-rule="evenodd"
          />
        </svg>
      </div>
      <div class="p-4">
        <h1 class="text-4xl font-bold text-gray-700 mb-1">
          Claim successfully filed.
        </h1>
        <span class="text-gray-600 font-bold" v-if="claim.documentId">
          GetAddify Claim ID: {{ claim.documentId.toUpperCase() }}
        </span>
        <div class="mt-4 text-xl text-gray-600">
          <p class="my-4">Don’t worry, we’re on it!</p>
          <p class="my-4" v-if="claim.modifiedBy">
            Meanwhile we’ve sent a confirmation message to
            {{ maskEmail(claim.modifiedBy) }}
          </p>
        </div>
      </div>
    </div>
    <div class="p-8 bg-white rounded-lg mt-8" v-if="nextSteps.length > 0">
      <h2 class="text-gray-800 text-3xl font-bold mb-6">Next Steps</h2>
      <ol>
        <li v-for="st in nextSteps" :key="st">
          <div class="flex items-center text-lg text-gray-600 my-4 md:my-0">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="hidden h-12 w-12 text-lula mr-4 md:block"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M13 7l5 5m0 0l-5 5m5-5H6"
              />
            </svg>
            <p>
              {{ st }}
            </p>
          </div>
        </li>
      </ol>
    </div>
    <div class="flex justify-between mt-4">
      <span />
      <button
        @click="
          $router.push({ name: `${$store.state.account.type}/Dashboard` })
        "
        class="bg-lula-gradient text-white font-bold hover:bg-lula-gradient-alt"
      >
        Return to Dashboard
      </button>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { maskEmail } from "@/utils";

export default {
  setup() {
    const store = useStore();
    const route = useRoute();

    return {
      maskEmail,
      claim: computed(() => {
        return store.state.claims.find((c) => c.entityId === route.params.id);
      }),
      nextSteps: computed(() => {
        return (
          store.state.settings.find((st) => {
            if (st.key === "carsclaimsteps") {
              return st.value;
            }
            return false;
          })?.value || ""
        )
          .split("|")
          .filter((x) => x);
      }),
    };
  },
};
</script>
