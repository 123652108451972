export const Providers = Object.freeze({
  Bouncie: { id: "bouncie", name: "Bouncie" },
  Zubie: { id: "zubie", name: "Zubie" },
  Samsara: { id: "samsara", name: "Samsara" },
  Motive: { id: "motive", name: "Motive" },
});

async function processOauthFlow(provider, queryParameters, store) {
  const accessCode = queryParameters?.code;

  if (!accessCode) {
    console.warn(
      `[TEL] ${provider.name} connection failed. Cannot get the accessCode. Details:`,
      queryParameters,
    );
    return false;
  }

  const account = await store.dispatch("getAccount");

  const requestInfo = {
    tenantId: account.entityId,
    accessCode: accessCode,
  };

  const response = await store.dispatch("createTelematicsIntegration", {
    providerId: provider.id,
    requestInfo,
  });

  if (!response.success) {
    console.warn(
      `[TEL] Cannot create the ${provider.name} integration. API error:`,
      response.error,
    );
    return false;
  }

  console.info(
    `[TEL] ${provider.name} integration was successfully created:`,
    response.data,
  );

  return true;
}

export async function connectIntegration(provider, queryParameters, store) {
  switch (provider.id) {
    case Providers.Bouncie.id:
    case Providers.Zubie.id:
      return processOauthFlow(provider, queryParameters, store);
    default:
      console.error("[TEL] Unknown telematics provider:", provider);
      return false;
  }
}
