<template>
  <div class="flex min-h-screen items-center justify-center text-gray-800 p-4">
    <div class="w-full bg-white rounded-lg border p-4 lg:w-1/3">
      <div class="flex flex-col items-center justify-center">
        <CheckCircleIcon class="text-lula-primary fill-current w-24 h-24" />
        <h1 class="font-bold text-2xl">We've received your claim!</h1>
        <p>You will receive updates at {{ contactEmail }}</p>
      </div>
      <hr class="m-auto my-4 w-2/3" />
      <div>
        <span class="text-md font-bold"> Here what you can expect next: </span>
        <ul class="text-gray-600">
          <li class="my-2" v-for="step in nextSteps" :key="step">
            {{ step }}
          </li>
        </ul>
      </div>
      <div class="flex justify-center mt-8">
        <router-link :to="{ name: 'Claims/Landing' }">
          <button type="button" class="bg-lula-primary text-white">
            Go Back
          </button>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { CheckCircleIcon } from "@heroicons/vue/24/solid";
export default {
  components: { CheckCircleIcon },
  computed: {
    contactEmail() {
      return this.$store.state.claimForm?.claimantEmail ?? "";
    },
    nextSteps() {
      return `
        Your claim submission will be reviewed by the GetAddify Team for completeness and accuracy, and the information will be forwarded to our external claims adjusters. (GetAddify does not process claims)|When a claim adjuster is assigned, you will receive an email with their information and the claim number.|The claim adjuster will be your primary point of contact for your claim. They will request any additional information needed to process your claim. Any questions concerning your claim or obtaining status should be directed to the adjuster handling your claim.
      `.split("|");
    },
  },
};
</script>
