<template>
  <div class="h-screen w-screen flex justify-center items-center">
    <div class="w-1/2 h-1/2 -mt-16 md:w-1/4">
      <div class="relative">
        <img
          class="rounded-3xl shadow-lg w-full h-full"
          src="@/assets/loader-background.png"
        />
        <img class="absolute bottom-0" src="@/assets/loader-animation.svg" />
      </div>
    </div>
  </div>
</template>
