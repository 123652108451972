<template>
  <div class="w-full py-8">
    <div class="w-full bg-white rounded-md border p-4">
      <h1 class="font-bold text-gray-800 text-2xl">Contact Details</h1>
      <hr class="my-2" />
      <form @submit.prevent="saveContact">
        <div class="grid grid-cols-1 md:grid-cols-2">
          <div class="p-1" v-for="detail in contactDetails" :key="detail.key">
            <label class="text-gray-800 font-bold">
              {{ detail.label }}
            </label>
            <br />
            <input
              :disabled="detail.disabled"
              :type="detail.type"
              v-model="account[detail.key]"
            />
          </div>
        </div>
        <div class="flex justify-end mt-2">
          <button
            type="submit"
            class="bg-lula-gradient text-white font-bold hover:bg-lula-gradient-alt"
          >
            Save Contact
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { useStore } from "vuex";
import { useToast } from "vue-toastification";

function getAccountObject(account) {
  return account ? { ...account } : {};
}

const contactDetails = [
  {
    label: "First Name",
    key: "contactFirstName",
    type: "text",
    disabled: false,
  },
  { label: "Last Name", key: "contactLastName", type: "text", disabled: false },
  {
    label: "Phone Number",
    key: "contactNumber",
    type: "text",
    disabled: false,
  },
  { label: "Email Address", key: "contactEmail", type: "text", disabled: true },
  {
    label: "Title / Position",
    key: "contactPosition",
    type: "text",
    disabled: false,
  },
];

export default {
  setup() {
    const store = useStore();
    const toast = useToast();

    const account = ref(getAccountObject(store.state.account));
    onMounted(() => store.dispatch("getMetrics"));

    return {
      account,
      contactDetails,
      async saveContact() {
        toast("Saving contact.");
        try {
          const saveDetails = {};
          contactDetails.forEach((detail) => {
            saveDetails[detail.key] = account.value[detail.key];
          });
          account.value = await store.dispatch(
            "saveDetailsForAccount",
            saveDetails,
          );
          toast.clear();
          toast("Contact saved.");
        } catch (err) {
          toast.clear();
          toast("Failed to saved contact.");
        }
      },
    };
  },
};
</script>
