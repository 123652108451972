<template>
  <div class="w-full text-gray-800">
    <div class="w-full">
      <div class="bg-white border rounded-md p-4">
        <div class="w-full flex justify-between items-center pb-2">
          <h1 class="font-bold text-4xl">File claims directly with Athens Insurance Services</h1>
        </div>
        <div class="pb-2">
          <span class="pb-1"
            >In order to serve you better and make sure claims are processed sooner, we are asking our customers to file directly with Athens Insurance Services, Inc. They will be able to answer any claims inquiries.
          </span>
        </div>
        <div class="pb-2">
          <span class="">File a claim by emailing
            <a href="mailto:concertaddifyrental@athensadmin.com">
              concertaddifyrental@athensadmin.com
            </a>
            or calling
            <a href="tel:1-888-534-6375">1-888-534-6375</a>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Claims',
};
</script>
