<template>
  <div class="w-full py-8">
    <div class="flex justify-between items-center">
      <h1 class="font-bold text-gray-800 text-4xl">Drivers</h1>
      <button
        @click="showNewDriver = true"
        class="hidden bg-lula-gradient font-bold text-white hover:bg-lula-gradient-alt sm:block"
      >
        Request new Driver
      </button>
    </div>
    <div class="mt-8">
      <h2 class="font-bold text-gray-800 text-2xl mb-2">Approved Drivers</h2>
      <div class="w-full bg-white rounded-md border mt-2 p-4">
        <p class="text-center text-gray-600" v-if="drivers.length === 0">
          No Drivers
        </p>
        <table class="w-full" v-else>
          <tr
            class="text-left cursor-pointer font-bold text-gray-600 border-b text-gray-800"
          >
            <th class="font-bold pb-2">Driver</th>
            <th class="hidden pb-2 font-normal md:table-cell">
              License Number
            </th>
          </tr>
          <tr
            @click="selectDriver(driver.entityId)"
            class="text-gray-600 cursor-pointer hover:bg-gray-100"
            v-for="driver in drivers"
            :key="driver.entityId"
          >
            <td class="py-4">
              <div class="flex items-center ml-2">
                <img
                  alt="driver icon"
                  src="../assets/driver-placeholder-icon.svg"
                />
                <div class="ml-2">
                  <span class="font-bold">
                    {{ driver.name }}
                  </span>
                  <br class="md:hidden" />
                  <span
                    class="text-green-500 font-bold md:hidden"
                    v-if="driver.status === 'Active'"
                  >
                    {{ driver.status }}
                  </span>
                  <span class="text-gray-600 font-bold md:hidden" v-else>
                    {{ driver.status }}
                  </span>
                </div>
              </div>
            </td>
            <td class="hidden py-4 md:table-cell">
              {{ driver.license }}
            </td>
          </tr>
          <tr
            class="cursor-pointer text-gray-600 hover:bg-gray-100"
            @click="driverIdx += 5"
            v-if="driverIdx <= drivers.length - 1"
          >
            <td class="px-4 py-2">View More</td>
            <td class="hidden px-4 py-2 md:table-cell" />
            <td class="hidden px-4 py-2 md:table-cell" />
          </tr>
        </table>
      </div>
      <button
        @click="showNewDriver = true"
        class="w-full mt-2 bg-lula-gradient font-bold text-white hover:bg-lula-gradient-alt sm:hidden"
      >
        Request new Driver
      </button>
    </div>
    <hr class="my-8" />
    <div class="flex flex-col lg:flex-row">
      <div class="w-full mt-4 lg:mt-0">
        <h2 class="text-xl mb-2 font-bold text-gray-800">Pending Drivers</h2>
        <div class="w-full bg-white rounded-md border p-4 lg:mr-2">
          <p
            class="text-center text-gray-600"
            v-if="pendingDrivers.length === 0"
          >
            No Drivers
          </p>
          <table class="w-full" v-else>
            <tr
              class="text-left cursor-pointer font-bold text-gray-600 border-b text-gray-800"
            >
              <th class="font-bold pb-2">Driver</th>
              <th class="hidden pb-2 font-normal md:table-cell">Status</th>
            </tr>
            <tr
              @click="selectDriver(driver.entityId)"
              class="text-gray-600 cursor-pointer hover:bg-gray-100"
              v-for="driver in pendingDrivers"
              :key="driver.entityId"
            >
              <td class="py-4">
                <div class="flex items-center ml-2">
                  <img
                    alt="driver icon"
                    src="../assets/driver-placeholder-icon.svg"
                  />
                  <div class="ml-2">
                    <span class="font-bold">
                      {{ driver.name }}
                    </span>
                    <br />
                    <span class="hidden text-gray-600 md:inline">
                      {{ driver.license }}
                    </span>
                    <div
                      class="flex text-lula items-center md:hidden"
                      v-if="driver.insuranceCriteriaStatus === 'Under Review'"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-5 w-5"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                        />
                      </svg>
                      <span class="font-bold ml-1">Under Review</span>
                    </div>
                    <div
                      class="md:hidden"
                      v-else-if="driver.status === 'Inactive'"
                    >
                      <span class="ml-1 font-bold"> Inactive </span>
                    </div>
                  </div>
                </div>
              </td>
              <td class="hidden py-4 md:table-cell">
                <div
                  class="flex text-lula items-center"
                  v-if="driver.insuranceCriteriaStatus === 'Under Review'"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                    />
                  </svg>
                  <span class="ml-1 font-bold">Under Review</span>
                </div>
                <div
                  class="hidden md:block"
                  v-else-if="driver.status === 'Inactive'"
                >
                  <span class="ml-1 font-bold"> Inactive </span>
                </div>
              </td>
            </tr>
            <tr
              class="cursor-pointer text-gray-600 hover:bg-gray-100"
              @click="pendingDriverIdx += 5"
              v-if="pendingDriverIdx <= pendingDrivers.length - 1"
            >
              <td class="px-4 py-2">View More</td>
              <td class="hidden px-4 py-2 md:table-cell" />
            </tr>
          </table>
        </div>
      </div>
      <div class="w-full mt-4 lg:mt-0 lg:ml-2">
        <h2 class="text-xl mb-2 font-bold text-gray-800">Declined Drivers</h2>
        <div class="w-full bg-white rounded-md border p-4">
          <p
            class="text-center text-gray-600"
            v-if="declinedDrivers.length === 0"
          >
            No Drivers
          </p>
          <table class="w-full" v-else>
            <tr
              class="text-left cursor-pointer font-bold text-gray-600 border-b text-gray-800"
            >
              <th class="font-bold pb-2">Driver</th>
              <th class="hidden pb-2 font-normal md:table-cell">Status</th>
            </tr>
            <tr
              @click="selectDriver(driver.entityId)"
              class="text-gray-600 cursor-pointer hover:bg-gray-100"
              v-for="driver in declinedDrivers"
              :key="driver.entityId"
            >
              <td class="py-4">
                <div class="flex items-center ml-2">
                  <img
                    alt="driver icon"
                    src="../assets/driver-placeholder-icon.svg"
                  />
                  <div class="ml-2">
                    <span class="font-bold">
                      {{ driver.name }}
                    </span>
                    <br />
                    <span class="hidden text-gray-600 md:inline">
                      {{ driver.license }}
                    </span>
                    <div class="flex items-center text-red-500 md:hidden">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                      <span class="ml-1 font-bold"> Declined </span>
                    </div>
                  </div>
                </div>
              </td>
              <td class="hidden py-4 text-red-500 md:table-cell">
                <div class="flex items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                  <span class="ml-1 font-bold"> Declined </span>
                </div>
              </td>
            </tr>
            <tr
              class="cursor-pointer text-gray-600 hover:bg-gray-100"
              @click="declinedDriverIdx += 5"
              v-if="declinedDriverIdx <= declinedDrivers.length - 1"
            >
              <td class="px-4 py-2">View More</td>
              <td class="hidden px-4 py-2 md:table-cell" />
            </tr>
          </table>
        </div>
      </div>
    </div>
    <modal v-if="showNewDriver">
      <div
        class="relative w-full h-full overflow-scroll max-w-screen-xl bg-white rounded-md p-4 border md:w-2/3 lg:w-1/2 md:h-auto"
      >
        <div class="mb-2">
          <h2 class="font-bold text-gray-800 text-2xl md:text-3xl">
            Request new Driver
          </h2>
        </div>
        <div v-if="confirmDrivers">
          <table
            :class="{ 'opacity-0': loading }"
            class="w-full text-lg text-left text-gray-800"
          >
            <tr class="border-b">
              <th class="py-2">Driver</th>
              <th class="hidden text-gray-600 py-2 font-normal md:table-cell">
                Date of Hire
              </th>
              <th class="hidden text-gray-600 py-2 font-normal md:table-cell">
                License
              </th>
            </tr>
            <tr
              class="text-gray-600"
              v-for="driver in newDrivers"
              :key="driver.license"
            >
              <th class="py-4">
                <div class="pl-4 flex items-center">
                  <img
                    alt="driver icon"
                    src="../assets/driver-placeholder-icon.svg"
                  />
                  <div class="flex flex-col">
                    <span class="ml-2">
                      {{ driver.firstName }} {{ driver.lastName }}
                    </span>
                    <span class="ml-2 font-normal md:hidden">
                      {{ driver.license }}
                    </span>
                  </div>
                </div>
              </th>
              <th class="hidden py-4 font-normal md:table-cell">
                {{ formatDriverDate(driver.dateOfHire) }}
              </th>
              <th class="hidden py-4 font-normal md:table-cell">
                {{ driver.license }}
              </th>
            </tr>
          </table>
          <div
            @click="confirmDrivers = false"
            :class="{ 'opacity-0': loading }"
            class="flex items-center bg-gray-50 cursor-pointer text-lg font-bold text-gray-600 p-4 rounded-md hover:bg-gray-100"
          >
            <img
              alt="driver icon"
              src="../assets/driver-placeholder-icon.svg"
            />
            <div class="flex flex-col">
              <span class="ml-2"> Request Additional Driver </span>
            </div>
          </div>
          <div
            class="flex justify-end flex-col mt-2 md:flex-row"
            :class="{ 'opacity-0 md:opacity-100': loading }"
          >
            <button
              type="button"
              :class="{
                'bg-gray-100 hover:bg-gray-200': !loading,
                'bg-gray-100 cursor-default text-gray-500': loading,
              }"
              :disabled="loading"
              @click="showNewDriver = false"
            >
              Cancel
            </button>
            <button
              @click="requestDrivers"
              :disabled="loading"
              :class="{
                'bg-lula-gradient text-white hover:bg-lula-gradient-alt':
                  !loading,
                'bg-gray-100 cursor-default text-gray-500': loading,
              }"
              class="mt-1 md:ml-2 md:mt-0"
            >
              Request Drivers
            </button>
          </div>
          <Loader v-if="loading" />
        </div>
        <form @submit.prevent="addDriver" v-else>
          <div class="grid grid-cols-1 text-lg md:grid-cols-2">
            <div
              class="m-1"
              :class="{ 'opacity-0': loading }"
              v-for="detail in driverDetails"
              :key="detail.key"
            >
              <label class="font-bold text-gray-800">
                {{ detail.label }}
              </label>
              <br />
              <state-select
                v-model="newDriver[detail.key]"
                v-if="detail.key === 'state'"
                required
              />
              <input
                v-model="newDriver[detail.key]"
                type="number"
                min="0"
                required
                v-else-if="detail.key === 'licenseYears'"
              />
              <input
                v-model="newDriver[detail.key]"
                :type="detail.type"
                required
                v-else
              />
            </div>
            <Loader v-if="loading" />
          </div>
          <div
            @click="confirmDrivers = true"
            v-if="newDrivers.length > 0"
            :class="{ 'opacity-0': loading }"
            class="bg-gray-50 text-lg rounded-md p-4 cursor-pointer font-bold mx-1 my-2 text-gray-600 hover:bg-gray-100"
          >
            Return to new drivers
          </div>
          <div class="flex justify-end flex-col mt-2 md:flex-row">
            <button
              type="button"
              :class="{
                'bg-gray-100 hover:bg-gray-200': !loading,
                'bg-gray-100 cursor-default text-gray-500': loading,
              }"
              :disabled="loading"
              @click="showNewDriver = false"
            >
              Cancel
            </button>
            <button
              type="submit"
              :disabled="loading || !canRequestNewDriver"
              :class="{
                'bg-lula-gradient text-white hover:bg-lula-gradient-alt':
                  !loading && canRequestNewDriver,
                'bg-gray-100 cursor-default text-gray-500':
                  loading || !canRequestNewDriver,
              }"
              class="mt-1 md:ml-2 md:mt-0"
            >
              Add Driver
            </button>
          </div>
        </form>
      </div>
    </modal>
  </div>
</template>

<script>
import { computed, ref, watch } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { useToast } from "vue-toastification";
import { Loader } from "@getaddify/lula-components";
import moment from "moment";

const driverDetails = [
  { label: "First Name", key: "firstName", type: "text" },
  { label: "Last Name", key: "lastName", type: "text" },
  { label: "Date of Birth", key: "dateOfBirth", type: "date" },
  { label: "Date of Hire", key: "dateOfHire", type: "date" },
  { label: "CDL Number", key: "license", type: "text" },
  { label: "CDL Class", key: "class", type: "text" },
  { label: "Years with CDL", key: "licenseYears", type: "number" },
  { label: "CDL State", key: "state", type: "text" },
];

function getDriverObject(details, account) {
  const driver = {};
  details.forEach((detail) => {
    switch (detail.key) {
      case "state": {
        driver[detail.key] = account.state;
        break;
      }
      case "licenseYears": {
        driver[detail.key] = 0;
        break;
      }
      case "text":
      default: {
        driver[detail.key] = "";
      }
    }
  });
  return driver;
}

export default {
  components: { Loader },
  setup() {
    const store = useStore();
    const router = useRouter();
    const toast = useToast();

    const showNewDriver = ref(false);
    const loading = ref(false);

    const driverIdx = ref(4);
    const pendingDriverIdx = ref(4);
    const declinedDriverIdx = ref(4);

    const confirmDrivers = ref(false);
    const newDriver = ref(getDriverObject(driverDetails, store.state.account));
    const newDrivers = ref([]);
    watch(showNewDriver, () => {
      newDriver.value = getDriverObject(driverDetails, store.state.account);
      newDrivers.value = [];
    });

    return {
      loading,
      showNewDriver,
      driverDetails,
      newDriver,
      canRequestNewDriver: computed(() => {
        return (
          newDriver.value.firstName.trim() &&
          newDriver.value.lastName.trim() &&
          newDriver.value.license.trim() &&
          newDriver.value.licenseYears !== null &&
          newDriver.value.dateOfBirth !== null &&
          newDriver.value.dateOfHire !== null
        );
      }),
      driverIdx,
      drivers: computed(() => {
        return store.state.drivers
          .filter((driver) => {
            return (
              driver.insuranceCriteriaStatus === "Approved" &&
              driver.status === "Active"
            );
          })
          .map((driver) => {
            return {
              ...driver,
              name: `${driver.firstName} ${driver.lastName}`,
            };
          })
          .filter((_, idx) => idx <= driverIdx.value);
      }),
      pendingDriverIdx,
      pendingDrivers: computed(() => {
        return store.state.drivers
          .filter((driver) => {
            return (
              driver.insuranceCriteriaStatus === "Under Review" ||
              (driver.insuranceCriteriaStatus === "Approved" &&
                driver.status === "Inactive")
            );
          })
          .map((driver) => {
            return {
              ...driver,
              name: `${driver.firstName} ${driver.lastName}`,
            };
          })
          .sort((a) => (a.insuranceCriteriaStatus === "Under Review" ? -1 : 1))
          .filter((_, idx) => idx <= pendingDriverIdx.value);
      }),
      declinedDriverIdx,
      declinedDrivers: computed(() => {
        return store.state.drivers
          .filter((driver) => {
            return driver.insuranceCriteriaStatus === "Declined";
          })
          .map((driver) => {
            return {
              ...driver,
              name: `${driver.firstName} ${driver.lastName}`,
            };
          })
          .filter((_, idx) => idx <= declinedDriverIdx.value);
      }),
      selectDriver(driverId) {
        router.push({
          name: `${store.state.account.type}/Driver`,
          params: { id: driverId },
        });
      },
      async driverStatusChange(driver) {
        const driverStatus = driver.status;
        try {
          toast(
            `${driverStatus === "Inactive" ? "Deactivating" : "Activating"} driver.`,
          );
          const savedDriver = await store.dispatch("saveDriverForAccount", {
            entityId: driver.entityId,
            status: driverStatus === "Inactive" ? "Inactive" : "Active",
          });
          await store.dispatch("getDrivers");
          toast.clear();
          toast(
            `Driver ${savedDriver.status === "Active" ? "activated" : "deactivated"}.`,
          );
        } catch (err) {
          toast.clear();
          toast(
            `Failed to ${driverStatus === "Active" ? "deactivate" : "activate"} driver.`,
          );
        }
      },
      confirmDrivers,
      newDrivers,
      formatDriverDate(dateString) {
        return moment(dateString, "yyyy-MM-DD").format("MM/DD/yyyy");
      },
      addDriver() {
        newDrivers.value = [...newDrivers.value, newDriver.value];
        confirmDrivers.value = true;
        newDriver.value = getDriverObject(driverDetails, store.state.account);
      },
      async requestDrivers() {
        try {
          loading.value = true;
          toast(
            `Requesting ${newDrivers.value.length > 1 ? "drivers" : "driver"}.`,
          );
          const driverRequests = [];
          newDrivers.value.forEach((driver) => {
            driverRequests.push(
              store.dispatch("saveDriverForAccount", {
                ...driver,
                dateOfBirth: moment(driver.dateOfBirth, "yyyy-MM-DD")
                  .local()
                  .utc()
                  .format(),
                dateOfHire: moment(driver.dateOfHire, "yyyy-MM-DD")
                  .local()
                  .utc()
                  .format(),
              }),
            );
          });
          await Promise.all(driverRequests);
          await store.dispatch("getDrivers");
          setTimeout(() => {
            showNewDriver.value = false;
            loading.value = false;
            confirmDrivers.value = false;
            toast.clear();
            toast(
              `${newDrivers.value.length > 1 ? "Drivers" : "Driver"} requested.`,
            );
          }, 2500);
        } catch (err) {
          loading.value = false;
          toast.clear();
          toast(
            `Failed to request ${newDrivers.value.length > 1 ? "drivers" : "driver"}.`,
          );
        }
      },
    };
  },
};
</script>
