<template>
  <div class="flex flex-col w-full md:mt-24 md:flex-row" v-if="driver">
    <div class="w-full mb-2 md:w-5/12">
      <img
        src="@/assets/driver-license-1.svg"
        class="transition w-full rounded-xl shadow-lg m-auto hover:shadow-xl sm:w-1/2 md:w-full"
      />
    </div>
    <form
      @submit.prevent="saveDriver"
      class="bg-white p-4 rounded-2xl w-full md:mx-2 shadow-lg"
    >
      <div class="grid grid-cols-1 md:grid-cols-2">
        <div v-for="field in fields" :key="field.key" class="m-1">
          <label class="font-bold">{{ field.label }}</label>
          <br />
          <state-select
            v-if="field.type === 'state-select'"
            v-model="driver[field.key]"
            :required="field.required"
            :disabled="
              account.tenantId === 'carsync' &&
              !getLaunchDarklyFlag('cutoff-carsync')
            "
          />
          <input
            v-model="driver[field.key]"
            :type="field.type"
            :required="field.required"
            :disabled="
              account.tenantId === 'carsync' &&
              !getLaunchDarklyFlag('cutoff-carsync')
            "
            v-else
          />
        </div>
      </div>
      <div
        class="w-full flex flex-col justify-end mt-4 md:flex-row"
        v-if="
          account.tenantId !== 'carsync' ||
          getLaunchDarklyFlag('cutoff-carsync')
        "
      >
        <button
          @click="$router.go(-1)"
          type="button"
          class="bg-gray-100 hover:bg-gray-200 md:mx-1 mb-1 md:mb-0"
        >
          Cancel
        </button>
        <button
          class="bg-lula-gradient text-white hover:bg-lula-gradient-alt"
          type="submit"
        >
          Save
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { useToast } from "vue-toastification";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { ref } from "vue";
import moment from "moment";

function getDriverObject(driver = null) {
  if (!driver) return {};
  return {
    ...driver,
    dateOfBirth: moment.utc(driver.dateOfBirth).format("yyyy-MM-DD"),
    dateOfHire: moment.utc(driver.dateOfHire).format("yyyy-MM-DD"),
  };
}

export default {
  setup() {
    const toast = useToast();
    const router = useRouter();
    const route = useRoute();
    const store = useStore();

    const driver = ref(
      getDriverObject(
        store.state.drivers.find(
          ({ entityId }) => entityId === route.params.id,
        ),
      ),
    );

    return {
      account: store.state.account,
      driver,
      fields: [
        { key: "license", label: "DL Number", type: "text", required: true },
        { key: "state", label: "State", type: "state-select", required: true },
        { key: "firstName", label: "First Name", type: "text", required: true },
        { key: "lastName", label: "Last Name", type: "text", required: true },
        {
          key: "dateOfBirth",
          label: "Date of Birth",
          type: "date",
          required: true,
        },
        {
          key: "dateOfHire",
          label: "Date of Hire",
          type: "date",
          required: false,
        },
        { key: "email", label: "Email", type: "email", required: false },
        { key: "phone", label: "Phone", type: "text", required: false },
      ],
      async saveDriver() {
        toast("Saving driver.");
        try {
          const save = await store.dispatch(
            "saveDriverForAccount",
            driver.value,
          );
          await store.dispatch("getDrivers");
          router.replace({
            name: `${store.state.account.type}/Driver`,
            params: { id: save.entityId },
          });
          toast.clear();
          toast("Driver saved.");
        } catch (err) {
          toast.clear();
          toast("Failed to save driver.");
        }
      },
    };
  },
};
</script>
