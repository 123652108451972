<template>
  <div class="w-full mt-4">
    <div ref="claimsFormRef" />
    <div
      :class="{ hidden: claimFormLoaded }"
      class="animate-pulse bg-white w-full border rounded p-4 mt-4"
    >
      <div class="bg-gray-100 w-full h-screen rounded" />
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { useToast } from "vue-toastification";
import {
  initializeShadowDom,
  fetchFrontend,
  mountFrontend,
} from "@getaddify/lula-components";
import { useAuth } from "../auth";

export default {
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const toast = useToast();

    const claimFormLoaded = ref(false);
    const claimsFormRef = ref(null);

    onMounted(async () => {
      await store.dispatch("getSystemSettings");
      const [formShadow, formRoot] = initializeShadowDom(claimsFormRef.value);
      await fetchFrontend(formShadow, process.env["VUE_APP_CLAIMS_UI_URL"]);
      const auth = await useAuth();
      const headers = await auth.requestHeaders();
      mountFrontend("claims-form", formRoot, {
        mode: "trucks",
        launchDarklyApiKey: process.env["VUE_APP_LAUNCHDARKLY_ID"],
        graphUrl: process.env["VUE_APP_CLAIMS_GRAPH"],
        graphHeaders: headers,
        mapsApiKey: process.env["VUE_APP_GOOGLE_MAPS_APIKEY"],
        graphHeaders: {},
        onMounted() {
          claimFormLoaded.value = true;
        },
        showMessage(message, err = false) {
          if (err) {
            toast.error(message);
          } else {
            toast(message);
          }
        },
        onClaimSubmit(claim) {
          router.replace({
            name: `${store.state.account.type}/Claim`,
            params: { id: claim.id },
          });
        },
        tenantId: store.state.account.entityId,
        claimId: route.params.id === "new" ? null : route.params.id,
        claimUrl: "/insurance/claims/{{claimId}}",
        account: store.state.account,
        vehicles: store.state.vehicles,
        drivers: store.state.drivers,
        nextSteps:
          store.state.settings.find((st) => {
            if (st.key === "truckingclaimsteps") {
              return st.value;
            }
            return false;
          })?.value || "",
      });
    });

    return {
      claimFormLoaded,
      claimsFormRef,
    };
  },
};
</script>
