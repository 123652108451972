<template>
  <div class="w-full py-8">
    <div class="flex justify-between">
      <h1 class="text-3xl font-bold text-gray-800 sm:text-4xl">
        Insurance Documents
      </h1>
      <button
        @click="requestDocument = true"
        class="hidden bg-lula-gradient text-white font-bold hover:bg-lula-gradient-alt md:block"
      >
        Request a Document
      </button>
    </div>
    <div v-if="getLaunchDarklyFlag('test-enroll-button')">
      <button
        @click="enroll"
        class="hidden font-bold text-white bg-lula-gradient hover:bg-lula-gradient-alt md:block"
      >
        Enroll
      </button>
      <div v-if="policyNumber">
        <div>
          {{ policyNumber }}
        </div>
        <div>
          <a :href="policyNumberLink" target="_blank">COI</a>
        </div>
      </div>
    </div>
    <div class="mt-8">
      <h2 class="text-2xl font-bold text-gray-800 mb-2">Policy</h2>
      <div class="bg-white w-full rounded-md border p-4">
        <table class="w-full" v-if="policyDocs.length > 0">
          <tr class="text-left text-gray-800">
            <th class="pb-2">
              <span class="hidden md:inline">Document ID</span>
              <span class="md:hidden">Document</span>
            </th>
            <th class="hidden pb-2 font-normal md:table-cell">Description</th>
            <th class="hidden pb-2 font-normal md:table-cell">Issue Date</th>
          </tr>
          <tr
            class="text-gray-600 border-t cursor-pointer hover:bg-gray-100"
            @click="selectInsuranceDocument(doc)"
            v-for="doc in policyDocs"
            :key="doc.policyNumber"
          >
            <td class="md:p-2">
              <span class="hidden md:block">
                {{ doc.policyNumber }}
              </span>
              <div
                class="flex justify-between items-center p-2 text-gray-600 md:hidden"
              >
                <div>
                  <span class="font-bold">{{ doc.policyNumber }}</span>
                  <br />
                  <span>{{ doc.description }}</span>
                </div>
                <span>{{ doc.issueDate }}</span>
              </div>
            </td>
            <td class="hidden py-2 md:table-cell">
              {{ doc.description }}
            </td>
            <td class="hidden py-2 md:block">
              {{ doc.issueDate }}
            </td>
          </tr>
        </table>
        <p class="text-center text-gray-600" v-else>No Policy Documents</p>
      </div>
    </div>
    <div class="mt-8">
      <div class="block md:flex justify-between items-center mb-2">
        <h2 class="text-2xl font-bold text-gray-800">
          Certificate of Insurance
        </h2>
        <button
          v-if="getLaunchDarklyFlag('generate-new-coi')"
          @click="isCoiGenerationRequested = true"
          class="w-full md:w-auto mt-2 md:mt-0 bg-lula-gradient text-white font-bold hover:bg-lula-gradient-alt md:block plus-icon"
        >
          Generate New COI
        </button>
      </div>
      <div class="bg-white w-full rounded-md border p-4">
        <table class="w-full" v-if="certificateDocs.length > 0">
          <tr class="text-left text-gray-800">
            <th class="pb-2">
              <span class="hidden md:inline">Document ID</span>
              <span class="md:hidden">Document</span>
            </th>
            <th class="hidden pb-2 font-normal md:table-cell">Holder</th>
            <th class="hidden pb-2 font-normal md:table-cell">Description</th>
            <th class="hidden pb-2 font-normal md:table-cell">Revision</th>
            <th class="hidden pb-2 font-normal md:table-cell">Last update</th>
            <th class="hidden pb-2 font-normal md:table-cell"></th>
          </tr>
          <tr
            class="text-gray-600 border-t cursor-pointer hover:bg-gray-100"
            @click="viewCoiDocumentDetails(doc.entityId)"
            v-for="doc in certificateDocs"
            :key="doc.certificateNumber"
          >
            <td class="md:p-2">
              <span class="hidden md:inline">
                {{ doc.certificateNumber }}
              </span>
              <div
                class="flex justify-between items-center p-2 text-gray-600 md:hidden"
              >
                <div>
                  <span class="font-bold">
                    {{ doc.certificateNumber }}
                  </span>
                  <br />
                  <span>
                    {{ doc.certificateHolder || doc.description }}
                  </span>
                </div>
                <span
                  v-if="getLaunchDarklyFlag('generate-new-coi')"
                  @click.stop="viewCoiDocumentDetails(doc.entityId)"
                  class="font-bold text-gray-600 hover:underline"
                >
                  View
                </span>
              </div>
            </td>
            <td class="hidden py-2 md:table-cell truncate">
              {{ doc.certificateHolder }}
            </td>
            <td class="hidden py-2 md:table-cell truncate">
              {{ doc.description }}
            </td>
            <td class="hidden py-2 md:table-cell">
              {{ getRevisionText(doc) }}
            </td>
            <td class="hidden py-2 md:table-cell">
              {{ getFormattedModifiedDateFromCoi(doc) }}
            </td>
            <td class="hidden py-2 md:table-cell">
              <span
                v-if="getLaunchDarklyFlag('generate-new-coi')"
                @click.stop="viewCoiDocumentDetails(doc.entityId)"
                class="font-bold text-gray-600 hover:underline"
              >
                View
              </span>
            </td>
          </tr>
        </table>
        <p class="text-center text-gray-600" v-else>
          No Certificates of Insurance
        </p>
      </div>
    </div>
    <div class="mt-8">
      <h2 class="text-2xl font-bold text-gray-800 mb-2">Insurance Card</h2>
      <div class="bg-white w-full rounded-md border p-4">
        <table class="w-full" v-if="insuranceCardDocs.length > 0">
          <tr class="text-left text-gray-800">
            <th class="pb-2">
              <span class="hidden md:inline">Vehicle</span>
              <span class="md:hidden">Vehicle</span>
            </th>
            <th class="hidden font-normal pb-2 md:table-cell">Description</th>
            <th class="hidden font-normal pb-2 md:table-cell">Created</th>
          </tr>
          <tr
            class="text-gray-600 border-t cursor-pointer hover:bg-gray-100"
            @click="selectInsuranceDocument(doc)"
            v-for="doc in insuranceCardDocs"
            :key="doc.fullPath"
          >
            <td class="md:p-2">
              <span class="hidden md:inline">
                {{ doc.vehicle.key || doc.vehicle.vin }}
              </span>
              <div class="p-2 md:hidden">
                <div>
                  <span class="font-bold">
                    {{ doc.vehicle.key || doc.vehicle.vin }}
                  </span>
                  <br />
                  <span class="text-gray-800">
                    {{ doc.description }}
                  </span>
                </div>
              </div>
            </td>
            <td class="hidden py-2 md:table-cell">
              {{ doc.description }}
            </td>
            <td class="hidden py-2 md:table-cell">
              {{ doc.created }}
            </td>
          </tr>
        </table>
        <p class="text-center text-gray-600" v-else>No Insurance Cards</p>
      </div>
      <button
        @click="requestDocument = true"
        class="w-full mt-2 bg-lula-gradient text-white font-bold hover:bg-lula-gradient-alt md:hidden"
      >
        Request a Document
      </button>
    </div>
    <modal v-if="resendDocument">
      <div
        class="w-full h-full overflow-scroll max-w-screen-xl bg-white rounded-md p-4 border md:w-2/3 lg:w-1/2 md:h-auto"
      >
        <div class="mb-2">
          <h2 class="font-bold text-gray-800 text-2xl md:text-3xl">
            Resend a Document
          </h2>
          <span
            class="font-bold text-gray-600"
            v-if="resendDocument.certificateNumber"
          >
            {{ resendDocument.certificateNumber }}
          </span>
        </div>
        <form @submit.prevent="submitResendDocument">
          <div class="mb-2">
            <div class="text-lg">
              <label class="font-bold text-gray-800"> Recipient Email </label>
              <br />
              <input
                type="text"
                placeholder="Where would you like us to send this document?"
                v-model="resendRecipient"
                required
              />
            </div>
          </div>
          <div class="flex justify-end flex-col md:flex-row">
            <button
              @click="
                resendDocument = false;
                resendRecipient = '';
              "
              type="button"
              class="bg-gray-100 text-gray-800 mr-0 hover:bg-gray-200 md:mr-1"
            >
              Cancel
            </button>
            <button
              type="submit"
              class="bg-lula-gradient text-white mt-1 hover:bg-lula-gradient-alt md:mt-0"
            >
              Resend
            </button>
          </div>
        </form>
      </div>
    </modal>
    <modal v-if="requestDocument">
      <div
        class="w-full h-full overflow-scroll max-w-screen-xl bg-white rounded-md p-4 border md:w-2/3 lg:w-1/2 md:h-auto"
      >
        <div class="mb-2">
          <h2 class="font-bold text-gray-800 text-2xl md:text-3xl">
            Request a Document
          </h2>
        </div>
        <form @submit.prevent="submitDocumentRequest">
          <div class="text-lg">
            <div class="my-2">
              <label class="font-bold text-gray-800"> Document Type </label>
              <br />
              <select class="mt-1" v-model="documentRequest.docType">
                <option value="policy">Policy</option>
                <option value="certificate-of-insurance">
                  Certificate of Insurance
                </option>
                <option value="insurance-card">Insurance Card</option>
              </select>
            </div>
            <div
              class="my-2"
              v-if="documentRequest.docType === 'certificate-of-insurance'"
            >
              <label class="font-bold text-gray-800">
                Certificate Holder
              </label>
              <br />
              <input
                type="text"
                placeholder="Name of the Ceritificate Holder"
                v-model="documentRequest.certificateHolder"
              />
            </div>
            <div
              class="my-2"
              v-if="documentRequest.docType === 'certificate-of-insurance'"
            >
              <label class="font-bold text-gray-800">
                Certificate Holder Address
              </label>
              <br />
              <div class="grid grid-cols-1 md:grid-cols-3">
                <div class="md:col-span-2 mb-1 md:mr-1">
                  <input
                    type="text"
                    placeholder="Address Line One"
                    v-model="documentRequest.certificateHolderAddressLineOne"
                  />
                </div>
                <div class="mb-1">
                  <input
                    type="text"
                    placeholder="Address Line Two"
                    v-model="documentRequest.certificateHolderAddressLineTwo"
                  />
                </div>
                <div class="mb-1 md:mr-1">
                  <input
                    type="text"
                    placeholder="City"
                    v-model="documentRequest.certificateHolderCity"
                  />
                </div>
                <div class="mb-1 md:mr-1">
                  <input
                    type="text"
                    placeholder="Zipcode"
                    v-model="documentRequest.certificateHolderZipcode"
                  />
                </div>
                <div class="mb-1">
                  <state-select
                    type="text"
                    v-model="documentRequest.certificateHolderState"
                  />
                </div>
              </div>
            </div>
            <div
              class="my-2"
              v-if="documentRequest.docType === 'certificate-of-insurance'"
            >
              <label class="font-bold text-gray-800"> Recipient Email </label>
              <br />
              <input
                type="text"
                placeholder="Would you like us to send this to someone?"
                v-model="documentRequest.certificateHolderRecipient"
              />
            </div>
            <div
              class="my-2"
              v-if="requestCanIncludeVehicle(documentRequest.docType)"
            >
              <label class="font-bold text-gray-800"> Vehicle </label>
              <br />
              <input
                list="account-vehicles-list"
                :value="selectedVehicle.key || selectedVehicle.vin"
                class="cursor-pointer"
                @click="documentRequest.vehicleId = null"
                v-if="selectedVehicle"
              />
              <input
                list="account-vehicles-list"
                placeholder="Select a Vehicle"
                v-model="documentRequest.vehicleId"
                v-else
              />
              <datalist id="account-vehicles-list">
                <option
                  v-for="vehicle in $store.state.vehicles"
                  :value="vehicle.entityId"
                  :key="vehicle.entityId"
                >
                  <span v-if="vehicle.key">
                    {{ vehicle.key }}
                  </span>
                  <span v-else>
                    {{ vehicle.year }} {{ vehicle.make }} {{ vehicle.model }}
                    {{ vehicle.vin }}
                  </span>
                </option>
              </datalist>
            </div>
            <div class="my-2">
              <label class="font-bold text-gray-800"> Notes </label>
              <br />
              <textarea class="mt-1" rows="3" v-model="documentRequest.notes" />
            </div>
          </div>
          <div class="flex justify-end flex-col md:flex-row">
            <button
              @click="requestDocument = false"
              type="button"
              class="bg-gray-100 text-gray-800 mr-0 hover:bg-gray-200 md:mr-1"
            >
              Cancel
            </button>
            <button
              type="submit"
              class="bg-lula-gradient text-white mt-1 hover:bg-lula-gradient-alt md:mt-0"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </modal>
    <CoiGenerationModal
      v-if="isCoiGenerationRequested"
      :generationInProgress="isCoiGenerationInProgress"
      @generationCanceled="isCoiGenerationRequested = false"
      @generationRequested="submitCoiGenerationRequest"
    >
    </CoiGenerationModal>
  </div>
</template>

<script>
import { ref, onMounted, computed } from "vue";
import { useStore } from "vuex";
import { useToast } from "vue-toastification";
import { useRouter } from "vue-router";
import {
  getFormattedModifiedDateFromCoi,
  getRevisionText,
} from "@/trucks/coi-helpers";
import moment from "moment";
import CoiGenerationModal from "@/trucks/CoiGenerationModal.vue";

export default {
  setup() {
    const store = useStore();
    const toast = useToast();
    const router = useRouter();

    function resetDocumentRequest() {
      documentRequest.value = {
        docType: "policy",
        vehicleId: null,
        notes: "",
        certificateHolder: "",
      };
    }

    const resendDocument = ref(false);
    const resendRecipient = ref("");
    const requestDocument = ref(false);
    const isCoiGenerationRequested = ref(false);
    const isCoiGenerationInProgress = ref(false);
    const documentRequest = ref({});
    const policyNumber = ref("");
    const selectedVehicle = computed(() => {
      return store.state.vehicles.find(({ entityId }) => {
        return entityId === documentRequest.value.vehicleId;
      });
    });

    onMounted(resetDocumentRequest);

    const insuranceDocuments = ref(store.state.insuranceDocuments);
    onMounted(async () => {
      insuranceDocuments.value = await store.dispatch("getInsuranceDocuments");
    });

    return {
      resendDocument,
      resendRecipient,
      requestDocument,
      isCoiGenerationRequested,
      isCoiGenerationInProgress,
      documentRequest,
      selectedVehicle,
      getFormattedModifiedDateFromCoi,
      getRevisionText,
      requestCanIncludeVehicle(docType) {
        return ["certificate-of-insurance", "insurance-card"].includes(docType);
      },
      viewCoiDocumentDetails(coiId) {
        if (!store.state.launchDarkly.variation("generate-new-coi")) {
          return;
        }

        router.push({
          name: `${store.state.account.type}/InsuranceDocumentDetails`,
          params: { id: coiId },
        });
      },
      async submitCoiGenerationRequest(documentConfig) {
        isCoiGenerationInProgress.value = true;

        const generationResult = await store.dispatch(
          "saveCoiDocument",
          documentConfig,
        );
        insuranceDocuments.value = await store.dispatch(
          "getInsuranceDocuments",
        );
        isCoiGenerationRequested.value = false;
        isCoiGenerationInProgress.value = false;

        if (generationResult?.doc) {
          toast("COI generated.");
        } else if (generationResult?.errors) {
          generationResult.errors.forEach((error) =>
            console.warn(`[INSDTL] ${error.code}: ${error.message}`),
          );
          toast("Failed to generate COI document.");
        } else {
          toast("Failed to generate COI document.");
        }
      },
      async submitDocumentRequest() {
        toast("Requesting document.");
        try {
          const docVehicle = store.state.vehicles.find(({ entityId }) => {
            return entityId === documentRequest.value.vehicleId;
          });
          await store.dispatch("submitDocumentRequestForAccount", {
            ...documentRequest.value,
            vehicle: docVehicle ? docVehicle.vin : null,
          });
          requestDocument.value = false;
          resetDocumentRequest();
          toast.clear();
          toast("Document requested.");
        } catch (err) {
          toast.clear();
          toast("Failed to request document.");
        }
      },
      insuranceCardDocs: computed(() => {
        return insuranceDocuments.value
          .filter((doc) => {
            return doc.docType === "insurance-card";
          })
          .filter((doc) => doc.vehicle)
          .map((doc) => {
            return {
              ...doc,
              vehicle: store.state.vehicles.find(
                ({ entityId }) => entityId === doc.vehicle,
              ),
              created: moment.utc(doc.created).format("MM/DD/yyyy"),
            };
          });
      }),
      policyDocs: computed(() => {
        return insuranceDocuments.value
          .filter((doc) => {
            return doc.docType === "policy";
          })
          .map((doc) => {
            return {
              ...doc,
              issueDate: moment.utc(doc.issueDate).format("MM/DD/yyyy"),
            };
          });
      }),
      certificateDocs: computed(() => {
        return insuranceDocuments.value
          .filter((doc) => {
            return doc.docType === "certificate-of-insurance";
          })
          .map((doc) => {
            const docVehicle = store.state.vehicles.find(
              ({ entityId }) => entityId === doc.vehicle,
            );
            return {
              ...doc,
              vehicle: docVehicle,
            };
          });
      }),
      async selectInsuranceDocument(doc) {
        store.dispatch("logEvent", { name: "download_insurance_document" });
        const url = await store.dispatch("getFileDownloadUrl", doc.filePath);
        window.open(url, "_blank");
      },
      policyNumber,
      policyNumberLink: computed(() => {
        return `${process.env["VUE_APP_POLICYMANAGEMENT_API"]}/coi/${policyNumber.value}`;
      }),
      async submitResendDocument() {
        toast("Resending document.");
        try {
          await store.dispatch("submitDocumentResendForAccount", {
            ...resendDocument.value,
            recipientEmail: resendRecipient.value,
          });
          toast.clear();
          toast("Document is being sent.");
          resendRecipient.value = "";
          resendDocument.value = false;
        } catch (err) {
          toast.clear();
          toast("Failed to resend document.");
        }
      },
      async enroll() {
        const customerInfo = await store.dispatch("getAccount", {});
        let d = new Date();
        let startDate = new Date(d.getFullYear(), d.getMonth(), d.getDate());
        let endDate = new Date(d.getFullYear() + 1, d.getMonth(), d.getDate());
        const policyNumberFromAPI = await store.dispatch("enroll", {
          PolicyNumber: "",
          StartDate: startDate.toISOString(),
          EndDate: endDate.toISOString(),
          PhysicalAddress: {
            AddressLine1: customerInfo.businessAddressLineOne,
            AddressLine2: customerInfo.businessAddressLineTwo,
            City: customerInfo.businessCity,
            State: customerInfo.state,
            Zip: customerInfo.businessZipcode,
            Country: "USA",
          },
          MailingAddress: {
            AddressLine1: customerInfo.businessAddressLineOne,
            AddressLine2: customerInfo.businessAddressLineTwo,
            City: customerInfo.businessCity,
            State: customerInfo.state,
            Zip: customerInfo.businessZipcode,
            Country: "USA",
          },
          Phone: customerInfo.businessPhone,
          Email: customerInfo.contactEmail,
          Premium: {
            Fees: { Amount: 100.0, Currency: "USD" },
            OtherFees: { Amount: 10.0, Currency: "USD" },
          },
          AutoLiabilityCoverage: {
            CombinedSingleLimit: "1000000.00",
            Deductible: "2500.00",
            CoveredPollutionCost: "150.00",
            UninsuredMotoristLimitPerPerson: "15000.00",
            UninsuredMotoristLimitPerAccident: "25000.00",
            PersonalInjuryProtectionLimitPerPerson: "20000.00",
            StartDate: startDate.toISOString(),
            EndDate: endDate.toISOString(),
          },
          CargoCoverage: {
            CoverageLimit: "10000.00",
            Deductible: "2500.00",
            StartDate: startDate.toISOString(),
            EndDate: endDate.toISOString(),
          },
          IssuingDate: startDate.toISOString(),
        });
        policyNumber.value = policyNumberFromAPI;
      },
    };
  },
  components: { CoiGenerationModal },
};
</script>

<style>
.plus-icon {
  position: relative;
}

.plus-icon:after {
  position: absolute;
  content: "+";
  font-size: 1.75rem;
  top: 0rem;
  left: 1rem;
  font-weight: 300;
}
</style>
