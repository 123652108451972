<template>
  <router-view class="w-full" />
</template>

<script>
import { onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";

export default {
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const goToAccountDetailsPage = async () => {
      await router.push({
        name: `${store.state.account.type}/AccountDetails`,
        params: {},
      });
    };

    onMounted(async () => {
      const isFeatureEnabled = await store.dispatch(
        "getLaunchDarklyFlag",
        "telematics-integration",
      );

      if (!isFeatureEnabled) {
        console.warn(
          "[MOTIV] Telematics integration feature is disabled. Redirecting to the account page...",
        );
        return goToAccountDetailsPage();
      }

      const error = route.query?.error;

      if (error) {
        const errorDescription = route.query?.error_description || "";
        console.warn(
          `[MOTIV] Motive connection failed. Error: '${error}'. ${errorDescription}`,
        );
        return goToAccountDetailsPage();
      }

      const accessCode = route.query?.code;

      if (!accessCode) {
        console.warn(
          "[MOTIV] Motive connection failed. Cannot get the accessCode. Details:",
          route.query,
        );
        return goToAccountDetailsPage();
      }

      const account = await store.dispatch("getAccount");

      const requestInfo = {
        tenantId: account.entityId,
        accessCode: accessCode,
      };

      const response = await store.dispatch(
        "createMotiveIntegration",
        requestInfo,
      );

      if (!response.success) {
        console.warn(
          "[MOTIV] Cannot create the Motive integration. API error:",
          response.error,
        );
        return goToAccountDetailsPage();
      }

      console.log(
        "[MOTIV] Motive integration was successfully created:",
        response.data,
      );

      return goToAccountDetailsPage();
    });
  },
};
</script>
