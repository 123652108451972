<template>
  <div
    :class="{ hidden: billingPageHidden }"
    ref="billingPageEnabled"
    class="w-full h-full"
  >
    <div class="w-full">
      <div class="w-full grid md:grid-cols-7 mt-3 gap-2">
        <div class="col-span-4">
          <h1 class="font-bold text-4xl">Billing</h1>
        </div>
        <div class="col-span-3">
          <h1 class="font-bold text-2xl">Billing Information</h1>
        </div>
      </div>
      <div class="w-full min-h-fit grid grid-cols-1 md:grid-cols-7 my-1 gap-2">
        <div
          class="col-span-1 md:col-span-4 md:h-full transition overflow-scroll bg-white rounded-2xl p-4 my-2 md:mr-2 shadow-lg text-sm hover:shadow-xl md:text-base"
        >
          <span class="font-bold">Upcoming Payment</span>
          <br />
          <div class="tracking-wide mt-4 font-bold">
            <span class="text-4xl">${{ monthlySubscription }}</span>
            <span class="text-1s ml-2">monthly</span>
          </div>
          <div class="text-sm tracking-wide mt-2 text-gray-400">
            <span>Your next autopay will process on </span>
            <span class="font-bold text-gray-600">{{
              parseNextPaymentDate(daysUntilBillingCycle)
            }}</span>
          </div>
          <div class="text-sm tracking-wide mt-1">
            <a
              v-if="!paymentMethodModalFlag"
              :href="stripeRedirctURL"
              class="underline text-lula"
              target="_blank"
              rel="noopener noreferrer"
            >
              Edit payment method
            </a>
            <span
              v-else
              @click="() => togglePaymentMethods()"
              class="underline text-lula cursor-pointer"
            >
              Edit payment methods
            </span>
          </div>
        </div>
        <div
          class="col-span-1 md:col-span-3 grid sm:grid-cols-2 h-full transition overflow-scroll bg-white rounded-2xl p-4 md:p-6 my-2 shadow-lg text-sm hover:shadow-xl md:text-base"
        >
          <div class="col-span-1 text-ellipsis">
            <span class="text-1m text-gray-400">Contact</span>
            <br />
            <div class="text-sm tracking-wide md:mt-4">
              {{ contactFirstName + " " + contactLastName }}
            </div>
            <div class="text-xs tracking-wide">{{ contactPosition }}</div>
            <div class="text-xs tracking-wide">{{ contactEmail }}</div>
            <div class="text-xs tracking-wide">{{ contactNumber }}</div>
          </div>
          <div class="col-span-1 mt-2 sm:mt-0 md:ml-3 text-ellipsis">
            <span class="text-1m text-gray-400">Billing Address</span>
            <br />
            <div class="text-1m tracking-wide text-sm md:mt-4">
              {{ businessLegalName }}
            </div>
            <div class="text-1m tracking-wide text-xs">
              {{ businessAddressLineOne }}
            </div>
            <div class="text-1m tracking-wide text-xs">
              {{ businessCity + ", " + state }}
            </div>
            <div class="text-1m tracking-wide text-xs">
              {{ businessZipcode }}
            </div>
          </div>
        </div>
      </div>
      <Loader v-if="isLoading" class="mt-56" />
      <div v-if="showUnpaidInvoices" class="w-full mt-12">
        <unpaid-invoice-card
          v-for="(invoice, index) in unpaidInvoiceList"
          :key="'unpaid_invoice_item'.concat(index)"
          :invoice-id="invoice.id"
          :amount-due="invoice.amountRemaining.amount"
          :date-due="invoice.dueDate"
          :attempted="invoice.invoiceAttempt.attempted"
          :description="invoice.lineItems[0].description"
          @on-paid="paidInvoice"
          @toggle-loading="toggleLoading"
        />
      </div>
      <div class="w-full mt-12">
        <div class="w-full my-3">
          <h1 class="font-bold text-2xl">Recent transactions</h1>
        </div>
        <table class="w-full bg-white rounded-2xl">
          <caption></caption>
          <thead>
            <tr class="text-left text-gray-400 border-b-2">
              <th class="p-4">Date</th>
              <th>Category</th>
              <th>Description</th>
              <th>Status</th>
              <th>Amount</th>
              <th>Receipt</th>
            </tr>
          </thead>
          <tbody v-show="chargesList.length > 0">
            <tr
              v-for="charge in chargesList"
              :key="charge.id"
              class="text-sm border-b-2"
            >
              <td class="p-6">{{ charge.parsedDate }}</td>
              <td>{{ charge.category }}</td>
              <td>{{ charge.description }}</td>
              <td>
                <span
                  class="px-2 py-1 rounded-full"
                  :class="{
                    'bg-green-300': charge.paymentStatus === 'Succeeded',
                    'bg-red-300': charge.paymentStatus === 'Pending',
                  }"
                >
                  {{ charge.paymentStatus }}
                </span>
              </td>
              <td>${{ charge.amount }}</td>
              <td>
                <a
                  :href="charge.receiptInformation"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="underline text-yellow-400"
                >
                  Download
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div :class="{ hidden: hideRedirectMessage }" class="w-full">
    <span class="text-ellipsis">
      You are being redirected to Stripe, please click the following url you are
      not automatically redirected:
      <a :href="stripeRedirctURL" class="text-blue-600 underline">{{
        stripeRedirctURL
      }}</a>
    </span>
  </div>
  <payment-methods
    v-if="showPaymentMethods"
    @toggle="togglePaymentMethods"
    @open-add-modal="toggleAddMethodsModal"
  />
  <billing-add-payment-modal
    v-if="showAddPaymentModal"
    @toggle-modal="toggleAddMethodsModal"
  />
</template>

<script>
import { ref, onMounted } from "vue";
import { useStore } from "vuex";
import { Loader } from "@getaddify/lula-components";

import PaymentMethods from "./PaymentMethods.vue";
import BillingAddPaymentModal from "./BillingAddPaymentModal.vue";
import UnpaidInvoiceCard from "./UnpaidInvoiceCard.vue";

function parseNextPaymentDate(daysUntilPayment) {
  const dateObj = new Date();
  dateObj.setUTCDate(dateObj.getUTCDate() + daysUntilPayment + 1);
  return dateObj.toDateString();
}

export default {
  setup() {
    const store = useStore();
    const { monthlySubscription, daysUntilBillingCycle } =
      store.state.accountMetrics;
    const {
      contactEmail,
      contactFirstName,
      contactLastName,
      contactNumber,
      contactPosition,
      businessAddressLineOne,
      businessCity,
      businessLegalName,
      businessZipcode,
      state,
      demo,
      entityId,
      type,
    } = store.state.account;
    const billingPageHidden = ref(true); // TODO: We can completely remove these once billing page is fully implemented
    const hideRedirectMessage = ref(true); // TODO: We can completely remove these once billing page is fully implemented
    const stripeRedirctURL = ref(null);
    const chargesList = ref([]);
    const showPaymentMethods = ref(false);
    const paymentMethodModalFlag = ref(false);
    const showAddPaymentModal = ref(false);
    const unpaidInvoiceList = ref([]);
    const showUnpaidInvoices = ref(false);
    const isLoading = ref(true);
    const xConfiguration = type == "Car Rental" ? "auto" : "trucking";

    onMounted(async () => {
      try {
        const billingEnabledFlag = await store.dispatch(
          "getLaunchDarklyFlag",
          "show-car-rental-billing-page",
        );
        stripeRedirctURL.value = await store.dispatch(
          "getAccountStripePortal",
          {
            returnUrl: window.location.origin,
          },
        );
        paymentMethodModalFlag.value = await store.dispatch(
          "getLaunchDarklyFlag",
          "Billing-page-shows-payment-method-modal",
        );
        if (billingEnabledFlag) {
          billingPageHidden.value = false;
        } else {
          hideRedirectMessage.value = false;
          window.location.replace(stripeRedirctURL.value);
        }
        const chargeResponse = await store.dispatch("getChargesListFromLpg", {
          accountId: entityId,
          xTestMode: demo,
        });
        chargesList.value = chargeResponse.transactions;
        const invoiceList = await store.dispatch("getInvoicesFromLPG", {
          accountId: entityId,
          xTestMode: demo,
          xConfiguration,
        });
        showUnpaidInvoices.value = await store.dispatch(
          "getLaunchDarklyFlag",
          "billing-page-shows-overdue-invoices",
        );
        unpaidInvoiceList.value = invoiceList.invoiceList.filter(
          // We have to add a date here since Stripe has time set in ISO format for UTC time zone, their "today" will always be a day ahead
          (invoiceObj) =>
            invoiceObj.invoiceStatus == "open" &&
            Date.parse(invoiceObj.dueDate) < Date.now() + 24 * 60 * 60 * 1000,
        );
      } catch (err) {
        console.error(
          "[BILLING] There was an error loading the billing page: ",
          err,
        );
      } finally {
        isLoading.value = false;
      }
    });

    return {
      chargesList,
      contactEmail,
      contactFirstName,
      contactLastName,
      contactNumber,
      contactPosition,
      billingPageHidden,
      businessAddressLineOne,
      businessCity,
      businessLegalName,
      businessZipcode,
      daysUntilBillingCycle,
      hideRedirectMessage,
      isLoading,
      monthlySubscription,
      parseNextPaymentDate,
      paymentMethodModalFlag,
      showAddPaymentModal,
      showPaymentMethods,
      showUnpaidInvoices,
      state,
      stripeRedirctURL,
      unpaidInvoiceList,
    };
  },
  components: {
    PaymentMethods,
    BillingAddPaymentModal,
    UnpaidInvoiceCard,
    Loader,
  },
  methods: {
    togglePaymentMethods() {
      this.showPaymentMethods = !this.showPaymentMethods;
    },
    toggleAddMethodsModal() {
      this.showAddPaymentModal = !this.showAddPaymentModal;
      this.showPaymentMethods = !this.showPaymentMethods;
    },
    paidInvoice(invoiceRes, invoiceId) {
      if (invoiceRes.success) {
        const invoiceObj = invoiceRes.invoicePaymentAttempt;
        for (let i = 0; i < this.unpaidInvoiceList.length; i++) {
          if (this.unpaidInvoiceList[i]["id"] == invoiceId) {
            this.unpaidInvoiceList.splice(i, 1);
          }
        }
        let newChargeObj = {
          id: invoiceObj.id,
          amount: (invoiceObj.amountPaid / 100).toString(),
          category: "Monthly Payment",
          description: "Payment for Invoice",
          parsedDate: new Date(Date.now()).toLocaleDateString(),
          receiptInformation: invoiceObj.hostedInvoiceUrl,
          paymentStatus: invoiceObj.status === "paid" ? "Succeeded" : "Pending",
        };
        console.log("[BILLING] NEW CHARGE OBJ", newChargeObj);
        this.chargesList.unshift(newChargeObj);
      }
    },
    toggleLoading(bool) {
      this.isLoading = bool;
    },
  },
};
</script>
