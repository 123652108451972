<template>
  <modal class="py-0 md:py-10">
    <div
      class="max-h-full h-screen md:h-auto w-full overflow-auto max-w-screen-xl self-start bg-grey md:w-2/3 lg:w-1/2 relative bg-white rounded-md p-6 border"
    >
      <button
        class="close-button absolute left-4 top-5 p-2 font-bold text-xl hover:text-gray-1000"
        @click="cancelGeneration"
      ></button>
      <div class="mb-2">
        <h2 class="font-bold text-gray-800 text-center text-2xl md:text-3xl">
          Generate New COI
        </h2>
      </div>

      <div v-if="currentStep == holderInfoStep">
        <div class="mt-4">
          <label class="font-bold text-gray-800 text-xl block">
            Would you like to add a certificate holder?
          </label>
          <toggle-switch
            class="mt-3"
            v-model="needToAddCertificateHolder"
          ></toggle-switch>
        </div>

        <div v-if="needToAddCertificateHolder" class="mt-2">
          <div class="relative pb-4">
            <label class="required font-bold text-gray-800 block">
              Company Name
            </label>
            <input
              v-model.trim="v$.holderInfo.companyName.$model"
              type="text"
              class="mt-1"
              placeholder="Name of the Ceritificate Holder"
            />
            <span
              class="text-xs pl-4 absolute left-0 bottom-0"
              v-if="
                v$.holderInfo.companyName.$dirty &&
                v$.holderInfo.companyName.required.$invalid
              "
            >
              The value is required
            </span>
            <span
              class="text-xs pl-4 absolute left-0 bottom-0"
              v-if="
                v$.holderInfo.companyName.$dirty &&
                v$.holderInfo.companyName.maxLength.$invalid
              "
            >
              The value is too long
            </span>
          </div>

          <div class="relative pb-4">
            <label class="required font-bold text-gray-800 mt-1 block">
              Address Line 1
            </label>
            <vue-google-autocomplete
              id="coi-holder-address-line-one-input"
              ref="addressAutoCompleteInput"
              class="mt-1"
              placeholder="Address Line One"
              country="us"
              @placechanged="onAddressSelected"
              @inputChange="onAddressInputChanged"
            />
            <span
              class="text-xs pl-4 absolute left-0 bottom-0"
              v-if="
                v$.holderInfo.addressLineOne.$dirty &&
                v$.holderInfo.addressLineOne.required.$invalid
              "
            >
              The value is required
            </span>
            <span
              class="text-xs pl-4 absolute left-0 bottom-0"
              v-if="
                v$.holderInfo.addressLineOne.$dirty &&
                v$.holderInfo.addressLineOne.maxLength.$invalid
              "
            >
              The value is too long
            </span>
          </div>

          <div class="relative pb-4">
            <label class="font-bold text-gray-800 mt-1 block">
              Address Line 2
            </label>
            <input
              v-model.trim="v$.holderInfo.addressLineTwo.$model"
              type="text"
              class="mt-1"
              placeholder="Address Line Two"
            />
            <span
              class="text-xs pl-4 absolute left-0 bottom-0"
              v-if="
                v$.holderInfo.addressLineTwo.$dirty &&
                v$.holderInfo.addressLineTwo.maxLength.$invalid
              "
            >
              The value is too long
            </span>
          </div>
        </div>

        <div v-if="needToAddCertificateHolder" class="md:flex md:gap-3">
          <div class="relative pb-4 md:w-1/3">
            <label class="required font-bold text-gray-800 block mt-1">
              City
            </label>
            <input
              v-model.trim="v$.holderInfo.city.$model"
              type="text"
              class="mt-1"
              placeholder="City"
            />
            <span
              class="text-xs pl-4 absolute left-0 bottom-0"
              v-if="
                v$.holderInfo.city.$dirty &&
                v$.holderInfo.city.required.$invalid
              "
            >
              The value is required
            </span>
            <span
              class="text-xs pl-4 absolute left-0 bottom-0"
              v-if="
                v$.holderInfo.city.$dirty &&
                v$.holderInfo.city.maxLength.$invalid
              "
            >
              The value is too long
            </span>
          </div>

          <div class="relative pb-4 md:w-1/3">
            <label class="required font-bold text-gray-800 block mt-1">
              State
            </label>
            <state-select v-model="v$.holderInfo.state.$model" class="mt-1" />
            <span
              class="text-xs pl-4 absolute left-0 bottom-0"
              v-if="
                v$.holderInfo.state.$dirty &&
                v$.holderInfo.state.required.$invalid
              "
            >
              The value is required
            </span>
          </div>

          <div class="relative pb-4 md:w-1/3">
            <label class="required font-bold text-gray-800 block mt-1">
              Zip Code
            </label>
            <input
              v-model.trim="v$.holderInfo.zipCode.$model"
              type="text"
              class="mt-1"
              placeholder="Zip Code"
            />
            <span
              class="text-xs pl-4 absolute left-0 bottom-0"
              v-if="
                v$.holderInfo.zipCode.$dirty &&
                v$.holderInfo.zipCode.required.$invalid
              "
            >
              The value is required
            </span>
            <span
              class="text-xs pl-4 absolute left-0 bottom-0"
              v-if="
                v$.holderInfo.zipCode.$dirty &&
                v$.holderInfo.zipCode.maxLength.$invalid
              "
            >
              The value is too long
            </span>
          </div>
        </div>

        <div class="relative pb-4 mt-1">
          <label
            :class="{
              'required font-bold text-gray-800 mt-1 block':
                !needToAddCertificateHolder,
              'font-bold text-gray-800 mt-1 block': needToAddCertificateHolder,
            }"
          >
            Recipient's Email
          </label>
          <input
            v-model.trim="v$.recipientEmail.$model"
            type="text"
            class="mt-1"
            placeholder="Would you like us to send this to someone?"
          />
          <span
            class="text-xs pl-4 absolute left-0 bottom-0"
            v-if="
              v$.recipientEmail.$dirty &&
              v$.recipientEmail.email &&
              v$.recipientEmail.email.$invalid
            "
          >
            The value should be a valid email
          </span>
          <span
            class="text-xs pl-4 absolute left-0 bottom-0"
            v-if="
              v$.recipientEmail.$dirty &&
              v$.recipientEmail.maxLength &&
              v$.recipientEmail.maxLength.$invalid
            "
          >
            The value is too long
          </span>
        </div>

        <div class="relative pb-4 mt-1">
          <label class="font-bold text-gray-800 mt-1 block">
            Document Description (Optional)
          </label>
          <input
            v-model.trim="v$.documentDescription.$model"
            type="text"
            class="mt-1"
            placeholder="Description"
          />
          <span
            class="text-xs pl-4 absolute left-0 bottom-0"
            v-if="
              v$.documentDescription.$dirty &&
              v$.documentDescription.maxLength &&
              v$.documentDescription.maxLength.$invalid
            "
          >
            The value is too long
          </span>
        </div>

        <div class="flex justify-end flex-col md:flex-row mt-4">
          <button
            @click="goToDriverAndVehicleInfoStep"
            :disabled="!isHolderScreenValid"
            :class="{
              'bg-lula-gradient text-white mt-1 hover:bg-lula-gradient-alt md:mt-0':
                isHolderScreenValid,
              'bg-gray-300 cursor-default text-gray-500': !isHolderScreenValid,
            }"
          >
            Next
          </button>
        </div>
      </div>

      <div v-if="currentStep == driverAndVehicleInfoStep">
        <div class="mt-4">
          <label class="font-bold text-gray-800 text-xl block">
            Would you like to specify any driver?
          </label>
          <toggle-switch class="mt-3" v-model="needToAddDriver"></toggle-switch>
          <div v-if="needToAddDriver" class="relative pb-4 mt-2">
            <label class="required font-bold text-gray-800"> Driver </label>
            <br />
            <input
              v-if="selectedDriver"
              :value="getFormattedDriverInfo(selectedDriver)"
              @click="selectedDriverId = null"
              list="account-drivers-list"
              class="cursor-pointer mt-1"
            />
            <input
              v-else
              class="mt-1"
              list="account-drivers-list"
              placeholder="Select a Driver"
              v-model="v$.selectedDriverId.$model"
            />
            <datalist id="account-drivers-list">
              <option
                v-for="driver in approvedDrivers"
                :value="getFormattedDriverInfo(driver)"
                :key="driver.entityId"
              ></option>
            </datalist>
            <span
              class="text-xs pl-4 absolute left-0 bottom-0"
              v-if="
                v$.selectedDriverId.$dirty &&
                v$.selectedDriverId.driverShouldExist.$invalid
              "
            >
              The value should be selected from the list
            </span>
          </div>
        </div>

        <div class="mt-2">
          <label class="font-bold text-gray-800 text-xl block">
            Would you like to specify any vehicle?
          </label>
          <toggle-switch
            class="mt-3"
            v-model="needToAddVehicle"
          ></toggle-switch>
          <div v-if="needToAddVehicle" class="relative pb-4 mt-2">
            <label class="required font-bold text-gray-800"> Vehicle </label>
            <br />
            <input
              v-if="selectedVehicle"
              :value="getFormattedVehicleInfo(selectedVehicle)"
              @click="selectedVehicleId = null"
              list="account-vehicles-list"
              class="cursor-pointer mt-1"
            />
            <input
              v-else
              class="mt-1"
              list="account-vehicles-list"
              placeholder="Select a vehicle from the list"
              v-model="v$.selectedVehicleId.$model"
            />
            <datalist id="account-vehicles-list">
              <option
                v-for="vehicle in approvedVehicles"
                :value="getFormattedVehicleInfo(vehicle)"
                :key="vehicle.entityId"
              >
                <span>{{ vehicle.vin }}</span>
              </option>
            </datalist>
            <span
              class="text-xs pl-4 absolute left-0 bottom-0"
              v-if="
                v$.selectedVehicleId.$dirty &&
                v$.selectedVehicleId.vehicleShouldExist.$invalid
              "
            >
              The value should be selected from the list
            </span>
          </div>
        </div>

        <div class="flex justify-between flex-col md:flex-row mt-4">
          <button
            @click="goToHolderInfoStep"
            :disabled="generationInProgress"
            class="bg-gray-100 mr-0 hover:bg-gray-200 md:mr-2"
          >
            Back
          </button>
          <button
            @click="requestGeneration"
            :disabled="
              generationInProgress ||
              v$.selectedDriverId.$invalid ||
              v$.selectedVehicleId.$invalid
            "
            :class="{
              'bg-lula-gradient text-white mt-1 hover:bg-lula-gradient-alt md:mt-0':
                !generationInProgress &&
                !v$.selectedDriverId.$invalid &&
                !v$.selectedVehicleId.$invalid,
              'bg-gray-300 cursor-default text-gray-500':
                generationInProgress ||
                v$.selectedDriverId.$invalid ||
                v$.selectedVehicleId.$invalid,
            }"
          >
            {{ generationInProgress ? "Generating..." : "Generate COI" }}
          </button>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import { ref, computed, reactive, watch } from "vue";
import { useStore } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required, maxLength, email } from "@vuelidate/validators";
import VueGoogleAutocomplete from "vue-google-autocomplete";

const addressAutoCompleteInput = ref(null);

export default {
  props: ["generationInProgress"],
  emits: ["generationRequested", "generationCanceled"],
  components: {
    VueGoogleAutocomplete,
  },
  setup(props, { emit }) {
    const store = useStore();

    const approvedStatus = "Approved";
    const holderInfoStep = "holder-info";
    const driverAndVehicleInfoStep = "driver-and-vehicle-info";
    const currentStep = ref(holderInfoStep);

    const needToAddCertificateHolder = ref(true);
    let isAddressSelecting = false;

    const getFormattedDriverInfo = (driver) =>
      `${driver.firstName} ${driver.lastName} - ${driver.license}`;
    const getFormattedVehicleInfo = (vehicle) =>
      vehicle.key ||
      `${vehicle.year} ${vehicle.make} ${vehicle.model} ${vehicle.vin}`;

    const approvedDrivers = computed(() =>
      store.state.drivers.filter(
        (driver) => driver.insuranceCriteriaStatus === approvedStatus,
      ),
    );

    const needToAddDriver = ref(false);
    const selectedDriverId = ref(null);
    const selectedDriver = computed(() =>
      approvedDrivers.value.find(
        (driver) => getFormattedDriverInfo(driver) === selectedDriverId.value,
      ),
    );

    const approvedVehicles = computed(() =>
      store.state.vehicles.filter(
        (vehicle) => vehicle.insuranceCriteriaStatus === approvedStatus,
      ),
    );

    const needToAddVehicle = ref(false);
    const selectedVehicleId = ref(null);
    const selectedVehicle = computed(() =>
      approvedVehicles.value.find(
        (vehicle) =>
          getFormattedVehicleInfo(vehicle) === selectedVehicleId.value,
      ),
    );

    const validatableFields = reactive({
      holderInfo: {
        companyName: "",
        addressLineOne: "",
        addressLineTwo: "",
        city: "",
        state: "",
        zipCode: "",
      },
      recipientEmail: "",
      documentDescription: "",
      selectedDriverId,
      selectedVehicleId,
    });

    const driverShouldExist = () => !!selectedDriver.value;
    const vehicleShouldExist = () => !!selectedVehicle.value;

    const validationRules = computed(() => {
      const recipientEmailBaseValidator = { email, maxLength: maxLength(1000) };

      const rules = {
        holderInfo: {
          companyName: { required, maxLength: maxLength(255) },
          addressLineOne: { required, maxLength: maxLength(500) },
          addressLineTwo: { maxLength: maxLength(500) },
          city: { required, maxLength: maxLength(255) },
          state: { required },
          zipCode: { required, maxLength: maxLength(255) },
        },
        recipientEmail: recipientEmailBaseValidator,
        documentDescription: { maxLength: maxLength(255) },
        selectedDriverId: { required, driverShouldExist },
        selectedVehicleId: { required, vehicleShouldExist },
      };

      if (!needToAddCertificateHolder.value) {
        rules.holderInfo.companyName = {};
        rules.holderInfo.addressLineOne = {};
        rules.holderInfo.addressLineTwo = {};
        rules.holderInfo.city = {};
        rules.holderInfo.state = {};
        rules.holderInfo.zipCode = {};
      }

      const isRecipientEmailEmpty =
        validatableFields.recipientEmail.trim().length == 0;

      if (needToAddCertificateHolder.value && isRecipientEmailEmpty) {
        rules.recipientEmail = {};
      } else if (needToAddCertificateHolder.value && !isRecipientEmailEmpty) {
        rules.recipientEmail = recipientEmailBaseValidator;
      } else {
        rules.recipientEmail = { required, ...recipientEmailBaseValidator };
      }

      if (!needToAddDriver.value) {
        rules.selectedDriverId = {};
      }

      if (!needToAddVehicle.value) {
        rules.selectedVehicleId = {};
      }

      return rules;
    });

    const updateAddressAutoCompleteInput = () =>
      setTimeout(
        () =>
          addressAutoCompleteInput.value?.update(
            validatableFields.holderInfo.addressLineOne,
          ),
        0,
      );

    const v$ = useVuelidate(validationRules, validatableFields);

    const isHolderScreenValid = computed(
      () =>
        !v$.value.holderInfo.$invalid &&
        !v$.value.recipientEmail.$invalid &&
        !v$.value.documentDescription.$invalid,
    );

    watch(needToAddCertificateHolder, () => updateAddressAutoCompleteInput());

    return {
      addressAutoCompleteInput,
      validatableFields,
      v$,
      approvedDrivers,
      approvedVehicles,

      needToAddCertificateHolder,
      needToAddVehicle,
      selectedVehicleId,
      selectedVehicle,

      needToAddDriver,
      selectedDriverId,
      selectedDriver,

      isHolderScreenValid,
      holderInfoStep,
      driverAndVehicleInfoStep,
      currentStep,

      getFormattedDriverInfo,
      getFormattedVehicleInfo,
      updateAddressAutoCompleteInput,

      onAddressInputChanged(input) {
        if (isAddressSelecting) {
          addressAutoCompleteInput.value.update(
            validatableFields.holderInfo.addressLineOne,
          );
        } else {
          validatableFields.holderInfo.addressLineOne = input.newVal;
        }

        isAddressSelecting = false;
      },
      onAddressSelected(address) {
        isAddressSelecting = true;
        validatableFields.holderInfo.addressLineOne = `${address.street_number ?? ""} ${address.route ?? ""}`;
        validatableFields.holderInfo.city = address.locality ?? "";
        validatableFields.holderInfo.zipCode = address.postal_code ?? "";
        validatableFields.holderInfo.state =
          address.administrative_area_level_1 ?? "";
      },
      cancelGeneration() {
        emit("generationCanceled");
      },
      goToDriverAndVehicleInfoStep() {
        currentStep.value = driverAndVehicleInfoStep;
      },
      goToHolderInfoStep() {
        currentStep.value = holderInfoStep;
        updateAddressAutoCompleteInput();
      },
      requestGeneration() {
        const certificateHolder = {
          companyName: validatableFields.holderInfo.companyName,
          addressLineOne: validatableFields.holderInfo.addressLineOne,
          addressLineTwo: validatableFields.holderInfo.addressLineTwo,
          city: validatableFields.holderInfo.city,
          state: validatableFields.holderInfo.state,
          zipCode: validatableFields.holderInfo.zipCode,
        };

        const documentConfiguration = {
          certificateHolder: needToAddCertificateHolder.value
            ? certificateHolder
            : null,
          driverId: needToAddDriver.value
            ? selectedDriver.value.entityId
            : null,
          vehicleId: needToAddVehicle.value
            ? selectedVehicle.value.entityId
            : null,
          recipientEmail: validatableFields.recipientEmail || null,
          documentDescription: validatableFields.documentDescription || null,
        };

        emit("generationRequested", documentConfiguration);
      },
    };
  },
};
</script>

<style scoped>
.close-button:after {
  content: "\2715";
}

.required:after {
  content: "*";
}
</style>
