<template>
  <div class="flex min-h-screen items-center justify-center text-gray-800 p-4">
    <div class="flex flex-col w-full md:w-3/4">
      <div class="mb-8">
        <h1 class="text-lula-alt font-bold text-lg">Report a Claim</h1>
        <span class="text-4xl font-bold">
          Reporting a claim is quick and easy
        </span>
      </div>
      <div class="bg-white rounded-lg border p-4 py-8">
        <div class="mb-8">
          <h2 class="font-bold text-2xl">We're here to help</h2>
          <p>
            Our step-by-step guide makes it easy to file a claim. Just follow
            these steps.
          </p>
        </div>
        <div
          class="flex flex-col justify-center items-center gap-4 lg:flex-row"
        >
          <div class="flex flex-col items-center justify-center gap-4">
            <IdentificationIcon
              class="h-12 w-12 fill-current text-lula-primary"
            />
            <span class="text-center">Tell us about yourself</span>
          </div>
          <div class="h-12 bg-gray-200 rounded w-1 lg:h-1 lg:w-48" />
          <div class="flex flex-col items-center justify-center gap-4">
            <MapPinIcon class="h-12 w-12 fill-current text-lula-primary" />
            <span class="text-center">Basic incident details</span>
          </div>
          <div class="h-12 bg-gray-200 rounded w-1 lg:h-1 lg:w-48" />
          <div class="flex flex-col items-center justify-center gap-4">
            <PaperClipIcon class="h-12 w-12 fill-current text-lula-primary" />
            <span class="text-center">Photos and documents</span>
          </div>
          <div class="h-12 bg-gray-200 rounded w-1 lg:h-1 lg:w-48" />
          <div class="flex flex-col items-center justify-center gap-4">
            <PaperAirplaneIcon
              class="h-12 w-12 fill-current text-lula-primary"
            />
            <span class="text-center">Review and submit claim</span>
          </div>
        </div>
        <div class="mt-16 text-center lg:text-left">
          After submitting, you will receive status updates and more.
        </div>
      </div>
      <div class="flex justify-end items-center mt-4 gap-4">
        <router-link :to="{ name: 'Claims/Landing' }" class="font-bold">
          Cancel
        </router-link>
        <router-link :to="{ name: 'Claims/ThirdPartyDetails' }">
          <button class="bg-lula-primary text-white">Continue</button>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import {
  IdentificationIcon,
  MapPinIcon,
  PaperClipIcon,
  PaperAirplaneIcon,
} from "@heroicons/vue/24/solid";
export default {
  components: {
    IdentificationIcon,
    MapPinIcon,
    PaperClipIcon,
    PaperAirplaneIcon,
  },
};
</script>
