import axios from "axios";
const moment = require("moment-timezone");
import {
  saveFile,
  dispatchEvent,
  getFileDownloadUrl,
  getFileNameList,
  saveFileMeta,
} from "./firebase";
import { requestHeaders } from "./auth";

const { v4: uuidv4 } = require("uuid");

async function findVehicleDocForAccountById(account, vehicleId) {
  const vehicles = await getVehiclesForAccount();
  const vehicle = vehicles.find((vehicle) => vehicle.entityId === vehicleId);
  vehicle.changelog = null;
  return vehicle;
}

async function findDriverDocForAccountById(account, driverId) {
  const drivers = await getDriversForAccount();
  const driver = drivers.find((driver) => driver.entityId === driverId);
  driver.changelog = null;
  return driver;
}

async function getAccountFromId() {
  const headers = await requestHeaders();
  const response = await axios({
    method: "GET",
    url: `${process.env["VUE_APP_FIREBASE_FUNCTION_SUBDOMAIN"]}/customer-getAccountFromId`,
    withCredentials: true,
    headers,
  });
  return response.data.doc;
}

function generateRandomString(alphabet, length) {
  const array = new Uint32Array(12);
  self.crypto.getRandomValues(array);
  let string = "";
  for (let i = 0; i < length; i++) {
    const index = array[i];
    string += alphabet.charAt(index % 31);
  }
  return string;
}

function generateCertificateNumber() {
  const prefix = "LTCOI";
  const alphabet = "23456789ABCDEFGHJKLMNPQRSTUVWXYZ";
  const length = 12;

  const number = generateRandomString(alphabet, length);
  return prefix + number;
}

async function getCoiDocumentToSave(documentConfig, account) {
  const certificateHolder = documentConfig.certificateHolder;

  let driver = null;
  if (documentConfig.driverId) {
    const driverDoc = await findDriverDocForAccountById(
      account,
      documentConfig.driverId,
    );

    driver = driverDoc;
  }

  let vehicle = null;
  if (documentConfig.vehicleId) {
    const vehicleDoc = await findVehicleDocForAccountById(
      account,
      documentConfig.vehicleId,
    );

    vehicle = vehicleDoc;
  }

  let recipientEmail = null;
  if (documentConfig.recipientEmail) {
    recipientEmail = documentConfig.recipientEmail;
  }

  const systemSettings = await getSystemSettings();

  const now = moment();
  const entityId = uuidv4();
  const description = documentConfig.documentDescription;
  return {
    docType: "certificate-of-insurance",
    filePath: `accounts/${account.id}/documents/coi-${entityId}.pdf`,
    entityId,

    vehicledata: vehicle ? vehicle : null,
    driverdata: driver ? driver : null,
    vehicle: vehicle ? vehicle.entityId : null,
    driver: driver ? driver.entityId : null,

    certificateNumber: generateCertificateNumber(),
    revisionNumber: 1,
    certificateDate: now.format(moment.ISO_8601),

    certificateHolder: certificateHolder ? certificateHolder.companyName : null,
    certificateHolderAddressLineOne: certificateHolder
      ? certificateHolder.addressLineOne
      : null,
    certificateHolderAddressLineTwo: certificateHolder
      ? certificateHolder?.addressLineTwo || null
      : null,
    certificateHolderState: certificateHolder ? certificateHolder.state : null,
    certificateHolderZipcode: certificateHolder
      ? certificateHolder.zipCode
      : null,
    certificateHolderCity: certificateHolder ? certificateHolder.city : null,

    insuredName: account.businessLegalName,
    insuredAddressLineOne: account.businessAddressLineOne,
    insuredAddressLineTwo: account.businessAddressLineTwo || null,
    insuredState: account.state,
    insuredCity: account.businessCity,
    insuredZipcode: account.businessZipcode,

    contactName: systemSettings.truckingContactName,
    contactPhone: systemSettings.truckingContactPhone,
    contactEmail: systemSettings.truckingContactEmail,
    contactNaic: systemSettings.truckingContactNaic,

    producerName: systemSettings.truckingProducerName,
    producerAddressLineOne: systemSettings.truckingProducerAddressLineOne,
    producerAddressLineTwo: systemSettings.truckingProducerAddressLineTwo,
    producerCity: systemSettings.truckingProducerCity,
    producerState: systemSettings.truckingProducerState,
    producerZipcode: systemSettings.truckingProducerZipcode,

    insurerName: systemSettings.truckingInsurerName,
    insurerNaic: systemSettings.truckingInsurerNaic,

    recipientEmail: recipientEmail || null,
    description: description || null,
  };
}

async function saveCertificateOfInsurance(documentConfig) {
  const account = await getAccountFromId();
  const documentData = await getCoiDocumentToSave(documentConfig, account);
  const headers = await requestHeaders();
  const response = await axios({
    method: "POST",
    url: `${process.env["VUE_APP_POLICYMANAGEMENT_API"]}/insurancedocument/${account.entityId}/${documentData.entityId}`,
    data: documentData,
    withCredentials: true,
    headers,
  });
  return response.data;
}

async function sendEmailWithCoiToHolder(parameters) {
  const headers = await requestHeaders();
  const response = await axios({
    method: "POST",
    url: `${process.env["VUE_APP_FIREBASE_FUNCTION_SUBDOMAIN"]}/customer-sendEmailWithCoiToHolder`,
    data: parameters,
    withCredentials: true,
    headers,
  });
  return response.data.result;
}

async function getEpisodesForAccount() {
  const headers = await requestHeaders();
  const response = await axios({
    method: "GET",
    url: `${process.env["VUE_APP_FIREBASE_FUNCTION_SUBDOMAIN"]}/customer-getEpisodesForAccount`,
    withCredentials: true,
    headers,
  });
  return response.data.docs;
}

async function getVehiclesForAccount() {
  const headers = await requestHeaders();
  const response = await axios({
    method: "GET",
    url: `${process.env["VUE_APP_FIREBASE_FUNCTION_SUBDOMAIN"]}/customer-getVehiclesForAccount`,
    withCredentials: true,
    headers,
  });
  return response.data.docs;
}

async function saveVehicleForAccount(vehicle) {
  const headers = await requestHeaders();
  const response = await axios({
    method: "POST",
    url: `${process.env["VUE_APP_FIREBASE_FUNCTION_SUBDOMAIN"]}/customer-saveVehicleForAccount`,
    data: vehicle,
    withCredentials: true,
    headers,
  });
  return response.data.doc;
}

async function subscribeVehiclesForAccount(vehicles = []) {
  const headers = await requestHeaders();
  const response = await axios({
    method: "POST",
    url: `${process.env["VUE_APP_FIREBASE_FUNCTION_SUBDOMAIN"]}/customer-subscribeVehiclesForAccount`,
    data: { vehicles },
    withCredentials: true,
    headers,
  });
  return response.data.docs;
}

async function getMetricsForAccount() {
  const headers = await requestHeaders();
  const response = await axios({
    method: "POST",
    url: `${process.env["VUE_APP_FIREBASE_FUNCTION_SUBDOMAIN"]}/customer-getMetricsForAccount`,
    withCredentials: true,
    headers,
  });
  return response.data;
}

async function removeVehicleFromAccount(vehicle) {
  const headers = await requestHeaders();
  const response = await axios({
    method: "POST",
    url: `${process.env["VUE_APP_FIREBASE_FUNCTION_SUBDOMAIN"]}/customer-removeVehicleFromAccount`,
    data: vehicle,
    withCredentials: true,
    headers,
  });
  return response.data.doc;
}

async function removeDriverFromAccount(driver) {
  const headers = await requestHeaders();
  const response = await axios({
    method: "POST",
    url: `${process.env["VUE_APP_FIREBASE_FUNCTION_SUBDOMAIN"]}/customer-removeDriverFromAccount`,
    data: driver,
    withCredentials: true,
    headers,
  });
  return response.data.doc;
}

async function saveBillingSettingsForAccount(settings) {
  const headers = await requestHeaders();
  const response = await axios({
    method: "POST",
    url: `${process.env["VUE_APP_FIREBASE_FUNCTION_SUBDOMAIN"]}/customer-saveBillingSettingsForAccount`,
    data: settings,
    withCredentials: true,
    headers,
  });
  return response.data.doc;
}

async function saveDetailsForAccount(details) {
  const headers = await requestHeaders();
  const response = await axios({
    method: "POST",
    url: `${process.env["VUE_APP_FIREBASE_FUNCTION_SUBDOMAIN"]}/customer-saveDetailsForAccount`,
    data: details,
    withCredentials: true,
    headers,
  });
  return response.data.doc;
}

async function processTelematicsRequest(requestCall) {
  try {
    const response = await requestCall();

    return {
      success: true,
      data: response.data,
    };
  } catch (error) {
    return {
      success: false,
      statusCode: error?.response?.status || 0,
      error: error?.response?.data || {},
    };
  }
}

async function createSamsaraIntegration(requestInfo) {
  const headers = await requestHeaders();
  return await processTelematicsRequest(() =>
    axios({
      method: "POST",
      url: `${process.env["VUE_APP_TELEMATICS_API"]}/samsara/integrations`,
      data: requestInfo,
      withCredentials: true,
      headers,
    }),
  );
}

async function createMotiveIntegration(requestInfo) {
  const headers = await requestHeaders();
  return await processTelematicsRequest(() =>
    axios({
      method: "POST",
      url: `${process.env["VUE_APP_TELEMATICS_API"]}/motive/integrations`,
      data: requestInfo,
      withCredentials: true,
      headers,
    }),
  );
}

async function createTelematicsIntegration(providerId, requestInfo) {
  const headers = await requestHeaders();
  return await processTelematicsRequest(() =>
    axios({
      method: "POST",
      url: `${process.env["VUE_APP_TELEMATICS_API"]}/${providerId}/integrations`,
      data: requestInfo,
      withCredentials: true,
      headers,
    }),
  );
}

async function getTelematicsAccountStatus(accountEntityId) {
  const headers = await requestHeaders();
  return await processTelematicsRequest(() =>
    axios({
      method: "GET",
      url: `${process.env["VUE_APP_TELEMATICS_API"]}/tenant-statuses/${accountEntityId}`,
      withCredentials: true,
      headers,
    }),
  );
}

async function getVehicleAccountStatus(accountEntityId) {
  const headers = await requestHeaders();
  return await processTelematicsRequest(() =>
    axios({
      method: "GET",
      url: `${process.env["VUE_APP_TELEMATICS_API"]}/vehicles/${accountEntityId}`,
      withCredentials: true,
      headers,
    }),
  );
}

async function getOffRentMileage(vin, startDate, endDate, tenantId) {
  const headers = await requestHeaders();
  return await processTelematicsRequest(() =>
    axios({
      method: "GET",
      url: `${process.env["VUE_APP_TELEMATICS_API"]}/off-rent-mileage/${vin}`,
      withCredentials: true,
      params: {
        start_date: startDate,
        end_date: endDate,
        tenant_id: tenantId,
      },
      headers,
    }),
  );
}

async function getDriversForAccount() {
  const headers = await requestHeaders();
  const response = await axios({
    method: "GET",
    url: `${process.env["VUE_APP_FIREBASE_FUNCTION_SUBDOMAIN"]}/customer-getDriversForAccount`,
    withCredentials: true,
    headers,
  });
  return response.data.docs;
}

async function getAccountStripePortal(returnUrl) {
  const headers = await requestHeaders();
  const response = await axios({
    method: "GET",
    url: `${process.env["VUE_APP_FIREBASE_FUNCTION_SUBDOMAIN"]}/customer-getAccountStripePortal`,
    params: { returnUrl },
    withCredentials: true,
    headers,
  });
  return response.data.stripeCustomerPortalUrl;
}

async function addVehicleChangelog(
  vehicleEntityId,
  entityId,
  eventType,
  fieldName,
  newValue,
  oldValue,
) {
  const headers = await requestHeaders();
  const response = await axios({
    method: "POST",
    url: `${process.env["VUE_APP_FIREBASE_FUNCTION_SUBDOMAIN"]}/customer-addVehicleChangelog`,
    data: {
      vehicleEntityId,
      entityId,
      eventType,
      fieldName,
      newValue,
      oldValue,
    },
    withCredentials: true,
    headers,
  });
  return response.data.doc;
}

async function enroll(policy) {
  const headers = await requestHeaders();
  const response = await axios({
    method: "PUT",
    url: `${process.env["VUE_APP_POLICYMANAGEMENT_API"]}`,
    data: policy,
    withCredentials: true,
    headers,
  });
  const policyNumber = await axios({
    method: "GET",
    url: `${process.env["VUE_APP_POLICYMANAGEMENT_API"]}/${response.data.policyId}`,
    withCredentials: true,
    headers,
  });
  return policyNumber.data;
}
async function getClaimsForAccount() {
  const headers = await requestHeaders();
  const response = await axios({
    method: "GET",
    url: `${process.env["VUE_APP_FIREBASE_FUNCTION_SUBDOMAIN"]}/customer-getClaimsForAccount`,
    withCredentials: true,
    headers,
  });
  return response.data.docs;
}

async function saveClaimForAccount(claim) {
  const headers = await requestHeaders();
  const response = await axios({
    method: "POST",
    url: `${process.env["VUE_APP_FIREBASE_FUNCTION_SUBDOMAIN"]}/customer-saveClaimForAccount`,
    data: claim,
    withCredentials: true,
    headers,
  });
  return response.data.doc;
}

async function saveDriverForAccount(driver) {
  const headers = await requestHeaders();
  const response = await axios({
    method: "POST",
    url: `${process.env["VUE_APP_FIREBASE_FUNCTION_SUBDOMAIN"]}/customer-saveDriverForAccount`,
    data: driver,
    withCredentials: true,
    headers,
  });
  return response.data.doc;
}

async function getInsuranceDocumentsForAccount(accountId) {
  const headers = await requestHeaders();
  const response = await axios({
    method: "GET",
    url: `${process.env["VUE_APP_POLICYMANAGEMENT_API"]}/insurancedocuments/${accountId}`,
    withCredentials: true,
    headers,
  });
  return response.data.map((doc) => JSON.parse(doc));
}

async function getSystemSettings() {
  const headers = await requestHeaders();
  const response = await axios({
    method: "GET",
    url: `${process.env["VUE_APP_FIREBASE_FUNCTION_SUBDOMAIN"]}/customer-getSystemSettings`,
    withCredentials: true,
    headers,
  });
  return response.data.docs;
}

async function submitDocumentRequestForAccount(documentRequest) {
  const headers = await requestHeaders();
  const response = await axios({
    method: "POST",
    url: `${process.env["VUE_APP_FIREBASE_FUNCTION_SUBDOMAIN"]}/customer-submitDocumentRequestForAccount`,
    withCredentials: true,
    data: documentRequest,
    headers,
  });
  return response.data.doc;
}

async function submitDocumentResendForAccount(accountId, documentRequest) {
  const headers = await requestHeaders();
  const response = await axios({
    method: "POST",
    url: `${process.env["VUE_APP_FIREBASE_FUNCTION_SUBDOMAIN"]}/customer-submitDocumentResendForAccount`,
    withCredentials: true,
    data: documentRequest,
    headers,
  });
  return response.data.doc;
}

async function saveEpisodesForAccount(episodes) {
  const headers = await requestHeaders();
  const response = await axios({
    method: "POST",
    url: `${process.env["VUE_APP_FIREBASE_FUNCTION_SUBDOMAIN"]}/customer-saveEpisodesForAccount`,
    withCredentials: true,
    data: { episodes },
    headers,
  });
  return response.data.docs;
}

async function saveClaimAttachment(accountId, claimId, file) {
  const snap = await saveFile(
    `accounts/${accountId}/claims/${claimId}/${file.name}`,
    file,
  );
  return snap;
}

async function saveVehicleDocument(accountId, vehicleId, file, meta) {
  const filePath = `accounts/${accountId}/vehicles/${vehicleId}/documents/${file.name}`;
  const snap = await saveFile(filePath, file);
  await saveFileMeta(filePath, {
    customMetadata: {
      active: true,
      ...meta,
    },
  });
  return snap;
}

async function getVehicleDocuments(accountId, vehicleId) {
  const list = await getFileNameList(
    `accounts/${accountId}/vehicles/${vehicleId}/documents`,
  );
  return list;
}

async function getClaimDocuments(accountId, claimId) {
  const list = await getFileNameList(`accounts/${accountId}/claims/${claimId}`);
  return list;
}

async function getBlackBookVehicle(vin) {
  const headers = await requestHeaders();
  const response = await axios({
    method: "POST",
    url: `${process.env["VUE_APP_FIREBASE_FUNCTION_SUBDOMAIN"]}/customer-getBlackBookVehicle`,
    withCredentials: true,
    data: {
      vin,
    },
    headers,
  });
  return response.data.vehicle;
}

async function activateVehicleNow(vehicleId) {
  const headers = await requestHeaders();
  const response = await axios({
    method: "POST",
    url: `${process.env["VUE_APP_FIREBASE_FUNCTION_SUBDOMAIN"]}/customer-activateVehicleNow`,
    withCredentials: true,
    data: {
      vehicleId,
    },
    headers,
  });
  return response.data.success;
}

async function activateVehicleNowWithMessage(vehicleId, isDemo) {
  try {
    const headers = await requestHeaders();
    const response = await axios({
      method: "POST",
      url: `${process.env["VUE_APP_FIREBASE_FUNCTION_SUBDOMAIN"]}/customer-activateVehicleNow`,
      withCredentials: true,
      headers,
      data: {
        vehicleId,
        isDemo,
      },
    });
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
}

async function activateVehicleLater(vehicleId) {
  const headers = await requestHeaders();
  const response = await axios({
    method: "POST",
    url: `${process.env["VUE_APP_FIREBASE_FUNCTION_SUBDOMAIN"]}/customer-activateVehicleLater`,
    withCredentials: true,
    headers,
    data: {
      vehicleId,
    },
  });
  return response.data.success;
}

async function cancelEpisode(entityId) {
  const headers = await requestHeaders();
  const response = await axios({
    method: "POST",
    url: `${process.env["VUE_APP_FIREBASE_FUNCTION_SUBDOMAIN"]}/customer-cancelEpisode`,
    data: { entityId },
    headers,
    withCredentials: true,
  });
  return response.data;
}

async function createEpisodeFromEpisode(
  entityId,
  paymentIntent,
  days,
  accountId,
) {
  const headers = await requestHeaders();
  const response = await axios({
    method: "POST",
    url: `${process.env["VUE_APP_FIREBASE_FUNCTION_SUBDOMAIN"]}/customer-createEpisodeFromEpisode`,
    data: {
      entityId,
      paymentIntent,
    },
    params: {
      days,
      accountId,
    },
    withCredentials: true,
    headers,
  });
  return response.data;
}

async function getReservations() {
  const url = `${process.env["VUE_APP_FIREBASE_FUNCTION_SUBDOMAIN"]}/customer-getReservations`;
  const headers = await requestHeaders();
  const { data } = await axios.get(url, {
    withCredentials: true,
    headers,
  });
  return data;
}

async function getVinAudit(vin) {
  const url = `${process.env["VUE_APP_FIREBASE_FUNCTION_SUBDOMAIN"]}/customer-getVinAudit`;
  const headers = await requestHeaders();
  try {
    const response = await axios({
      method: "POST",
      url,
      withCredentials: true,
      headers,
      data: {
        vin,
      },
    });
    return response;
  } catch (e) {
    return { error: e, success: false };
  }
}
async function getUnderwritingResponse(inputs) {
  try {
    const headers = await requestHeaders();
    const response = await axios({
      method: "POST",
      url: process.env["VUE_APP_NEW_UNDERWRITING_URL"],
      data: inputs,
      withCredentials: true,
      headers,
      validateStatus: function (status) {
        return status >= 200 && status < 500; // default
      },
    });
    return response;
  } catch (e) {
    return { error: e, success: false };
  }
}

async function getVinDetailsResponse(vin) {
  try {
    if (!vin) {
      throw new Error("Missing VIN");
    }
    const headers = await requestHeaders();
    const url = `${process.env.VUE_APP_VIN_DETAILS_URL}/${vin}`;
    const response = await axios.get(url, {
      headers,
      withCredentials: true,
    });
    return { success: true, data: response.data };
  } catch (error) {
    console.error("[SVC] fetchVinDetails error:", error);
    return { success: false, error: error.message };
  }
}

async function getChargesListFromLpg(accountId, xTestMode) {
  try {
    const headers = await requestHeaders();
    const chargesList = await axios({
      method: "POST",
      url: `${process.env["VUE_APP_FIREBASE_FUNCTION_SUBDOMAIN"]}/customer-getTransactionsForAccount`,
      withCredentials: true,
      headers,
      data: {
        accountId,
        xTestMode,
      },
    });
    return chargesList.data;
  } catch (err) {
    return { error: err, success: false };
  }
}

async function getPaymentMethodsFromLpg(accountId, xTestMode) {
  try {
    const headers = await requestHeaders();
    const paymentMethodList = await axios({
      method: "POST",
      url: `${process.env["VUE_APP_FIREBASE_FUNCTION_SUBDOMAIN"]}/customer-getPaymentMethodsForCustomer`,
      withCredentials: true,
      headers,
      data: {
        accountId,
        xTestMode,
      },
    });
    return paymentMethodList.data;
  } catch (err) {
    return { error: err, success: false };
  }
}

async function createClientTokenUsingLPG(accountId, xTestMode) {
  try {
    const headers = await requestHeaders();
    const clientToken = await axios({
      method: "POST",
      url: `${process.env["VUE_APP_FIREBASE_FUNCTION_SUBDOMAIN"]}/customer-createClientTokenUsingLPG`,
      withCredentials: true,
      headers,
      data: {
        accountId,
        xTestMode,
      },
    });
    return clientToken.data;
  } catch (err) {
    console.error("[SVC] There was an issue calling cloud functions");
    return { error: err, success: false };
  }
}

async function updateDefaultPaymentMethodViaLPG(
  accountId,
  xTestMode,
  paymentMethodId,
  xConfiguration,
) {
  try {
    const headers = await requestHeaders();
    const defaultPaymentMethod = await axios({
      method: "POST",
      url: `${process.env["VUE_APP_FIREBASE_FUNCTION_SUBDOMAIN"]}/customer-updateDefaultPaymentMethodViaLPG`,
      withCredentials: true,
      headers,
      data: {
        accountId,
        xTestMode,
        paymentMethodId,
        xConfiguration,
      },
    });
    return defaultPaymentMethod.data;
  } catch (err) {
    console.error("[SVC] There was an issue calling cloud functions");
    return { error: err, success: false };
  }
}

async function getCustomerDetailsFromLPG(accountId, xTestMode, xConfiguration) {
  try {
    const headers = await requestHeaders();
    const customerDetails = await axios({
      method: "POST",
      url: `${process.env["VUE_APP_FIREBASE_FUNCTION_SUBDOMAIN"]}/customer-getCustomerDetailsFromLPG`,
      withCredentials: true,
      headers,
      data: {
        accountId,
        xTestMode,
        xConfiguration,
      },
    });
    return customerDetails.data;
  } catch (err) {
    console.error("[SVC] There was an issue calling cloud functions");
    return { error: err, success: false };
  }
}

async function deletePaymentMethodViaLPG(
  accountId,
  xTestMode,
  xConfiguration,
  paymentMethodId,
) {
  try {
    const headers = await requestHeaders();
    const deletePM = await axios({
      method: "POST",
      url: `${process.env["VUE_APP_FIREBASE_FUNCTION_SUBDOMAIN"]}/customer-deletePaymentMethodViaLPG`,
      withCredentials: true,
      headers,
      data: {
        accountId,
        xTestMode,
        xConfiguration,
        paymentMethodId,
      },
    });
    return deletePM.data;
  } catch (err) {
    console.error("[SVC] There was an issue calling cloud functions");
    return { error: err, success: false };
  }
}

async function getInvoicesFromLPG(accountId, xTestMode, xConfiguration) {
  try {
    const headers = await requestHeaders();
    const invoiceList = await axios({
      method: "POST",
      url: `${process.env["VUE_APP_FIREBASE_FUNCTION_SUBDOMAIN"]}/customer-getInvoicesFromLPG`,
      withCredentials: true,
      headers,
      data: {
        accountId,
        xTestMode,
        xConfiguration,
      },
    });
    return invoiceList.data;
  } catch (err) {
    console.error(
      "[SVC] There was an issue calling the customer-getInvoicesFromLPG cloud functions",
    );
    return { error: err, success: false };
  }
}

async function payInvoicesViaLPG(
  tenantId,
  invoiceId,
  xTestMode,
  xConfiguration,
) {
  try {
    const headers = await requestHeaders();
    const invoiceList = await axios({
      method: "POST",
      url: `${process.env["VUE_APP_FIREBASE_FUNCTION_SUBDOMAIN"]}/customer-payInvoicesViaLPG`,
      withCredentials: true,
      headers,
      data: {
        tenantId,
        invoiceId,
        xTestMode,
        xConfiguration,
      },
    });
    return invoiceList.data;
  } catch (err) {
    console.error(
      `[SVC] There was an issue calling the customer-payInvoicesViaLPG cloud functions: ${err}`,
    );
    return { success: false, message: err.message ? err.message : err };
  }
}

async function addAccountToQueue(accountId, coverageType) {
  try {
    const url = `${process.env["VUE_APP_ONBOARDING_QUEUE_URL"]}${accountId}`;
    await axios.post(url, { CoverageType: coverageType });
  } catch (err) {
    console.log(err);
    return;
  }
}

export {
  saveCertificateOfInsurance,
  sendEmailWithCoiToHolder,
  saveClaimAttachment,
  dispatchEvent,
  getFileDownloadUrl,
  getVehicleDocuments,
  getBlackBookVehicle,
  activateVehicleNow,
  activateVehicleNowWithMessage,
  activateVehicleLater,
  getClaimDocuments,
  saveVehicleDocument,
  addVehicleChangelog,
  getAccountStripePortal,
  getVehiclesForAccount,
  getEpisodesForAccount,
  saveVehicleForAccount,
  subscribeVehiclesForAccount,
  submitDocumentRequestForAccount,
  submitDocumentResendForAccount,
  getMetricsForAccount,
  removeVehicleFromAccount,
  saveDetailsForAccount,
  saveBillingSettingsForAccount,
  getAccountFromId,
  getTelematicsAccountStatus,
  getVehicleAccountStatus,
  getOffRentMileage,
  createSamsaraIntegration,
  createMotiveIntegration,
  createTelematicsIntegration,
  getDriversForAccount,
  getClaimsForAccount,
  saveClaimForAccount,
  getInsuranceDocumentsForAccount,
  enroll,
  saveDriverForAccount,
  getSystemSettings,
  removeDriverFromAccount,
  saveEpisodesForAccount,
  cancelEpisode,
  createEpisodeFromEpisode,
  getReservations,
  getVinAudit,
  getUnderwritingResponse,
  getVinDetailsResponse,
  getChargesListFromLpg,
  getPaymentMethodsFromLpg,
  createClientTokenUsingLPG,
  updateDefaultPaymentMethodViaLPG,
  getCustomerDetailsFromLPG,
  deletePaymentMethodViaLPG,
  getInvoicesFromLPG,
  payInvoicesViaLPG,
  addAccountToQueue,
};
