<template>
  <router-view class="w-full" />
</template>

<script>
import { onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";

export default {
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const goToAccountDetailsPage = async () => {
      await router.push({
        name: `${store.state.account.type}/AccountDetails`,
        params: {},
      });
    };

    onMounted(async () => {
      const isFeatureEnabled = await store.dispatch(
        "getLaunchDarklyFlag",
        "telematics-integration",
      );

      const accessCode = route.query?.code;
      const account = await store.dispatch("getAccount");

      //When user has not signed in to lula app, this means the user started the samsara oauth flow from customer presales.
      //Therefore, we should redirect them back to presales.
      if (!account) {
        const presalesURL = process.env["VUE_APP_CUSTOMER_PRESALES_URL"];
        const target =
          presalesURL + "/samsara-oauth-handler?code=" + accessCode;
        window.location.href = target;
        return;
      }

      //continue with lula-app samsara redirect logic
      if (!isFeatureEnabled) {
        console.warn(
          "[SAMS] Telematics integration feature is disabled. Redirecting to the account page...",
        );
        return goToAccountDetailsPage();
      }

      if (!accessCode) {
        console.warn(
          "[SAMS] Samsara connection failed. Cannot get the accessCode. Details:",
          route.query,
        );
        return goToAccountDetailsPage();
      }

      const requestInfo = {
        tenantId: account.entityId,
        accessCode: accessCode,
      };

      const response = await store.dispatch(
        "createSamsaraIntegration",
        requestInfo,
      );

      if (!response.success) {
        console.warn(
          "[SAMS] Cannot create the Samsara integration. API error:",
          response.error,
        );
        return goToAccountDetailsPage();
      }

      console.log(
        "[SAMS] Samsara integration was successfully created:",
        response.data,
      );

      return goToAccountDetailsPage();
    });
  },
};
</script>
