<template>
  <div class="w-full flex flex-col items-left justify-left pt-8">
    <div class="mb-8" v-if="getLaunchDarklyFlag('episode-manual')">
      <h1 class="font-bold text-2xl text-gray-800 mb-4">Book Episodes</h1>
      <div ref="episodebook" />
    </div>
    <div>
      <h1 class="font-bold text-2xl text-gray-800 mb-4">Scheduled Episodes</h1>
      <div ref="episodehistory" />
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { Subject } from "rxjs";
import {
  initializeShadowDom,
  fetchFrontend,
  mountFrontend,
} from "@getaddify/lula-components";
import { mapSystemSettings } from "@/utils";
import moment from "moment";
import { useAuth } from "../auth";

function mapEpisodeHistory(episodes, vehicles) {
  return (episodes || [])
    .filter((episode) => {
      const startDate = moment.utc(episode.startDate).local();
      return new Date() <= startDate.toDate();
    })
    .map((episode) => {
      return {
        ...episode,
        vehicle: vehicles.find(({ entityId }) => {
          return entityId === episode.vehicle;
        }),
      };
    })
    .sort((a, b) => {
      const aStart = moment.utc(a.startDate).local().toDate();
      const bStart = moment.utc(b.startDate).local().toDate();
      return aStart > bStart ? 1 : -1;
    });
}

export default {
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    const episodes = new Subject();
    const episodebook = ref(null);
    const episodehistory = ref(null);

    onMounted(async () => {
      const [shadow, root] = initializeShadowDom(episodebook.value);
      await fetchFrontend(shadow, process.env["VUE_APP_EPISODES_UI_URL"]);

      const auth = await useAuth();
      const headers = await auth.requestHeaders();

      mountFrontend("episode-book", root, {
        defaultVehicleId: route.query.vehicle || null,
        episodePricePerDay: store.state.account.episodePrice,
        graphHeaders: headers,
        async getSupportUrl() {
          const settings = mapSystemSettings(
            await store.dispatch("getSystemSettings"),
          );
          return settings.truckingsupportlink;
        },
        async getBillingUrl() {
          const portal = await store.dispatch("getAccountStripePortal", {
            returnUrl: window.location.origin,
          });
          return portal;
        },
        async saveEpisodes(confirmationEpisodes) {
          const savedEpisodes = await store.dispatch(
            "saveEpisodesForAccount",
            confirmationEpisodes,
          );
          episodes.next(
            mapEpisodeHistory(
              await store.dispatch("getEpisodes"),
              store.state.vehicles,
            ),
          );
          return savedEpisodes;
        },
        getVehicles() {
          return store.state.vehicles
            .map((v) => {
              return { ...v, id: v.entityId };
            })
            .filter((v) => v.status === "Active");
        },
      });
    });

    onMounted(async () => {
      const [shadow, root] = initializeShadowDom(episodehistory.value);
      await fetchFrontend(shadow, process.env["VUE_APP_EPISODES_UI_URL"]);

      const auth = await useAuth();
      const headers = await auth.requestHeaders();

      mountFrontend("episode-history-grid", root, {
        episodes,
        emptyMessage: "No Scheduled Episodes",
        graphHeaders: headers,
        selectEpisode(episode) {
          router.push({
            name: `${store.state.account.type}/Episode`,
            params: { id: episode.entityId },
          });
        },
      });
      episodes.next(
        mapEpisodeHistory(
          await store.dispatch("getEpisodes"),
          store.state.vehicles,
        ),
      );
    });

    return {
      episodebook,
      episodehistory,
    };
  },
};
</script>
