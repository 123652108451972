<template>
  <div class="w-full">
    <div
      class="py-8 w-full"
      :class="{
        'opacity-0': accountStatus === null,
        'opacity-100': accountStatus !== null,
      }"
    >
      <div class="w-full flex justify-between items-center">
        <h1
          class="font-bold text-3xl text-left text-gray-800"
          v-if="accountStatus"
        >
          GetAddify Bucks
        </h1>
        <h1 class="font-bold text-3xl text-left text-gray-800" v-else>
          Wallet
        </h1>
        <button
          @click="manageBilling"
          class="hidden bg-lula-gradient text-white font-bold hover:bg-lula-gradient-alt md:block"
        >
          Manage Billing
        </button>
      </div>
      <div class="my-2" style="min-height: 200px">
        <div ref="billingMetrics" />
      </div>
      <div class="my-2" :class="{ 'opacity-0': !accountStatus }">
        <h2 class="font-bold text-2xl text-gray-800 mb-2">
          Recent Transactions
        </h2>
        <div ref="billingRecentTransactions" />
      </div>
    </div>
    <Loader v-if="accountStatus === null" />
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { useStore } from "vuex";
import {
  Loader,
  initializeShadowDom,
  fetchFrontend,
  mountFrontend,
} from "@getaddify/lula-components";
import { Subject } from "rxjs";
import { useAuth } from "../auth";

export default {
  components: { Loader },
  setup() {
    const store = useStore();

    const billingMetrics = ref(null);
    const billingRecentTransactions = ref(null);

    const walletSettings = ref(null);
    const settingsSubject = new Subject();

    const autoRefillAmount = ref(store.state.account.autoRefillAmount || null);
    const autoRefillSubject = new Subject();

    const accountStatus = ref(null);
    const statusSubject = new Subject();

    settingsSubject.subscribe({
      next: (val) => (walletSettings.value = val),
    });

    autoRefillSubject.subscribe({
      next: (val) => (autoRefillAmount.value = val),
    });

    statusSubject.subscribe({
      next: (val) => {
        console.log(val);
        accountStatus.value = val;
      },
    });

    onMounted(async () => {
      const [metricsShadow, metricsRoot] = initializeShadowDom(
        billingMetrics.value,
      );
      await fetchFrontend(metricsShadow, process.env["VUE_APP_BILLING_UI_URL"]);
      const auth = await useAuth();
      const headers = await auth.requestHeaders();

      mountFrontend("billing-wallet-metrics", metricsRoot, {
        graphUrl: process.env["VUE_APP_BILLING_GRAPH"],
        graphHeaders: headers,
        tenantId: store.state.account.entityId,
        walletSettings: settingsSubject,
        walletStatus: statusSubject,
        autoRefillAmount: autoRefillSubject,
        defaultEpisodePrice: parseFloat(store.state.account.episodePrice),
        activeVehicleCount: store.state.vehicles.filter(
          (v) => v.status === "Active",
        ).length,
        onSettingsMounted() {
          autoRefillSubject.next(autoRefillAmount.value);
        },
        getAccountRefillSetting() {
          return store.state.account.autoRefillAmount;
        },
        async saveSettings(settings) {
          const account = await store.dispatch(
            "saveBillingSettingsForAccount",
            {
              ...store.state.account,
              autoRefillAmount: settings.autoRefillAmount,
            },
          );
          autoRefillSubject.next(account.autoRefillAmount);
          return account;
        },
      });
    });

    onMounted(async () => {
      const [transactionsShadow, transactionsRoot] = initializeShadowDom(
        billingRecentTransactions.value,
      );
      await fetchFrontend(
        transactionsShadow,
        process.env["VUE_APP_BILLING_UI_URL"],
      );
      mountFrontend("billing-recent-transactions", transactionsRoot, {
        graphUrl: process.env["VUE_APP_BILLING_GRAPH"],
        graphHeaders: headers,
        tenantId: store.state.account.entityId,
        episodes: store.state.episodes,
        vehicles: store.state.vehicles,
      });
    });

    return {
      accountStatus,
      walletSettings,
      billingRecentTransactions,
      billingMetrics,
      async manageBilling() {
        try {
          store.commit("setLoading", true);
          const portal = await store.dispatch("getAccountStripePortal", {
            returnUrl: window.location.origin,
          });
          window.location.replace(portal);
        } catch (err) {
          store.commit("setLoading", false);
        }
      },
    };
  },
};
</script>
