<template>
  <label class="inline-block cursor-pointer">
    <div class="relative flex w-40 h-12 bg-gray-300 rounded-full">
      <input
        type="checkbox"
        class="sr-only"
        v-model="isChecked"
        @change="handleChangeEvent"
      />
      <div
        class="label flex justify-center items-center absolute left-0 top-0 w-1/2 h-full text-center"
      >
        {{ leftLabel }}
      </div>
      <div
        class="label flex justify-center items-center absolute right-0 top-0 w-1/2 h-full text-center"
      >
        {{ rightLabel }}
      </div>
      <div
        class="slider flex justify-center items-center absolute right-0 top-0 w-1/2 h-full rounded-full transition text-center text-white bg-lula-gradient hover:bg-lula-gradient-alt"
      >
        {{ isChecked ? leftLabel : rightLabel }}
      </div>
    </div>
  </label>
</template>

<script>
import { ref } from "vue";

export default {
  props: {
    modelValue: {
      default: false,
      type: Boolean,
    },
    leftLabel: {
      default: "Yes",
      type: String,
    },
    rightLabel: {
      default: "No",
      type: String,
    },
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const isChecked = ref(props.modelValue);

    return {
      isChecked,

      handleChangeEvent() {
        emit("update:modelValue", isChecked.value);
      },
    };
  },
};
</script>

<style scoped>
input:checked ~ .slider {
  transform: translateX(-100%);
}

.slider,
.label {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
</style>
