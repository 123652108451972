<template>
  <router-view class="w-full" />
</template>

<script>
import { onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import { connectIntegration } from "@/telematics";

export default {
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const goToIntegrationsPage = async () => {
      await router.push({
        name: `${store.state.account.type}/Integrations`,
        params: {},
      });
    };

    const goToDefaultPage = async () => {
      await router.push({
        name: `${store.state.account.type}/Dashboard`,
        params: {},
      });
    };

    onMounted(async () => {
      const isFeatureEnabled = await store.dispatch(
        "getLaunchDarklyFlag",
        "show-car-rental-integration-page",
      );

      if (!isFeatureEnabled) {
        console.warn(
          "[TEL] Telematics integration feature is disabled. Redirecting to the default page...",
        );
        return goToDefaultPage();
      }

      const isConnected = await connectIntegration(
        route.meta.provider,
        route.query,
        store,
      );
      return isConnected ? goToIntegrationsPage() : goToDefaultPage();
    });
  },
};
</script>
