import Dashboard from "@/cars/Dashboard.vue";
import Vehicle from "@/cars/Vehicle.vue";
import NewVehicle from "@/cars/NewVehicle.vue";
import CustomerActivatesVehicleFlow from "@/cars/CustomerActivatesVehicleFlow.vue";
import Driver from "@/cars/Driver.vue";
import Claims from "@/cars/Claims.vue";
import Integrations from "@/cars/ProviderIntegrations.vue";
import ClaimForm from "@/cars/ClaimForm.vue";
import LegacyClaimForm from "@/cars/LegacyClaimForm.vue";
import ClaimConfirmation from "@/cars/ClaimConfirmation.vue";
import Reservation from "@/cars/Reservation.vue";
import Billing from "@/cars/Billing.vue";
import LulaSafe from "@/cars/lulasafe/LulaSafe.vue";
import TelematicsProviderAuthHandlerPage from "@/cars/TelematicsProviderAuthHandlerPage.vue";
import { Providers as TelematicsProviders } from "@/telematics";
import Support from "@/cars/Support.vue";

export default [
  {
    name: "Dashboard",
    path: "",
    component: Dashboard,
  },
  {
    name: "NewVehicle",
    path: "/vehicle/add/",
    component: NewVehicle,
  },
  {
    name: "ResumeVehicleActivation",
    path: "/vehicle/add/:id",
    component: NewVehicle,
  },
  {
    name: "CustomerActivatesVehicleFlow",
    path: "/addvehicle",
    component: CustomerActivatesVehicleFlow,
  },
  {
    name: "ResumeCustomerActivatesVehicleFlow",
    path: "/addvehicle/:id",
    component: CustomerActivatesVehicleFlow,
  },
  {
    name: "Vehicle",
    path: "/vehicle/:id",
    component: Vehicle,
  },
  {
    name: "Driver",
    path: "/driver/:id",
    component: Driver,
  },
  {
    name: "Claims",
    path: "/claims",
    component: Claims,
  },
  {
    name: "ClaimForm",
    path: "/claims/:id",
    component: ClaimForm,
    meta: { nav: "Claims" },
  },
  {
    name: "LegacyClaimForm",
    path: "/claim/new",
    component: LegacyClaimForm,
  },
  {
    name: "ClaimConfirmation",
    path: "/claim/:id/confirmation",
    component: ClaimConfirmation,
  },
  {
    name: "ReservationCoverage",
    path: "/reservations",
    component: Reservation,
  },
  {
    name: "Billing",
    path: "/billing",
    component: Billing,
  },
  {
    name: "LulaSafe",
    path: "/lulaSafe",
    component: LulaSafe,
  },
  {
    name: "Integrations",
    path: "/integrations",
    component: Integrations,
  },
  {
    name: "BouncieRedirectPage",
    path: "/bouncie_oauth_handler",
    component: TelematicsProviderAuthHandlerPage,
    meta: { provider: TelematicsProviders.Bouncie },
  },
  {
    name: "ZubieRedirectPage",
    path: "/zubie_oauth_handler",
    component: TelematicsProviderAuthHandlerPage,
    meta: { provider: TelematicsProviders.Zubie },
  },
  {
    name: "Support",
    path: "/support",
    component: Support,
  }
];
