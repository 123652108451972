<template>
  <div class="w-full py-8">
    <div>
      <h1 class="font-bold text-2xl text-gray-800 mb-4">Episode History</h1>
      <div ref="episodehistory" />
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { ref, onMounted } from "vue";
import { Subject } from "rxjs";
import { mapVehiclesToEpisodes } from "@/utils";
import {
  initializeShadowDom,
  fetchFrontend,
  mountFrontend,
} from "@getaddify/lula-components";
import moment from "moment";
import { useAuth } from "../auth";

function mapEpisodeHistory(episodes, vehicles) {
  return (episodes || [])
    .map((episode) => {
      const vehicle = vehicles.find(({ entityId }) => {
        return entityId === episode.vehicle;
      });
      return {
        ...episode,
        vehicle,
      };
    })
    .sort((a, b) => {
      const aStart = moment.utc(a.startDate).local().toDate();
      const bStart = moment.utc(b.startDate).local().toDate();
      return aStart < bStart ? 1 : -1;
    });
}

export default {
  setup() {
    const store = useStore();
    const router = useRouter();

    const episodes = new Subject();
    const episodehistory = ref(null);

    onMounted(async () => {
      const [shadow, root] = initializeShadowDom(episodehistory.value);
      await fetchFrontend(shadow, process.env["VUE_APP_EPISODES_UI_URL"]);

      const auth = await useAuth();
      const headers = await auth.requestHeaders();

      mountFrontend("episode-history-grid", root, {
        episodes,
        emptyMessage: "No Episodes",
        graphHeaders: headers,
        selectEpisode(episode) {
          router.push({
            name: `${store.state.account.type}/Episode`,
            params: { id: episode.entityId },
          });
        },
      });
      const mappedVehicles = mapVehiclesToEpisodes(
        store.state.vehicles,
        await store.dispatch("getEpisodes"),
      );
      episodes.next(mapEpisodeHistory(store.state.episodes, mappedVehicles));
    });

    return { episodehistory };
  },
};
</script>
