<template>
  <form
    @submit.prevent="submitThirdPartyDetails"
    class="flex min-h-screen items-center justify-center text-gray-800 p-4"
  >
    <div class="flex flex-col w-full md:w-2/3">
      <div class="mb-8">
        <h1 class="text-lula-alt font-bold text-lg">Report a Claim</h1>
        <span class="text-4xl font-bold"> Personal information </span>
      </div>
      <div class="bg-white rounded-lg border p-4 py-8">
        <div class="mb-8">
          <h2 class="font-bold text-2xl">Tell us about yourself</h2>
          <p>
            Let us know who is reporting this claim and and we'll keep you
            updated.
          </p>
        </div>
        <div class="grid grid-cols-4 gap-4">
          <div :class="field.class" v-for="field in fields" :key="field.key">
            <label class="font-bold">
              {{ field.label }}
            </label>
            <select
              class="mt-1 bg-white border rounded-lg text-lg hover:bg-white"
              v-if="field.type === 'select'"
              v-model="details[field.key]"
            >
              <option
                v-for="opt in field.options"
                :key="opt.key"
                :value="opt.key"
              >
                {{ opt.value }}
              </option>
            </select>
            <input
              type="tel"
              class="bg-white border rounded-lg text-lg hover:bg-white"
              :placeholder="field.placeholder"
              v-model="details[field.key]"
              @keyup="phoneFormat(field.key)"
              v-else-if="field.type === 'tel'"
            />
            <input
              :type="field.type ?? 'text'"
              class="bg-white border rounded-lg text-lg hover:bg-white"
              :placeholder="field.placeholder"
              v-model="details[field.key]"
              v-else
            />
          </div>
        </div>
        <br v-if="details.partyType !== 'myself'" />
        <div
          class="border rounded-lg p-4"
          v-if="details.partyType !== 'myself'"
        >
          <h3 class="mb-2 font-bold text-xl">Representative information</h3>
          <div class="grid grid-cols-4 gap-4">
            <div
              :class="field.class"
              v-for="field in representativeFields"
              :key="field.key"
            >
              <label class="font-bold">
                {{ field.label }} {{ field.required ? "*" : "" }}
              </label>
              <select
                class="mt-1 bg-white border rounded-lg text-lg hover:bg-white"
                v-if="field.type === 'select'"
                v-model="details[field.key]"
              >
                <option
                  v-for="opt in field.options"
                  :key="opt.key"
                  :value="opt.key"
                >
                  {{ opt.value }}
                </option>
              </select>
              <vue-google-autocomplete
                :id="field.key"
                class="bg-white border rounded-lg text-lg hover:bg-white"
                placeholder="Address line one"
                @placechanged="getAddressData"
                country="us"
                :required="field.required"
                v-model="details[field.key]"
                v-else-if="field.key === 'representativeAddressLineOne'"
              />
              <state-select
                class="bg-white border rounded-lg text-lg hover:bg-white"
                :required="field.required"
                v-model="details[field.key]"
                v-else-if="field.type === 'state'"
              />
              <div
                class="grid grid-cols-4 gap-2"
                v-else-if="field.type === 'tel'"
              >
                <input
                  type="tel"
                  class="bg-white border rounded-lg text-lg hover:bg-white col-span-3"
                  :placeholder="field.placeholder"
                  :required="field.required"
                  v-model="details[field.key]"
                  @keyup="phoneFormat(field.key)"
                />
                <input
                  type="text"
                  class="bg-white border rounded-lg text-lg hover:bg-white"
                  placeholder="Ext."
                  v-model="details[field.extensionKey]"
                />
              </div>
              <input
                type="text"
                class="bg-white border rounded-lg text-lg hover:bg-white"
                :placeholder="field.placeholder"
                v-model="details[field.key]"
                v-else
              />
            </div>
          </div>
        </div>
        <br />
        <div class="border rounded-lg p-4">
          <h3 class="mb-2 font-bold text-xl">Claimant information</h3>
          <div class="grid grid-cols-4 gap-4">
            <div
              :class="field.class"
              v-for="field in claimantFields"
              :key="field.key"
            >
              <label class="font-bold">
                {{ field.label }} {{ field.required ? "*" : "" }}
              </label>
              <select
                class="mt-1 bg-white border rounded-lg text-lg hover:bg-white"
                v-if="field.type === 'select'"
                v-model="details[field.key]"
                :required="field.required"
              >
                <option
                  v-for="opt in field.options"
                  :key="opt.key"
                  :value="opt.key"
                >
                  {{ opt.value }}
                </option>
              </select>
              <state-select
                class="bg-white border rounded-lg text-lg hover:bg-white"
                :required="field.required"
                v-model="details[field.key]"
                v-else-if="field.type === 'state'"
              />
              <vue-google-autocomplete
                :id="field.key"
                class="bg-white border rounded-lg text-lg hover:bg-white"
                placeholder="Address line one"
                @placechanged="getAddressData"
                country="us"
                :required="field.required"
                v-model="details[field.key]"
                v-else-if="field.key === 'addressLineOne'"
              />
              <div
                class="grid grid-cols-4 gap-2"
                v-else-if="field.type === 'tel'"
              >
                <input
                  type="tel"
                  class="bg-white col-span-3 border rounded-lg text-lg hover:bg-white"
                  :placeholder="field.placeholder"
                  :required="field.required"
                  v-model="details[field.key]"
                  @keyup="phoneFormat(field.key)"
                />
                <input
                  type="text"
                  class="bg-white border rounded-lg text-lg hover:bg-white"
                  placeholder="Ext."
                  v-model="details[field.extensionKey]"
                />
              </div>
              <input
                :type="field.type ?? 'text'"
                class="bg-white border rounded-lg text-lg hover:bg-white"
                :placeholder="field.placeholder"
                :required="field.required"
                v-model="details[field.key]"
                v-else
              />
            </div>
          </div>
        </div>
        <br />
        <div class="border rounded-lg p-4">
          <h3 class="mb-2 font-bold text-xl">Your policy information</h3>
          <div class="grid grid-cols-4 gap-4">
            <div
              :class="field.class"
              v-for="field in policyFields"
              :key="field.key"
            >
              <label class="font-bold">
                {{ field.label }}
              </label>
              <select
                class="mt-1 bg-white border rounded-lg text-lg hover:bg-white"
                v-if="field.type === 'select'"
                v-model="details[field.key]"
              >
                <option
                  v-for="opt in field.options"
                  :key="opt.key"
                  :value="opt.key"
                >
                  {{ opt.value }}
                </option>
              </select>
              <div
                class="grid grid-cols-4 gap-2"
                v-else-if="field.type === 'tel'"
              >
                <input
                  type="tel"
                  class="bg-white border rounded-lg text-lg col-span-3 hover:bg-white"
                  :placeholder="field.placeholder"
                  v-model="details[field.key]"
                  @keyup="phoneFormat(field.key)"
                />
                <input
                  type="text"
                  class="bg-white border rounded-lg text-lg hover:bg-white"
                  placeholder="Ext."
                  v-model="details[field.extensionKey]"
                />
              </div>
              <input
                :type="field.type ?? 'text'"
                class="bg-white border rounded-lg text-lg hover:bg-white"
                :placeholder="field.placeholder"
                v-model="details[field.key]"
                v-else
              />
            </div>
          </div>
        </div>
      </div>
      <div class="flex justify-end items-center mt-4 gap-4">
        <span @click="onCancel" class="font-bold cursor-pointer"> Cancel </span>
        <button type="submit" class="bg-lula-primary text-white">
          Continue
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import { onMounted, ref, computed } from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import VueGoogleAutocomplete from "vue-google-autocomplete";

export default {
  components: { VueGoogleAutocomplete },
  setup() {
    const route = useRoute();
    const store = useStore();
    const router = useRouter();
    const representativeFields = computed(() => {
      return [
        {
          key: "representativeCompany",
          type: "text",
          label: "Company Name",
          class: "col-span-4",
          placeholder: "Company name",
          required: true,
        },
        {
          key: "representativeContactName",
          type: "text",
          label: "Contact Name",
          class: "col-span-4",
          placeholder: "Contact name",
          required: true,
        },
        {
          key: "representativeEmail",
          type: "email",
          label: "Contact email",
          class: "col-span-2",
          placeholder: "What email would you like to receive updates?",
          required: true,
        },
        {
          key: "representativePhone",
          type: "tel",
          label: "Contact phone",
          class: "col-span-2",
          placeholder: "Phone number",
          required: true,
          extensionKey: "representativePhoneExtension",
        },
        {
          key: "representativeAddressLineOne",
          type: "text",
          label: "Address line one",
          class: "col-span-4 md:col-span-3",
          placeholder: "Representative address line one",
          required: true,
        },
        {
          key: "representativeAddressLineTwo",
          type: "text",
          label: "Line two",
          class: "col-span-4 md:col-span-1",
          placeholder: "Address line two",
          required: false,
        },
        {
          key: "representativeCity",
          type: "text",
          label: "City",
          class: "col-span-4 md:col-span-2",
          placeholder: "City",
          required: true,
        },
        {
          key: "representativeState",
          type: "state",
          label: "State",
          class: "col-span-2 md:col-span-1",
          placeholder: "State",
          required: true,
        },
        {
          key: "representativeZipcode",
          type: "text",
          label: "Zipcode",
          class: "col-span-2 md:col-span-1",
          placeholder: "Zipcode",
          required: true,
        },
      ];
    });
    const claimantFields = computed(() => {
      return [
        {
          key: "firstName",
          type: "text",
          label: "First name",
          class: "col-span-2",
          placeholder: "First name",
          required: true,
        },
        {
          key: "lastName",
          type: "text",
          label: "Last name",
          class: "col-span-2",
          placeholder: "Last name",
          required: true,
        },
        {
          key: "contactEmail",
          type: "email",
          label: "Email",
          class: "col-span-2",
          placeholder: "Contact email",
          required: true,
        },
        {
          key: "contactPhone",
          type: "tel",
          label: "Phone number",
          class: "col-span-2",
          placeholder: "Contact phone number",
          required: true,
          extensionKey: "contactPhoneExtension",
        },
        {
          key: "addressLineOne",
          type: "text",
          label: "Address line one",
          class: "col-span-4 md:col-span-3",
          placeholder: "Address line one",
          required: true,
        },
        {
          key: "addressLineTwo",
          type: "text",
          label: "Line two",
          class: "col-span-4 md:col-span-1",
          placeholder: "Address line two",
          required: false,
        },
        {
          key: "city",
          type: "text",
          label: "City",
          class: "col-span-4 md:col-span-2",
          placeholder: "City",
          required: true,
        },
        {
          key: "state",
          type: "state",
          label: "State",
          class: "col-span-2 md:col-span-1",
          placeholder: "State",
          required: true,
        },
        {
          key: "zipcode",
          type: "text",
          label: "Zipcode",
          class: "col-span-2 md:col-span-1",
          placeholder: "Zipcode",
          required: true,
        },
        {
          key: "dlNumber",
          type: "text",
          label: "DL Number",
          class: "col-span-3",
          placeholder: "Driver license number",
          required: false,
        },
        {
          key: "dlState",
          type: "state",
          label: "DL State",
          class: "col-span-1",
          placeholder: "DL State",
          required: false,
        },
      ];
    });
    const policyFields = computed(() => {
      return [
        {
          key: "policyNumber",
          type: "text",
          label: "Policy number",
          class: "col-span-4",
          placeholder: "Policy number",
        },
        {
          key: "policyCarrierName",
          type: "text",
          label: "Insurance carrier name",
          class: "col-span-4",
          placeholder: "Insurance carrier name",
        },
        {
          key: "policyCarrierContactPhone",
          type: "tel",
          label: "Contact phone",
          class: "col-span-2",
          placeholder: "Insurance carrier phone number",
          extensionKey: "policyCarrierContactPhoneExtension",
        },
        {
          key: "policyCarrierContactEmail",
          type: "email",
          label: "Contact email",
          class: "col-span-2",
          placeholder: "Insurance carrier email",
        },
      ];
    });
    const fields = computed(() => {
      return [
        {
          key: "partyType",
          type: "select",
          label: "Who is reporting this claim?",
          class: "col-span-4",
          options: [
            {
              key: "myself",
              value: "Myself",
            },
            {
              key: "attorney",
              value: "Attorney",
            },
            {
              key: "insurance-company",
              value: "Insurance Company",
            },
            {
              key: "healthcare-provider",
              value: "Healthcare Provider",
            },
            {
              key: "adjuster",
              value: "Adjuster",
            },
          ],
        },
      ];
    });
    const details = ref({
      partyType: store.state.claimForm?.partyType ?? "myself",
      firstName: store.state.claimForm?.claimantFirstName ?? "",
      lastName: store.state.claimForm?.claimantLastName ?? "",
      contactEmail: store.state.claimForm?.claimantEmail ?? "",
      contactPhone: store.state.claimForm?.claimantPhone ?? "",
      contactPhoneExtension:
        store.state.claimForm?.claimantPhoneExtension ?? "",
      addressLineOne: store.state.claimForm?.claimantAddressLineOne ?? "",
      addressLineTwo: store.state.claimForm?.claimantAddressLineTwo ?? "",
      city: store.state.claimForm?.claimantAddressCity ?? "",
      state: store.state.claimForm?.claimantAddressState ?? "",
      zipcode: store.state.claimForm?.claimantAddressZipcode ?? "",
      dlNumber: store.state.claimForm?.claimantLicenseNumber ?? "",
      dlState: store.state.claimForm?.claimantLicenseState ?? "",
      representativeCompany: store.state.claimForm?.representativeCompany ?? "",
      representativeContactName:
        store.state.claimForm?.representativeName ?? "",
      representativeEmail: store.state.claimForm?.representativeEmail ?? "",
      representativePhone: store.state.claimForm?.representativePhone ?? "",
      representativePhoneExtension:
        store.state.claimForm?.representativePhone ?? "",
      representativeAddressLineOne:
        store.state.claimForm?.representativeAddressLineOne ?? "",
      representativeAddressLineTwo:
        store.state.claimForm?.representativeAddressLineTwo ?? "",
      representativeCity: store.state.claimForm?.representativeCity ?? "",
      representativeState: store.state.claimForm?.representativeState ?? "",
      representativeZipcode: store.state.claimForm?.representativeZipcode ?? "",
      policyNumber: store.state.claimForm?.policyNumber ?? "",
      policyCarrierName: store.state.claimForm?.policyCarrierName ?? "",
      policyCarrierContactPhone:
        store.state.claimForm?.policyCarrierPhone ?? "",
      policyCarrierContactPhoneExtension:
        store.state.claimForm?.policyCarrierPhoneExtension ?? "",
      policyCarrierContactEmail:
        store.state.claimForm?.policyCarrierEmail ?? "",
    });
    onMounted(() => {
      Object.keys({ ...details.value }).forEach((k) => {
        const el = document.getElementById(k);
        if (!el) return;
        el.value = details.value[k];
      });
    });
    return {
      fields,
      details,
      representativeFields,
      claimantFields,
      policyFields,
      submitThirdPartyDetails() {
        store.commit("setClaimFormItems", {
          partyType: details.value.partyType ?? "myself",
          claimantFirstName: details.value.firstName ?? "",
          claimantLastName: details.value.lastName ?? "",
          claimantEmail: details.value.contactEmail ?? "",
          claimantPhone: details.value.contactPhone ?? "",
          claimantPhoneExtension: details.value.contactPhoneExtension ?? "",
          claimantAddressLineOne: details.value.addressLineOne ?? "",
          claimantAddressLineTwo: details.value.addressLineTwo ?? "",
          claimantAddressCity: details.value.city ?? "",
          claimantAddressState: details.value.state ?? "",
          claimantAddressZipcode: details.value.zipcode ?? "",
          claimantLicenseNumber: details.value.dlNumber ?? "",
          claimantLicenseState: details.value.dlState ?? "",
          representativeCompany: details.value.representativeCompany ?? "",
          representativeName: details.value.representativeContactName ?? "",
          representativeEmail: details.value.representativeEmail ?? "",
          representativePhone: details.value.representativePhone ?? "",
          representativePhoneExtension:
            details.value.representativePhoneExtension ?? "",
          representativeAddressLineOne:
            details.value.representativeAddressLineOne ?? "",
          representativeAddressLineTwo:
            details.value.representativeAddressLineTwo ?? "",
          representativeCity: details.value.representativeCity ?? "",
          representativeState: details.value.representativeState ?? "",
          representativeZipcode: details.value.representativeZipcode ?? "",
          policyNumber: details.value.policyNumber ?? "",
          policyCarrierName: details.value.policyCarrierName ?? "",
          policyCarrierPhone: details.value.policyCarrierContactPhone ?? "",
          policyCarrierPhoneExtension:
            details.value.policyCarrierContactPhoneExtension ?? "",
          policyCarrierEmail: details.value.policyCarrierContactEmail ?? "",
        });
        if (route.query.edit) {
          router.push({ name: "Claims/ReviewDetails" });
        } else {
          router.push({ name: "Claims/IncidentDetails" });
        }
      },
      getAddressData(addressData, locationData, fieldKey) {
        if (fieldKey === "addressLineOne") {
          details.value.addressLineOne = [
            addressData.street_number,
            addressData.route,
          ].join(" ");
          details.value.city = addressData.locality;
          details.value.zipcode = addressData.postal_code;
          details.value.state = addressData.administrative_area_level_1;
        }
        if (fieldKey === "representativeAddressLineOne") {
          details.value.representativeAddressLineOne = [
            addressData.street_number,
            addressData.route,
          ].join(" ");
          details.value.representativeCity = addressData.locality;
          details.value.representativeZipcode = addressData.postal_code;
          details.value.representativeState =
            addressData.administrative_area_level_1;
        }
        const addressInput = document.querySelector(`#${fieldKey}`);
        if (addressInput) {
          addressInput.value = [
            addressData.street_number,
            addressData.route,
          ].join(" ");
        }
      },
      onCancel() {
        if (confirm("Are you sure you want to start this process over?")) {
          router.push({ name: "Claims/Landing" });
        }
      },
      phoneFormat(key) {
        let input = details.value[key] ?? "";
        input = input.replace(/\D/g, "").substring(0, 10);
        var size = input.length;
        if (size > 0) {
          input = "(" + input;
        }
        if (size > 3) {
          input = input.slice(0, 4) + ") " + input.slice(4);
        }
        if (size > 6) {
          input = input.slice(0, 9) + "-" + input.slice(9);
        }
        details.value[key] = input;
      },
    };
  },
};
</script>
