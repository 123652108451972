<template>
  <div class="w-full">
    <div ref="claimsFormRef" />
    <div
      :class="{ hidden: claimFormLoaded }"
      class="animate-pulse bg-white w-full border rounded p-4 mt-4"
    >
      <div class="bg-gray-100 w-full h-screen rounded" />
    </div>
    <div
      class="hidden transition-all opacity-100 duration-1000"
      ref="captchaRef"
    />
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { useToast } from "vue-toastification";
import { Subject } from "rxjs";
import {
  initializeShadowDom,
  fetchFrontend,
  mountFrontend,
} from "@getaddify/lula-components";
import { useAuth } from "../auth";

export default {
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const toast = useToast();

    const claimFormLoaded = ref(false);
    const claimsFormRef = ref(null);
    const captchaRef = ref(null);

    onMounted(async () => {
      const tokenSubject = new Subject();
      if (route.params.id === "new") {
        captchaRef.value.classList.add("-mt-12");
        window.grecaptcha.render(captchaRef.value, {
          sitekey: process.env.VUE_APP_CAPTCHA_SITEKEY,
          callback(token) {
            if (token) {
              tokenSubject.next(token);
              captchaRef.value.classList.remove("opacity-100");
              captchaRef.value.classList.add("opacity-0");
              captchaRef.value.classList.add("w-0");
              setTimeout(() => {
                captchaRef.value.classList.remove("-mt-12");
                captchaRef.value.classList.add("hidden");
              }, 1000);
            }
          },
        });
      }
      const [formShadow, formRoot] = initializeShadowDom(claimsFormRef.value);
      await fetchFrontend(formShadow, process.env["VUE_APP_CLAIMS_UI_URL"]);
      const auth = await useAuth();
      const headers = await auth.requestHeaders();
      mountFrontend("claims-form", formRoot, {
        environment: process.env["VUE_APP_ENVIRONMENT"],
        userId: store.state.user?.email,
        userEmail: store.state.user?.email,
        mode: (store.state.account?.defaultPolicy || "").includes(
          "bbf38936-2383-4c2b-918a-3d8627e396b5",
        )
          ? "orp_cars"
          : "cars",
        launchDarklyApiKey: process.env["VUE_APP_LAUNCHDARKLY_ID"],
        graphUrl: process.env["VUE_APP_CLAIMS_GRAPH"],
        mapsApiKey: process.env["VUE_APP_GOOGLE_MAPS_APIKEY"],
        graphHeaders: headers,
        adjustersJson:
          store.state.settings.find((s) => s.key === "carsadjusters")?.value ||
          "[]",
        tenantId: store.state.account.entityId,
        onMounted() {
          claimFormLoaded.value = true;
        },
        showMessage(message, err = false) {
          toast.clear();
          if (err) {
            toast.error(message);
          } else {
            toast(message);
          }
        },
        onClaimSubmit(claim) {
          toast.clear();
          toast(
            route.params.id === "new" ? "Claim submitted." : "Claim updated.",
          );
          if (route.params.id === "new") {
            store.dispatch("logEvent", {
              name: "claim_submitted",
              data: { claimId: claim.id },
            });
          }
          router.replace({
            name: `${store.state.account.type}/ClaimForm`,
            params: { id: claim.id },
          });
        },
        onViewVehicle(vehicle) {
          const vehicleLink = router.resolve({
            name: `${store.state.account.type}/Vehicle`,
            params: { id: vehicle.entityId },
          });
          window.open(vehicleLink.href, "_blank");
        },
        claimId: route.params.id === "new" ? null : route.params.id,
        claimUrl: "/claims/{{claimId}}",
        account: store.state.account,
        vehicles: store.state.vehicles,
        drivers: store.state.drivers,
        nextSteps:
          store.state.settings.find((st) => {
            if (st.key === "carsclaimsteps") {
              return st.value;
            }
            return false;
          })?.value || "",
      });
    });

    return {
      claimFormLoaded,
      claimsFormRef,
      captchaRef,
    };
  },
};
</script>
