<template>
  <div class="w-full py-8">
    <div id="lula-bucks-opt-in-banner" class="w-full">
      <div ref="lulaBucksOptIn"></div>
    </div>
    <div class="w-full flex justify-left mb-4">
      <div>
        <span class="text-gray-600 text-lg">
          {{ displayDate }}
        </span>
        <h1 class="font-bold text-gray-800 text-2xl">
          Welcome back, {{ account.contactFirstName }}! 👋
        </h1>
        <span>Your billing date is {{ accountBillingDate }}</span>
      </div>
    </div>
    <div class="flex flex-col lg:flex-row">
      <div
        class="w-full transition bg-white rounded-xl flex flex-row p-4 shadow-lg mb-2 justify-between items-center hover:shadow-xl lg:mb-0 lg:flex-col lg:items-baseline"
      >
        <img src="../assets/truck-icon.svg" class="hidden w-12 mb-4 lg:block" />
        <div>
          <div class="mb-2">
            <span class="text-gray-600 text-sm">
              Your Total Active Vehicles
            </span>
          </div>
          <span class="font-bold text-gray-800 text-3xl">
            {{ activeVehicles.length }}
          </span>
        </div>
        <img src="../assets/truck-icon.svg" class="w-12 lg:hidden" />
      </div>
      <div
        class="w-full transition bg-white rounded-xl flex flex-row p-4 shadow-lg mb-2 justify-between items-center hover:shadow-xl lg:mb-0 lg:flex-col lg:items-baseline lg:ml-2"
      >
        <img
          src="../assets/truck-shield.svg"
          class="hidden w-12 mb-4 lg:block"
        />
        <div>
          <div class="mb-2">
            <span class="text-gray-600 text-sm"> Active Episodes </span>
          </div>
          <span class="font-bold text-gray-800 text-3xl">
            {{ activeEpisodes.length }}
          </span>
          <span
            class="text-lula text-sm"
            v-if="activeVehicles.length > 1 || activeVehicles.length === 0"
          >
            Out of {{ activeVehicles.length }} Vehicles
          </span>
          <span class="text-lula text-sm" v-else>
            Out of {{ activeVehicles.length }} Vehicle
          </span>
        </div>
        <img src="../assets/truck-shield.svg" class="w-12 lg:hidden" />
      </div>
      <div
        class="w-full transition bg-white rounded-xl flex flex-row p-4 shadow-lg mb-2 justify-between items-center hover:shadow-xl lg:mb-0 lg:flex-col lg:items-baseline lg:ml-2"
      >
        <img
          src="../assets/purple-alert.svg"
          class="hidden w-12 mb-4 lg:block"
        />
        <div>
          <div class="mb-2">
            <span class="text-gray-600 text-sm"> Need to file a claim? </span>
          </div>
          <button
            @click="submitClaim"
            class="p-0 py-2 w-full bg-gray-50 text-gray-600 text-sm hover:bg-gray-100"
          >
            Submit a Claim
          </button>
        </div>
        <img src="../assets/purple-alert.svg" class="w-12 lg:hidden" />
      </div>
    </div>
    <hr class="my-8" />
    <div>
      <div class="flex justify-between items-center mb-2">
        <h1 class="text-gray-800 text-2xl font-bold">Episode Summary</h1>
        <!-- <button
          @click="$router.push({ name: `${account.type}/Episodes` })"
          class="hidden bg-lula-gradient text-white font-bold hover:bg-lula-gradient-alt sm:block"
        >
          Book an Episode
        </button> -->
      </div>
      <div class="pb-4">
        <div ref="episodesummary" />
        <button
          @click="$router.push({ name: `${account.type}/Episodes` })"
          class="w-full mt-2 bg-lula-gradient text-white font-bold hover:bg-lula-gradient-alt sm:hidden"
        >
          Book an Episode
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Subject } from "rxjs";
import {
  initializeShadowDom,
  fetchFrontend,
  mountFrontend,
} from "@getaddify/lula-components";
import { mapVehiclesToEpisodes, getBillingDate } from "@/utils";
import moment from "moment";
import { useAuth } from "../auth";

export default {
  setup() {
    const store = useStore();
    const router = useRouter();

    const summary = new Subject();
    const episodesummary = ref(null);
    const lulaBucksOptIn = ref(null);

    const account = ref(store.state.account);

    onMounted(() => {
      router.replace({ name: `${account.value.type}/Dashboard` });
    });

    onMounted(async () => {
      const [episodeSummaryShadow, episodeSummaryRoot] = initializeShadowDom(
        episodesummary.value,
      );
      await fetchFrontend(
        episodeSummaryShadow,
        process.env["VUE_APP_EPISODES_UI_URL"],
      );
      const auth = await useAuth();
      const headers = await auth.requestHeaders();

      mountFrontend("episode-summary-grid", episodeSummaryRoot, {
        summary,
        emptyMessage: "No Episodes",
        graphHeaders: headers,
        bookMore(vehicle) {
          router.push({
            name: `${store.state.account.type}/EpisodesScheduled`,
            query: { vehicle: vehicle.entityId },
          });
        },
        selectEpisode(vehicle) {
          if (vehicle.currentEpisode) {
            router.push({
              name: `${store.state.account.type}/Episode`,
              params: { id: vehicle.currentEpisode.entityId },
            });
          } else if (vehicle.upcomingEpisode) {
            router.push({
              name: `${store.state.account.type}/Episode`,
              params: { id: vehicle.upcomingEpisode.entityId },
            });
          } else {
            router.push({
              name: `${store.state.account.type}/Vehicle`,
              params: { id: vehicle.entityId },
            });
          }
        },
      });

      const activeVehicles = store.state.vehicles.filter(
        (v) => v.status === "Active",
      );
      summary.next(mapVehiclesToEpisodes(activeVehicles, store.state.episodes));
    });

    onMounted(async () => {
      const [billingShadow, billingRoot] = initializeShadowDom(
        lulaBucksOptIn.value,
      );
      await fetchFrontend(billingShadow, process.env["VUE_APP_BILLING_UI_URL"]);
      mountFrontend("opt-in", billingRoot, {
        tenantId: store.state.account.entityId,
        graphUrl: process.env["VUE_APP_BILLING_GRAPH"],
        graphHeaders: headers,
        defaultEpisodePrice: parseFloat(store.state.account.episodePrice),
        activeVehicleCount: store.state.vehicles.filter(
          (v) => v.status === "Active",
        ).length,
      });
    });

    return {
      episodesummary,
      lulaBucksOptIn,
      account,
      displayDate: computed(() => moment().format("dddd, MMMM Do YYYY")),
      activeVehicles: computed(() => {
        return store.state.vehicles.filter((v) => v.status === "Active");
      }),
      submitClaim() {
        router.push({ name: `${store.state.account.type}/InsuranceClaims` });
      },
      activeEpisodes: computed(() => {
        return mapVehiclesToEpisodes(
          store.state.vehicles,
          store.state.episodes,
        ).filter((v) => v.currentEpisode);
      }),
      accountBillingDate: computed(() => {
        return getBillingDate(account);
      }),
    };
  },
};
</script>
